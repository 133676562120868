//@ts-nocheck
export default {
  glyphs: {
    0: {
      ha: 770,
      x_min: 81,
      x_max: 688,
      o: 'm 688 412 q 612 95 688 203 q 386 -14 535 -14 q 161 93 239 -14 q 81 401 83 199 l 81 578 q 159 894 81 787 q 385 1001 236 1001 q 608 898 531 1001 q 688 593 686 794 l 688 412 m 607 589 q 552 845 607 758 q 385 932 496 932 q 219 847 275 932 q 162 597 163 762 l 162 403 q 219 144 162 234 q 386 55 277 55 q 550 142 494 55 q 607 399 606 230 l 607 589 z ',
    },
    1: {
      ha: 770,
      x_min: 121,
      x_max: 471,
      o: 'm 471 0 l 389 0 l 389 890 l 121 790 l 121 866 l 457 991 l 471 991 l 471 0 z ',
    },
    2: {
      ha: 770,
      x_min: 71,
      x_max: 711,
      o: 'm 711 0 l 93 0 l 93 62 l 431 446 q 548 606 515 543 q 581 729 581 669 q 526 877 581 823 q 374 932 471 932 q 213 868 275 932 q 152 703 152 804 l 71 703 q 109 854 71 786 q 216 962 146 923 q 374 1001 286 1001 q 586 930 510 1001 q 663 736 663 859 q 620 585 663 667 q 468 380 576 503 l 193 68 l 711 68 l 711 0 z ',
    },
    3: {
      ha: 770,
      x_min: 66,
      x_max: 686,
      o: 'm 273 538 l 357 538 q 479 563 427 538 q 559 630 532 587 q 587 730 587 673 q 533 878 587 825 q 379 932 479 932 q 223 876 284 932 q 163 728 163 820 l 81 728 q 120 868 81 805 q 227 966 159 932 q 379 1001 296 1001 q 591 928 513 1001 q 668 727 668 854 q 623 596 668 656 q 499 506 578 536 q 639 417 591 480 q 686 268 686 355 q 604 64 686 141 q 382 -14 521 -14 q 221 22 294 -14 q 107 120 147 57 q 66 268 66 184 l 148 268 q 214 114 148 174 q 382 55 279 55 q 546 109 488 55 q 605 265 605 163 q 542 416 605 364 q 355 469 478 469 l 273 469 l 273 538 z ',
    },
    4: {
      ha: 770,
      x_min: 45,
      x_max: 745,
      o: 'm 591 318 l 745 318 l 745 250 l 591 250 l 591 0 l 510 0 l 510 250 l 45 250 l 45 296 l 503 987 l 591 987 l 591 318 m 145 318 l 510 318 l 510 882 l 474 820 l 145 318 z ',
    },
    5: {
      ha: 770,
      x_min: 114,
      x_max: 709,
      o: 'm 148 506 l 196 987 l 690 987 l 690 912 l 266 912 l 231 578 q 417 633 311 633 q 630 546 551 633 q 709 315 709 459 q 632 73 709 159 q 414 -14 555 -14 q 205 58 286 -14 q 114 260 125 130 l 192 260 q 260 107 203 159 q 414 55 317 55 q 574 122 520 55 q 628 313 628 189 q 568 490 628 422 q 403 559 507 559 q 302 544 345 559 q 212 488 260 530 l 148 506 z ',
    },
    6: {
      ha: 770,
      x_min: 88,
      x_max: 700,
      o: 'm 572 995 l 572 924 l 562 924 q 283 820 385 924 q 171 530 182 715 q 275 617 212 587 q 416 648 338 648 q 623 558 546 648 q 700 323 700 469 q 664 150 700 227 q 561 30 628 73 q 408 -14 494 -14 q 177 89 266 -14 q 88 355 88 192 l 88 436 q 209 847 88 701 q 551 995 330 992 l 572 995 m 403 578 q 259 533 326 578 q 170 416 192 487 l 170 347 q 236 137 170 218 q 408 56 302 56 q 561 131 503 56 q 620 321 620 206 q 561 508 620 437 q 403 578 503 578 z ',
    },
    7: {
      ha: 770,
      x_min: 52,
      x_max: 701,
      o: 'm 701 941 l 279 0 l 195 0 l 614 918 l 52 918 l 52 987 l 701 987 l 701 941 z ',
    },
    8: {
      ha: 770,
      x_min: 72,
      x_max: 701,
      o: 'm 675 733 q 630 596 675 656 q 512 507 585 535 q 649 412 597 477 q 701 262 701 347 q 614 61 701 135 q 387 -14 528 -14 q 158 60 243 -14 q 72 262 72 134 q 121 412 72 346 q 257 507 171 477 q 140 596 184 535 q 97 733 97 656 q 176 928 97 856 q 385 1001 255 1001 q 595 928 515 1001 q 675 733 675 854 m 619 261 q 553 413 619 353 q 385 472 488 472 q 218 414 283 472 q 153 261 153 355 q 216 111 153 167 q 387 55 279 55 q 555 111 492 55 q 619 261 619 166 m 593 734 q 535 875 593 819 q 385 932 476 932 q 236 877 293 932 q 178 734 178 823 q 236 594 178 647 q 386 541 293 541 q 535 594 477 541 q 593 734 593 648 z ',
    },
    9: {
      ha: 770,
      x_min: 68,
      x_max: 681,
      o: 'm 600 457 q 492 358 556 393 q 352 323 427 323 q 203 367 268 323 q 104 487 139 410 q 68 654 68 564 q 106 832 68 752 q 211 957 144 912 q 367 1001 279 1001 q 598 897 515 1001 q 681 602 681 793 l 681 528 q 567 127 681 261 q 219 -7 453 -7 l 204 -7 l 205 63 l 233 63 q 503 163 410 66 q 600 457 595 261 m 362 393 q 508 442 444 393 q 600 568 573 490 l 600 614 q 537 848 600 765 q 368 931 475 931 q 210 853 272 931 q 149 658 149 776 q 208 469 149 545 q 362 393 267 393 z ',
    },
    '\u0000': { ha: 0, x_min: 0, x_max: 0, o: '' },
    '\u0002': { ha: 0, x_min: 0, x_max: 0, o: '' },
    '\r': { ha: 338, x_min: 0, x_max: 0, o: '' },
    ' ': { ha: 338, x_min: 0, x_max: 0, o: '' },
    '!': {
      ha: 313,
      x_min: 104,
      x_max: 216,
      o: 'm 193 275 l 117 275 l 113 987 l 197 987 l 193 275 m 104 47 q 119 86 104 71 q 159 102 134 102 q 200 86 185 102 q 216 47 216 71 q 200 10 216 25 q 159 -5 185 -5 q 119 10 134 -5 q 104 47 104 25 z ',
    },
    '"': {
      ha: 399,
      x_min: 97,
      x_max: 325,
      o: 'm 165 947 l 151 734 l 97 734 l 99 1042 l 165 1042 l 165 947 m 325 947 l 311 734 l 256 734 l 259 1042 l 325 1042 l 325 947 z ',
    },
    '#': {
      ha: 808,
      x_min: 58,
      x_max: 814,
      o: 'm 511 278 l 298 278 l 245 0 l 178 0 l 231 278 l 58 278 l 58 341 l 242 341 l 298 640 l 109 640 l 109 705 l 311 705 l 364 987 l 431 987 l 378 705 l 591 705 l 645 987 l 713 987 l 659 705 l 814 705 l 814 640 l 647 640 l 591 341 l 764 341 l 764 278 l 578 278 l 526 0 l 458 0 l 511 278 m 309 341 l 523 341 l 579 640 l 366 640 l 309 341 z ',
    },
    $: {
      ha: 770,
      x_min: 78,
      x_max: 692,
      o: 'm 611 243 q 562 367 611 319 q 390 457 513 414 q 175 571 237 505 q 113 743 113 637 q 181 922 113 853 q 366 1000 249 991 l 366 1137 l 435 1137 l 435 1000 q 620 911 554 989 q 685 697 685 832 l 604 697 q 549 866 604 804 q 398 929 494 929 q 248 879 302 929 q 194 744 194 828 q 243 617 194 663 q 412 531 293 571 q 585 453 531 490 q 666 366 639 416 q 692 245 692 315 q 623 65 692 134 q 432 -12 553 -3 l 432 -141 l 363 -141 l 363 -13 q 153 73 227 -4 q 78 283 78 150 l 159 283 q 221 118 159 178 q 393 58 283 58 q 550 109 490 58 q 611 243 611 161 z ',
    },
    '%': {
      ha: 1026,
      x_min: 74,
      x_max: 958,
      o: 'm 74 798 q 129 944 74 886 q 267 1002 183 1002 q 406 944 351 1002 q 460 793 460 886 l 460 745 q 407 601 460 659 q 269 543 353 543 q 130 600 185 543 q 74 750 74 656 l 74 798 m 140 745 q 175 642 140 682 q 269 601 210 601 q 360 642 326 601 q 395 748 395 683 l 395 798 q 359 902 395 861 q 267 943 324 943 q 175 902 211 943 q 140 793 140 861 l 140 745 m 571 242 q 626 388 571 330 q 764 446 680 446 q 902 388 847 446 q 958 237 958 331 l 958 189 q 903 43 958 101 q 765 -14 848 -14 q 626 43 682 -14 q 571 193 571 100 l 571 242 m 636 189 q 671 85 636 125 q 765 44 706 44 q 858 85 823 44 q 892 193 892 126 l 892 242 q 857 347 892 307 q 764 387 822 387 q 671 347 707 387 q 636 239 636 307 l 636 189 m 294 82 l 243 115 l 726 886 l 777 854 l 294 82 z ',
    },
    '&': {
      ha: 854,
      x_min: 73,
      x_max: 835,
      o: 'm 274 538 q 188 666 215 610 q 161 777 161 722 q 223 941 161 880 q 389 1001 285 1001 q 541 946 483 1001 q 600 808 600 892 q 487 616 600 710 l 378 532 l 650 216 q 711 441 711 315 l 787 441 q 700 157 787 273 l 835 0 l 737 0 l 652 99 q 528 16 598 46 q 380 -14 458 -14 q 156 59 239 -14 q 73 252 73 131 q 116 388 73 323 q 264 532 158 452 l 274 538 m 380 55 q 499 81 441 55 q 604 155 557 107 l 328 475 l 318 486 l 287 462 q 154 252 154 353 q 215 109 154 163 q 380 55 276 55 m 243 779 q 334 584 243 696 l 423 652 q 494 720 467 683 q 522 808 522 758 q 486 896 522 861 q 388 932 449 932 q 282 889 321 932 q 243 779 243 846 z ',
    },
    "'": {
      ha: 236,
      x_min: 81,
      x_max: 153,
      o: 'm 153 946 l 142 739 l 81 739 q 84 1042 84 940 l 153 1042 l 153 946 z ',
    },
    '(': {
      ha: 443,
      x_min: 95,
      x_max: 421,
      o: 'm 95 399 q 133 686 95 547 q 244 941 171 825 q 401 1107 317 1057 l 421 1052 q 332 959 376 1020 q 254 814 288 898 q 198 625 219 730 q 176 387 176 520 q 209 115 176 245 q 297 -116 241 -16 q 421 -267 353 -216 l 401 -319 q 242 -152 315 -267 q 132 101 169 -37 q 95 399 95 239 z ',
    },
    ')': {
      ha: 452,
      x_min: 22,
      x_max: 349,
      o: 'm 349 389 q 312 101 349 239 q 202 -151 275 -37 q 42 -319 130 -266 l 22 -267 q 145 -119 89 -219 q 235 113 202 -20 q 267 401 267 245 q 235 672 267 541 q 145 905 202 802 q 22 1055 87 1008 l 42 1107 q 200 940 127 1055 q 311 686 273 825 q 349 389 349 546 z ',
    },
    '*': {
      ha: 589,
      x_min: 20,
      x_max: 571,
      o: 'm 245 678 l 20 751 l 41 820 l 267 736 l 264 987 l 334 987 l 329 734 l 549 821 l 571 752 l 345 674 l 496 475 l 439 432 l 294 639 l 155 433 l 98 475 l 245 678 z ',
    },
    '+': {
      ha: 784,
      x_min: 51,
      x_max: 728,
      o: 'm 427 502 l 728 502 l 728 426 l 427 426 l 427 99 l 345 99 l 345 426 l 51 426 l 51 502 l 345 502 l 345 818 l 427 818 l 427 502 z ',
    },
    ',': {
      ha: 266,
      x_min: 41,
      x_max: 184,
      o: 'm 89 -184 l 41 -149 q 104 22 102 -66 l 104 127 l 184 127 l 184 43 q 89 -184 184 -98 z ',
    },
    '-': {
      ha: 397,
      x_min: 33,
      x_max: 358,
      o: 'm 358 401 l 33 401 l 33 470 l 358 470 l 358 401 z ',
    },
    '.': {
      ha: 332,
      x_min: 98,
      x_max: 214,
      o: 'm 98 49 q 114 89 98 73 q 156 106 129 106 q 199 89 183 106 q 214 49 214 73 q 199 10 214 25 q 156 -5 183 -5 q 114 10 129 -5 q 98 49 98 25 z ',
    },
    '/': {
      ha: 551,
      x_min: 20,
      x_max: 506,
      o: 'm 94 -85 l 20 -85 l 433 987 l 506 987 l 94 -85 z ',
    },
    ':': {
      ha: 292,
      x_min: 86,
      x_max: 203,
      o: 'm 86 49 q 101 89 86 73 q 144 106 117 106 q 186 89 171 106 q 202 49 202 73 q 186 10 202 25 q 144 -5 171 -5 q 101 10 117 -5 q 86 49 86 25 m 87 675 q 103 715 87 699 q 145 732 118 732 q 188 715 172 732 q 203 675 203 699 q 188 636 203 651 q 145 621 172 621 q 103 636 118 621 q 87 675 87 651 z ',
    },
    ';': {
      ha: 271,
      x_min: 41,
      x_max: 196,
      o: 'm 80 675 q 95 715 80 699 q 138 732 111 732 q 180 715 165 732 q 196 675 196 699 q 180 636 196 651 q 138 621 165 621 q 95 636 111 621 q 80 675 80 651 m 89 -184 l 41 -149 q 104 22 102 -66 l 104 127 l 184 127 l 184 43 q 89 -184 184 -98 z ',
    },
    '<': {
      ha: 710,
      x_min: 52,
      x_max: 613,
      o: 'm 141 444 l 613 241 l 613 155 l 52 412 l 52 478 l 613 734 l 613 649 l 141 444 z ',
    },
    '=': {
      ha: 768,
      x_min: 101,
      x_max: 667,
      o: 'm 667 562 l 101 562 l 101 634 l 667 634 l 667 562 m 667 283 l 101 283 l 101 355 l 667 355 l 667 283 z ',
    },
    '>': {
      ha: 720,
      x_min: 84,
      x_max: 657,
      o: 'm 566 447 l 84 652 l 84 736 l 657 479 l 657 414 l 84 157 l 84 241 l 566 447 z ',
    },
    '?': {
      ha: 631,
      x_min: 57,
      x_max: 572,
      o: 'm 255 274 q 277 403 256 354 q 364 518 297 452 q 451 611 431 584 q 480 672 470 639 q 491 747 491 705 q 446 880 491 831 q 320 928 401 928 q 189 882 239 928 q 138 756 139 836 l 57 756 q 132 934 58 867 q 320 1001 206 1001 q 504 933 437 1001 q 572 748 572 865 q 538 611 572 675 q 412 461 505 547 q 336 274 336 397 l 255 274 m 244 47 q 259 86 244 71 q 300 102 273 102 q 341 86 326 102 q 356 47 356 71 q 341 10 356 25 q 300 -5 326 -5 q 259 10 273 -5 q 244 47 244 25 z ',
    },
    '@': {
      ha: 1268,
      x_min: 77,
      x_max: 1198,
      o: 'm 1190 348 q 1153 163 1186 248 q 1061 32 1120 78 q 925 -14 1002 -14 q 818 21 859 -14 q 763 118 777 56 q 561 -14 690 -14 q 419 68 466 -14 q 385 290 372 151 q 435 487 395 400 q 540 622 475 573 q 679 670 605 670 q 770 655 731 670 q 862 597 810 641 l 827 210 q 847 88 821 132 q 933 45 874 45 q 1068 126 1017 45 q 1126 348 1120 208 q 1022 761 1139 623 q 667 900 905 900 q 409 828 524 900 q 227 626 294 757 q 153 324 161 495 q 198 23 144 153 q 358 -176 251 -106 q 614 -246 466 -246 q 737 -231 677 -246 q 838 -193 798 -216 l 859 -247 q 751 -290 821 -273 q 612 -307 682 -307 q 320 -231 442 -307 q 137 -11 198 -155 q 85 324 77 132 q 168 658 93 513 q 373 883 243 804 q 669 963 503 963 q 962 891 842 963 q 1140 680 1082 819 q 1190 348 1198 541 m 456 194 q 494 89 464 126 q 575 52 525 52 q 760 225 701 52 l 791 575 q 684 608 745 608 q 553 548 608 608 q 472 383 498 488 q 456 194 446 277 z ',
    },
    A: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 z ',
    },
    B: {
      ha: 851,
      x_min: 125,
      x_max: 774,
      o: 'm 125 0 l 125 987 l 416 987 q 657 922 575 987 q 739 729 739 857 q 697 596 739 652 q 583 515 656 539 q 722 430 669 496 q 774 278 774 364 q 690 74 774 147 q 455 0 606 0 l 125 0 m 208 475 l 208 71 l 458 71 q 628 125 566 71 q 691 277 691 180 q 631 421 691 368 q 465 475 571 475 l 208 475 m 208 545 l 436 545 q 598 594 541 547 q 655 731 655 641 q 596 871 655 826 q 416 916 536 916 l 208 916 l 208 545 z ',
    },
    C: {
      ha: 902,
      x_min: 89,
      x_max: 824,
      o: 'm 824 308 q 712 69 807 152 q 460 -14 618 -14 q 266 41 351 -14 q 136 197 182 96 q 89 427 90 298 l 89 555 q 135 789 89 687 q 268 946 181 890 q 467 1001 354 1001 q 717 915 625 1001 q 824 678 809 829 l 740 678 q 467 930 709 930 q 253 829 332 930 q 173 552 173 729 l 173 431 q 251 159 173 260 q 460 57 328 57 q 658 120 591 57 q 740 308 724 182 l 824 308 z ',
    },
    D: {
      ha: 909,
      x_min: 125,
      x_max: 823,
      o: 'm 125 0 l 125 987 l 401 987 q 618 932 522 987 q 769 774 715 877 q 823 539 823 671 l 823 448 q 769 214 823 316 q 618 56 715 111 q 395 0 521 0 l 125 0 m 208 916 l 208 71 l 395 71 q 646 174 551 71 q 740 454 740 277 l 740 541 q 647 813 740 711 q 402 916 554 916 l 208 916 z ',
    },
    E: {
      ha: 790,
      x_min: 125,
      x_max: 739,
      o: 'm 670 473 l 208 473 l 208 71 l 739 71 l 739 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 544 l 670 544 l 670 473 z ',
    },
    F: {
      ha: 781,
      x_min: 125,
      x_max: 736,
      o: 'm 669 461 l 208 461 l 208 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 532 l 669 532 l 669 461 z ',
    },
    G: {
      ha: 949,
      x_min: 98,
      x_max: 838,
      o: 'm 838 117 q 702 21 794 56 q 494 -14 609 -14 q 288 42 378 -14 q 149 199 198 98 q 98 433 99 301 l 98 557 q 202 882 98 763 q 481 1001 306 1001 q 726 923 633 1001 q 837 709 819 846 l 753 709 q 665 874 735 818 q 481 930 595 930 q 262 831 343 930 q 182 554 182 733 l 182 437 q 220 238 182 325 q 330 104 258 152 q 494 57 401 57 q 680 91 602 57 q 754 143 730 113 l 754 398 l 489 398 l 489 469 l 838 469 l 838 117 z ',
    },
    H: {
      ha: 983,
      x_min: 125,
      x_max: 857,
      o: 'm 857 0 l 772 0 l 772 473 l 208 473 l 208 0 l 125 0 l 125 987 l 208 987 l 208 544 l 772 544 l 772 987 l 857 987 l 857 0 z ',
    },
    I: {
      ha: 370,
      x_min: 143,
      x_max: 227,
      o: 'm 227 0 l 143 0 l 143 987 l 227 987 l 227 0 z ',
    },
    J: {
      ha: 764,
      x_min: 48,
      x_max: 645,
      o: 'm 561 987 l 645 987 l 645 294 q 564 70 645 153 q 347 -14 483 -14 q 125 62 203 -14 q 48 272 48 137 l 132 272 q 188 111 132 165 q 347 57 244 57 q 500 119 441 57 q 561 289 559 182 l 561 987 z ',
    },
    K: {
      ha: 876,
      x_min: 125,
      x_max: 851,
      o: 'm 347 490 l 208 353 l 208 0 l 125 0 l 125 987 l 208 987 l 208 455 l 714 987 l 819 987 l 406 548 l 851 0 l 749 0 l 347 490 z ',
    },
    L: {
      ha: 732,
      x_min: 125,
      x_max: 696,
      o: 'm 209 71 l 696 71 l 696 0 l 125 0 l 125 987 l 209 987 l 209 71 z ',
    },
    M: {
      ha: 1202,
      x_min: 125,
      x_max: 1076,
      o: 'm 235 987 l 600 116 l 965 987 l 1076 987 l 1076 0 l 993 0 l 993 430 l 1000 871 l 632 0 l 568 0 l 201 867 l 208 433 l 208 0 l 125 0 l 125 987 l 235 987 z ',
    },
    N: {
      ha: 986,
      x_min: 125,
      x_max: 860,
      o: 'm 860 0 l 777 0 l 209 845 l 209 0 l 125 0 l 125 987 l 209 987 l 777 142 l 777 987 l 860 987 l 860 0 z ',
    },
    O: {
      ha: 940,
      x_min: 85,
      x_max: 854,
      o: 'm 854 440 q 807 201 854 304 q 673 42 760 98 q 471 -14 585 -14 q 191 110 298 -14 q 85 444 85 234 l 85 546 q 133 785 85 681 q 268 945 180 889 q 469 1001 355 1001 q 670 946 583 1001 q 805 791 758 891 q 854 558 852 690 l 854 440 m 771 547 q 690 827 771 726 q 469 928 610 928 q 250 826 332 928 q 168 543 168 725 l 168 440 q 250 162 168 264 q 471 59 331 59 q 692 160 612 59 q 771 443 771 262 l 771 547 z ',
    },
    P: {
      ha: 855,
      x_min: 125,
      x_max: 793,
      o: 'm 208 402 l 208 0 l 125 0 l 125 987 l 461 987 q 704 909 615 987 q 793 692 793 830 q 708 478 793 553 q 459 402 622 402 l 208 402 m 208 473 l 461 473 q 646 530 583 473 q 710 691 710 588 q 647 854 710 793 q 467 916 583 915 l 208 916 l 208 473 z ',
    },
    Q: {
      ha: 940,
      x_min: 82,
      x_max: 852,
      o: 'm 852 440 q 802 194 852 299 q 660 36 752 90 l 840 -121 l 782 -172 l 581 2 q 467 -14 525 -14 q 267 42 355 -14 q 131 200 179 98 q 82 435 83 302 l 82 546 q 130 785 82 681 q 265 945 178 889 q 466 1001 353 1001 q 669 945 581 1001 q 804 786 756 890 q 852 547 852 682 l 852 440 m 768 547 q 688 827 768 726 q 466 928 607 928 q 247 826 329 928 q 165 543 165 725 l 165 440 q 247 162 165 264 q 467 59 328 59 q 688 160 608 59 q 768 442 768 261 l 768 547 z ',
    },
    R: {
      ha: 882,
      x_min: 123,
      x_max: 821,
      o: 'm 494 411 l 207 411 l 207 0 l 123 0 l 123 987 l 444 987 q 690 911 602 987 q 779 696 779 834 q 724 529 779 602 q 574 429 668 456 l 821 9 l 821 0 l 732 0 l 494 411 m 207 482 l 463 482 q 631 542 568 482 q 695 696 695 602 q 629 859 695 801 q 442 916 562 916 l 207 916 l 207 482 z ',
    },
    S: {
      ha: 823,
      x_min: 60,
      x_max: 768,
      o: 'm 684 243 q 626 373 684 325 q 415 464 568 420 q 191 557 262 507 q 91 746 91 629 q 184 930 91 859 q 422 1001 277 1001 q 598 963 521 1001 q 719 857 676 925 q 762 706 762 789 l 677 706 q 608 868 677 807 q 422 930 539 930 q 242 879 309 930 q 175 747 175 829 q 235 625 175 672 q 429 541 296 577 q 630 466 562 505 q 733 372 699 427 q 768 244 768 318 q 674 57 768 127 q 429 -14 581 -14 q 237 24 324 -14 q 105 128 150 61 q 60 282 60 195 l 143 282 q 221 117 143 178 q 429 57 299 57 q 614 108 544 57 q 684 243 684 159 z ',
    },
    T: {
      ha: 829,
      x_min: 35,
      x_max: 795,
      o: 'm 795 916 l 456 916 l 456 0 l 373 0 l 373 916 l 35 916 l 35 987 l 795 987 l 795 916 z ',
    },
    U: {
      ha: 913,
      x_min: 110,
      x_max: 805,
      o: 'm 805 987 l 805 313 q 761 140 804 214 q 638 26 718 66 q 457 -14 559 -14 q 207 72 301 -14 q 110 307 113 157 l 110 987 l 193 987 l 193 319 q 264 126 193 195 q 457 57 335 57 q 650 126 579 57 q 721 319 721 195 l 721 987 l 805 987 z ',
    },
    V: {
      ha: 857,
      x_min: 21,
      x_max: 836,
      o: 'm 422 122 l 428 101 l 434 122 l 745 987 l 836 987 l 469 0 l 389 0 l 21 987 l 112 987 l 422 122 z ',
    },
    W: {
      ha: 1245,
      x_min: 41,
      x_max: 1209,
      o: 'm 307 267 l 338 113 l 375 260 l 589 987 l 665 987 l 876 260 l 913 112 l 945 267 l 1124 987 l 1209 987 l 956 0 l 876 0 l 652 772 l 627 870 l 603 772 l 374 0 l 294 0 l 41 987 l 127 987 l 307 267 z ',
    },
    X: {
      ha: 850,
      x_min: 37,
      x_max: 813,
      o: 'm 425 570 l 703 987 l 803 987 l 476 500 l 813 0 l 713 0 l 425 431 l 136 0 l 37 0 l 375 500 l 47 987 l 147 987 l 425 570 z ',
    },
    Y: {
      ha: 831,
      x_min: 16,
      x_max: 812,
      o: 'm 414 449 l 716 987 l 812 987 l 456 372 l 456 0 l 372 0 l 372 372 l 16 987 l 115 987 l 414 449 z ',
    },
    Z: {
      ha: 831,
      x_min: 61,
      x_max: 772,
      o: 'm 162 71 l 772 71 l 772 0 l 61 0 l 61 63 l 647 916 l 79 916 l 79 987 l 750 987 l 750 928 l 162 71 z ',
    },
    '[': {
      ha: 333,
      x_min: 111,
      x_max: 334,
      o: 'm 334 1059 l 192 1059 l 192 -142 l 334 -142 l 334 -212 l 111 -212 l 111 1128 l 334 1128 l 334 1059 z ',
    },
    '\\': {
      ha: 547,
      x_min: 33,
      x_max: 524,
      o: 'm 33 987 l 112 987 l 524 -85 l 445 -85 l 33 987 z ',
    },
    ']': {
      ha: 333,
      x_min: 0,
      x_max: 224,
      o: 'm 0 1128 l 224 1128 l 224 -212 l 0 -212 l 0 -142 l 143 -142 l 143 1059 l 0 1059 l 0 1128 z ',
    },
    '^': {
      ha: 578,
      x_min: 52,
      x_max: 518,
      o: 'm 286 880 l 131 494 l 52 494 l 255 987 l 316 987 l 518 494 l 439 494 l 286 880 z ',
    },
    _: {
      ha: 600,
      x_min: 1,
      x_max: 598,
      o: 'm 598 -68 l 1 -68 l 1 0 l 598 0 l 598 -68 z ',
    },
    '`': {
      ha: 397,
      x_min: 70,
      x_max: 297,
      o: 'm 297 852 l 223 852 l 70 1042 l 168 1042 l 297 852 z ',
    },
    a: {
      ha: 744,
      x_min: 68,
      x_max: 656,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 z ',
    },
    b: {
      ha: 770,
      x_min: 105,
      x_max: 696,
      o: 'm 696 359 q 621 87 696 188 q 416 -14 545 -14 q 184 100 263 -14 l 181 0 l 105 0 l 105 1042 l 186 1042 l 186 627 q 415 747 263 747 q 621 648 545 747 q 696 372 696 549 l 696 359 m 615 374 q 559 598 615 519 q 400 677 503 677 q 268 638 322 677 q 186 526 214 600 l 186 195 q 401 57 247 57 q 558 136 502 57 q 615 374 615 216 z ',
    },
    c: {
      ha: 715,
      x_min: 64,
      x_max: 659,
      o: 'm 377 55 q 519 103 462 55 q 581 227 576 151 l 659 227 q 617 104 656 159 q 514 18 578 49 q 377 -14 450 -14 q 148 87 233 -14 q 64 357 64 187 l 64 381 q 102 573 64 490 q 211 702 140 656 q 376 747 281 747 q 575 675 497 747 q 659 486 654 604 l 581 486 q 519 625 576 572 q 376 678 463 678 q 206 599 267 678 q 145 376 145 519 l 145 353 q 206 134 145 212 q 377 55 267 55 z ',
    },
    d: {
      ha: 772,
      x_min: 73,
      x_max: 664,
      o: 'm 73 374 q 150 646 73 545 q 357 747 226 747 q 583 630 505 747 l 583 1042 l 664 1042 l 664 0 l 588 0 l 585 98 q 355 -14 507 -14 q 151 89 229 -14 q 73 364 73 191 l 73 374 m 155 359 q 212 138 155 219 q 370 57 268 57 q 583 189 520 57 l 583 534 q 372 677 520 677 q 212 596 269 677 q 155 359 155 516 z ',
    },
    e: {
      ha: 718,
      x_min: 61,
      x_max: 665,
      o: 'm 390 -14 q 221 33 296 -14 q 103 161 145 79 q 61 346 61 243 l 61 375 q 102 566 61 481 q 216 699 143 650 q 375 747 290 747 q 587 656 509 747 q 665 407 665 565 l 665 361 l 142 361 l 142 346 q 213 138 142 221 q 393 55 285 55 q 508 79 458 55 q 599 155 558 102 l 650 116 q 390 -14 560 -14 m 375 678 q 221 611 283 678 q 144 431 158 544 l 585 431 l 585 439 q 524 612 581 545 q 375 678 467 678 z ',
    },
    f: {
      ha: 460,
      x_min: 43,
      x_max: 467,
      o: 'm 164 0 l 164 667 l 43 667 l 43 734 l 164 734 l 164 823 q 221 996 164 935 q 381 1056 279 1056 q 467 1044 427 1056 l 461 977 q 387 985 427 985 q 283 943 320 985 q 245 825 245 901 l 245 734 l 420 734 l 420 667 l 245 667 l 245 0 l 164 0 z ',
    },
    g: {
      ha: 770,
      x_min: 73,
      x_max: 665,
      o: 'm 73 374 q 150 647 73 546 q 357 747 226 747 q 585 628 507 747 l 589 734 l 665 734 l 665 15 q 585 -210 665 -127 q 370 -292 506 -292 q 224 -258 294 -292 q 115 -167 155 -224 l 160 -118 q 365 -224 246 -224 q 524 -164 467 -224 q 583 3 581 -104 l 583 95 q 355 -14 505 -14 q 151 88 228 -14 q 73 363 73 190 l 73 374 m 155 359 q 212 138 155 219 q 370 57 268 57 q 583 191 520 57 l 583 532 q 500 640 554 603 q 372 677 447 677 q 212 596 269 677 q 155 359 155 516 z ',
    },
    h: {
      ha: 762,
      x_min: 106,
      x_max: 659,
      o: 'm 186 610 q 289 711 227 675 q 425 747 351 747 q 601 681 543 747 q 659 481 659 614 l 659 0 l 578 0 l 578 482 q 537 629 578 581 q 406 677 496 677 q 272 630 330 677 q 186 503 214 583 l 186 0 l 106 0 l 106 1042 l 186 1042 l 186 610 z ',
    },
    i: {
      ha: 311,
      x_min: 101,
      x_max: 213,
      o: 'm 197 0 l 115 0 l 115 734 l 197 734 l 197 0 m 101 946 q 116 985 101 969 q 157 1001 131 1001 q 198 985 182 1001 q 213 946 213 969 q 198 907 213 923 q 157 892 182 892 q 116 907 131 892 q 101 946 101 923 z ',
    },
    j: {
      ha: 317,
      x_min: -71,
      x_max: 210,
      o: 'm 193 734 l 193 -87 q 144 -243 193 -189 q 1 -296 96 -296 q -71 -283 -36 -296 l -69 -216 q -8 -225 -39 -225 q 113 -86 113 -225 l 113 734 l 193 734 m 153 1001 q 195 985 180 1001 q 210 946 210 969 q 195 907 210 923 q 153 892 180 892 q 113 907 127 892 q 98 946 98 923 q 113 985 98 969 q 153 1001 127 1001 z ',
    },
    k: {
      ha: 680,
      x_min: 106,
      x_max: 674,
      o: 'm 280 370 l 187 280 l 187 0 l 106 0 l 106 1042 l 187 1042 l 187 375 l 264 458 l 545 734 l 647 734 l 336 425 l 674 0 l 577 0 l 280 370 z ',
    },
    l: {
      ha: 311,
      x_min: 115,
      x_max: 197,
      o: 'm 197 0 l 115 0 l 115 1042 l 197 1042 l 197 0 z ',
    },
    m: {
      ha: 1231,
      x_min: 102,
      x_max: 1131,
      o: 'm 180 734 l 182 614 q 284 714 223 681 q 420 747 345 747 q 640 605 593 747 q 745 710 680 673 q 890 747 811 747 q 1131 490 1126 747 l 1131 0 l 1050 0 l 1050 484 q 1007 629 1049 582 q 871 677 966 677 q 724 620 784 675 q 656 486 663 565 l 656 0 l 575 0 l 575 490 q 531 630 574 584 q 396 677 487 677 q 264 633 319 677 q 183 503 210 589 l 183 0 l 102 0 l 102 734 l 180 734 z ',
    },
    n: {
      ha: 763,
      x_min: 106,
      x_max: 659,
      o: 'm 183 734 l 186 608 q 289 712 227 676 q 425 747 351 747 q 601 681 543 747 q 659 481 659 614 l 659 0 l 578 0 l 578 482 q 537 629 578 581 q 406 677 496 677 q 272 630 330 677 q 186 503 214 583 l 186 0 l 106 0 l 106 734 l 183 734 z ',
    },
    o: {
      ha: 778,
      x_min: 61,
      x_max: 716,
      o: 'm 61 378 q 102 568 61 484 q 218 700 143 653 q 388 747 293 747 q 625 645 534 747 q 716 372 716 542 l 716 355 q 675 164 716 249 q 559 33 634 79 q 389 -14 485 -14 q 152 89 243 -14 q 61 361 61 192 l 61 378 m 142 355 q 211 139 142 224 q 389 55 279 55 q 567 139 499 55 q 635 362 635 224 l 635 378 q 604 532 635 462 q 517 640 573 602 q 388 678 460 678 q 211 593 279 678 q 142 370 142 508 l 142 355 z ',
    },
    p: {
      ha: 770,
      x_min: 105,
      x_max: 696,
      o: 'm 696 359 q 620 87 696 188 q 416 -14 544 -14 q 186 92 266 -14 l 186 -282 l 105 -282 l 105 734 l 180 734 l 184 630 q 414 747 264 747 q 621 648 546 747 q 696 371 696 548 l 696 359 m 614 374 q 557 595 614 514 q 396 677 499 677 q 268 641 321 677 q 186 536 214 605 l 186 184 q 269 88 215 121 q 397 55 323 55 q 557 137 500 55 q 614 374 614 218 z ',
    },
    q: {
      ha: 774,
      x_min: 73,
      x_max: 664,
      o: 'm 73 374 q 150 647 73 546 q 358 747 226 747 q 584 634 507 747 l 588 734 l 664 734 l 664 -282 l 583 -282 l 583 91 q 357 -14 503 -14 q 151 88 228 -14 q 73 363 73 190 l 73 374 m 155 359 q 213 136 155 217 q 372 55 270 55 q 583 182 517 55 l 583 541 q 498 643 552 607 q 373 678 444 678 q 213 597 271 678 q 155 359 155 516 z ',
    },
    r: {
      ha: 467,
      x_min: 106,
      x_max: 447,
      o: 'm 445 665 q 390 669 419 669 q 261 627 314 669 q 186 504 209 585 l 186 0 l 106 0 l 106 734 l 185 734 l 186 617 q 393 747 251 747 q 447 739 427 747 l 445 665 z ',
    },
    s: {
      ha: 703,
      x_min: 68,
      x_max: 627,
      o: 'm 545 186 q 497 283 545 247 q 351 341 448 320 q 199 388 253 361 q 119 454 145 415 q 93 547 93 492 q 165 691 93 634 q 351 747 238 747 q 548 687 474 747 q 623 528 623 626 l 541 528 q 487 635 541 593 q 351 678 433 678 q 223 643 271 678 q 174 550 174 607 q 215 465 174 495 q 362 410 255 435 q 522 356 469 385 q 601 288 575 328 q 627 191 627 248 q 552 42 627 98 q 356 -14 477 -14 q 148 48 228 -14 q 68 205 68 111 l 150 205 q 210 95 155 134 q 356 55 265 55 q 493 92 441 55 q 545 186 545 130 z ',
    },
    t: {
      ha: 446,
      x_min: 16,
      x_max: 397,
      o: 'm 232 922 l 232 734 l 384 734 l 384 667 l 232 667 l 232 178 q 254 87 232 117 q 328 58 276 58 q 393 64 348 58 l 397 -2 q 310 -14 365 -14 q 189 35 227 -14 q 151 178 151 83 l 151 667 l 16 667 l 16 734 l 151 734 l 151 922 l 232 922 z ',
    },
    u: {
      ha: 763,
      x_min: 103,
      x_max: 658,
      o: 'm 578 93 q 344 -14 505 -14 q 165 55 227 -14 q 103 256 104 123 l 103 734 l 184 734 l 184 267 q 353 57 184 57 q 576 203 530 57 l 576 734 l 658 734 l 658 0 l 579 0 l 578 93 z ',
    },
    v: {
      ha: 668,
      x_min: 26,
      x_max: 641,
      o: 'm 334 112 l 557 734 l 641 734 l 367 0 l 301 0 l 26 734 l 109 734 l 334 112 z ',
    },
    w: {
      ha: 1047,
      x_min: 43,
      x_max: 999,
      o: 'm 281 169 l 294 106 l 310 172 l 486 734 l 555 734 l 730 177 l 749 100 l 764 171 l 915 734 l 999 734 l 785 0 l 716 0 l 528 582 l 519 622 l 510 581 l 325 0 l 256 0 l 43 734 l 126 734 l 281 169 z ',
    },
    x: {
      ha: 675,
      x_min: 33,
      x_max: 642,
      o: 'm 336 436 l 534 734 l 631 734 l 382 374 l 642 0 l 546 0 l 337 311 l 128 0 l 33 0 l 292 374 l 43 734 l 138 734 l 336 436 z ',
    },
    y: {
      ha: 660,
      x_min: 22,
      x_max: 640,
      o: 'm 338 117 l 553 734 l 640 734 l 327 -125 l 311 -163 q 124 -296 250 -296 q 62 -287 95 -296 l 61 -220 l 103 -224 q 200 -194 163 -224 q 262 -93 237 -165 l 298 6 l 22 734 l 111 734 l 338 117 z ',
    },
    z: {
      ha: 675,
      x_min: 59,
      x_max: 636,
      o: 'm 159 68 l 636 68 l 636 0 l 59 0 l 59 60 l 504 664 l 73 664 l 73 734 l 606 734 l 606 673 l 159 68 z ',
    },
    '{': {
      ha: 458,
      x_min: 46,
      x_max: 452,
      o: 'm 432 -274 q 260 -163 318 -240 q 203 40 203 -87 l 203 190 q 46 368 203 368 l 46 439 q 203 616 203 439 l 203 771 q 260 972 203 895 q 432 1083 317 1048 l 450 1029 q 284 764 284 977 l 284 620 q 159 404 284 453 q 284 188 284 351 l 284 33 q 452 -220 287 -165 l 432 -274 z ',
    },
    '|': {
      ha: 307,
      x_min: 121,
      x_max: 189,
      o: 'm 189 -183 l 121 -183 l 121 987 l 189 987 l 189 -183 z ',
    },
    '}': {
      ha: 458,
      x_min: 6,
      x_max: 412,
      o: 'm 6 -220 q 174 33 171 -165 l 174 185 q 304 403 174 357 q 174 619 174 449 l 174 764 q 8 1029 174 978 l 26 1083 q 198 971 142 1048 q 255 767 255 893 l 255 616 q 412 439 255 439 l 412 368 q 255 190 255 368 l 255 40 q 197 -163 255 -87 q 26 -274 140 -240 l 6 -220 z ',
    },
    '~': {
      ha: 951,
      x_min: 98,
      x_max: 850,
      o: 'm 850 518 q 794 352 850 417 q 650 287 737 287 q 558 306 601 287 q 454 379 516 324 q 363 447 393 433 q 299 461 333 461 q 205 421 239 461 q 172 305 172 381 l 98 304 q 153 470 98 406 q 299 534 208 534 q 394 513 349 534 q 502 436 439 492 q 580 376 547 393 q 650 359 612 359 q 745 401 709 359 q 780 518 780 444 l 850 518 z ',
    },
    '¡': {
      ha: 307,
      x_min: 95,
      x_max: 207,
      o: 'm 118 468 l 193 468 l 198 -243 l 114 -243 l 118 468 m 207 693 q 192 654 207 670 q 151 639 177 639 q 110 654 125 639 q 95 693 95 670 q 110 732 95 716 q 151 747 125 747 q 192 732 177 747 q 207 693 207 716 z ',
    },
    '¢': {
      ha: 756,
      x_min: 81,
      x_max: 676,
      o: 'm 394 55 q 535 102 477 55 q 598 227 592 149 l 676 227 q 601 66 671 132 q 431 -12 532 -1 l 431 -166 l 350 -166 l 350 -11 q 153 102 224 5 q 81 357 81 199 l 81 381 q 152 630 81 532 q 350 745 224 729 l 350 894 l 431 894 l 431 745 q 604 663 536 734 q 676 486 672 592 l 598 486 q 536 625 593 572 q 393 678 479 678 q 223 599 283 678 q 162 376 162 519 l 162 353 q 223 134 162 212 q 394 55 283 55 z ',
    },
    '£': {
      ha: 793,
      x_min: 63,
      x_max: 742,
      o: 'm 274 437 l 280 250 q 237 71 281 132 l 742 71 l 742 0 l 66 0 l 66 71 l 132 71 q 184 143 167 79 q 197 249 198 193 l 191 437 l 63 437 l 63 508 l 188 508 l 182 705 q 257 921 182 840 q 457 1001 332 1001 q 652 929 581 1001 q 724 738 724 858 l 640 738 q 589 880 640 829 q 451 930 538 930 q 316 870 366 930 q 266 705 266 810 l 272 508 l 505 508 l 505 437 l 274 437 z ',
    },
    '¤': {
      ha: 1004,
      x_min: 74,
      x_max: 932,
      o: 'm 767 90 q 646 14 714 41 q 502 -14 578 -14 q 237 90 349 -14 l 132 -18 l 74 41 l 182 150 q 98 412 98 264 q 188 682 98 566 l 74 798 l 132 857 l 245 742 q 502 837 357 837 q 759 741 647 837 l 874 858 l 932 798 l 817 680 q 905 412 905 564 q 822 152 905 267 l 932 41 l 874 -18 l 767 90 m 174 412 q 218 237 174 319 q 338 109 262 156 q 502 62 415 62 q 665 109 589 62 q 785 238 741 157 q 828 412 828 319 q 784 588 828 507 q 664 715 739 669 q 502 761 589 761 q 339 715 415 761 q 219 588 263 669 q 174 412 174 507 z ',
    },
    '¥': {
      ha: 716,
      x_min: 16,
      x_max: 699,
      o: 'm 357 496 l 605 987 l 699 987 l 428 478 l 640 478 l 640 418 l 397 418 l 397 279 l 640 279 l 640 220 l 397 220 l 397 0 l 314 0 l 314 220 l 74 220 l 74 279 l 314 279 l 314 418 l 74 418 l 74 478 l 286 478 l 16 987 l 111 987 l 357 496 z ',
    },
    '¦': {
      ha: 301,
      x_min: 108,
      x_max: 189,
      o: 'm 108 -183 l 108 340 l 189 340 l 189 -183 l 108 -183 m 189 473 l 108 473 l 108 987 l 189 987 l 189 473 z ',
    },
    '§': {
      ha: 840,
      x_min: 69,
      x_max: 759,
      o: 'm 759 292 q 717 177 759 224 q 602 108 675 130 q 696 28 663 75 q 729 -94 729 -20 q 644 -271 729 -205 q 411 -336 558 -336 q 272 -317 337 -336 q 160 -259 206 -298 q 69 -43 69 -182 l 151 -42 q 221 -207 151 -148 q 411 -267 291 -267 q 583 -219 519 -267 q 647 -96 647 -172 q 624 -12 647 -43 q 546 47 600 20 q 372 106 492 73 q 193 173 252 138 q 103 257 134 208 q 73 374 73 305 q 113 489 73 441 q 224 559 153 537 q 135 639 166 591 q 104 760 104 686 q 191 935 104 869 q 423 1001 279 1001 q 660 924 575 1001 q 744 709 744 848 l 663 709 q 597 870 663 808 q 423 932 532 932 q 248 885 311 932 q 185 761 185 839 q 206 675 185 707 q 279 618 227 644 q 438 562 330 592 q 635 492 571 527 q 729 409 699 457 q 759 292 759 361 m 308 523 q 195 475 235 514 q 155 375 155 435 q 179 286 155 319 q 257 228 203 254 q 450 164 312 202 l 512 145 q 633 193 588 154 q 677 290 677 232 q 632 397 677 357 q 469 475 587 437 l 308 523 z ',
    },
    '¨': {
      ha: 597,
      x_min: 93,
      x_max: 502,
      o: 'm 93 947 q 108 986 93 970 q 149 1002 123 1002 q 190 986 174 1002 q 205 947 205 970 q 190 909 205 924 q 149 893 174 893 q 108 909 123 893 q 93 947 93 924 m 390 946 q 405 985 390 969 q 446 1001 420 1001 q 487 985 471 1001 q 502 946 502 969 q 487 908 502 924 q 446 892 471 892 q 405 908 420 892 q 390 946 390 924 z ',
    },
    '©': {
      ha: 1110,
      x_min: 73,
      x_max: 1035,
      o: 'm 760 412 q 705 254 760 309 q 547 199 649 199 q 384 271 446 199 q 321 465 321 342 l 321 526 q 384 716 321 644 q 547 789 446 789 q 705 734 648 789 q 761 576 761 680 l 694 576 q 547 728 694 728 q 432 673 475 728 q 389 523 389 618 l 389 461 q 431 315 389 370 q 547 260 474 260 q 656 296 619 260 q 693 412 693 332 l 760 412 m 130 494 q 185 271 130 375 q 340 105 241 166 q 554 44 440 44 q 766 104 667 44 q 921 269 864 165 q 977 494 977 373 q 921 719 977 615 q 766 882 865 822 q 554 942 667 942 q 338 880 438 942 q 184 715 239 819 q 130 494 130 612 m 73 494 q 136 749 73 631 q 312 933 199 866 q 554 1001 424 1001 q 796 933 684 1001 q 971 749 908 866 q 1035 494 1035 631 q 974 244 1035 361 q 801 57 913 127 q 554 -14 688 -14 q 308 56 420 -14 q 134 243 196 126 q 73 494 73 359 z ',
    },
    ª: {
      ha: 614,
      x_min: 106,
      x_max: 515,
      o: 'm 440 478 q 426 542 430 507 q 275 469 367 469 q 151 508 196 469 q 106 616 106 548 q 163 732 106 690 q 330 773 220 773 l 424 773 l 424 814 q 397 904 424 872 q 315 936 370 936 q 219 912 254 936 q 184 839 184 888 l 111 843 q 168 957 111 912 q 315 1001 224 1001 q 448 952 399 1001 q 498 813 498 904 l 498 600 q 515 478 498 537 l 440 478 m 289 533 q 364 553 325 533 q 424 604 403 574 l 424 718 l 336 718 q 180 618 180 718 q 289 533 180 533 z ',
    },
    '«': {
      ha: 633,
      x_min: 83,
      x_max: 564,
      o: 'm 168 363 l 355 100 l 283 100 l 83 357 l 83 370 l 283 627 l 355 627 l 168 363 m 377 363 l 564 100 l 493 100 l 293 357 l 293 370 l 493 627 l 564 627 l 377 363 z ',
    },
    '¬': {
      ha: 758,
      x_min: 84,
      x_max: 635,
      o: 'm 635 262 l 553 262 l 553 454 l 84 454 l 84 526 l 635 526 l 635 262 z ',
    },
    '®': {
      ha: 1114,
      x_min: 69,
      x_max: 1032,
      o: 'm 69 494 q 133 749 69 631 q 308 933 196 866 q 551 1001 420 1001 q 793 933 681 1001 q 969 749 905 866 q 1032 494 1032 631 q 970 243 1032 359 q 797 56 909 126 q 551 -14 684 -14 q 305 56 417 -14 q 131 243 193 126 q 69 494 69 359 m 127 494 q 183 269 127 373 q 339 104 240 164 q 551 44 437 44 q 763 104 664 44 q 918 269 862 164 q 974 494 974 374 q 919 717 974 614 q 765 881 864 820 q 551 942 667 942 q 338 882 437 942 q 183 719 239 822 q 127 494 127 615 m 441 452 l 441 218 l 374 218 l 374 787 l 549 787 q 703 745 649 787 q 756 618 756 703 q 661 485 756 528 q 728 430 709 467 q 747 342 747 393 q 750 267 747 290 q 759 229 753 244 l 759 218 l 690 218 q 680 341 680 242 q 655 427 680 401 q 568 452 629 452 l 441 452 m 441 513 l 564 513 q 654 542 618 513 q 690 617 690 570 q 660 700 690 675 q 559 726 631 724 l 441 726 l 441 513 z ',
    },
    '¯': {
      ha: 593,
      x_min: 93,
      x_max: 513,
      o: 'm 513 911 l 93 911 l 93 977 l 513 977 l 513 911 z ',
    },
    '°': {
      ha: 525,
      x_min: 97,
      x_max: 427,
      o: 'm 427 831 q 379 713 427 761 q 263 665 331 665 q 146 713 195 665 q 97 831 97 762 q 146 951 97 901 q 263 1001 196 1001 q 379 951 330 1001 q 427 831 427 901 m 158 831 q 188 756 158 786 q 263 726 218 726 q 337 756 308 726 q 366 831 366 786 q 337 909 366 878 q 263 939 308 939 q 189 909 219 939 q 158 831 158 878 z ',
    },
    '±': {
      ha: 736,
      x_min: 57,
      x_max: 678,
      o: 'm 413 562 l 678 562 l 678 493 l 413 493 l 413 196 l 338 196 l 338 493 l 57 493 l 57 562 l 338 562 l 338 859 l 413 859 l 413 562 m 653 0 l 87 0 l 87 68 l 653 68 l 653 0 z ',
    },
    '²': {
      ha: 502,
      x_min: 52,
      x_max: 452,
      o: 'm 452 451 l 65 451 l 65 502 l 282 715 q 354 839 354 789 q 327 908 354 882 q 245 934 299 934 q 155 904 186 934 q 123 824 123 873 l 52 824 q 105 945 52 897 q 244 994 159 994 q 378 951 330 994 q 426 840 426 909 q 346 689 426 772 l 309 652 l 155 510 l 452 510 l 452 451 z ',
    },
    '³': {
      ha: 502,
      x_min: 48,
      x_max: 441,
      o: 'm 181 751 l 239 751 q 327 777 294 752 q 359 842 359 801 q 330 909 359 884 q 245 934 300 934 q 161 909 194 934 q 129 844 129 884 l 58 844 q 111 951 58 909 q 245 994 164 994 q 381 953 332 994 q 431 843 431 912 q 405 773 431 805 q 332 725 379 741 q 441 597 441 696 q 388 486 441 528 q 246 444 334 444 q 103 487 159 444 q 48 600 48 530 l 120 600 q 156 530 120 557 q 248 503 191 503 q 339 528 307 503 q 370 599 370 554 q 231 695 370 695 l 181 695 l 181 751 z ',
    },
    '´': {
      ha: 391,
      x_min: 84,
      x_max: 312,
      o: 'm 214 1042 l 312 1042 l 155 852 l 84 852 l 214 1042 z ',
    },
    µ: {
      ha: 773,
      x_min: 110,
      x_max: 664,
      o: 'm 191 734 l 191 302 q 233 118 191 180 q 369 55 276 55 q 511 94 458 55 q 583 212 563 133 l 583 734 l 664 734 l 664 0 l 590 0 l 585 104 q 374 -14 519 -14 q 191 71 250 -14 l 191 -282 l 110 -282 l 110 734 l 191 734 z ',
    },
    '¶': {
      ha: 660,
      x_min: 56,
      x_max: 543,
      o: 'm 462 0 l 462 353 l 389 353 q 212 392 287 353 q 96 504 136 431 q 56 670 56 576 q 146 901 56 814 q 391 987 237 987 l 543 987 l 543 0 l 462 0 z ',
    },
    '·': {
      ha: 341,
      x_min: 111,
      x_max: 226,
      o: 'm 111 486 q 125 526 111 510 q 168 543 140 543 q 210 526 195 543 q 226 486 226 510 q 210 447 226 463 q 168 431 195 431 q 125 447 140 431 q 111 486 111 463 z ',
    },
    '¸': {
      ha: 338,
      x_min: 66,
      x_max: 271,
      o: 'm 167 0 l 159 -43 q 271 -159 271 -58 q 217 -258 271 -222 q 71 -295 163 -295 l 66 -242 q 165 -220 127 -242 q 203 -161 203 -198 q 174 -106 203 -121 q 84 -86 144 -92 l 104 0 l 167 0 z ',
    },
    '¹': {
      ha: 502,
      x_min: 85,
      x_max: 315,
      o: 'm 315 451 l 243 451 l 243 901 l 85 853 l 85 914 l 305 987 l 315 987 l 315 451 z ',
    },
    º: {
      ha: 625,
      x_min: 92,
      x_max: 530,
      o: 'm 92 768 q 152 936 92 871 q 311 1001 212 1001 q 470 936 410 1001 q 530 764 530 871 l 530 701 q 471 532 530 597 q 312 468 411 468 q 152 532 212 468 q 92 704 92 596 l 92 768 m 165 701 q 203 578 165 623 q 312 532 241 532 q 418 577 379 532 q 457 703 457 622 l 457 768 q 418 891 457 846 q 311 936 378 936 q 204 890 243 936 q 165 764 165 844 l 165 701 z ',
    },
    '»': {
      ha: 629,
      x_min: 78,
      x_max: 566,
      o: 'm 150 640 l 350 380 l 350 367 l 150 110 l 78 110 l 265 373 l 78 640 l 150 640 m 366 640 l 566 380 l 566 367 l 366 110 l 294 110 l 481 373 l 294 640 l 366 640 z ',
    },
    '¼': {
      ha: 1006,
      x_min: 78,
      x_max: 949,
      o: 'm 307 450 l 235 450 l 235 900 l 78 852 l 78 913 l 298 986 l 307 986 l 307 450 m 281 87 l 231 120 l 713 892 l 763 859 l 281 87 m 863 186 l 949 186 l 949 127 l 863 127 l 863 0 l 791 0 l 791 127 l 521 127 l 518 172 l 787 535 l 863 535 l 863 186 m 595 186 l 791 186 l 791 447 l 770 413 l 595 186 z ',
    },
    '½': {
      ha: 1050,
      x_min: 68,
      x_max: 974,
      o: 'm 251 87 l 201 120 l 683 892 l 733 859 l 251 87 m 297 450 l 225 450 l 225 900 l 68 852 l 68 913 l 288 986 l 297 986 l 297 450 m 974 0 l 587 0 l 587 51 l 804 264 q 876 388 876 338 q 848 457 876 431 q 767 483 821 483 q 676 453 708 483 q 644 373 644 422 l 573 373 q 627 494 573 446 q 766 543 680 543 q 899 500 851 543 q 947 389 947 458 q 867 238 947 321 l 830 201 l 677 59 l 974 59 l 974 0 z ',
    },
    '¾': {
      ha: 1078,
      x_min: 80,
      x_max: 1027,
      o: 'm 378 87 l 328 120 l 810 892 l 861 859 l 378 87 m 941 186 l 1027 186 l 1027 127 l 941 127 l 941 0 l 869 0 l 869 127 l 599 127 l 596 172 l 865 535 l 941 535 l 941 186 m 673 186 l 869 186 l 869 447 l 848 413 l 673 186 m 213 751 l 271 751 q 358 777 326 752 q 391 842 391 801 q 361 909 391 884 q 277 934 332 934 q 193 909 226 934 q 161 844 161 884 l 90 844 q 143 951 90 909 q 277 994 196 994 q 413 953 363 994 q 463 843 463 912 q 437 773 463 805 q 363 725 411 741 q 473 597 473 696 q 420 486 473 528 q 278 444 366 444 q 135 487 191 444 q 80 600 80 530 l 152 600 q 188 530 152 557 q 280 503 223 503 q 371 528 339 503 q 402 599 402 554 q 262 695 402 695 l 213 695 l 213 751 z ',
    },
    '¿': {
      ha: 637,
      x_min: 60,
      x_max: 578,
      o: 'm 374 466 q 355 342 372 382 q 275 239 338 302 q 195 155 211 175 q 140 -5 140 83 q 186 -140 140 -93 q 318 -188 231 -188 q 447 -140 399 -188 q 496 -14 495 -93 l 578 -14 q 505 -193 576 -126 q 318 -260 434 -260 q 129 -192 197 -260 q 60 -7 60 -124 q 96 137 60 68 q 229 297 132 205 q 291 419 286 345 l 292 466 l 374 466 m 385 693 q 370 654 385 670 q 329 638 355 638 q 288 654 303 638 q 273 693 273 670 q 288 731 273 715 q 329 747 303 747 q 370 731 355 747 q 385 693 385 715 z ',
    },
    Æ: {
      ha: 1265,
      x_min: 12,
      x_max: 1236,
      o: 'm 1236 0 l 682 0 l 671 264 l 267 264 l 111 0 l 12 0 l 609 987 l 1195 987 l 1195 918 l 724 918 l 740 549 l 1139 549 l 1139 479 l 743 479 l 760 68 l 1236 68 l 1236 0 m 312 340 l 668 340 l 644 903 l 312 340 z ',
    },
    '×': {
      ha: 727,
      x_min: 63,
      x_max: 655,
      o: 'm 63 121 l 306 369 l 73 606 l 127 661 l 359 423 l 591 661 l 645 606 l 412 369 l 655 121 l 602 68 l 359 315 l 117 68 l 63 121 z ',
    },
    Ø: {
      ha: 940,
      x_min: 85,
      x_max: 864,
      o: 'm 854 440 q 807 201 854 304 q 673 42 760 98 q 471 -14 585 -14 q 254 52 345 -14 l 186 -56 l 115 -56 l 208 91 q 85 446 85 216 l 85 546 q 133 785 85 681 q 268 945 180 889 q 469 1001 355 1001 q 722 906 621 1001 l 794 1021 l 864 1021 l 763 860 q 854 547 854 738 l 854 440 m 168 440 q 251 159 168 263 l 680 839 q 469 928 599 928 q 250 826 332 928 q 168 543 168 725 l 168 440 m 771 547 q 717 787 771 690 l 294 116 q 471 59 367 59 q 692 160 612 59 q 771 443 771 262 l 771 547 z ',
    },
    Þ: {
      ha: 823,
      x_min: 124,
      x_max: 743,
      o: 'm 205 987 l 205 781 l 426 781 q 595 747 523 781 q 705 648 667 712 q 743 500 743 584 q 661 299 743 375 q 435 220 578 222 l 205 220 l 205 0 l 124 0 l 124 987 l 205 987 m 205 713 l 205 288 l 425 288 q 597 346 532 288 q 662 499 662 404 q 600 652 662 594 q 435 713 538 710 l 205 713 z ',
    },
    ß: {
      ha: 814,
      x_min: 102,
      x_max: 743,
      o: 'm 184 0 l 102 0 l 102 764 q 167 974 102 900 q 347 1047 232 1047 q 516 990 451 1047 q 581 839 581 932 q 571 767 581 800 q 538 691 562 734 q 506 619 514 647 q 497 559 497 591 q 525 486 497 521 q 618 402 553 450 q 713 308 683 354 q 743 208 743 261 q 671 47 743 109 q 488 -14 600 -14 q 370 3 431 -14 q 281 41 308 19 l 304 109 q 381 72 329 90 q 479 54 433 54 q 612 98 562 54 q 662 208 662 141 q 632 287 662 249 q 540 371 602 326 q 447 462 478 416 q 416 560 416 508 q 458 701 416 625 q 501 834 501 777 q 458 938 501 897 q 354 978 416 978 q 184 770 186 978 l 184 0 z ',
    },
    æ: {
      ha: 1175,
      x_min: 58,
      x_max: 1124,
      o: 'm 858 -14 q 696 23 764 -14 q 588 126 629 60 q 470 23 547 60 q 298 -14 393 -14 q 121 44 184 -14 q 58 199 58 101 q 133 366 58 305 q 347 429 208 427 l 530 429 l 530 488 q 487 628 530 578 q 358 678 444 678 q 214 634 270 678 q 157 519 157 590 l 77 528 q 156 686 77 625 q 358 747 235 747 q 503 711 443 747 q 590 603 563 675 q 695 709 631 671 q 838 747 759 747 q 1047 666 970 747 q 1124 437 1123 584 l 1124 365 l 611 365 l 611 345 q 676 132 611 209 q 858 55 741 55 q 1078 135 983 55 l 1109 76 q 858 -14 1011 -14 m 311 54 q 436 85 372 54 q 530 160 499 117 l 530 363 l 356 363 q 205 322 263 363 q 140 210 146 281 l 140 196 q 184 92 140 130 q 311 54 229 54 m 838 678 q 687 612 748 678 q 613 431 626 545 l 1044 431 l 1044 452 q 989 617 1044 557 q 838 678 935 678 z ',
    },
    ð: {
      ha: 808,
      x_min: 101,
      x_max: 715,
      o: 'm 550 884 q 715 437 711 714 l 715 363 q 677 169 715 255 q 571 34 640 82 q 417 -14 503 -14 q 257 28 329 -14 q 143 147 185 70 q 101 316 101 224 q 182 563 101 471 q 398 654 263 654 q 525 628 466 654 q 623 557 583 602 q 481 841 595 727 l 328 747 l 294 796 l 433 883 q 201 1000 340 955 l 227 1070 q 505 926 391 1021 l 636 1008 l 671 959 l 550 884 m 634 424 l 633 463 q 547 551 606 519 q 413 584 488 584 q 243 513 304 584 q 182 316 182 441 q 213 185 182 246 q 297 91 243 125 q 420 56 351 56 q 575 141 515 56 q 634 368 634 225 l 634 424 z ',
    },
    '÷': {
      ha: 789,
      x_min: 49,
      x_max: 726,
      o: 'm 726 437 l 49 437 l 49 515 l 726 515 l 726 437 m 336 738 q 351 778 336 762 q 393 794 366 794 q 435 778 420 794 q 451 738 451 762 q 435 698 451 714 q 393 682 420 682 q 351 698 366 682 q 336 738 336 714 m 336 197 q 351 237 336 221 q 393 254 366 254 q 435 237 420 254 q 451 197 451 221 q 435 158 451 174 q 393 142 420 142 q 351 158 366 142 q 336 197 336 174 z ',
    },
    ø: {
      ha: 773,
      x_min: 60,
      x_max: 715,
      o: 'm 60 378 q 101 568 60 484 q 217 700 142 653 q 387 747 292 747 q 532 715 465 747 l 585 823 l 649 823 l 581 685 q 680 557 645 636 q 715 378 715 477 l 715 355 q 674 164 715 250 q 558 33 633 79 q 389 -14 484 -14 q 253 14 315 -14 l 199 -95 l 136 -95 l 203 43 q 98 172 135 91 q 60 355 60 252 l 60 378 m 141 355 q 165 216 141 281 q 236 111 189 152 l 500 649 q 387 678 449 678 q 210 593 278 678 q 141 370 141 508 l 141 355 m 634 378 q 612 509 634 448 q 547 614 589 571 l 284 78 q 389 55 330 55 q 566 139 498 55 q 634 362 634 224 l 634 378 z ',
    },
    þ: {
      ha: 780,
      x_min: 108,
      x_max: 699,
      o: 'm 699 359 q 623 87 699 188 q 419 -14 547 -14 q 189 92 269 -14 l 189 -282 l 108 -282 l 108 1042 l 189 1042 l 189 632 q 418 747 269 747 q 624 648 548 747 q 699 372 699 549 l 699 359 m 618 374 q 560 595 618 514 q 399 677 503 677 q 271 641 325 677 q 189 536 218 605 l 189 183 q 271 89 218 122 q 401 55 325 55 q 561 137 503 55 q 618 374 618 218 z ',
    },
    đ: {
      ha: 795,
      x_min: 73,
      x_max: 782,
      o: 'm 782 852 l 664 852 l 664 0 l 588 0 l 585 98 q 355 -14 507 -14 q 151 89 229 -14 q 73 364 73 191 l 73 374 q 150 646 73 545 q 357 747 226 747 q 583 630 505 747 l 583 852 l 367 852 l 367 922 l 583 922 l 583 1042 l 664 1042 l 664 922 l 782 922 l 782 852 m 155 359 q 212 138 155 219 q 370 57 268 57 q 583 189 520 57 l 583 534 q 372 677 520 677 q 212 596 269 677 q 155 359 155 516 z ',
    },
    Ħ: {
      ha: 957,
      x_min: 16,
      x_max: 939,
      o: 'm 840 761 l 939 761 l 939 696 l 840 696 l 840 0 l 757 0 l 757 473 l 193 473 l 193 0 l 109 0 l 109 696 l 16 696 l 16 761 l 109 761 l 109 987 l 193 987 l 193 761 l 757 761 l 757 987 l 840 987 l 840 761 m 193 544 l 757 544 l 757 696 l 193 696 l 193 544 z ',
    },
    ı: {
      ha: 309,
      x_min: 114,
      x_max: 195,
      o: 'm 195 0 l 114 0 l 114 734 l 195 734 l 195 0 z ',
    },
    ĸ: {
      ha: 753,
      x_min: 110,
      x_max: 716,
      o: 'm 285 336 l 192 336 l 192 0 l 110 0 l 110 734 l 192 734 l 192 408 l 283 408 l 583 734 l 682 734 l 357 376 l 716 0 l 613 0 l 285 336 z ',
    },
    Ł: {
      ha: 734,
      x_min: 14,
      x_max: 693,
      o: 'm 206 554 l 414 620 l 414 559 l 206 493 l 206 71 l 693 71 l 693 0 l 122 0 l 122 466 l 14 432 l 14 493 l 122 527 l 122 987 l 206 987 l 206 554 z ',
    },
    ł: {
      ha: 359,
      x_min: 17,
      x_max: 342,
      o: 'm 220 583 l 342 631 l 342 569 l 220 522 l 220 0 l 139 0 l 139 490 l 17 442 l 17 504 l 139 551 l 139 1042 l 220 1042 l 220 583 z ',
    },
    Ŋ: {
      ha: 972,
      x_min: 123,
      x_max: 852,
      o: 'm 852 987 l 852 -58 q 798 -235 852 -174 q 645 -296 743 -296 q 568 -283 606 -296 l 577 -214 q 644 -225 608 -225 q 736 -182 704 -225 q 768 -58 768 -138 l 768 0 l 206 848 l 206 0 l 123 0 l 123 987 l 206 987 l 768 140 l 768 987 l 852 987 z ',
    },
    ŋ: {
      ha: 773,
      x_min: 106,
      x_max: 665,
      o: 'm 179 734 l 186 609 q 421 747 260 747 q 604 674 543 747 q 665 454 664 601 l 665 -60 q 611 -236 665 -176 q 459 -296 556 -296 q 375 -283 416 -296 l 385 -214 q 459 -224 415 -224 q 551 -182 519 -224 q 584 -60 584 -140 l 584 446 q 541 620 584 563 q 403 677 498 677 q 270 639 324 677 q 188 525 215 601 l 188 0 l 106 0 l 106 734 l 179 734 z ',
    },
    Œ: {
      ha: 1297,
      x_min: 73,
      x_max: 1221,
      o: 'm 1221 0 l 649 0 q 438 -14 521 -14 q 250 36 332 -14 q 121 177 167 86 q 73 384 75 269 l 73 597 q 119 806 73 713 q 248 950 164 899 q 437 1001 331 1001 q 649 987 527 1001 l 1218 987 l 1218 916 l 690 916 l 690 544 l 1152 544 l 1152 473 l 690 473 l 690 71 l 1221 71 l 1221 0 m 438 54 q 607 64 528 54 l 607 922 q 437 932 527 932 q 231 842 307 932 q 155 600 155 753 l 155 391 q 231 145 155 237 q 438 54 308 54 z ',
    },
    œ: {
      ha: 1283,
      x_min: 64,
      x_max: 1236,
      o: 'm 64 378 q 105 570 64 484 q 220 701 146 655 q 388 747 294 747 q 559 700 484 747 q 675 563 633 653 q 789 698 716 648 q 947 747 861 747 q 1158 663 1080 747 q 1236 430 1236 578 l 1236 365 l 718 365 l 718 345 q 785 137 718 220 q 957 55 852 55 q 1177 136 1099 55 l 1213 80 q 957 -14 1122 -14 q 789 35 865 -14 q 675 168 714 84 q 559 33 633 80 q 389 -14 485 -14 q 154 89 243 -14 q 64 361 64 192 l 64 378 m 145 355 q 212 138 145 222 q 389 55 279 55 q 565 138 498 55 q 631 362 631 222 l 631 378 q 564 594 631 510 q 388 678 496 678 q 212 594 279 678 q 145 370 145 510 l 145 355 m 947 678 q 796 610 859 678 q 721 433 734 541 l 1155 433 l 1155 454 q 1098 615 1155 553 q 947 678 1041 678 z ',
    },
    ſ: {
      ha: 315,
      x_min: 113,
      x_max: 416,
      o: 'm 113 0 l 113 823 q 169 995 113 935 q 323 1056 224 1056 q 416 1043 367 1056 l 405 978 q 334 987 371 987 q 231 944 267 987 q 194 825 194 901 l 194 0 l 113 0 z ',
    },
    Ə: {
      ha: 972,
      x_min: 84,
      x_max: 890,
      o: 'm 492 -14 q 193 101 302 -14 q 84 420 84 216 l 84 500 l 806 500 l 806 546 q 716 828 806 726 q 464 929 627 929 q 186 848 304 929 l 159 910 q 464 1001 276 1001 q 776 878 662 1001 q 890 539 890 755 l 890 455 q 838 214 890 321 q 694 46 786 106 q 492 -14 602 -14 m 492 58 q 712 160 626 58 q 806 432 798 263 l 168 432 l 168 396 q 254 148 168 239 q 492 58 339 58 z ',
    },
    ƒ: {
      ha: 454,
      x_min: -40,
      x_max: 463,
      o: 'm 407 667 l 241 667 l 241 -60 q 187 -236 241 -176 q 35 -296 132 -296 q -40 -283 -3 -296 l -31 -216 q 35 -227 -5 -227 q 127 -184 95 -227 q 160 -60 160 -140 l 160 667 l 38 667 l 38 734 l 160 734 l 160 823 q 220 1000 160 939 q 370 1056 276 1056 q 463 1043 414 1056 l 452 976 q 378 985 422 985 q 277 943 312 985 q 241 825 241 901 l 241 734 l 407 734 l 407 667 z ',
    },
    Ơ: {
      ha: 968,
      x_min: 90,
      x_max: 982,
      o: 'm 859 440 q 812 201 859 304 q 677 42 765 98 q 475 -14 590 -14 q 274 42 362 -14 q 138 200 186 98 q 90 435 90 302 l 90 546 q 137 784 90 680 q 272 944 184 888 q 474 1001 360 1001 q 638 966 566 1001 q 762 867 711 931 q 871 915 835 870 q 907 1061 907 959 l 982 1061 q 939 886 982 951 q 801 807 896 821 q 859 555 857 701 l 859 440 m 777 547 q 696 827 777 726 q 474 928 615 928 q 255 826 336 928 q 173 543 173 725 l 173 440 q 254 162 173 264 q 475 59 336 59 q 696 160 616 59 q 777 442 777 261 l 777 547 z ',
    },
    ơ: {
      ha: 777,
      x_min: 60,
      x_max: 803,
      o: 'm 60 378 q 101 568 60 484 q 217 700 142 653 q 387 747 292 747 q 620 650 530 747 q 707 689 681 654 q 733 812 733 724 l 803 812 q 767 661 803 713 q 660 596 732 608 q 715 386 713 506 l 715 355 q 674 164 715 250 q 558 33 633 79 q 389 -14 484 -14 q 151 90 241 -14 q 60 361 60 193 l 60 378 m 141 355 q 209 139 141 224 q 389 55 277 55 q 566 139 498 55 q 634 362 634 224 l 634 378 q 566 593 634 508 q 387 678 497 678 q 210 593 278 678 q 141 370 141 508 l 141 355 z ',
    },
    Ư: {
      ha: 945,
      x_min: 110,
      x_max: 1028,
      o: 'm 805 987 l 805 844 q 921 896 886 850 q 956 1047 956 942 l 1028 1047 q 973 849 1028 913 q 805 781 918 786 l 805 313 q 761 140 804 214 q 638 26 718 66 q 457 -14 559 -14 q 207 72 301 -14 q 110 307 113 157 l 110 987 l 193 987 l 193 319 q 264 126 193 195 q 457 57 335 57 q 650 126 579 57 q 721 319 721 195 l 721 987 l 805 987 z ',
    },
    ư: {
      ha: 814,
      x_min: 103,
      x_max: 842,
      o: 'm 842 800 q 797 640 842 688 q 658 587 753 591 l 658 0 l 579 0 l 578 93 q 344 -14 505 -14 q 165 55 227 -14 q 103 256 104 123 l 103 734 l 184 734 l 184 267 q 353 57 184 57 q 576 203 530 57 l 576 734 l 658 734 l 658 644 q 764 703 743 648 q 774 800 774 730 l 842 800 z ',
    },
    ȷ: {
      ha: 317,
      x_min: -79,
      x_max: 202,
      o: 'm 202 734 l 202 -60 q 148 -236 202 -175 q -3 -296 94 -296 q -79 -283 -41 -296 l -70 -216 q -3 -227 -41 -227 q 89 -184 57 -227 q 121 -60 121 -140 l 121 734 l 202 734 z ',
    },
    ə: {
      ha: 715,
      x_min: 62,
      x_max: 662,
      o: 'm 342 747 q 505 701 431 747 q 620 572 578 655 q 662 389 662 489 l 662 351 q 621 165 662 249 q 509 34 580 81 q 353 -14 437 -14 q 140 71 218 -14 q 62 302 62 155 l 62 368 l 581 368 l 581 388 q 514 596 581 513 q 342 679 448 679 q 210 656 262 679 q 121 597 157 634 l 87 653 q 342 747 178 747 m 353 55 q 502 123 439 55 q 578 299 565 191 l 144 299 l 144 279 q 201 118 144 180 q 353 55 258 55 z ',
    },
    ˆ: {
      ha: 597,
      x_min: 109,
      x_max: 485,
      o: 'm 485 860 l 485 853 l 410 853 l 296 977 l 182 853 l 109 853 l 109 862 l 270 1035 l 321 1035 l 485 860 z ',
    },
    ˇ: {
      ha: 567,
      x_min: 97,
      x_max: 475,
      o: 'm 286 899 l 403 1031 l 475 1031 l 475 1023 l 315 846 l 257 846 l 97 1023 l 97 1031 l 170 1031 l 286 899 z ',
    },
    ˉ: {
      ha: 593,
      x_min: 93,
      x_max: 513,
      o: 'm 513 911 l 93 911 l 93 977 l 513 977 l 513 911 z ',
    },
    '˘': {
      ha: 587,
      x_min: 94,
      x_max: 486,
      o: 'm 486 1010 q 432 884 486 932 q 290 836 379 836 q 148 884 201 836 q 94 1010 94 932 l 163 1010 q 196 924 163 954 q 290 895 230 895 q 383 926 349 895 q 416 1010 416 957 l 486 1010 z ',
    },
    '˙': {
      ha: 311,
      x_min: 100,
      x_max: 212,
      o: 'm 100 937 q 115 976 100 960 q 156 992 130 992 q 197 976 182 992 q 212 937 212 960 q 197 899 212 915 q 156 884 182 884 q 115 899 130 884 q 100 937 100 915 z ',
    },
    '˚': {
      ha: 431,
      x_min: 79,
      x_max: 341,
      o: 'm 79 946 q 118 1038 79 1000 q 210 1075 156 1075 q 302 1038 264 1075 q 341 946 341 1000 q 304 856 341 892 q 210 819 267 819 q 117 856 154 819 q 79 946 79 892 m 132 946 q 154 890 132 912 q 210 867 176 867 q 267 889 243 867 q 290 946 290 911 q 266 1004 290 982 q 210 1027 242 1027 q 154 1004 177 1027 q 132 946 132 981 z ',
    },
    '˛': {
      ha: 347,
      x_min: 41,
      x_max: 253,
      o: 'm 235 0 q 139 -90 164 -54 q 114 -159 114 -125 q 172 -216 114 -216 q 243 -193 208 -216 l 253 -250 q 156 -280 209 -280 q 71 -250 102 -280 q 41 -165 41 -220 q 83 -65 41 -115 q 195 25 125 -14 l 235 0 z ',
    },
    '˜': {
      ha: 604,
      x_min: 72,
      x_max: 528,
      o: 'm 528 1007 q 490 902 528 942 q 396 863 452 863 q 295 900 345 863 q 208 937 246 937 q 154 916 176 937 q 133 857 133 895 l 72 859 q 109 962 72 922 q 204 1003 146 1003 q 260 991 237 1003 q 303 966 283 980 q 344 940 323 951 q 393 928 365 928 q 446 951 424 928 q 468 1011 468 973 l 528 1007 z ',
    },
    '˝': {
      ha: 524,
      x_min: 81,
      x_max: 527,
      o: 'm 429 1041 l 527 1041 l 338 836 l 256 836 l 429 1041 m 219 1041 l 312 1041 l 155 836 l 81 836 l 219 1041 z ',
    },
    '˳': {
      ha: 422,
      x_min: 106,
      x_max: 313,
      o: 'm 106 -156 q 137 -87 106 -115 q 211 -59 168 -59 q 283 -87 254 -59 q 313 -156 313 -115 q 283 -223 313 -196 q 211 -250 254 -250 q 137 -223 168 -250 q 106 -156 106 -197 m 156 -156 q 172 -191 156 -177 q 211 -204 188 -204 q 250 -191 234 -204 q 265 -156 265 -177 q 250 -120 265 -134 q 211 -106 234 -106 q 172 -120 188 -106 q 156 -156 156 -134 z ',
    },
    '̀': {
      ha: 0,
      x_min: -543,
      x_max: -315,
      o: 'm -315 852 l -389 852 l -543 1042 l -444 1042 l -315 852 z ',
    },
    '́': {
      ha: 0,
      x_min: -417,
      x_max: -189,
      o: 'm -288 1042 l -189 1042 l -347 852 l -417 852 l -288 1042 z ',
    },
    '̃': {
      ha: 0,
      x_min: -579,
      x_max: -123,
      o: 'm -123 1007 q -161 902 -123 942 q -255 863 -199 863 q -356 900 -307 863 q -444 937 -405 937 q -497 916 -475 937 q -518 857 -518 895 l -579 859 q -542 962 -579 922 q -447 1003 -505 1003 q -391 991 -414 1003 q -348 966 -368 980 q -307 940 -328 951 q -258 928 -286 928 q -205 951 -227 928 q -183 1011 -183 973 l -123 1007 z ',
    },
    '̉': {
      ha: 0,
      x_min: -447,
      x_max: -243,
      o: 'm -424 837 l -424 922 q -338 940 -365 926 q -311 985 -311 953 q -348 1033 -311 1016 q -447 1050 -385 1050 l -442 1102 q -296 1070 -349 1102 q -243 985 -243 1038 q -274 912 -243 940 q -362 879 -304 884 l -363 837 l -424 837 z ',
    },
    '̏': {
      ha: 0,
      x_min: -663,
      x_max: -211,
      o: 'm -376 849 l -458 849 l -663 1029 l -559 1029 l -376 849 m -211 849 l -285 849 l -454 1029 l -359 1029 l -211 849 z ',
    },
    '̣': {
      ha: 0,
      x_min: -456,
      x_max: -344,
      o: 'm -456 -157 q -441 -118 -456 -134 q -400 -102 -426 -102 q -359 -118 -374 -102 q -344 -157 -344 -134 q -359 -195 -344 -179 q -400 -210 -374 -210 q -441 -195 -426 -210 q -456 -157 -456 -179 z ',
    },
    '΄': {
      ha: 352,
      x_min: 135,
      x_max: 265,
      o: 'm 185 1077 l 265 1077 l 191 871 l 135 871 l 185 1077 z ',
    },
    '΅': {
      ha: 665,
      x_min: 104,
      x_max: 559,
      o: 'm 321 1128 l 412 1128 l 363 958 l 297 958 l 321 1128 m 104 947 q 119 986 104 970 q 159 1002 134 1002 q 201 986 186 1002 q 216 947 216 970 q 201 909 216 924 q 159 893 186 893 q 119 909 134 893 q 104 947 104 924 m 447 946 q 462 985 447 969 q 503 1001 477 1001 q 544 985 528 1001 q 559 946 559 969 q 544 908 559 924 q 503 892 528 892 q 462 908 477 892 q 447 946 447 924 z ',
    },
    '·': {
      ha: 341,
      x_min: 111,
      x_max: 226,
      o: 'm 111 486 q 125 526 111 510 q 168 543 140 543 q 210 526 195 543 q 226 486 226 510 q 210 447 226 463 q 168 431 195 431 q 125 447 140 431 q 111 486 111 463 z ',
    },
    Γ: {
      ha: 766,
      x_min: 125,
      x_max: 721,
      o: 'm 721 916 l 209 916 l 209 0 l 125 0 l 125 987 l 721 987 l 721 916 z ',
    },
    Δ: {
      ha: 968,
      x_min: 30,
      x_max: 932,
      o: 'm 527 987 l 932 0 l 30 0 l 448 987 l 527 987 m 146 71 l 817 71 l 486 889 l 146 71 z ',
    },
    Θ: {
      ha: 935,
      x_min: 80,
      x_max: 849,
      o: 'm 654 458 l 279 458 l 279 526 l 654 526 l 654 458 m 849 440 q 802 201 849 304 q 667 42 755 98 q 465 -14 580 -14 q 265 42 353 -14 q 129 200 177 98 q 80 435 81 302 l 80 546 q 128 785 80 681 q 263 945 176 889 q 464 1001 351 1001 q 665 946 578 1001 q 800 791 752 891 q 849 558 847 690 l 849 440 m 766 547 q 686 827 766 726 q 464 928 605 928 q 245 826 327 928 q 163 543 163 725 l 163 440 q 244 162 163 264 q 465 59 326 59 q 686 160 606 59 q 766 442 766 261 l 766 547 z ',
    },
    Λ: {
      ha: 900,
      x_min: 46,
      x_max: 849,
      o: 'm 447 865 l 136 0 l 46 0 l 407 987 l 488 987 l 849 0 l 759 0 l 447 865 z ',
    },
    Ξ: {
      ha: 777,
      x_min: 79,
      x_max: 703,
      o: 'm 79 71 l 703 71 l 703 0 l 79 0 l 79 71 m 144 532 l 631 532 l 631 461 l 144 461 l 144 532 m 87 987 l 684 987 l 684 916 l 87 916 l 87 987 z ',
    },
    Π: {
      ha: 983,
      x_min: 128,
      x_max: 854,
      o: 'm 854 0 l 770 0 l 770 916 l 212 916 l 212 0 l 128 0 l 128 987 l 854 987 l 854 0 z ',
    },
    Σ: {
      ha: 777,
      x_min: 43,
      x_max: 726,
      o: 'm 478 504 l 148 71 l 726 71 l 726 0 l 43 0 l 43 62 l 391 515 l 43 924 l 43 987 l 689 987 l 689 916 l 154 916 l 478 528 l 478 504 z ',
    },
    Φ: {
      ha: 993,
      x_min: 60,
      x_max: 935,
      o: 'm 539 860 q 745 812 654 858 q 885 683 835 766 q 935 496 935 600 q 886 309 935 392 q 746 179 837 226 q 539 130 656 132 l 539 0 l 455 0 l 455 130 q 249 178 338 132 q 109 308 159 224 q 60 494 60 391 q 109 681 60 597 q 249 811 159 764 q 455 860 340 858 l 455 987 l 539 987 l 539 860 m 539 789 l 539 202 q 769 284 687 205 q 852 496 852 364 q 768 707 852 627 q 539 789 685 786 m 143 494 q 226 284 143 363 q 455 202 309 205 l 455 788 q 227 706 311 785 q 143 494 143 627 z ',
    },
    Ψ: {
      ha: 964,
      x_min: 83,
      x_max: 877,
      o: 'm 519 336 q 719 426 646 346 q 793 635 793 507 l 793 987 l 877 987 l 877 638 q 782 374 877 474 q 519 264 686 274 l 519 0 l 435 0 l 435 264 q 180 373 274 275 q 83 629 85 471 l 83 987 l 167 987 l 167 635 q 200 485 167 551 q 294 381 234 419 q 435 336 355 343 l 435 987 l 519 987 l 519 336 z ',
    },
    Ω: {
      ha: 909,
      x_min: 81,
      x_max: 819,
      o: 'm 508 81 q 670 222 612 117 q 730 486 728 327 l 730 574 q 653 833 730 736 q 449 930 576 930 q 245 832 321 930 q 170 570 170 734 l 170 494 q 228 226 170 334 q 395 81 287 117 l 395 0 l 81 0 l 81 71 l 277 71 q 136 248 187 136 q 85 494 85 359 l 85 572 q 131 797 85 699 q 259 948 176 895 q 449 1001 342 1001 q 640 947 556 1001 q 768 795 723 893 q 814 573 814 698 l 814 494 q 762 246 814 358 q 623 71 710 134 l 819 71 l 819 0 l 508 0 l 508 81 z ',
    },
    α: {
      ha: 782,
      x_min: 75,
      x_max: 777,
      o: 'm 663 734 l 663 182 q 732 59 664 59 q 766 64 746 59 l 777 1 q 711 -14 749 -14 q 626 17 658 -14 q 585 119 595 47 q 355 -14 511 -14 q 158 74 233 -14 q 76 318 83 163 l 75 361 q 151 644 75 541 q 357 747 227 747 q 584 608 511 747 l 593 734 l 663 734 m 157 347 q 212 131 157 208 q 370 54 268 54 q 582 218 519 54 l 582 500 q 498 632 552 585 q 372 678 444 678 q 214 594 271 678 q 157 347 157 510 z ',
    },
    β: {
      ha: 823,
      x_min: 120,
      x_max: 739,
      o: 'm 403 1001 q 604 929 528 1001 q 680 741 680 857 q 645 617 680 673 q 546 530 610 561 q 689 437 638 504 q 739 274 739 370 q 656 63 739 140 q 434 -14 573 -14 q 304 9 366 -14 q 201 73 242 33 l 201 -271 l 120 -271 l 120 721 q 158 862 120 797 q 261 964 195 926 q 403 1001 327 1001 m 600 744 q 545 879 600 826 q 403 932 491 932 q 260 871 318 932 q 201 718 201 811 l 201 161 q 293 84 232 113 q 428 55 355 55 q 597 113 537 55 q 658 271 658 171 q 598 428 658 368 q 435 489 538 488 l 336 489 l 336 558 l 397 558 q 600 744 600 558 z ',
    },
    γ: {
      ha: 680,
      x_min: 30,
      x_max: 653,
      o: 'm 569 734 l 653 734 l 382 21 l 382 -281 l 301 -281 l 301 21 l 30 734 l 114 734 l 328 162 l 342 102 l 359 162 l 569 734 z ',
    },
    δ: {
      ha: 773,
      x_min: 62,
      x_max: 711,
      o: 'm 148 874 q 216 1009 148 958 q 395 1060 285 1060 q 592 1015 505 1060 l 585 945 q 395 989 490 989 q 275 959 321 989 q 229 876 229 928 q 275 788 229 823 q 417 728 321 753 q 634 591 558 687 q 711 361 711 495 l 711 342 q 670 157 711 239 q 556 31 630 76 q 387 -14 481 -14 q 152 85 243 -14 q 62 347 62 184 l 62 363 q 131 577 62 486 q 300 686 200 668 l 300 692 q 187 767 227 720 q 148 874 148 814 m 144 342 q 211 135 144 214 q 387 55 277 55 q 563 135 497 55 q 629 349 629 215 l 629 363 q 598 493 629 431 q 513 595 567 554 q 395 647 458 636 q 214 567 283 647 q 144 357 144 487 l 144 342 z ',
    },
    ε: {
      ha: 755,
      x_min: 73,
      x_max: 678,
      o: 'm 73 201 q 225 376 73 337 q 122 443 158 400 q 85 538 85 486 q 162 692 85 637 q 373 747 239 747 q 582 687 498 747 q 666 536 666 627 l 585 536 q 524 638 585 597 q 373 678 463 678 q 223 642 278 678 q 168 540 168 605 q 376 408 168 408 l 496 408 l 496 340 l 366 340 q 209 307 262 340 q 155 200 155 273 q 214 95 155 134 q 373 55 273 55 q 533 99 470 55 q 597 210 597 143 l 678 210 q 592 47 678 109 q 373 -14 507 -14 q 155 45 238 -14 q 73 201 73 104 z ',
    },
    ζ: {
      ha: 683,
      x_min: 50,
      x_max: 627,
      o: 'm 627 987 l 627 935 l 356 589 q 237 405 271 483 q 203 246 203 328 q 241 144 203 182 q 374 81 279 106 l 495 47 q 584 -2 559 26 q 608 -76 608 -30 q 573 -180 608 -124 q 495 -262 539 -236 l 449 -224 q 530 -85 530 -158 q 503 -36 530 -54 q 379 6 476 -18 q 235 56 283 31 q 161 115 188 81 q 122 244 122 163 q 288 621 122 413 l 529 918 l 50 918 l 50 987 l 627 987 z ',
    },
    η: {
      ha: 773,
      x_min: 107,
      x_max: 665,
      o: 'm 182 734 l 186 617 q 421 747 264 747 q 605 680 546 747 q 665 469 665 612 l 665 -281 l 584 -281 l 584 465 q 541 626 584 573 q 403 678 498 678 q 189 507 237 678 l 189 0 l 107 0 l 107 734 l 182 734 z ',
    },
    θ: {
      ha: 795,
      x_min: 94,
      x_max: 701,
      o: 'm 701 418 q 623 98 701 210 q 398 -14 544 -14 q 175 97 254 -14 q 94 409 96 208 l 94 572 q 173 890 94 779 q 397 1001 252 1001 q 621 891 542 1001 q 701 578 700 781 l 701 418 m 175 525 l 620 525 l 620 583 q 561 842 620 752 q 397 932 503 932 q 233 842 291 932 q 175 580 175 751 l 175 525 m 620 456 l 175 456 l 175 409 q 233 149 175 243 q 398 55 292 55 q 559 143 501 55 q 620 399 618 232 l 620 456 z ',
    },
    ι: {
      ha: 416,
      x_min: 123,
      x_max: 370,
      o: 'm 205 734 l 205 183 q 227 94 205 123 q 300 64 249 64 q 366 71 332 64 l 370 3 q 283 -7 332 -7 q 161 42 199 -7 q 123 184 123 90 l 123 734 l 205 734 z ',
    },
    λ: {
      ha: 764,
      x_min: 33,
      x_max: 736,
      o: 'm 171 1029 q 323 926 277 1029 l 340 882 l 614 154 q 653 84 633 104 q 701 64 673 64 l 730 67 l 736 0 q 690 -7 720 -7 q 602 20 637 -7 q 538 123 568 47 l 358 603 l 123 0 l 33 0 l 316 713 l 253 876 l 243 898 q 155 960 213 960 l 123 958 l 120 1022 q 171 1029 142 1029 z ',
    },
    ξ: {
      ha: 682,
      x_min: 81,
      x_max: 626,
      o: 'm 612 904 q 442 932 526 932 q 272 889 332 932 q 212 774 212 847 q 464 613 212 613 l 548 613 l 548 543 l 462 543 q 239 481 317 543 q 162 301 162 418 q 219 152 162 210 q 408 61 277 94 q 568 11 538 28 q 612 -28 598 -5 q 626 -85 626 -51 q 595 -175 626 -127 q 515 -258 564 -223 l 467 -219 l 496 -193 q 548 -92 548 -146 q 522 -48 548 -64 q 410 -10 496 -31 q 267 35 324 12 q 168 94 209 58 q 104 180 127 129 q 81 304 81 231 q 139 477 81 406 q 303 578 197 548 q 177 654 223 603 q 130 772 130 705 q 213 940 130 880 q 443 1001 296 1001 q 623 970 545 1001 l 612 904 z ',
    },
    π: {
      ha: 827,
      x_min: 35,
      x_max: 808,
      o: 'm 768 664 l 643 664 l 643 185 q 665 94 643 124 q 739 64 686 64 q 804 71 770 64 l 808 3 q 721 -7 770 -7 q 601 40 639 -7 q 562 178 563 87 l 562 664 l 239 664 l 239 0 l 158 0 l 158 664 l 35 664 l 35 734 l 768 734 l 768 664 z ',
    },
    ρ: {
      ha: 773,
      x_min: 107,
      x_max: 696,
      o: 'm 401 747 q 618 643 541 747 q 696 357 696 539 l 696 347 q 622 84 696 181 q 418 -14 548 -14 q 189 90 267 -14 l 189 -282 l 107 -282 l 107 416 q 189 657 109 568 q 401 747 270 747 m 189 182 q 399 55 250 55 q 558 131 502 55 q 615 361 615 208 q 559 593 615 508 q 401 678 504 678 q 248 603 306 678 q 189 399 190 528 l 189 182 z ',
    },
    ς: {
      ha: 743,
      x_min: 75,
      x_max: 664,
      o: 'm 389 747 q 588 676 511 747 q 664 485 664 606 l 587 485 q 533 626 587 573 q 389 678 479 678 q 220 596 282 678 q 157 375 157 514 l 157 353 q 429 62 157 128 q 561 5 529 37 q 593 -83 593 -26 q 560 -177 593 -125 q 487 -255 526 -229 l 442 -216 l 462 -199 q 515 -92 515 -149 q 493 -38 515 -56 q 403 -5 471 -20 q 158 120 241 30 q 75 352 75 210 l 75 381 q 114 570 75 486 q 225 701 153 654 q 389 747 296 747 z ',
    },
    σ: {
      ha: 772,
      x_min: 62,
      x_max: 768,
      o: 'm 768 664 l 555 664 q 670 542 629 618 q 711 366 711 465 l 711 343 q 670 161 711 243 q 555 33 629 79 q 387 -14 481 -14 q 152 89 241 -14 q 62 361 62 192 l 62 378 q 152 635 62 536 q 389 734 243 734 l 768 734 l 768 664 m 143 355 q 210 138 143 222 q 387 55 277 55 q 563 138 495 55 q 630 362 630 221 l 630 378 q 563 584 630 503 q 385 664 496 664 q 210 583 277 664 q 143 372 143 502 l 143 355 z ',
    },
    τ: {
      ha: 710,
      x_min: 52,
      x_max: 657,
      o: 'm 657 665 l 384 665 l 384 186 q 407 89 384 121 q 482 56 431 56 q 549 68 505 56 l 567 7 q 467 -14 528 -14 q 341 36 380 -14 q 302 189 302 85 l 302 665 l 52 665 l 52 734 l 657 734 l 657 665 z ',
    },
    υ: {
      ha: 743,
      x_min: 105,
      x_max: 659,
      o: 'm 186 734 l 186 271 q 229 110 187 165 q 350 55 271 55 q 515 142 452 55 q 578 366 578 229 q 498 734 576 544 l 587 734 q 642 551 626 637 q 659 368 659 464 q 576 90 659 193 q 353 -14 494 -14 q 169 60 232 -14 q 105 275 105 133 l 105 734 l 186 734 z ',
    },
    φ: {
      ha: 952,
      x_min: 77,
      x_max: 878,
      o: 'm 431 -13 q 168 100 259 -2 q 77 376 77 201 q 123 585 77 492 q 253 734 170 677 l 296 675 q 196 547 232 628 q 158 375 159 467 q 229 151 158 235 q 431 56 300 68 l 431 593 q 472 695 431 656 q 576 734 513 734 q 729 686 659 734 q 838 555 799 639 q 878 370 878 471 q 781 101 878 205 q 513 -13 684 -2 l 513 -324 l 431 -324 l 431 -13 m 513 56 q 720 153 644 66 q 797 369 797 239 q 733 579 795 495 q 576 664 671 664 q 530 645 548 664 q 513 595 513 625 l 513 56 z ',
    },
    ψ: {
      ha: 949,
      x_min: 82,
      x_max: 875,
      o: 'm 509 734 l 509 56 q 719 154 644 66 q 794 392 794 241 q 699 734 792 557 l 788 734 q 856 561 837 640 q 875 393 875 481 q 780 100 875 204 q 509 -12 684 -4 l 509 -300 l 429 -300 l 429 -12 q 174 101 263 0 q 82 388 85 202 l 82 734 l 163 734 l 163 393 q 232 156 165 243 q 429 57 298 69 l 429 734 l 509 734 z ',
    },
    ω: {
      ha: 1145,
      x_min: 93,
      x_max: 1052,
      o: 'm 274 734 q 174 359 176 541 q 221 137 174 219 q 355 55 269 55 q 484 121 438 55 q 531 316 530 188 l 531 522 l 613 522 l 613 317 q 660 122 614 189 q 790 55 705 55 q 923 136 874 55 q 971 359 971 218 q 870 734 969 538 l 959 734 q 1031 543 1011 629 q 1052 360 1052 456 q 983 86 1052 186 q 793 -14 915 -14 q 656 28 713 -14 q 572 158 599 71 q 351 -14 519 -14 q 161 87 229 -14 q 93 360 93 187 q 114 549 93 460 q 186 734 136 637 l 274 734 z ',
    },
    ϑ: {
      ha: 797,
      x_min: 90,
      x_max: 774,
      o: 'm 774 458 q 662 434 726 438 l 662 284 q 588 64 661 141 q 379 -14 516 -14 q 169 67 247 -14 q 90 292 90 149 l 90 513 l 172 515 l 172 288 q 227 117 172 177 q 379 57 281 57 q 529 112 480 57 q 581 279 578 167 l 581 435 q 331 540 422 450 q 239 775 239 631 l 239 783 q 296 947 239 884 q 443 1009 353 1009 q 604 939 546 1009 q 662 739 662 868 l 662 505 q 767 525 718 509 l 774 458 m 320 769 q 389 589 320 658 q 581 507 457 519 l 581 749 q 443 938 577 938 q 354 898 387 938 q 320 787 321 858 l 320 769 z ',
    },
    ϒ: {
      ha: 745,
      x_min: -32,
      x_max: 783,
      o: 'm 707 995 q 783 977 748 995 l 764 912 q 718 922 747 922 q 656 899 681 922 q 608 824 632 876 l 418 403 l 418 0 l 334 0 l 334 406 l 142 825 q 94 900 118 877 q 34 922 70 922 q -12 912 4 922 l -32 976 q 44 995 2 995 q 142 963 104 995 q 212 861 180 931 l 361 526 l 376 475 l 392 528 l 540 860 q 611 964 574 934 q 707 995 649 995 z ',
    },
    ϖ: {
      ha: 1092,
      x_min: 38,
      x_max: 1067,
      o: 'm 1067 664 l 954 664 q 1014 360 1014 525 q 949 87 1014 188 q 768 -14 884 -14 q 547 157 601 -14 q 326 -14 493 -14 q 145 87 210 -14 q 81 360 81 188 q 140 664 81 524 l 38 664 l 38 734 l 1067 734 l 1067 664 m 933 359 q 865 664 930 509 l 229 664 q 161 359 163 508 q 206 137 161 219 q 330 55 251 55 q 459 122 414 55 q 506 316 505 189 l 506 507 l 588 507 l 588 317 q 635 122 589 189 q 765 55 680 55 q 888 137 844 55 q 933 359 933 219 z ',
    },
    Ђ: {
      ha: 1034,
      x_min: 37,
      x_max: 976,
      o: 'm 798 916 l 414 916 l 414 554 q 529 588 469 575 q 637 600 590 600 q 887 520 798 600 q 976 287 976 440 q 937 134 976 201 q 825 30 898 66 q 651 -7 751 -7 l 650 60 q 828 121 764 60 q 892 287 892 183 q 827 465 892 403 q 637 527 761 527 q 414 481 526 527 l 414 0 l 330 0 l 330 916 l 37 916 l 37 987 l 798 987 l 798 916 z ',
    },
    Є: {
      ha: 917,
      x_min: 90,
      x_max: 825,
      o: 'm 825 308 q 714 69 808 152 q 462 -14 619 -14 q 268 41 352 -14 q 137 197 183 96 q 90 427 91 298 l 90 555 q 136 788 90 686 q 269 945 182 890 q 468 1001 355 1001 q 719 915 627 1001 q 825 678 810 829 l 742 678 q 468 930 711 930 q 256 831 335 930 q 174 566 177 733 l 174 535 l 586 535 l 586 465 l 174 465 l 174 431 q 252 159 174 260 q 462 57 330 57 q 659 120 593 57 q 741 308 726 182 l 825 308 z ',
    },
    Љ: {
      ha: 1466,
      x_min: 35,
      x_max: 1390,
      o: 'm 819 987 l 819 575 l 1069 575 q 1240 539 1166 574 q 1352 438 1313 503 q 1390 288 1390 373 q 1302 78 1390 155 q 1061 0 1214 0 l 735 0 l 735 916 l 355 916 l 332 508 q 291 190 319 289 q 211 46 262 90 q 70 0 159 1 l 35 0 l 35 71 l 62 71 q 167 115 132 75 q 221 247 202 156 q 252 529 241 338 l 276 987 l 819 987 m 819 505 l 819 71 l 1063 71 q 1242 130 1177 71 q 1307 289 1307 190 q 1244 443 1307 384 q 1072 505 1181 503 l 819 505 z ',
    },
    Њ: {
      ha: 1499,
      x_min: 126,
      x_max: 1423,
      o: 'm 210 532 l 768 532 l 768 987 l 852 987 l 852 561 l 1097 561 q 1271 527 1197 561 q 1384 430 1345 493 q 1423 282 1423 367 q 1336 77 1423 153 q 1098 0 1249 1 l 768 0 l 768 462 l 210 462 l 210 0 l 126 0 l 126 987 l 210 987 l 210 532 m 852 490 l 852 73 l 1096 73 q 1275 130 1211 73 q 1340 283 1340 187 q 1277 432 1340 376 q 1102 490 1213 489 l 852 490 z ',
    },
    Ћ: {
      ha: 1117,
      x_min: 37,
      x_max: 1000,
      o: 'm 797 916 l 418 916 l 418 549 q 670 584 545 584 q 919 510 840 584 q 1000 288 998 437 l 1000 0 l 916 0 l 916 283 q 858 457 916 401 q 670 513 800 513 q 418 477 546 513 l 418 0 l 334 0 l 334 916 l 37 916 l 37 987 l 797 987 l 797 916 z ',
    },
    Џ: {
      ha: 979,
      x_min: 125,
      x_max: 852,
      o: 'm 125 987 l 210 987 l 210 71 l 768 71 l 768 987 l 852 987 l 852 0 l 534 0 l 534 -239 l 450 -239 l 450 0 l 125 0 l 125 987 z ',
    },
    Б: {
      ha: 871,
      x_min: 120,
      x_max: 776,
      o: 'm 715 916 l 204 916 l 204 559 l 449 559 q 624 525 550 559 q 737 429 698 492 q 776 281 776 366 q 688 75 776 150 q 446 0 601 0 l 120 0 l 120 987 l 715 987 l 715 916 m 204 488 l 204 71 l 448 71 q 627 127 563 71 q 692 282 692 184 q 629 431 692 376 q 454 488 567 486 l 204 488 z ',
    },
    Д: {
      ha: 1005,
      x_min: 48,
      x_max: 960,
      o: 'm 855 71 l 960 71 l 960 -239 l 876 -239 l 876 0 l 132 0 l 132 -239 l 48 -239 l 48 71 l 114 71 q 236 300 189 149 q 292 631 282 451 l 309 987 l 855 987 l 855 71 m 211 71 l 772 71 l 772 916 l 389 916 l 376 633 q 324 309 367 457 q 211 71 281 160 z ',
    },
    Ж: {
      ha: 1222,
      x_min: 16,
      x_max: 1207,
      o: 'm 766 470 l 654 470 l 654 0 l 571 0 l 571 470 l 456 470 l 121 0 l 16 0 l 381 510 l 40 987 l 141 987 l 452 541 l 571 541 l 571 987 l 654 987 l 654 541 l 771 541 l 1082 987 l 1182 987 l 841 511 l 1207 0 l 1102 0 l 766 470 z ',
    },
    З: {
      ha: 826,
      x_min: 61,
      x_max: 762,
      o: 'm 664 730 q 597 876 664 823 q 406 930 530 930 q 229 873 298 930 q 159 728 159 816 l 75 728 q 118 869 75 807 q 238 966 161 931 q 406 1001 315 1001 q 656 930 566 1001 q 747 728 747 859 q 699 593 747 652 q 570 506 651 534 q 762 268 762 454 q 666 63 762 140 q 406 -14 569 -14 q 225 24 307 -14 q 102 126 144 62 q 61 274 61 190 l 144 274 q 218 118 144 179 q 406 56 292 56 q 606 113 534 56 q 679 265 679 170 q 614 416 679 366 q 416 467 550 466 l 290 467 l 290 539 l 412 539 q 600 588 535 540 q 664 730 664 635 z ',
    },
    И: {
      ha: 986,
      x_min: 127,
      x_max: 857,
      o: 'm 774 987 l 857 987 l 857 0 l 774 0 l 774 845 l 212 0 l 127 0 l 127 987 l 212 987 l 212 143 l 774 987 z ',
    },
    Л: {
      ha: 975,
      x_min: 27,
      x_max: 848,
      o: 'm 848 987 l 848 0 l 765 0 l 765 916 l 348 916 l 325 508 q 283 190 311 289 q 203 46 255 90 q 62 0 151 1 l 27 0 l 27 71 l 54 71 q 159 115 124 75 q 214 247 195 156 q 244 529 233 338 l 269 987 l 848 987 z ',
    },
    У: {
      ha: 871,
      x_min: 37,
      x_max: 833,
      o: 'm 454 355 l 735 987 l 833 987 l 452 161 l 427 114 q 216 -14 355 -14 q 152 -7 174 -14 l 159 61 q 216 56 174 56 q 351 142 300 56 l 368 173 l 411 261 l 37 987 l 133 987 l 454 355 z ',
    },
    Ф: {
      ha: 1031,
      x_min: 52,
      x_max: 983,
      o: 'm 557 887 l 581 887 q 787 836 695 886 q 931 696 880 786 q 983 496 983 606 q 932 296 983 386 q 789 156 881 207 q 583 104 697 105 l 557 104 l 557 -41 l 476 -41 l 476 104 l 457 104 q 248 154 340 104 q 103 294 155 203 q 52 495 52 384 q 103 696 52 606 q 248 837 155 787 q 457 887 341 887 l 476 887 l 476 1028 l 557 1028 l 557 887 m 557 818 l 557 172 l 577 172 q 813 261 725 172 q 901 496 901 350 q 819 723 901 635 q 600 817 737 810 l 557 818 m 458 818 q 221 730 309 818 q 133 495 133 642 q 215 267 133 353 q 435 173 297 180 l 476 172 l 476 818 l 458 818 z ',
    },
    Ц: {
      ha: 991,
      x_min: 121,
      x_max: 945,
      o: 'm 121 987 l 205 987 l 205 71 l 764 71 l 764 987 l 848 987 l 848 72 l 945 72 l 939 -238 l 861 -238 l 861 0 l 121 0 l 121 987 z ',
    },
    Ч: {
      ha: 924,
      x_min: 117,
      x_max: 798,
      o: 'm 798 987 l 798 0 l 714 0 l 714 449 q 447 410 575 410 q 199 483 279 410 q 117 706 119 555 l 117 987 l 201 987 l 201 711 q 260 536 201 591 q 447 481 319 481 q 714 520 576 481 l 714 987 l 798 987 z ',
    },
    Ш: {
      ha: 1286,
      x_min: 123,
      x_max: 1162,
      o: 'm 208 987 l 208 71 l 602 71 l 602 987 l 686 987 l 686 71 l 1079 71 l 1079 987 l 1162 987 l 1162 0 l 123 0 l 123 987 l 208 987 z ',
    },
    Щ: {
      ha: 1309,
      x_min: 123,
      x_max: 1256,
      o: 'm 208 987 l 208 71 l 602 71 l 602 987 l 686 987 l 686 71 l 1079 71 l 1079 987 l 1162 987 l 1162 68 l 1256 68 l 1250 -238 l 1176 -238 l 1176 0 l 123 0 l 123 987 l 208 987 z ',
    },
    Ъ: {
      ha: 1075,
      x_min: -7,
      x_max: 979,
      o: 'm -7 987 l 408 987 l 408 559 l 652 559 q 827 525 753 559 q 940 429 901 492 q 979 281 979 366 q 892 75 979 150 q 650 0 804 0 l 324 0 l 324 918 l -7 918 l -7 987 m 408 488 l 408 71 l 652 71 q 831 127 766 71 q 895 282 895 184 q 833 431 895 376 q 658 488 770 486 l 408 488 z ',
    },
    Ы: {
      ha: 1160,
      x_min: 126,
      x_max: 1027,
      o: 'm 210 559 l 454 559 q 629 525 555 559 q 742 429 703 492 q 781 281 781 366 q 694 75 781 150 q 452 0 606 0 l 126 0 l 126 987 l 210 987 l 210 559 m 210 488 l 210 71 l 454 71 q 633 127 568 71 q 697 282 697 184 q 635 431 697 376 q 460 488 572 486 l 210 488 m 1027 0 l 944 0 l 944 987 l 1027 987 l 1027 0 z ',
    },
    Ь: {
      ha: 850,
      x_min: 121,
      x_max: 776,
      o: 'm 204 559 l 449 559 q 624 525 550 559 q 737 429 698 492 q 776 281 776 366 q 688 75 776 150 q 446 0 601 0 l 121 0 l 121 987 l 204 987 l 204 559 m 204 488 l 204 71 l 448 71 q 627 127 563 71 q 692 282 692 184 q 629 431 692 376 q 454 488 567 486 l 204 488 z ',
    },
    Э: {
      ha: 917,
      x_min: 96,
      x_max: 830,
      o: 'm 179 308 q 263 118 196 178 q 458 57 330 57 q 669 160 592 57 q 747 436 747 263 l 747 460 l 334 460 l 334 532 l 747 532 l 747 556 q 667 829 747 728 q 453 930 588 930 q 179 678 210 930 l 96 678 q 203 916 111 831 q 453 1001 296 1001 q 727 879 623 1001 q 830 549 830 758 l 830 431 q 784 198 830 300 q 653 41 737 96 q 458 -14 568 -14 q 205 70 300 -14 q 96 308 111 153 l 179 308 z ',
    },
    Ю: {
      ha: 1264,
      x_min: 142,
      x_max: 1177,
      o: 'm 1177 440 q 1129 201 1177 304 q 995 42 1082 98 q 793 -14 907 -14 q 513 110 620 -14 q 407 444 407 234 l 407 465 l 225 465 l 225 0 l 142 0 l 142 987 l 225 987 l 225 534 l 407 534 q 455 785 407 682 q 590 945 503 889 q 791 1001 677 1001 q 992 946 905 1001 q 1127 791 1080 891 q 1177 558 1175 690 l 1177 440 m 1093 547 q 1013 827 1093 726 q 791 928 932 928 q 572 826 654 928 q 490 543 490 725 l 490 440 q 572 162 490 264 q 793 59 653 59 q 1014 160 935 59 q 1093 443 1093 262 l 1093 547 z ',
    },
    Я: {
      ha: 849,
      x_min: 62,
      x_max: 736,
      o: 'm 652 0 l 652 402 l 368 402 l 153 0 l 62 0 l 295 425 q 149 526 199 458 q 99 690 99 593 q 188 908 99 829 q 437 987 276 987 l 736 987 l 736 0 l 652 0 m 182 690 q 244 533 182 593 q 413 473 305 473 l 652 473 l 652 916 l 434 916 q 248 858 313 916 q 182 690 182 799 z ',
    },
    б: {
      ha: 764,
      x_min: 66,
      x_max: 715,
      o: 'm 401 685 q 628 594 541 685 q 715 352 715 503 l 715 334 q 675 155 715 235 q 561 31 635 75 q 391 -14 487 -14 q 156 83 245 -14 q 66 341 66 180 l 66 406 q 153 770 66 634 q 399 933 239 907 q 542 977 500 949 q 583 1053 583 1005 l 652 1053 q 629 959 652 996 q 557 899 605 922 q 412 861 510 876 q 232 765 300 843 q 144 557 164 688 q 253 650 187 614 q 401 685 318 685 m 147 351 l 147 334 q 214 133 147 210 q 391 55 281 55 q 567 133 500 55 q 633 339 633 212 l 633 357 q 566 544 633 471 q 389 616 498 616 q 214 543 280 616 q 147 351 147 469 z ',
    },
    в: {
      ha: 777,
      x_min: 115,
      x_max: 702,
      o: 'm 115 0 l 115 734 l 384 734 q 598 684 524 734 q 673 538 673 634 q 640 440 673 483 q 543 377 606 397 q 658 315 614 363 q 702 203 702 267 q 630 53 702 106 q 428 0 559 0 l 115 0 m 195 338 l 195 68 l 431 68 q 572 103 522 68 q 621 205 621 138 q 572 302 621 266 q 431 338 522 338 l 195 338 m 195 406 l 401 406 q 592 536 592 408 q 539 634 592 603 q 384 665 487 665 l 195 665 l 195 406 z ',
    },
    г: {
      ha: 585,
      x_min: 111,
      x_max: 563,
      o: 'm 563 664 l 192 664 l 192 0 l 111 0 l 111 734 l 563 734 l 563 664 z ',
    },
    д: {
      ha: 796,
      x_min: 35,
      x_max: 745,
      o: 'm 89 68 l 125 115 q 223 454 210 231 l 236 734 l 669 734 l 669 68 l 745 68 l 745 -216 l 665 -216 l 665 0 l 113 0 l 113 -216 l 35 -216 l 36 68 l 89 68 m 182 68 l 588 68 l 588 658 l 313 658 l 304 467 q 182 68 292 205 z ',
    },
    ж: {
      ha: 1065,
      x_min: 16,
      x_max: 1042,
      o: 'm 667 339 l 569 339 l 569 0 l 488 0 l 488 339 l 391 339 l 119 0 l 16 0 l 320 376 l 50 734 l 149 734 l 389 412 l 488 412 l 488 734 l 569 734 l 569 412 l 668 412 l 909 734 l 1008 734 l 737 376 l 1042 0 l 938 0 l 667 339 z ',
    },
    з: {
      ha: 703,
      x_min: 66,
      x_max: 631,
      o: 'm 536 540 q 486 642 536 606 q 350 678 437 678 q 215 638 271 678 q 159 536 159 597 l 79 536 q 157 687 79 627 q 350 747 235 747 q 547 692 476 747 q 617 538 617 638 q 585 443 617 486 q 490 376 552 400 q 631 201 631 338 q 554 45 631 102 q 351 -13 477 -13 q 147 49 228 -13 q 66 210 66 111 l 148 210 q 207 99 148 144 q 351 55 265 55 q 496 94 444 55 q 549 200 549 133 q 500 307 549 274 q 353 339 452 339 l 241 339 l 241 410 l 367 410 q 536 540 536 414 z ',
    },
    и: {
      ha: 781,
      x_min: 111,
      x_max: 669,
      o: 'm 588 734 l 669 734 l 669 0 l 588 0 l 588 603 l 192 0 l 111 0 l 111 734 l 192 734 l 192 130 l 588 734 z ',
    },
    к: {
      ha: 720,
      x_min: 111,
      x_max: 709,
      o: 'm 305 336 l 193 336 l 193 0 l 111 0 l 111 734 l 193 734 l 193 408 l 304 408 l 573 734 l 675 734 l 374 378 l 709 0 l 602 0 l 305 336 z ',
    },
    л: {
      ha: 782,
      x_min: 30,
      x_max: 670,
      o: 'm 670 734 l 670 0 l 589 0 l 589 664 l 288 664 l 275 389 q 245 156 267 237 q 178 38 222 76 q 61 0 134 0 l 30 0 l 30 72 l 55 73 q 155 141 125 75 q 195 378 186 208 l 210 734 l 670 734 z ',
    },
    м: {
      ha: 1015,
      x_min: 115,
      x_max: 904,
      o: 'm 512 110 l 804 734 l 904 734 l 904 0 l 823 0 l 823 602 l 541 0 l 483 0 l 195 616 l 195 0 l 115 0 l 115 734 l 221 734 l 512 110 z ',
    },
    н: {
      ha: 781,
      x_min: 111,
      x_max: 669,
      o: 'm 669 0 l 588 0 l 588 336 l 193 336 l 193 0 l 111 0 l 111 734 l 193 734 l 193 405 l 588 405 l 588 734 l 669 734 l 669 0 z ',
    },
    п: {
      ha: 781,
      x_min: 111,
      x_max: 669,
      o: 'm 669 0 l 588 0 l 588 664 l 193 664 l 193 0 l 111 0 l 111 734 l 669 734 l 669 0 z ',
    },
    т: {
      ha: 660,
      x_min: 27,
      x_max: 633,
      o: 'm 633 665 l 366 665 l 366 0 l 284 0 l 284 665 l 27 665 l 27 734 l 633 734 l 633 665 z ',
    },
    ф: {
      ha: 1006,
      x_min: 75,
      x_max: 931,
      o: 'm 75 361 q 145 644 75 540 q 340 747 216 747 q 460 715 411 747 l 460 1042 l 541 1042 l 541 713 q 666 747 591 747 q 854 653 783 747 q 930 391 924 559 l 931 347 q 861 82 931 179 q 667 -14 790 -14 q 541 18 594 -14 l 541 -282 l 460 -282 l 460 17 q 338 -14 408 -14 q 153 74 222 -14 q 76 317 83 161 l 75 361 m 850 361 q 797 593 850 509 q 648 678 744 678 q 541 648 579 678 l 541 81 q 650 54 584 54 q 798 131 745 54 q 850 361 850 207 m 157 347 q 208 131 157 208 q 354 54 258 54 q 460 82 418 54 l 460 650 q 355 678 421 678 q 209 594 260 678 q 157 347 157 509 z ',
    },
    ц: {
      ha: 791,
      x_min: 111,
      x_max: 744,
      o: 'm 111 734 l 193 734 l 193 68 l 588 68 l 588 734 l 669 734 l 669 68 l 744 68 l 738 -218 l 663 -218 l 663 0 l 111 0 l 111 734 z ',
    },
    ч: {
      ha: 739,
      x_min: 75,
      x_max: 628,
      o: 'm 628 0 l 547 0 l 547 309 q 355 272 446 272 q 143 336 209 272 q 75 536 77 400 l 75 734 l 155 734 l 155 540 q 202 388 157 435 q 355 342 247 342 q 547 377 453 345 l 547 734 l 628 734 l 628 0 z ',
    },
    ш: {
      ha: 1102,
      x_min: 111,
      x_max: 1005,
      o: 'm 193 734 l 193 68 l 519 68 l 519 734 l 600 734 l 600 68 l 924 68 l 924 734 l 1005 734 l 1005 0 l 111 0 l 111 734 l 193 734 z ',
    },
    щ: {
      ha: 1114,
      x_min: 107,
      x_max: 1089,
      o: 'm 189 734 l 189 68 l 515 68 l 515 734 l 597 734 l 597 68 l 920 68 l 920 734 l 1001 734 l 1001 68 l 1089 68 l 1083 -218 l 1008 -218 l 1008 0 l 107 0 l 107 734 l 189 734 z ',
    },
    ъ: {
      ha: 875,
      x_min: 24,
      x_max: 817,
      o: 'm 24 734 l 338 734 l 338 467 l 550 467 q 693 438 631 467 q 786 356 754 408 q 817 235 817 304 q 744 64 817 128 q 547 0 671 0 l 258 0 l 258 665 l 24 665 l 24 734 m 338 398 l 338 68 l 549 68 q 686 113 636 68 q 736 233 736 157 q 687 352 736 307 q 555 398 639 397 l 338 398 z ',
    },
    ы: {
      ha: 1043,
      x_min: 115,
      x_max: 923,
      o: 'm 195 467 l 407 467 q 550 438 488 467 q 643 356 611 408 q 674 235 674 304 q 601 64 674 128 q 404 0 528 0 l 115 0 l 115 734 l 195 734 l 195 467 m 195 398 l 195 68 l 406 68 q 543 113 493 68 q 593 233 593 157 q 544 352 593 307 q 412 398 496 397 l 195 398 m 923 0 l 842 0 l 842 734 l 923 734 l 923 0 z ',
    },
    ь: {
      ha: 743,
      x_min: 115,
      x_max: 674,
      o: 'm 195 467 l 407 467 q 550 438 488 467 q 643 356 611 408 q 674 235 674 304 q 601 64 674 128 q 404 0 528 0 l 115 0 l 115 734 l 195 734 l 195 467 m 195 398 l 195 68 l 406 68 q 543 113 493 68 q 593 233 593 157 q 544 352 593 307 q 412 398 496 397 l 195 398 z ',
    },
    э: {
      ha: 743,
      x_min: 75,
      x_max: 664,
      o: 'm 351 678 q 210 630 267 678 q 154 505 154 581 l 75 505 q 112 627 75 571 q 214 715 149 682 q 351 747 278 747 q 571 653 486 747 q 663 405 656 558 l 664 353 q 625 162 664 247 q 515 32 585 78 q 351 -14 444 -14 q 153 59 231 -14 q 75 248 75 132 l 154 248 q 210 108 154 161 q 351 55 266 55 q 515 131 453 55 q 582 336 577 207 l 250 336 l 250 405 l 582 405 q 514 604 576 529 q 351 678 453 678 z ',
    },
    ю: {
      ha: 1129,
      x_min: 115,
      x_max: 1059,
      o: 'm 195 399 l 405 399 q 451 580 408 500 q 567 704 494 661 q 731 747 640 747 q 968 644 877 747 q 1059 372 1059 541 l 1059 355 q 1018 164 1059 250 q 902 33 977 79 q 732 -14 827 -14 q 502 82 592 -14 q 405 330 412 177 l 195 330 l 195 0 l 115 0 l 115 734 l 195 734 l 195 399 m 485 355 q 553 139 485 224 q 732 55 621 55 q 910 139 842 55 q 978 362 978 224 l 978 378 q 909 593 978 508 q 731 678 841 678 q 553 593 622 678 q 485 370 485 508 l 485 355 z ',
    },
    я: {
      ha: 754,
      x_min: 63,
      x_max: 641,
      o: 'm 641 734 l 641 0 l 559 0 l 559 296 l 332 296 l 153 0 l 63 0 l 248 306 q 131 382 172 326 q 89 515 89 438 q 160 674 89 614 q 354 734 232 734 l 641 734 m 170 513 q 211 408 170 449 q 326 365 252 366 l 559 365 l 559 665 l 352 665 q 219 624 267 665 q 170 513 170 583 z ',
    },
    ђ: {
      ha: 762,
      x_min: -18,
      x_max: 659,
      o: 'm 396 838 l 186 838 l 186 610 q 289 711 227 675 q 425 747 351 747 q 601 681 543 747 q 659 481 659 614 l 659 -60 q 605 -236 659 -176 q 453 -296 551 -296 q 370 -283 411 -296 l 379 -216 q 453 -227 408 -227 q 546 -184 513 -227 q 578 -60 578 -140 l 578 478 q 537 628 578 579 q 406 677 496 677 q 272 630 330 677 q 186 503 214 583 l 186 0 l 106 0 l 106 838 l -18 838 l -18 907 l 106 907 l 106 1042 l 186 1042 l 186 907 l 396 907 l 396 838 z ',
    },
    є: {
      ha: 743,
      x_min: 73,
      x_max: 668,
      o: 'm 386 55 q 527 102 469 55 q 590 227 584 149 l 668 227 q 626 104 665 159 q 523 18 587 49 q 386 -14 459 -14 q 157 86 241 -14 q 73 359 73 186 l 73 381 q 111 573 73 490 q 219 702 149 656 q 385 747 290 747 q 584 675 506 747 q 668 486 663 604 l 590 486 q 528 625 585 572 q 385 678 471 678 q 219 605 279 678 q 154 401 159 531 l 488 401 l 488 332 l 154 332 q 219 128 159 201 q 386 55 279 55 z ',
    },
    љ: {
      ha: 1187,
      x_min: 24,
      x_max: 1137,
      o: 'm 658 440 l 869 440 q 1069 376 997 439 q 1137 221 1137 316 q 1065 61 1137 122 q 871 0 993 1 l 576 0 l 576 664 l 283 664 l 269 361 q 210 82 257 162 q 62 0 163 2 l 24 0 l 24 72 l 50 73 q 151 141 120 75 q 191 378 181 208 l 205 734 l 658 734 l 658 440 m 658 372 l 658 68 l 868 68 q 1005 110 956 68 q 1055 222 1055 153 q 1006 330 1055 290 q 871 372 956 371 l 658 372 z ',
    },
    њ: {
      ha: 1196,
      x_min: 111,
      x_max: 1147,
      o: 'm 193 442 l 588 442 l 588 734 l 669 734 l 669 440 l 890 440 q 1077 379 1007 437 q 1147 221 1147 321 q 1076 61 1147 121 q 884 0 1004 1 l 588 0 l 588 374 l 193 374 l 193 0 l 111 0 l 111 734 l 193 734 l 193 442 m 669 372 l 669 68 l 880 68 q 1016 110 966 68 q 1066 222 1066 151 q 1016 332 1066 292 q 881 372 965 372 l 669 372 z ',
    },
    ћ: {
      ha: 774,
      x_min: -11,
      x_max: 659,
      o: 'm 404 846 l 186 846 l 186 610 q 289 711 227 675 q 425 747 351 747 q 601 681 543 747 q 659 481 659 614 l 659 0 l 578 0 l 578 482 q 537 629 578 581 q 406 677 496 677 q 272 630 330 677 q 186 503 214 583 l 186 0 l 106 0 l 106 846 l -11 846 l -11 915 l 106 915 l 106 1042 l 186 1042 l 186 915 l 404 915 l 404 846 z ',
    },
    џ: {
      ha: 781,
      x_min: 111,
      x_max: 669,
      o: 'm 193 734 l 193 68 l 588 68 l 588 734 l 669 734 l 669 0 l 433 0 l 433 -239 l 352 -239 l 352 0 l 111 0 l 111 734 l 193 734 z ',
    },
    Ѡ: {
      ha: 1233,
      x_min: 115,
      x_max: 1117,
      o: 'm 1117 987 l 1117 278 q 1044 64 1116 141 q 846 -14 972 -14 q 708 22 768 -14 q 616 129 647 57 q 522 22 583 57 q 381 -14 461 -14 q 188 62 259 -14 q 115 273 117 138 l 115 987 l 199 987 l 199 277 q 249 116 200 175 q 381 56 298 56 q 521 117 470 56 q 573 278 572 177 l 573 987 l 659 987 l 659 278 q 710 117 660 177 q 846 56 760 56 q 982 116 932 56 q 1034 278 1033 176 l 1034 987 l 1117 987 z ',
    },
    ѡ: {
      ha: 1143,
      x_min: 106,
      x_max: 1022,
      o: 'm 1022 734 l 1022 254 q 954 57 1021 129 q 773 -14 888 -14 q 648 17 703 -14 q 564 114 593 49 q 477 17 533 49 q 350 -14 422 -14 q 173 56 239 -14 q 106 254 108 127 l 106 734 l 187 734 l 187 258 q 239 100 188 155 q 350 54 283 54 q 476 110 429 54 q 524 260 524 165 l 524 734 l 605 734 l 605 260 q 650 109 605 164 q 773 54 696 54 q 894 107 848 54 q 941 254 939 161 l 941 734 l 1022 734 z ',
    },
    ѣ: {
      ha: 743,
      x_min: -20,
      x_max: 673,
      o: 'm 416 734 l 195 734 l 195 479 l 406 479 q 549 450 488 479 q 642 366 610 420 q 673 241 673 312 q 600 65 673 130 q 404 0 527 0 l 114 0 l 114 734 l -20 734 l -20 802 l 114 802 l 114 1049 l 195 1049 l 195 802 l 416 802 l 416 734 m 195 410 l 195 68 l 405 68 q 543 114 493 68 q 592 239 592 160 q 544 362 592 315 q 412 410 496 408 l 195 410 z ',
    },
    Ѥ: {
      ha: 1241,
      x_min: 142,
      x_max: 1137,
      o: 'm 226 551 l 402 551 q 506 880 402 759 q 779 1002 609 1002 q 1029 917 936 1002 q 1137 679 1122 832 l 1053 679 q 779 930 1022 930 q 565 830 645 930 q 486 551 486 730 l 846 551 l 846 482 l 486 482 l 486 432 q 563 161 486 264 q 774 58 640 58 q 971 120 905 58 q 1053 309 1038 183 l 1137 309 q 1027 71 1121 154 q 774 -13 932 -13 q 504 110 605 -13 q 402 437 402 233 l 402 482 l 226 482 l 226 0 l 142 0 l 142 987 l 226 987 l 226 551 z ',
    },
    ѥ: {
      ha: 1033,
      x_min: 113,
      x_max: 957,
      o: 'm 194 408 l 363 408 q 454 656 370 565 q 674 747 538 747 q 873 675 795 747 q 957 486 951 604 l 879 486 q 817 625 873 572 q 674 678 760 678 q 511 608 572 678 q 444 408 450 537 l 764 408 l 764 338 l 443 338 q 508 129 446 202 q 675 55 569 55 q 817 103 760 55 q 879 227 874 151 l 957 227 q 915 104 954 159 q 812 18 876 49 q 675 -14 748 -14 q 450 81 534 -14 q 362 338 367 176 l 194 338 l 194 0 l 113 0 l 113 734 l 194 734 l 194 408 z ',
    },
    Ѧ: {
      ha: 833,
      x_min: 25,
      x_max: 827,
      o: 'm 625 321 l 463 321 l 463 0 l 381 0 l 381 321 l 228 321 l 112 0 l 25 0 l 387 987 l 468 987 l 827 0 l 741 0 l 625 321 m 254 394 l 600 394 l 427 874 l 254 394 z ',
    },
    ѧ: {
      ha: 732,
      x_min: 17,
      x_max: 702,
      o: 'm 518 235 l 402 235 l 402 0 l 321 0 l 321 235 l 200 235 l 101 0 l 17 0 l 323 734 l 395 734 l 702 0 l 618 0 l 518 235 m 229 304 l 488 304 l 385 549 l 359 637 l 332 549 l 229 304 z ',
    },
    Ѩ: {
      ha: 1219,
      x_min: 155,
      x_max: 1186,
      o: 'm 238 394 l 528 394 l 746 987 l 827 987 l 1186 0 l 1099 0 l 984 321 l 822 321 l 822 0 l 740 0 l 740 321 l 587 321 l 471 0 l 384 0 l 502 322 l 238 322 l 238 0 l 155 0 l 155 987 l 238 987 l 238 394 m 612 394 l 958 394 l 786 874 l 612 394 z ',
    },
    ѩ: {
      ha: 1046,
      x_min: 136,
      x_max: 1005,
      o: 'm 218 304 l 447 304 l 627 734 l 698 734 l 1005 0 l 921 0 l 821 235 l 705 235 l 705 0 l 624 0 l 624 235 l 503 235 l 404 0 l 320 0 l 418 235 l 218 235 l 218 0 l 136 0 l 136 734 l 218 734 l 218 304 m 532 304 l 791 304 l 688 549 l 662 637 l 635 549 l 532 304 z ',
    },
    Ѫ: {
      ha: 1132,
      x_min: 106,
      x_max: 1025,
      o: 'm 106 0 l 106 268 q 183 463 109 398 q 408 528 256 528 l 475 528 l 183 987 l 952 987 l 660 528 l 724 528 q 950 462 878 528 q 1025 265 1023 396 l 1025 0 l 941 0 l 941 262 q 892 410 941 364 q 728 457 844 456 l 614 457 l 608 447 l 608 0 l 525 0 l 525 450 l 521 457 l 408 457 q 242 413 292 457 q 190 269 193 369 l 190 0 l 106 0 m 562 528 l 573 528 l 812 916 l 323 916 l 562 528 z ',
    },
    ѫ: {
      ha: 981,
      x_min: 107,
      x_max: 873,
      o: 'm 107 0 l 107 142 q 180 338 110 273 q 401 403 251 403 l 180 734 l 806 734 l 585 403 q 800 338 728 403 q 873 140 871 273 l 873 0 l 792 0 l 792 140 q 744 288 791 243 q 589 334 696 333 l 539 334 l 532 323 l 532 0 l 452 0 l 452 327 l 447 334 l 397 334 q 238 288 285 334 q 189 140 191 243 l 189 0 l 107 0 m 486 403 l 500 403 l 669 664 l 317 664 l 486 403 z ',
    },
    Ѭ: {
      ha: 1501,
      x_min: 142,
      x_max: 1394,
      o: 'm 225 529 l 812 529 l 812 528 l 844 528 l 552 987 l 1321 987 l 1029 528 l 1093 528 q 1319 462 1246 528 q 1394 265 1392 396 l 1394 0 l 1310 0 l 1310 264 q 1261 411 1309 366 q 1100 457 1213 456 l 983 457 l 977 447 l 977 0 l 894 0 l 894 450 l 890 457 l 777 457 q 611 413 661 457 q 559 269 562 369 l 559 0 l 475 0 l 475 264 q 547 460 476 395 l 225 460 l 225 0 l 142 0 l 142 987 l 225 987 l 225 529 m 930 528 l 942 528 l 1181 916 l 692 916 l 930 528 z ',
    },
    ѭ: {
      ha: 1287,
      x_min: 114,
      x_max: 1179,
      o: 'm 891 403 q 1106 338 1034 403 q 1179 140 1177 273 l 1179 0 l 1098 0 l 1098 140 q 1049 288 1097 243 q 895 334 1002 333 l 845 334 l 838 323 l 838 0 l 758 0 l 758 327 l 753 334 l 692 334 q 542 287 586 331 q 495 145 498 242 l 495 0 l 413 0 l 413 139 q 481 334 414 269 l 195 334 l 195 0 l 114 0 l 114 734 l 195 734 l 195 403 l 707 403 l 486 734 l 1112 734 l 891 403 m 792 403 l 806 403 l 975 664 l 623 664 l 792 403 z ',
    },
    Ѯ: {
      ha: 715,
      x_min: 79,
      x_max: 661,
      o: 'm 645 728 q 598 594 645 652 q 464 507 551 536 q 661 268 661 454 q 566 69 661 144 q 312 -5 471 -5 l 266 -5 q 157 -92 157 -7 q 188 -164 157 -130 q 272 -218 220 -198 l 242 -277 q 126 -200 173 -252 q 79 -90 79 -147 q 127 22 79 -18 q 275 62 176 62 l 316 62 q 507 117 435 62 q 578 265 578 172 q 509 421 578 372 q 308 470 439 470 l 218 470 l 218 540 l 304 540 q 564 730 564 540 q 503 867 564 817 q 329 918 441 917 l 105 918 l 105 987 l 319 987 q 558 918 471 987 q 645 728 645 849 m 389 1164 l 506 1296 l 578 1296 l 578 1288 l 418 1111 l 359 1111 l 200 1289 l 200 1296 l 273 1296 l 389 1164 z ',
    },
    ѯ: {
      ha: 672,
      x_min: 64,
      x_max: 594,
      o: 'm 497 541 q 444 630 497 597 q 295 664 391 663 l 94 664 l 94 734 l 289 734 q 502 681 425 734 q 579 538 579 628 q 543 442 579 485 q 437 374 506 399 q 594 206 594 334 q 510 53 594 111 q 283 -5 426 -5 l 249 -5 q 141 -92 141 -8 q 172 -163 141 -130 q 257 -218 203 -197 l 227 -277 q 110 -199 155 -251 q 64 -90 64 -147 q 113 22 64 -18 q 259 62 162 62 q 408 80 357 62 q 486 129 458 98 q 513 203 513 161 q 453 306 513 274 q 277 337 393 337 l 187 337 l 187 407 l 275 407 q 497 541 497 407 m 307 941 l 424 1073 l 496 1073 l 496 1065 l 336 887 l 277 887 l 118 1065 l 118 1073 l 191 1073 l 307 941 z ',
    },
    Ѳ: {
      ha: 935,
      x_min: 80,
      x_max: 849,
      o: 'm 849 440 q 802 201 849 304 q 667 42 755 98 q 465 -14 580 -14 q 265 42 353 -14 q 129 200 177 98 q 80 435 81 302 l 80 546 q 128 785 80 681 q 263 945 176 889 q 464 1001 351 1001 q 665 946 578 1001 q 800 791 752 891 q 849 558 847 690 l 849 440 m 163 525 l 766 525 l 766 547 q 686 827 766 726 q 464 928 605 928 q 245 826 327 928 q 163 543 163 725 l 163 525 m 766 456 l 163 456 l 163 440 q 244 162 163 264 q 465 59 326 59 q 686 160 606 59 q 766 442 766 261 l 766 456 z ',
    },
    ѳ: {
      ha: 785,
      x_min: 60,
      x_max: 715,
      o: 'm 60 378 q 101 568 60 484 q 217 700 142 653 q 387 747 292 747 q 624 644 533 747 q 715 372 715 541 l 715 355 q 674 164 715 250 q 558 33 633 79 q 389 -14 484 -14 q 151 90 241 -14 q 60 361 60 193 l 60 378 m 633 334 l 142 334 q 215 132 148 210 q 389 55 281 55 q 562 134 496 55 q 633 334 627 212 m 387 678 q 215 601 283 678 q 142 402 148 523 l 633 402 q 558 601 626 524 q 387 678 491 678 z ',
    },
    Ѵ: {
      ha: 855,
      x_min: 19,
      x_max: 836,
      o: 'm 370 173 l 391 99 l 412 173 l 619 819 q 695 957 649 915 q 814 1000 741 1000 l 836 1000 l 835 927 q 782 920 801 927 q 696 806 728 901 l 430 0 l 351 0 l 19 987 l 110 987 l 370 173 z ',
    },
    ѵ: {
      ha: 684,
      x_min: 30,
      x_max: 695,
      o: 'm 294 184 l 320 91 l 348 184 l 481 608 q 542 714 506 681 q 631 747 578 747 q 695 732 672 747 l 684 667 q 644 675 667 675 q 560 599 588 675 l 353 0 l 288 0 l 30 734 l 114 734 l 294 184 z ',
    },
    Ѻ: {
      ha: 935,
      x_min: 80,
      x_max: 849,
      o: 'm 849 440 q 807 213 849 313 q 687 56 765 113 q 508 -12 610 -2 l 508 -96 l 427 -96 l 427 -12 q 175 124 270 2 q 80 445 80 245 l 80 546 q 123 774 80 673 q 244 932 165 874 q 427 999 323 990 l 427 1080 l 508 1080 l 508 998 q 757 861 665 982 q 849 541 849 739 l 849 440 m 766 547 q 698 809 766 709 q 508 925 630 909 l 508 846 l 427 846 l 427 926 q 235 812 304 911 q 163 555 165 714 l 163 440 q 234 175 163 276 q 427 61 304 75 l 427 140 l 508 140 l 508 61 q 698 176 630 76 q 766 442 766 277 l 766 547 z ',
    },
    ѻ: {
      ha: 773,
      x_min: 60,
      x_max: 715,
      o: 'm 60 378 q 139 629 60 528 q 347 745 218 730 l 347 821 l 427 821 l 427 745 q 637 629 559 730 q 715 373 715 528 l 715 355 q 636 103 715 204 q 427 -12 557 2 l 427 -90 l 347 -90 l 347 -11 q 139 104 218 4 q 60 359 60 204 l 60 378 m 427 58 q 578 156 522 73 q 634 360 634 238 l 634 378 q 578 576 634 492 q 427 675 522 659 l 427 593 l 347 593 l 347 675 q 197 576 254 660 q 141 373 141 493 l 141 355 q 198 155 141 236 q 347 58 254 73 l 347 138 l 427 138 l 427 58 z ',
    },
    Ѽ: {
      ha: 1226,
      x_min: 115,
      x_max: 1115,
      o: 'm 850 986 q 1043 905 972 986 q 1115 682 1114 825 l 1115 292 q 1043 66 1115 147 q 850 -14 972 -14 q 709 21 770 -14 q 615 127 648 56 q 522 21 583 56 q 381 -14 460 -14 q 187 66 258 -14 q 115 293 115 147 l 115 680 q 186 905 115 823 q 381 986 258 986 l 381 916 q 249 853 298 916 q 199 683 200 791 l 199 292 q 249 119 199 182 q 381 56 298 56 q 521 117 470 56 q 573 277 572 177 l 573 599 l 657 599 l 657 277 q 709 117 658 177 q 850 56 760 56 q 982 120 934 56 q 1031 292 1031 183 l 1031 680 q 982 852 1031 789 q 850 916 934 916 l 850 986 m 926 1139 l 926 1082 l 905 1082 q 728 1136 814 1082 q 597 1189 642 1189 q 496 1077 496 1189 l 433 1077 l 433 1099 q 478 1210 434 1170 q 594 1249 522 1249 q 740 1195 654 1249 q 901 1139 825 1141 l 926 1139 m 559 935 q 608 1025 606 977 l 608 1098 l 679 1098 l 679 1027 q 652 957 678 992 q 593 904 627 922 l 559 935 z ',
    },
    ѽ: {
      ha: 1109,
      x_min: 96,
      x_max: 1011,
      o: 'm 768 742 q 945 667 880 742 q 1011 458 1010 592 l 1011 271 q 946 61 1011 137 q 768 -14 881 -14 q 639 17 695 -14 q 553 115 583 49 q 467 17 523 49 q 339 -14 411 -14 q 161 61 227 -14 q 96 269 96 136 l 96 456 q 161 666 96 591 q 339 742 226 742 l 339 673 q 221 616 265 673 q 176 460 177 559 l 176 271 q 220 112 176 170 q 339 54 264 54 q 466 110 418 54 q 513 260 513 165 l 513 425 l 593 425 l 593 254 q 642 108 595 161 q 768 54 690 54 q 884 110 840 54 q 930 263 928 166 l 930 456 q 886 615 930 557 q 768 673 842 673 l 768 742 m 869 919 l 869 861 l 849 861 q 671 915 757 861 q 595 956 623 945 q 541 968 567 968 q 439 857 439 968 l 377 857 l 377 876 q 421 989 377 949 q 538 1029 466 1029 q 601 1017 570 1029 q 681 976 633 1006 q 769 932 730 945 q 869 919 809 919 m 496 707 q 544 796 542 748 l 544 869 l 616 869 l 616 800 q 589 728 616 764 q 530 675 562 692 l 496 707 z ',
    },
    Ѿ: {
      ha: 1233,
      x_min: 115,
      x_max: 1117,
      o: 'm 1117 987 l 1117 278 q 1044 64 1116 141 q 846 -14 972 -14 q 708 22 768 -14 q 616 129 647 57 q 522 22 583 57 q 381 -14 461 -14 q 188 62 259 -14 q 115 273 117 138 l 115 987 l 199 987 l 199 277 q 249 116 200 175 q 381 56 298 56 q 521 117 470 56 q 573 278 572 177 l 573 987 l 659 987 l 659 278 q 710 117 660 177 q 846 56 760 56 q 982 116 932 56 q 1034 278 1033 176 l 1034 987 l 1117 987 m 362 1150 l 362 1206 l 874 1206 l 876 1150 l 650 1150 l 650 1074 l 575 1074 l 575 1150 l 362 1150 z ',
    },
    ѿ: {
      ha: 1143,
      x_min: 106,
      x_max: 1022,
      o: 'm 1022 734 l 1022 254 q 954 57 1021 129 q 773 -14 888 -14 q 648 17 703 -14 q 564 114 593 49 q 477 17 533 49 q 350 -14 422 -14 q 173 56 239 -14 q 106 254 108 127 l 106 734 l 187 734 l 187 258 q 239 100 188 155 q 350 54 283 54 q 476 110 429 54 q 524 260 524 165 l 524 734 l 605 734 l 605 260 q 650 109 605 164 q 773 54 696 54 q 894 107 848 54 q 941 254 939 161 l 941 734 l 1022 734 m 309 932 l 311 987 l 823 987 l 825 932 l 599 932 l 599 844 l 523 844 l 523 932 l 309 932 z ',
    },
    Ҁ: {
      ha: 879,
      x_min: 88,
      x_max: 811,
      o: 'm 510 -248 l 427 -248 l 427 -13 q 180 125 273 -1 q 88 447 88 250 l 88 552 q 135 785 88 682 q 268 945 182 888 q 463 1002 354 1002 q 713 916 621 1002 q 811 679 806 831 l 727 679 q 653 865 719 799 q 463 930 587 930 q 253 825 333 930 q 172 549 172 720 l 172 442 q 250 164 172 272 q 458 56 328 56 l 510 56 l 510 -248 z ',
    },
    ҁ: {
      ha: 726,
      x_min: 75,
      x_max: 664,
      o: 'm 439 -248 l 358 -248 l 358 -12 q 152 99 229 -1 q 75 357 75 199 l 75 381 q 114 570 75 486 q 225 701 153 654 q 389 747 296 747 q 587 674 509 747 q 664 485 664 600 l 586 485 q 531 624 586 569 q 389 678 476 678 q 220 596 283 678 q 157 375 157 515 l 157 353 q 217 139 157 221 q 382 54 278 57 l 439 54 l 439 -248 z ',
    },
    '҂': {
      ha: 847,
      x_min: 92,
      x_max: 777,
      o: 'm 391 293 l 587 176 l 551 112 l 353 227 l 227 0 l 136 0 l 288 270 l 92 386 l 128 450 l 327 334 l 476 601 l 279 717 l 317 782 l 514 666 l 652 910 l 741 910 l 578 624 l 777 508 l 737 444 l 542 559 l 391 293 z ',
    },
    '҃': {
      ha: 0,
      x_min: -584,
      x_max: -163,
      o: 'm -508 908 l -508 825 l -584 825 l -584 962 l -238 962 l -238 1035 l -163 1035 l -163 908 l -508 908 z ',
    },
    '҄': {
      ha: 0,
      x_min: -608,
      x_max: -115,
      o: 'm -276 1056 q -160 1016 -204 1056 q -115 903 -115 976 l -115 884 l -178 884 q -279 995 -178 995 q -332 984 -305 995 q -406 944 -359 972 q -496 902 -453 915 q -608 888 -539 888 l -608 946 l -580 946 q -425 1000 -508 948 q -293 1055 -342 1051 l -276 1056 z ',
    },
    '҅': {
      ha: 0,
      x_min: -442,
      x_max: -307,
      o: 'm -442 1017 l -442 1107 l -349 1107 l -349 1013 l -307 930 l -345 899 l -442 1017 z ',
    },
    '҆': {
      ha: 0,
      x_min: -408,
      x_max: -272,
      o: 'm -369 899 l -408 930 l -366 1013 l -366 1107 l -272 1107 l -272 1017 l -369 899 z ',
    },
    '҈': {
      ha: 0,
      x_min: -1020,
      x_max: 300,
      o: 'm -512 859 q -473 951 -512 916 q -370 987 -434 987 q -266 951 -305 987 q -227 859 -227 916 l -302 859 q -320 909 -302 890 q -370 928 -337 928 q -418 910 -401 928 q -435 859 -435 892 l -512 859 m -111 671 q -72 764 -111 728 q 31 799 -33 799 q 135 764 95 799 q 174 671 174 729 l 98 671 q 80 722 98 705 q 31 740 62 740 q -17 722 1 740 q -34 671 -34 704 l -111 671 m 15 330 q 55 423 15 388 q 157 458 94 458 q 261 422 222 458 q 300 330 300 387 l 224 330 q 207 381 224 363 q 157 399 189 399 q 109 380 126 399 q 92 330 92 361 l 15 330 m -118 -22 q -79 71 -118 35 q 24 106 -39 106 q 127 71 88 106 q 167 -22 167 35 l 91 -22 q 73 29 91 12 q 24 47 55 47 q -24 28 -7 47 q -41 -22 -41 9 l -118 -22 m -509 -214 q -470 -122 -509 -157 q -366 -87 -431 -87 q -262 -122 -302 -87 q -223 -214 -223 -157 l -299 -214 q -316 -164 -299 -183 q -366 -146 -334 -146 q -415 -164 -397 -146 q -432 -214 -432 -183 l -509 -214 m -901 671 q -861 764 -901 728 q -758 799 -822 799 q -654 764 -693 799 q -614 671 -614 728 l -690 671 q -708 721 -690 703 q -758 740 -725 740 q -806 722 -789 740 q -823 671 -823 704 l -901 671 m -1020 330 q -980 423 -1020 388 q -878 458 -941 458 q -774 422 -813 458 q -734 330 -734 387 l -810 330 q -828 381 -810 363 q -878 399 -846 399 q -926 380 -909 399 q -943 330 -943 361 l -1020 330 m -907 -22 q -868 71 -907 36 q -765 106 -828 106 q -661 71 -701 106 q -621 -22 -621 36 l -698 -22 q -716 29 -698 12 q -765 47 -734 47 q -813 29 -795 47 q -830 -22 -830 11 l -907 -22 z ',
    },
    '҉': {
      ha: 0,
      x_min: -1015,
      x_max: 243,
      o: 'm -318 -41 l -311 -50 l -393 -280 l -458 -280 l -411 -41 l -318 -41 m -450 764 l -458 773 l -376 1002 l -311 1002 l -358 764 l -450 764 m 9 428 l 18 436 l 243 352 l 243 286 l 9 334 l 9 428 m -780 294 l -789 286 l -1015 370 l -1015 436 l -780 388 l -780 294 m -153 680 l -152 690 l 65 794 l 111 748 l -87 612 l -153 680 m -617 14 l -618 3 l -836 -101 l -882 -54 l -683 81 l -617 14 m -712 583 l -724 585 l -824 806 l -780 852 l -646 650 l -712 583 m -60 109 l -48 108 l 52 -113 l 7 -161 l -125 42 l -60 109 z ',
    },
    Ҋ: {
      ha: 989,
      x_min: 127,
      x_max: 936,
      o: 'm 774 987 l 857 987 l 857 0 l 774 0 l 774 845 l 212 0 l 127 0 l 127 987 l 212 987 l 212 143 l 774 987 m 694 1230 q 641 1103 694 1152 q 498 1055 587 1055 q 356 1103 410 1055 q 302 1230 302 1151 l 371 1230 q 405 1143 371 1173 q 498 1114 438 1114 q 591 1145 557 1114 q 625 1230 625 1176 l 694 1230 m 841 -215 l 793 -180 q 857 -8 854 -97 l 857 97 l 936 97 l 936 12 q 841 -215 936 -129 z ',
    },
    ҋ: {
      ha: 794,
      x_min: 111,
      x_max: 748,
      o: 'm 588 734 l 669 734 l 669 0 l 588 0 l 588 603 l 192 0 l 111 0 l 111 734 l 192 734 l 192 130 l 588 734 m 584 998 q 531 871 584 920 q 388 823 477 823 q 246 871 300 823 q 192 998 192 919 l 261 998 q 295 911 261 941 q 388 882 328 882 q 481 913 448 882 q 515 998 515 944 l 584 998 m 653 -215 l 605 -180 q 669 -8 667 -97 l 669 97 l 748 97 l 748 12 q 653 -215 748 -129 z ',
    },
    ҍ: {
      ha: 743,
      x_min: -20,
      x_max: 673,
      o: 'm 416 918 l 195 918 l 195 479 l 406 479 q 549 450 488 479 q 642 366 610 420 q 673 241 673 312 q 600 65 673 130 q 404 0 527 0 l 114 0 l 114 918 l -20 918 l -20 987 l 114 987 l 114 1109 l 195 1109 l 195 987 l 416 987 l 416 918 m 195 410 l 195 68 l 405 68 q 543 114 493 68 q 592 239 592 160 q 544 362 592 315 q 412 410 496 408 l 195 410 z ',
    },
    Ҏ: {
      ha: 878,
      x_min: 122,
      x_max: 791,
      o: 'm 206 402 l 206 0 l 122 0 l 122 987 l 458 987 q 702 909 612 987 q 791 692 791 830 q 702 475 791 551 l 788 378 l 737 332 l 643 437 q 458 402 567 402 l 206 402 m 648 535 q 707 691 707 593 q 644 854 707 793 q 464 916 581 915 l 206 916 l 206 473 l 458 473 q 591 496 537 473 l 510 588 l 562 634 l 648 535 z ',
    },
    ҏ: {
      ha: 784,
      x_min: 105,
      x_max: 696,
      o: 'm 696 359 q 610 75 696 177 l 685 -16 l 633 -62 l 559 28 q 416 -14 496 -14 q 186 92 266 -14 l 186 -282 l 105 -282 l 105 734 l 180 734 l 184 630 q 414 747 264 747 q 621 648 546 747 q 696 371 696 548 l 696 359 m 614 374 q 557 595 614 514 q 396 677 499 677 q 268 641 321 677 q 186 536 214 605 l 186 184 q 269 88 215 121 q 397 55 323 55 q 511 89 463 55 l 436 181 l 489 227 l 559 140 q 614 363 614 220 l 614 374 z ',
    },
    Ґ: {
      ha: 761,
      x_min: 120,
      x_max: 717,
      o: 'm 717 918 l 715 918 l 715 916 l 203 916 l 203 0 l 120 0 l 120 987 l 635 987 l 635 1227 l 717 1227 l 717 918 z ',
    },
    ґ: {
      ha: 608,
      x_min: 107,
      x_max: 562,
      o: 'm 562 664 l 189 664 l 189 0 l 107 0 l 107 734 l 481 734 l 481 968 l 562 968 l 562 664 z ',
    },
    Ҕ: {
      ha: 802,
      x_min: 125,
      x_max: 744,
      o: 'm 721 916 l 209 916 l 209 561 l 349 561 q 643 460 541 561 q 744 174 744 359 q 706 -16 744 68 q 599 -146 669 -100 q 434 -193 529 -193 l 433 -126 q 602 -45 541 -126 q 663 174 663 37 q 582 408 663 328 q 349 489 501 489 l 209 489 l 209 0 l 125 0 l 125 987 l 721 987 l 721 916 z ',
    },
    ҕ: {
      ha: 691,
      x_min: 107,
      x_max: 636,
      o: 'm 560 664 l 189 664 l 189 424 l 281 424 q 544 341 452 424 q 636 112 636 259 q 602 -23 636 43 q 511 -129 568 -89 q 382 -182 454 -169 l 360 -119 q 503 -40 454 -98 q 555 112 553 19 q 485 289 556 227 q 283 352 413 352 l 189 352 l 189 0 l 107 0 l 107 734 l 560 734 l 560 664 z ',
    },
    Җ: {
      ha: 1271,
      x_min: 16,
      x_max: 1238,
      o: 'm 766 470 l 654 470 l 654 0 l 571 0 l 571 470 l 456 470 l 121 0 l 16 0 l 381 510 l 40 987 l 141 987 l 452 541 l 571 541 l 571 987 l 654 987 l 654 541 l 771 541 l 1082 987 l 1182 987 l 841 511 l 1207 0 l 1102 0 l 766 470 m 1238 -241 l 1156 -241 l 1156 83 l 1238 83 l 1238 -241 z ',
    },
    җ: {
      ha: 1078,
      x_min: 16,
      x_max: 1060,
      o: 'm 667 339 l 569 339 l 569 0 l 488 0 l 488 339 l 391 339 l 119 0 l 16 0 l 320 376 l 50 734 l 149 734 l 389 412 l 488 412 l 488 734 l 569 734 l 569 412 l 668 412 l 909 734 l 1008 734 l 737 376 l 1042 0 l 938 0 l 667 339 m 1060 -241 l 979 -241 l 979 83 l 1060 83 l 1060 -241 z ',
    },
    Қ: {
      ha: 928,
      x_min: 128,
      x_max: 876,
      o: 'm 347 463 l 212 463 l 212 0 l 128 0 l 128 987 l 212 987 l 212 534 l 343 534 l 725 987 l 830 987 l 425 502 l 860 0 l 756 0 l 347 463 m 876 -253 l 794 -253 l 794 71 l 876 71 l 876 -253 z ',
    },
    қ: {
      ha: 777,
      x_min: 111,
      x_max: 730,
      o: 'm 305 336 l 193 336 l 193 0 l 111 0 l 111 734 l 193 734 l 193 408 l 304 408 l 573 734 l 675 734 l 374 378 l 709 0 l 602 0 l 305 336 m 730 -241 l 648 -241 l 648 83 l 730 83 l 730 -241 z ',
    },
    Ҝ: {
      ha: 852,
      x_min: 121,
      x_max: 838,
      o: 'm 797 987 l 503 510 l 838 0 l 732 0 l 435 467 l 372 467 l 372 273 l 303 273 l 303 467 l 205 467 l 205 0 l 121 0 l 121 987 l 205 987 l 205 538 l 303 538 l 303 730 l 372 730 l 372 538 l 438 538 l 696 987 l 797 987 z ',
    },
    ҝ: {
      ha: 750,
      x_min: 110,
      x_max: 737,
      o: 'm 703 734 l 469 380 l 737 0 l 630 0 l 404 336 l 353 336 l 353 175 l 284 175 l 284 336 l 192 336 l 192 0 l 110 0 l 110 734 l 192 734 l 192 408 l 284 408 l 284 572 l 353 572 l 353 408 l 405 408 l 601 734 l 703 734 z ',
    },
    Ҡ: {
      ha: 1122,
      x_min: 55,
      x_max: 1118,
      o: 'm 605 463 l 469 463 l 469 0 l 386 0 l 386 918 l 55 918 l 55 987 l 469 987 l 469 534 l 601 534 l 983 987 l 1088 987 l 682 502 l 1118 0 l 1014 0 l 605 463 z ',
    },
    ҡ: {
      ha: 916,
      x_min: 35,
      x_max: 904,
      o: 'm 500 336 l 387 336 l 387 0 l 306 0 l 306 664 l 35 664 l 35 734 l 387 734 l 387 408 l 499 408 l 768 734 l 871 734 l 569 378 l 904 0 l 797 0 l 500 336 z ',
    },
    Ң: {
      ha: 982,
      x_min: 125,
      x_max: 929,
      o: 'm 857 0 l 772 0 l 772 473 l 208 473 l 208 0 l 125 0 l 125 987 l 208 987 l 208 544 l 772 544 l 772 987 l 857 987 l 857 0 m 929 -241 l 848 -241 l 848 83 l 929 83 l 929 -241 z ',
    },
    ң: {
      ha: 787,
      x_min: 111,
      x_max: 742,
      o: 'm 669 0 l 588 0 l 588 336 l 193 336 l 193 0 l 111 0 l 111 734 l 193 734 l 193 405 l 588 405 l 588 734 l 669 734 l 669 0 m 742 -241 l 661 -241 l 661 83 l 742 83 l 742 -241 z ',
    },
    Ҥ: {
      ha: 1399,
      x_min: 123,
      x_max: 1345,
      o: 'm 207 544 l 771 544 l 771 987 l 1345 987 l 1345 918 l 854 918 l 854 0 l 771 0 l 771 473 l 207 473 l 207 0 l 123 0 l 123 987 l 207 987 l 207 544 z ',
    },
    ҥ: {
      ha: 1005,
      x_min: 107,
      x_max: 956,
      o: 'm 189 405 l 584 405 l 584 734 l 956 734 l 956 664 l 665 664 l 665 0 l 584 0 l 584 336 l 189 336 l 189 0 l 107 0 l 107 734 l 189 734 l 189 405 z ',
    },
    Ҧ: {
      ha: 1416,
      x_min: 120,
      x_max: 1352,
      o: 'm 846 566 l 957 566 q 1251 465 1149 566 q 1352 179 1352 364 q 1315 -11 1352 74 q 1207 -141 1277 -95 q 1042 -187 1137 -187 l 1042 -121 q 1210 -39 1149 -121 q 1271 179 1271 42 q 1190 414 1271 333 q 958 494 1109 494 l 846 494 l 846 0 l 762 0 l 762 916 l 203 916 l 203 0 l 120 0 l 120 987 l 846 987 l 846 566 z ',
    },
    ҧ: {
      ha: 1183,
      x_min: 107,
      x_max: 1134,
      o: 'm 666 424 l 773 424 q 1040 341 947 424 q 1134 112 1134 258 q 1101 -23 1134 42 q 1011 -128 1067 -87 q 880 -182 955 -168 l 859 -119 q 1003 -38 953 -98 q 1053 112 1053 22 q 980 290 1054 227 q 775 352 907 352 l 666 352 l 666 0 l 585 0 l 585 664 l 189 664 l 189 0 l 107 0 l 107 734 l 666 734 l 666 424 z ',
    },
    Ҩ: {
      ha: 1032,
      x_min: 83,
      x_max: 972,
      o: 'm 972 -17 q 715 31 830 -17 q 502 -14 619 -14 q 286 44 380 -14 q 138 209 191 102 q 83 448 85 315 l 83 599 q 123 805 83 712 q 234 950 163 899 q 393 1002 304 1002 l 395 928 q 231 836 295 928 q 168 593 168 743 l 168 458 q 210 252 168 345 q 328 109 252 159 q 502 58 404 58 q 629 76 570 58 q 476 241 528 142 q 423 460 423 341 l 423 632 q 457 820 423 736 q 552 950 491 904 q 692 996 614 996 q 887 894 811 996 q 963 625 963 793 l 963 452 q 919 236 963 332 q 793 78 874 140 q 972 54 876 54 l 972 -17 m 507 458 q 560 252 507 342 q 709 113 613 162 q 835 251 790 162 q 880 453 880 340 l 880 633 q 829 843 880 763 q 692 922 778 922 q 558 843 609 922 q 507 629 507 763 l 507 458 z ',
    },
    ҩ: {
      ha: 825,
      x_min: 80,
      x_max: 781,
      o: 'm 781 -8 q 578 29 673 -8 q 407 -14 503 -14 q 238 36 313 -14 q 122 174 163 86 q 80 370 81 262 l 80 425 q 145 656 80 566 q 312 747 210 747 l 312 676 q 205 605 249 676 q 161 422 161 534 l 161 373 q 229 144 161 233 q 407 56 296 56 q 499 72 456 56 q 385 203 425 124 q 344 379 344 281 l 344 451 q 400 665 344 583 q 549 747 456 747 q 697 661 639 747 q 755 431 755 574 l 755 366 q 726 206 755 279 q 645 81 697 133 q 781 62 706 62 l 781 -8 m 425 378 q 463 221 425 289 q 570 113 500 154 q 673 375 673 201 l 673 439 q 638 608 673 541 q 549 676 604 676 q 458 615 491 676 q 425 449 425 553 l 425 378 z ',
    },
    Ҳ: {
      ha: 862,
      x_min: 37,
      x_max: 829,
      o: 'm 425 570 l 703 987 l 803 987 l 476 500 l 813 0 l 713 0 l 425 431 l 136 0 l 37 0 l 375 500 l 47 987 l 147 987 l 425 570 m 829 -241 l 747 -241 l 747 83 l 829 83 l 829 -241 z ',
    },
    ҳ: {
      ha: 686,
      x_min: 33,
      x_max: 669,
      o: 'm 336 436 l 534 734 l 631 734 l 382 374 l 642 0 l 546 0 l 337 311 l 128 0 l 33 0 l 292 374 l 43 734 l 138 734 l 336 436 m 669 -241 l 588 -241 l 588 83 l 669 83 l 669 -241 z ',
    },
    Ҵ: {
      ha: 1206,
      x_min: 24,
      x_max: 1117,
      o: 'm 294 918 l 24 918 l 24 987 l 294 987 l 294 987 l 378 987 l 378 987 l 667 987 l 667 918 l 378 918 l 378 71 l 937 71 l 937 987 l 1020 987 l 1020 72 l 1117 72 l 1111 -238 l 1034 -238 l 1034 0 l 294 0 l 294 918 z ',
    },
    ҵ: {
      ha: 907,
      x_min: 19,
      x_max: 856,
      o: 'm 223 665 l 19 665 l 19 734 l 499 734 l 499 665 l 304 665 l 304 68 l 700 68 l 700 734 l 781 734 l 781 68 l 856 68 l 850 -218 l 775 -218 l 775 0 l 223 0 l 223 665 z ',
    },
    Ҷ: {
      ha: 923,
      x_min: 117,
      x_max: 869,
      o: 'm 798 987 l 798 0 l 714 0 l 714 449 q 447 410 575 410 q 199 483 279 410 q 117 706 119 555 l 117 987 l 201 987 l 201 711 q 260 536 201 591 q 447 481 319 481 q 714 520 576 481 l 714 987 l 798 987 m 869 -241 l 788 -241 l 788 83 l 869 83 l 869 -241 z ',
    },
    ҷ: {
      ha: 746,
      x_min: 75,
      x_max: 701,
      o: 'm 628 0 l 547 0 l 547 309 q 355 272 446 272 q 143 336 209 272 q 75 536 77 400 l 75 734 l 155 734 l 155 540 q 202 388 157 435 q 355 342 247 342 q 547 377 453 345 l 547 734 l 628 734 l 628 0 m 701 -241 l 619 -241 l 619 83 l 701 83 l 701 -241 z ',
    },
    Ҹ: {
      ha: 921,
      x_min: 117,
      x_max: 798,
      o: 'm 201 987 l 201 705 q 260 536 203 590 q 439 481 317 483 l 439 713 l 509 713 l 509 484 q 714 520 606 490 l 714 987 l 798 987 l 798 0 l 714 0 l 714 449 q 509 412 602 418 l 509 244 l 439 244 l 439 410 q 198 485 277 412 q 117 706 119 557 l 117 987 l 201 987 z ',
    },
    ҹ: {
      ha: 764,
      x_min: 102,
      x_max: 656,
      o: 'm 656 0 l 574 0 l 574 309 q 436 275 505 283 l 436 160 l 366 160 l 366 272 q 165 342 228 276 q 102 547 102 408 l 102 734 l 184 734 l 184 543 q 226 392 184 440 q 366 342 268 345 l 366 541 l 436 541 l 436 345 q 574 377 511 355 l 574 734 l 656 734 l 656 0 z ',
    },
    Һ: {
      ha: 921,
      x_min: 113,
      x_max: 793,
      o: 'm 113 0 l 113 987 l 196 987 l 196 538 q 464 576 335 576 q 711 504 631 576 q 793 281 791 431 l 793 0 l 710 0 l 710 276 q 651 450 709 394 q 464 505 593 505 q 196 467 334 505 l 196 0 l 113 0 z ',
    },
    Ҽ: {
      ha: 1048,
      x_min: 24,
      x_max: 966,
      o: 'm 640 -14 q 343 95 452 -14 q 234 399 234 205 l 234 497 q 78 568 132 508 q 24 730 24 629 l 95 730 q 129 617 95 661 q 234 563 163 573 l 234 585 q 282 798 234 702 q 414 947 330 894 q 600 1000 498 1000 q 869 884 772 1000 q 966 564 966 768 l 966 495 l 318 495 l 318 393 q 402 146 318 235 q 640 56 486 56 q 905 137 793 56 l 930 75 q 640 -14 825 -14 m 318 560 l 882 560 l 882 589 q 806 837 882 747 q 600 928 730 928 q 396 831 473 928 q 318 578 318 735 l 318 560 z ',
    },
    ҽ: {
      ha: 819,
      x_min: -7,
      x_max: 751,
      o: 'm 475 -14 q 306 33 382 -14 q 189 161 231 79 q 146 346 146 243 l 146 375 q 32 443 71 391 q -7 579 -7 495 l 62 579 q 151 439 62 466 q 256 662 169 577 q 460 747 342 747 q 672 656 594 747 q 751 407 751 565 l 751 361 l 227 361 l 227 346 q 299 138 227 221 q 479 55 370 55 q 594 79 544 55 q 684 155 644 102 l 735 116 q 475 -14 646 -14 m 460 678 q 306 611 369 678 q 230 431 243 544 l 670 431 l 670 439 q 610 612 667 545 q 460 678 553 678 z ',
    },
    Ӄ: {
      ha: 852,
      x_min: 121,
      x_max: 810,
      o: 'm 205 0 l 121 0 l 121 987 l 205 987 l 205 543 l 324 543 l 694 987 l 795 987 l 424 543 q 709 440 608 541 q 810 155 810 340 q 772 -35 810 50 q 664 -166 734 -120 q 498 -212 594 -212 l 498 -144 q 664 -64 602 -144 q 726 155 726 16 q 647 388 726 307 q 416 469 568 469 l 205 469 l 205 0 z ',
    },
    ӄ: {
      ha: 732,
      x_min: 110,
      x_max: 677,
      o: 'm 380 406 q 600 314 523 392 q 677 105 677 237 q 643 -26 677 37 q 554 -130 610 -90 q 423 -182 498 -169 l 401 -119 q 543 -42 492 -100 q 596 105 594 16 q 526 276 597 216 q 318 336 455 336 l 192 336 l 192 0 l 110 0 l 110 734 l 192 734 l 192 408 l 283 408 l 572 734 l 675 734 l 380 406 z ',
    },
    Ӆ: {
      ha: 980,
      x_min: 27,
      x_max: 927,
      o: 'm 848 987 l 848 0 l 765 0 l 765 916 l 348 916 l 325 508 q 283 190 311 289 q 203 46 255 90 q 62 0 151 1 l 27 0 l 27 71 l 54 71 q 159 115 124 75 q 214 247 195 156 q 244 529 233 338 l 269 987 l 848 987 m 832 -215 l 784 -180 q 848 -8 846 -97 l 848 97 l 927 97 l 927 12 q 832 -215 927 -129 z ',
    },
    ӆ: {
      ha: 795,
      x_min: 30,
      x_max: 749,
      o: 'm 670 734 l 670 0 l 589 0 l 589 664 l 288 664 l 275 389 q 245 156 267 237 q 178 38 222 76 q 61 0 134 0 l 30 0 l 30 72 l 55 73 q 155 141 125 75 q 195 378 186 208 l 210 734 l 670 734 m 654 -215 l 606 -180 q 669 -8 667 -97 l 669 97 l 749 97 l 749 12 q 654 -215 749 -129 z ',
    },
    Ӈ: {
      ha: 979,
      x_min: 126,
      x_max: 851,
      o: 'm 210 987 l 210 524 l 768 524 l 768 987 l 851 987 l 851 -58 q 797 -235 851 -174 q 644 -296 742 -296 q 567 -283 606 -296 l 576 -214 q 644 -225 606 -225 q 735 -182 703 -225 q 768 -58 768 -138 l 768 453 l 210 453 l 210 0 l 126 0 l 126 987 l 210 987 z ',
    },
    ӈ: {
      ha: 773,
      x_min: 107,
      x_max: 665,
      o: 'm 189 734 l 189 405 l 584 405 l 584 734 l 665 734 l 665 -60 q 611 -236 665 -176 q 459 -296 556 -296 q 383 -283 420 -296 l 393 -216 q 459 -227 418 -227 q 551 -184 519 -227 q 584 -60 584 -140 l 584 336 l 189 336 l 189 0 l 107 0 l 107 734 l 189 734 z ',
    },
    Ӊ: {
      ha: 988,
      x_min: 125,
      x_max: 935,
      o: 'm 857 0 l 772 0 l 772 473 l 208 473 l 208 0 l 125 0 l 125 987 l 208 987 l 208 544 l 772 544 l 772 987 l 857 987 l 857 0 m 840 -215 l 792 -180 q 856 -8 854 -97 l 856 97 l 935 97 l 935 12 q 840 -215 935 -129 z ',
    },
    ӊ: {
      ha: 793,
      x_min: 111,
      x_max: 747,
      o: 'm 669 0 l 588 0 l 588 336 l 193 336 l 193 0 l 111 0 l 111 734 l 193 734 l 193 405 l 588 405 l 588 734 l 669 734 l 669 0 m 652 -215 l 604 -180 q 668 -8 666 -97 l 668 97 l 747 97 l 747 12 q 652 -215 747 -129 z ',
    },
    Ӎ: {
      ha: 1204,
      x_min: 125,
      x_max: 1150,
      o: 'm 235 987 l 600 116 l 965 987 l 1076 987 l 1076 0 l 993 0 l 993 430 l 1000 871 l 632 0 l 568 0 l 201 867 l 208 433 l 208 0 l 125 0 l 125 987 l 235 987 m 1055 -215 l 1007 -180 q 1071 -8 1069 -97 l 1071 97 l 1150 97 l 1150 12 q 1055 -215 1150 -129 z ',
    },
    ӎ: {
      ha: 1029,
      x_min: 115,
      x_max: 983,
      o: 'm 512 110 l 804 734 l 904 734 l 904 0 l 823 0 l 823 602 l 541 0 l 483 0 l 195 616 l 195 0 l 115 0 l 115 734 l 221 734 l 512 110 m 888 -215 l 840 -180 q 904 -8 902 -97 l 904 97 l 983 97 l 983 12 q 888 -215 983 -129 z ',
    },
    Ә: {
      ha: 972,
      x_min: 84,
      x_max: 890,
      o: 'm 464 1001 q 776 878 662 1001 q 890 539 890 755 l 890 455 q 838 214 890 321 q 694 46 786 106 q 492 -14 602 -14 q 193 101 302 -14 q 84 420 84 216 l 84 500 l 806 500 l 806 546 q 716 828 806 726 q 464 929 627 929 q 186 848 304 929 l 159 910 q 464 1001 276 1001 m 492 58 q 712 160 626 58 q 806 432 798 263 l 168 432 l 168 396 q 254 148 168 239 q 492 58 339 58 z ',
    },
    Ӡ: {
      ha: 800,
      x_min: 77,
      x_max: 703,
      o: 'm 593 916 l 98 916 l 98 987 l 690 987 l 691 922 l 391 569 q 624 489 545 567 q 703 268 703 412 q 616 62 703 138 q 389 -14 530 -14 q 229 21 301 -14 q 117 121 157 56 q 77 274 77 186 l 161 274 q 225 117 161 178 q 389 56 290 56 q 558 113 496 56 q 620 265 620 170 q 562 444 620 385 q 382 503 503 502 l 298 503 l 298 571 l 593 916 z ',
    },
    ӡ: {
      ha: 800,
      x_min: 77,
      x_max: 702,
      o: 'm 588 664 l 98 664 l 98 734 l 688 734 l 689 671 l 391 312 q 621 229 541 307 q 702 14 702 152 q 663 -135 702 -71 q 552 -234 624 -199 q 389 -268 480 -268 q 230 -233 302 -268 q 117 -133 158 -198 q 77 20 77 -68 l 159 20 q 223 -137 159 -75 q 389 -199 287 -199 q 559 -142 497 -199 q 621 11 621 -85 q 381 248 621 241 l 297 248 l 297 317 l 588 664 z ',
    },
    Ӻ: {
      ha: 790,
      x_min: 17,
      x_max: 744,
      o: 'm 744 916 l 232 916 l 232 0 l 149 0 l 149 987 l 744 987 l 744 916 m 432 511 l 17 511 l 17 581 l 432 581 l 432 511 m 300 92 l 300 -66 q 245 -237 299 -178 q 96 -296 191 -296 q 20 -283 57 -296 l 28 -214 q 96 -224 56 -224 q 220 -53 220 -224 l 220 92 l 300 92 z ',
    },
    ӻ: {
      ha: 612,
      x_min: 12,
      x_max: 590,
      o: 'm 590 664 l 219 664 l 219 0 l 138 0 l 138 734 l 590 734 l 590 664 m 435 382 l 20 382 l 20 451 l 435 451 l 435 382 m 292 92 l 292 -66 q 237 -237 291 -178 q 87 -296 183 -296 q 12 -283 49 -296 l 20 -214 q 87 -224 47 -224 q 212 -53 212 -224 l 212 92 l 292 92 z ',
    },
    Ӽ: {
      ha: 870,
      x_min: 37,
      x_max: 836,
      o: 'm 425 570 l 703 987 l 803 987 l 476 500 l 813 0 l 713 0 l 425 431 l 136 0 l 37 0 l 375 500 l 47 987 l 147 987 l 425 570 m 836 92 l 836 -66 q 781 -237 835 -178 q 631 -296 727 -296 q 555 -283 593 -296 l 564 -214 q 631 -224 591 -224 q 755 -53 755 -224 l 755 92 l 836 92 z ',
    },
    ӽ: {
      ha: 693,
      x_min: 33,
      x_max: 677,
      o: 'm 336 436 l 534 734 l 631 734 l 382 374 l 642 0 l 546 0 l 337 311 l 128 0 l 33 0 l 292 374 l 43 734 l 138 734 l 336 436 m 677 92 l 677 -66 q 622 -237 675 -178 q 472 -296 568 -296 q 396 -283 433 -296 l 405 -214 q 472 -224 432 -224 q 596 -53 596 -224 l 596 92 l 677 92 z ',
    },
    Ԁ: {
      ha: 795,
      x_min: 51,
      x_max: 706,
      o: 'm 622 572 l 622 987 l 706 987 l 706 0 l 378 0 q 139 78 227 0 q 51 288 51 155 q 138 495 51 418 q 376 572 224 572 l 622 572 m 622 71 l 622 502 l 378 502 q 200 445 265 502 q 134 289 134 387 q 198 131 134 191 q 372 71 261 72 l 622 71 z ',
    },
    Ԃ: {
      ha: 1084,
      x_min: 52,
      x_max: 1050,
      o: 'm 378 0 q 140 78 228 0 q 52 288 52 155 q 139 495 52 419 q 376 572 227 572 l 623 572 l 623 987 l 707 987 l 707 69 l 789 70 q 917 123 871 71 q 965 274 962 176 q 924 509 968 389 l 1004 509 q 1036 393 1022 452 q 1048 274 1050 333 q 976 74 1046 149 q 785 0 905 0 l 378 0 m 623 71 l 623 502 l 378 502 q 201 445 267 502 q 136 289 136 387 q 198 132 136 191 q 370 71 261 73 l 623 71 z ',
    },
    ԃ: {
      ha: 1193,
      x_min: 75,
      x_max: 1095,
      o: 'm 75 361 q 151 644 75 541 q 357 747 227 747 q 578 631 501 747 l 578 1058 l 659 1058 l 659 234 q 695 101 659 147 q 794 54 730 54 q 951 134 894 56 q 1011 359 1008 212 q 968 631 1013 474 l 1047 632 l 1063 572 q 1093 359 1095 448 q 1009 85 1090 184 q 794 -14 929 -14 q 595 123 640 -17 q 355 -14 519 -14 q 158 74 233 -14 q 76 318 83 163 l 75 361 m 578 544 q 372 677 517 677 q 214 593 271 677 q 157 347 157 509 q 212 132 157 208 q 370 56 268 56 q 581 188 519 56 l 578 234 l 578 544 z ',
    },
    Ԅ: {
      ha: 1097,
      x_min: 35,
      x_max: 1004,
      o: 'm 172 457 l 172 529 l 292 529 q 471 580 413 530 q 529 730 529 630 q 274 916 529 916 l 35 916 l 35 987 l 285 987 q 528 920 444 986 q 613 729 613 854 q 567 581 613 643 q 431 490 520 518 q 578 260 578 444 l 578 205 q 614 97 579 138 q 706 56 648 56 q 857 140 799 58 q 918 359 915 223 q 873 632 920 475 l 956 632 l 971 572 q 1002 359 1004 446 q 917 88 999 191 q 705 -14 834 -14 q 552 39 604 -17 q 495 206 499 95 l 495 257 q 445 405 495 353 q 303 457 394 457 l 172 457 z ',
    },
    ԅ: {
      ha: 894,
      x_min: 31,
      x_max: 844,
      o: 'm 483 144 q 506 73 483 94 q 578 52 528 52 q 712 115 666 54 q 761 284 759 176 q 713 500 764 392 l 797 500 q 829 392 815 446 q 842 284 844 338 q 768 65 840 146 q 578 -16 696 -16 q 448 20 490 -18 q 401 145 406 59 l 401 197 q 229 323 401 323 l 90 323 l 89 391 l 237 391 q 363 428 317 393 q 408 522 408 462 q 358 625 408 585 q 218 667 307 665 l 35 667 l 31 734 l 218 734 q 418 677 347 732 q 490 524 490 621 q 361 361 490 410 q 483 204 481 328 l 483 144 z ',
    },
    Ԇ: {
      ha: 741,
      x_min: 66,
      x_max: 668,
      o: 'm 136 446 l 136 515 l 258 515 q 441 568 378 517 q 505 715 505 619 q 445 864 505 810 q 258 918 385 918 l 66 918 l 66 987 l 261 987 q 501 917 417 986 q 585 714 585 848 q 540 573 585 635 q 412 483 494 512 q 542 405 498 461 q 585 247 585 348 q 586 132 585 146 q 589 104 587 117 l 668 104 l 668 9 q 644 -107 668 -50 q 573 -208 619 -165 l 524 -173 q 588 0 585 -92 l 549 0 q 503 166 503 46 l 503 248 q 444 394 503 341 q 280 446 385 446 l 136 446 z ',
    },
    ԇ: {
      ha: 696,
      x_min: 76,
      x_max: 629,
      o: 'm 135 317 l 134 386 l 299 386 q 479 522 479 390 q 298 665 479 661 l 76 665 l 77 734 l 286 734 q 489 680 418 734 q 560 524 560 626 q 429 355 560 399 q 559 184 559 321 l 559 109 l 629 109 l 629 14 q 604 -105 629 -48 q 534 -203 578 -163 l 486 -168 q 549 0 546 -85 l 501 0 q 478 109 479 24 l 478 177 q 302 317 478 317 l 135 317 z ',
    },
    Ԉ: {
      ha: 1343,
      x_min: 45,
      x_max: 1269,
      o: 'm 751 916 l 365 916 l 342 507 q 301 193 329 291 q 223 48 274 94 q 84 0 172 3 l 45 0 l 45 71 l 71 71 q 175 113 140 75 q 230 242 210 152 q 260 517 250 333 l 286 987 l 836 987 l 836 234 q 871 101 836 146 q 969 56 907 56 q 1125 135 1068 56 q 1184 359 1181 214 q 1140 631 1185 475 l 1222 632 q 1254 497 1240 566 q 1267 359 1269 429 q 1184 84 1265 183 q 969 -14 1103 -14 q 808 47 863 -14 q 751 228 753 109 l 751 916 z ',
    },
    ԉ: {
      ha: 1118,
      x_min: 35,
      x_max: 1053,
      o: 'm 570 664 l 293 664 l 279 361 q 220 83 267 164 q 71 0 174 2 l 35 0 l 35 72 l 60 73 q 159 138 128 75 q 199 366 190 201 l 215 734 l 651 734 l 651 234 q 685 99 651 145 q 779 54 720 54 q 917 122 866 56 q 970 314 967 189 q 926 572 972 427 l 1005 573 l 1021 516 q 1051 314 1053 395 q 976 73 1048 161 q 779 -14 903 -14 q 623 48 676 -14 q 570 233 570 111 l 570 664 z ',
    },
    Ԋ: {
      ha: 1353,
      x_min: 128,
      x_max: 1241,
      o: 'm 807 987 l 807 230 q 843 101 807 145 q 941 56 880 56 q 1097 136 1040 58 q 1156 359 1153 215 q 1112 631 1158 471 l 1194 632 q 1226 497 1212 566 q 1240 359 1241 429 q 1156 84 1237 183 q 941 -14 1075 -14 q 723 234 733 -20 l 723 452 l 212 452 l 212 0 l 128 0 l 128 987 l 212 987 l 212 524 l 723 524 l 723 987 l 807 987 z ',
    },
    ԋ: {
      ha: 1147,
      x_min: 102,
      x_max: 1059,
      o: 'm 576 333 l 183 333 l 183 0 l 102 0 l 102 734 l 183 734 l 183 401 l 576 401 l 576 734 l 657 734 l 657 227 q 693 98 658 142 q 785 54 728 54 q 923 122 872 56 q 976 314 973 189 q 932 572 978 422 l 1011 573 l 1027 516 q 1057 314 1059 395 q 982 73 1055 161 q 785 -14 909 -14 q 633 45 686 -17 q 576 234 581 108 l 576 333 z ',
    },
    Ԍ: {
      ha: 858,
      x_min: 92,
      x_max: 793,
      o: 'm 471 -14 q 275 40 361 -14 q 140 190 188 94 q 92 405 92 286 l 92 583 q 140 797 92 701 q 275 947 188 893 q 471 1002 361 1002 q 699 950 611 1002 l 671 884 q 471 930 581 930 q 258 833 340 930 q 176 577 176 736 l 176 405 q 258 154 176 251 q 471 56 340 56 q 644 118 582 58 q 709 288 707 178 q 675 533 711 393 l 758 533 q 787 384 780 432 q 793 288 793 335 q 701 67 790 148 q 471 -14 612 -14 z ',
    },
    ԍ: {
      ha: 711,
      x_min: 79,
      x_max: 654,
      o: 'm 400 54 q 572 204 569 56 q 556 364 572 287 l 635 364 q 653 204 654 262 q 584 43 650 100 q 400 -14 517 -14 q 169 89 258 -14 q 79 361 79 192 l 79 381 q 118 568 79 484 q 226 700 156 653 q 386 747 296 747 q 570 705 511 747 l 547 640 q 386 678 482 678 q 222 594 283 678 q 161 373 161 511 l 161 353 q 177 237 161 294 q 227 139 194 180 q 400 54 293 54 z ',
    },
    Ԏ: {
      ha: 982,
      x_min: 22,
      x_max: 888,
      o: 'm 370 916 l 22 916 l 22 987 l 804 987 l 804 916 l 454 916 l 454 234 q 490 101 454 146 q 589 56 526 56 q 745 138 689 58 q 804 359 801 218 q 759 631 805 478 l 841 632 l 859 562 q 886 359 888 446 q 803 85 884 184 q 589 -14 723 -14 q 431 44 486 -17 q 370 234 376 106 l 370 916 z ',
    },
    ԏ: {
      ha: 886,
      x_min: 45,
      x_max: 798,
      o: 'm 308 665 l 45 665 l 45 734 l 661 734 l 661 665 l 390 665 l 390 234 q 426 100 389 146 q 525 54 463 54 q 663 117 612 56 q 716 296 713 179 q 672 536 718 399 l 751 537 q 790 385 783 433 q 798 296 798 338 q 721 68 795 151 q 525 -14 648 -14 q 369 44 424 -17 q 308 234 313 106 l 308 665 z ',
    },
    Ԑ: {
      ha: 917,
      x_min: 98,
      x_max: 849,
      o: 'm 182 265 q 261 113 182 169 q 481 56 341 56 q 686 117 606 56 q 766 274 766 178 l 849 274 q 805 124 849 189 q 674 23 760 60 q 481 -14 587 -14 q 201 63 305 -14 q 98 268 98 140 q 150 420 98 359 q 304 506 201 480 q 164 594 216 534 q 113 728 113 653 q 210 929 113 857 q 481 1002 307 1002 q 661 967 579 1002 q 789 870 743 932 q 836 728 836 808 l 752 728 q 674 874 752 817 q 481 930 597 930 q 272 878 348 930 q 197 730 197 825 q 267 588 197 637 q 473 539 338 540 l 604 539 l 604 467 l 475 467 q 256 419 331 467 q 182 265 182 371 z ',
    },
    Ԓ: {
      ha: 981,
      x_min: 27,
      x_max: 928,
      o: 'm 848 987 l 848 0 l 765 0 l 765 916 l 348 916 l 325 508 q 283 190 311 289 q 203 46 255 90 q 62 0 151 1 l 27 0 l 27 71 l 54 71 q 159 115 124 75 q 214 247 195 156 q 244 529 233 338 l 269 987 l 848 987 m 928 92 l 928 -66 q 873 -237 927 -178 q 724 -296 819 -296 q 648 -283 685 -296 l 656 -214 q 724 -224 684 -224 q 848 -53 848 -224 l 848 92 l 928 92 z ',
    },
    ԓ: {
      ha: 796,
      x_min: 30,
      x_max: 751,
      o: 'm 670 734 l 670 0 l 589 0 l 589 664 l 288 664 l 275 389 q 245 156 267 237 q 178 38 222 76 q 61 0 134 0 l 30 0 l 30 72 l 55 73 q 155 141 125 75 q 195 378 186 208 l 210 734 l 670 734 m 751 92 l 751 -66 q 695 -237 749 -178 q 546 -296 642 -296 q 470 -283 507 -296 l 479 -214 q 546 -224 506 -224 q 670 -53 670 -224 l 670 92 l 751 92 z ',
    },
    ' ': { ha: 708, x_min: 0, x_max: 0, o: '' },
    ' ': { ha: 1417, x_min: 0, x_max: 0, o: '' },
    ' ': { ha: 708, x_min: 0, x_max: 0, o: '' },
    ' ': { ha: 1417, x_min: 0, x_max: 0, o: '' },
    ' ': { ha: 473, x_min: 0, x_max: 0, o: '' },
    ' ': { ha: 354, x_min: 0, x_max: 0, o: '' },
    ' ': { ha: 236, x_min: 0, x_max: 0, o: '' },
    ' ': { ha: 781, x_min: 0, x_max: 0, o: '' },
    ' ': { ha: 380, x_min: 0, x_max: 0, o: '' },
    ' ': { ha: 283, x_min: 0, x_max: 0, o: '' },
    ' ': { ha: 142, x_min: 0, x_max: 0, o: '' },
    '​': { ha: 0, x_min: 0, x_max: 0, o: '' },
    '‐': {
      ha: 392,
      x_min: 33,
      x_max: 358,
      o: 'm 358 401 l 33 401 l 33 470 l 358 470 l 358 401 z ',
    },
    '‑': {
      ha: 392,
      x_min: 33,
      x_max: 358,
      o: 'm 358 401 l 33 401 l 33 470 l 358 470 l 358 401 z ',
    },
    '–': {
      ha: 905,
      x_min: 108,
      x_max: 787,
      o: 'm 787 465 l 108 465 l 108 534 l 787 534 l 787 465 z ',
    },
    '—': {
      ha: 1072,
      x_min: 85,
      x_max: 991,
      o: 'm 991 465 l 85 465 l 85 534 l 991 534 l 991 465 z ',
    },
    '‗': {
      ha: 629,
      x_min: 31,
      x_max: 628,
      o: 'm 628 -234 l 31 -234 l 31 -165 l 628 -165 l 628 -234 m 628 -68 l 31 -68 l 31 0 l 628 0 l 628 -68 z ',
    },
    '‘': {
      ha: 247,
      x_min: 66,
      x_max: 210,
      o: 'm 161 1054 l 210 1019 q 147 847 149 939 l 147 760 l 66 760 l 66 836 q 92 956 66 899 q 161 1054 117 1014 z ',
    },
    '’': {
      ha: 247,
      x_min: 42,
      x_max: 185,
      o: 'm 90 745 l 42 781 q 105 953 103 863 l 105 1042 l 185 1042 l 185 973 q 90 745 185 831 z ',
    },
    '‚': {
      ha: 239,
      x_min: 28,
      x_max: 171,
      o: 'm 76 -157 l 28 -121 q 90 35 84 -46 l 90 139 l 171 139 l 171 71 q 76 -157 171 -71 z ',
    },
    '‛': {
      ha: 247,
      x_min: 60,
      x_max: 204,
      o: 'm 141 1042 l 141 953 q 204 781 143 863 l 155 745 q 60 973 60 833 l 60 1042 l 141 1042 z ',
    },
    '“': {
      ha: 415,
      x_min: 67,
      x_max: 381,
      o: 'm 162 1054 l 211 1019 q 148 847 150 939 l 148 760 l 67 760 l 67 836 q 93 956 67 899 q 162 1054 118 1014 m 332 1054 l 381 1019 q 318 847 320 939 l 318 760 l 237 760 l 237 836 q 263 956 237 899 q 332 1054 288 1014 z ',
    },
    '”': {
      ha: 418,
      x_min: 46,
      x_max: 355,
      o: 'm 94 745 l 46 781 q 109 953 107 863 l 109 1042 l 189 1042 l 189 973 q 94 745 189 831 m 260 745 l 212 781 q 275 953 273 863 l 275 1042 l 355 1042 l 355 973 q 260 745 355 831 z ',
    },
    '„': {
      ha: 402,
      x_min: 28,
      x_max: 330,
      o: 'm 76 -163 l 28 -127 q 90 50 88 -44 l 90 160 l 171 160 l 171 72 q 76 -163 171 -75 m 235 -163 l 186 -127 q 248 50 246 -45 l 248 160 l 330 160 l 330 72 q 235 -163 330 -75 z ',
    },
    '†': {
      ha: 764,
      x_min: 45,
      x_max: 717,
      o: 'm 717 663 l 424 663 l 424 0 l 342 0 l 342 663 l 45 663 l 45 734 l 342 734 l 342 987 l 424 987 l 424 734 l 717 734 l 717 663 z ',
    },
    '‡': {
      ha: 783,
      x_min: 57,
      x_max: 728,
      o: 'm 728 0 l 427 0 l 427 -282 l 345 -282 l 345 0 l 57 0 l 57 68 l 345 68 l 345 664 l 57 664 l 57 734 l 345 734 l 345 987 l 427 987 l 427 734 l 728 734 l 728 664 l 427 664 l 427 68 l 728 68 l 728 0 z ',
    },
    '•': {
      ha: 449,
      x_min: 99,
      x_max: 351,
      o: 'm 99 510 q 134 597 99 564 q 224 631 168 631 q 315 598 280 631 q 351 513 349 566 l 351 486 q 316 399 351 431 q 225 366 281 366 q 134 399 168 366 q 99 488 99 433 l 99 510 z ',
    },
    '‥': {
      ha: 630,
      x_min: 113,
      x_max: 507,
      o: 'm 113 49 q 128 89 113 73 q 170 106 143 106 q 213 89 197 106 q 229 49 229 73 q 213 10 229 25 q 170 -5 197 -5 q 128 10 143 -5 q 113 49 113 25 m 391 49 q 406 89 391 73 q 448 106 421 106 q 491 89 475 106 q 507 49 507 73 q 491 10 507 25 q 448 -5 475 -5 q 406 10 421 -5 q 391 49 391 25 z ',
    },
    '…': {
      ha: 890,
      x_min: 113,
      x_max: 772,
      o: 'm 113 49 q 128 89 113 73 q 170 106 143 106 q 213 89 197 106 q 229 49 229 73 q 213 10 229 25 q 170 -5 197 -5 q 128 10 143 -5 q 113 49 113 25 m 391 49 q 406 89 391 73 q 448 106 421 106 q 491 89 475 106 q 507 49 507 73 q 491 10 507 25 q 448 -5 475 -5 q 406 10 421 -5 q 391 49 391 25 m 656 49 q 672 89 656 73 q 714 106 687 106 q 757 89 741 106 q 772 49 772 73 q 757 10 772 25 q 714 -5 741 -5 q 672 10 687 -5 q 656 49 656 25 z ',
    },
    '‧': {
      ha: 235,
      x_min: 62,
      x_max: 173,
      o: 'm 62 436 q 76 475 62 459 q 117 490 91 490 q 158 475 143 490 q 173 436 173 459 q 158 397 173 413 q 117 382 143 382 q 76 397 91 382 q 62 436 62 413 z ',
    },
    '‰': {
      ha: 1355,
      x_min: 62,
      x_max: 1284,
      o: 'm 574 242 q 628 388 574 330 q 766 446 683 446 q 864 419 823 446 q 927 347 905 392 q 993 419 951 392 q 1091 446 1035 446 q 1230 388 1175 446 q 1284 237 1284 331 l 1284 189 q 1231 44 1284 102 q 1093 -14 1177 -14 q 993 13 1036 -14 q 927 83 951 39 q 865 13 905 39 q 768 -14 824 -14 q 629 43 684 -14 q 574 193 574 100 l 574 242 m 62 798 q 116 944 62 886 q 255 1002 171 1002 q 394 944 339 1002 q 448 793 448 886 l 448 745 q 394 601 448 659 q 256 543 340 543 q 117 600 173 543 q 62 750 62 656 l 62 798 m 640 189 q 674 85 640 126 q 768 44 708 44 q 860 85 826 44 q 895 193 895 126 l 895 242 q 860 347 895 307 q 766 387 825 387 q 674 346 709 387 q 640 239 640 306 l 640 189 m 960 189 q 995 85 960 126 q 1088 44 1029 44 q 1180 84 1146 44 q 1215 192 1215 125 l 1215 242 q 1180 347 1215 308 q 1087 387 1145 387 q 995 347 1030 387 q 960 240 960 307 l 960 189 m 127 745 q 162 642 127 682 q 256 601 197 601 q 348 642 314 601 q 382 748 382 683 l 382 798 q 347 902 382 861 q 255 943 312 943 q 163 901 199 943 q 127 793 127 860 l 127 745 m 287 82 l 237 115 l 719 886 l 769 854 l 287 82 z ',
    },
    '‹': {
      ha: 413,
      x_min: 73,
      x_max: 345,
      o: 'm 157 376 l 345 113 l 273 113 l 73 370 l 73 383 l 273 640 l 345 640 l 157 376 z ',
    },
    '›': {
      ha: 413,
      x_min: 66,
      x_max: 338,
      o: 'm 138 640 l 338 380 l 338 367 l 138 110 l 66 110 l 253 373 l 66 640 l 138 640 z ',
    },
    '⁄': {
      ha: 623,
      x_min: 42,
      x_max: 574,
      o: 'm 92 82 l 42 115 l 524 886 l 574 854 l 92 82 z ',
    },
    '⁴': {
      ha: 502,
      x_min: 40,
      x_max: 471,
      o: 'm 385 637 l 471 637 l 471 578 l 385 578 l 385 451 l 313 451 l 313 578 l 43 578 l 40 623 l 309 986 l 385 986 l 385 637 m 117 637 l 313 637 l 313 898 l 292 864 l 117 637 z ',
    },
    ⁿ: {
      ha: 585,
      x_min: 83,
      x_max: 508,
      o: 'm 142 985 l 155 892 q 223 967 181 940 q 321 994 266 994 q 508 784 508 994 l 508 441 l 433 441 l 433 787 q 302 929 429 929 q 210 898 245 929 q 159 817 175 867 l 159 441 l 83 441 l 83 985 l 142 985 z ',
    },
    '₤': {
      ha: 793,
      x_min: 65,
      x_max: 742,
      o: 'm 513 323 l 277 323 l 280 250 q 237 71 281 132 l 742 71 l 742 0 l 66 0 l 66 71 l 132 71 q 184 143 167 79 q 197 249 198 193 l 194 323 l 65 323 l 65 384 l 192 384 l 189 498 l 65 498 l 65 559 l 186 559 l 182 705 q 257 921 182 840 q 457 1001 332 1001 q 652 929 581 1001 q 724 738 724 858 l 640 738 q 589 880 640 829 q 451 930 538 930 q 316 870 366 930 q 266 705 266 810 l 270 559 l 513 559 l 513 498 l 272 498 l 276 384 l 513 384 l 513 323 z ',
    },
    '₦': {
      ha: 1068,
      x_min: 24,
      x_max: 1044,
      o: 'm 901 620 l 1044 620 l 1044 551 l 901 551 l 901 416 l 1044 416 l 1044 347 l 901 347 l 901 0 l 817 0 l 583 347 l 249 347 l 249 0 l 165 0 l 165 347 l 24 347 l 24 416 l 165 416 l 165 551 l 24 551 l 24 620 l 165 620 l 165 987 l 249 987 l 496 620 l 817 620 l 817 987 l 901 987 l 901 620 m 249 416 l 536 416 l 446 551 l 249 551 l 249 416 m 633 416 l 817 416 l 817 551 l 543 551 l 633 416 m 249 620 l 400 620 l 249 845 l 249 620 m 817 142 l 817 347 l 679 347 l 817 142 z ',
    },
    '₧': {
      ha: 1136,
      x_min: 125,
      x_max: 1032,
      o: 'm 1019 667 l 867 667 l 867 178 q 889 87 867 117 q 963 58 911 58 q 1029 64 983 58 l 1032 -2 q 945 -14 1000 -14 q 824 35 862 -14 q 786 178 786 83 l 786 667 l 675 667 q 589 471 668 542 q 370 397 510 399 l 206 397 l 206 0 l 125 0 l 125 987 l 359 987 q 578 922 496 987 q 674 734 661 857 l 786 734 l 786 922 l 867 922 l 867 734 l 1019 734 l 1019 667 m 206 466 l 359 466 q 534 525 473 466 q 595 691 595 583 q 534 860 595 801 q 361 918 473 918 l 206 918 l 206 466 z ',
    },
    '₨': {
      ha: 1453,
      x_min: 123,
      x_max: 1375,
      o: 'm 494 411 l 207 411 l 207 0 l 123 0 l 123 987 l 444 987 q 690 911 602 987 q 779 696 779 834 q 724 529 779 602 q 574 429 668 456 l 821 9 l 821 0 l 732 0 l 494 411 m 207 482 l 463 482 q 631 542 568 482 q 695 696 695 602 q 629 859 695 801 q 442 916 562 916 l 207 916 l 207 482 m 1294 186 q 1245 283 1294 247 q 1099 341 1197 320 q 948 388 1002 361 q 868 454 894 415 q 842 547 842 492 q 914 691 842 634 q 1100 747 987 747 q 1297 687 1223 747 q 1371 528 1371 626 l 1290 528 q 1236 635 1290 593 q 1100 678 1182 678 q 971 643 1020 678 q 923 550 923 607 q 963 465 923 495 q 1111 410 1004 435 q 1271 356 1217 385 q 1350 288 1324 328 q 1375 191 1375 248 q 1300 42 1375 98 q 1105 -14 1225 -14 q 897 48 977 -14 q 817 205 817 111 l 899 205 q 959 95 903 134 q 1105 55 1014 55 q 1242 92 1190 55 q 1294 186 1294 130 z ',
    },
    '₩': {
      ha: 1055,
      x_min: 24,
      x_max: 1031,
      o: 'm 638 654 l 844 654 l 912 987 l 998 987 l 925 654 l 1031 654 l 1031 585 l 910 585 l 878 435 l 1031 435 l 1031 366 l 863 366 l 784 0 l 701 0 l 625 366 l 429 366 l 352 0 l 269 0 l 189 366 l 24 366 l 24 435 l 174 435 l 142 585 l 24 585 l 24 654 l 127 654 l 54 987 l 140 987 l 209 654 l 417 654 l 488 987 l 569 987 l 638 654 m 254 435 l 371 435 l 403 585 l 223 585 l 254 435 m 684 435 l 800 435 l 830 585 l 652 585 l 684 435 m 444 435 l 610 435 l 580 585 l 475 585 l 444 435 m 338 278 l 357 366 l 269 366 l 287 279 l 311 127 l 338 278 m 769 286 l 785 366 l 698 366 l 715 284 l 743 126 l 769 286 m 490 654 l 566 654 l 554 709 l 528 857 l 501 707 l 490 654 z ',
    },
    '₪': {
      ha: 1059,
      x_min: 105,
      x_max: 954,
      o: 'm 443 734 q 618 667 562 734 q 677 464 675 601 l 677 233 l 596 233 l 596 466 q 555 615 594 567 q 427 665 517 664 l 186 665 l 186 0 l 105 0 l 105 734 l 443 734 m 382 0 l 382 500 l 463 500 l 463 68 l 700 68 q 832 117 793 68 q 873 267 871 166 l 873 734 l 954 734 l 954 275 q 896 67 953 135 q 720 0 840 0 l 382 0 z ',
    },
    '€': {
      ha: 770,
      x_min: 56,
      x_max: 707,
      o: 'm 554 382 l 270 382 l 270 370 q 343 138 270 220 q 551 56 417 56 q 700 79 631 56 l 707 7 q 551 -14 628 -14 q 285 86 382 -14 q 186 363 189 186 l 186 382 l 56 382 l 56 442 l 186 442 l 186 553 l 56 553 l 56 614 l 186 614 q 283 899 186 798 q 549 1001 380 1001 q 707 980 616 1001 l 700 906 q 550 930 624 930 q 343 849 416 930 q 270 614 270 769 l 554 614 l 554 553 l 270 553 l 270 442 l 554 442 l 554 382 z ',
    },
    '₱': {
      ha: 953,
      x_min: 24,
      x_max: 932,
      o: 'm 242 402 l 242 0 l 159 0 l 159 567 l 24 567 l 24 635 l 159 635 l 159 752 l 24 752 l 24 821 l 159 821 l 159 987 l 495 987 q 691 942 614 987 q 802 821 768 897 l 932 821 l 932 752 l 822 752 q 827 692 827 724 q 823 635 827 662 l 932 635 l 932 567 l 804 567 q 491 402 737 402 l 242 402 m 744 691 q 737 752 744 722 l 242 752 l 242 635 l 739 635 q 744 691 744 663 m 242 821 l 708 821 q 503 916 646 913 l 242 916 l 242 821 m 711 567 l 242 567 l 242 473 l 505 473 q 711 567 651 475 z ',
    },
    '₹': {
      ha: 678,
      x_min: 35,
      x_max: 644,
      o: 'm 621 916 l 418 916 q 513 730 500 849 l 644 730 l 621 659 l 513 659 q 423 473 504 540 q 204 406 342 406 l 538 9 l 538 0 l 441 0 l 90 417 l 90 477 l 200 477 q 356 526 295 477 q 429 659 417 574 l 35 659 l 57 730 l 429 730 q 357 863 417 813 q 191 916 296 913 l 45 916 l 68 987 l 644 987 l 621 916 z ',
    },
    '₺': {
      ha: 713,
      x_min: 30,
      x_max: 642,
      o: 'm 642 524 l 642 448 q 595 209 642 312 q 460 49 548 105 q 257 -7 372 -7 q 175 0 228 -7 l 175 462 l 30 399 l 30 479 l 175 542 l 175 652 l 30 589 l 30 669 l 175 731 l 175 987 l 258 987 l 258 767 l 422 838 l 422 758 l 258 688 l 258 578 l 422 648 l 422 569 l 258 498 l 258 66 q 478 166 398 66 q 558 446 557 267 l 558 524 l 642 524 z ',
    },
    '₼': {
      ha: 912,
      x_min: 72,
      x_max: 840,
      o: 'm 495 596 q 747 459 654 581 q 840 140 840 338 l 840 0 l 759 0 l 759 143 q 688 412 759 311 q 495 524 618 509 l 495 0 l 414 0 l 414 523 q 223 408 292 508 q 153 142 153 307 l 153 0 l 72 0 l 72 140 q 165 459 72 337 q 414 596 258 581 l 414 734 l 495 734 l 495 596 z ',
    },
    '₽': {
      ha: 891,
      x_min: 24,
      x_max: 830,
      o: 'm 494 230 l 245 230 l 245 0 l 162 0 l 162 230 l 24 230 l 24 301 l 162 301 l 162 402 l 24 402 l 24 473 l 162 473 l 162 987 l 498 987 q 742 908 654 987 q 830 694 830 829 q 745 479 830 555 q 500 402 659 403 l 245 402 l 245 301 l 494 301 l 494 230 m 245 473 l 498 473 q 682 530 616 473 q 747 692 747 587 q 683 854 747 794 q 509 916 620 913 l 245 916 l 245 473 z ',
    },
    '℅': {
      ha: 1021,
      x_min: 80,
      x_max: 958,
      o: 'm 446 701 q 397 588 446 634 q 269 543 347 543 q 135 601 189 543 q 80 749 80 659 l 80 798 q 134 943 80 884 q 267 1002 188 1002 q 396 956 347 1002 q 446 842 446 909 l 380 842 q 350 914 380 886 q 267 943 319 943 q 180 901 215 943 q 146 793 146 859 l 146 745 q 181 643 146 684 q 269 601 216 601 q 349 630 318 601 q 380 701 380 659 l 446 701 m 571 242 q 626 388 571 330 q 764 446 680 446 q 902 388 847 446 q 958 237 958 331 l 958 189 q 903 43 958 101 q 765 -14 848 -14 q 626 43 682 -14 q 571 193 571 100 l 571 242 m 636 189 q 671 85 636 125 q 765 44 706 44 q 858 85 823 44 q 892 193 892 126 l 892 242 q 857 347 892 307 q 764 387 822 387 q 671 347 707 387 q 636 239 636 307 l 636 189 m 294 82 l 243 115 l 725 886 l 776 854 l 294 82 z ',
    },
    ℓ: {
      ha: 646,
      x_min: 62,
      x_max: 559,
      o: 'm 477 -14 q 292 66 356 -14 q 228 295 228 146 l 228 321 q 62 293 148 293 l 62 370 q 228 403 150 370 l 228 796 q 275 981 229 915 q 400 1048 321 1048 q 513 991 469 1048 q 559 841 557 935 l 559 809 q 491 552 559 675 q 309 359 422 428 l 309 292 q 352 116 309 176 q 477 56 395 56 l 477 -14 m 309 451 q 432 608 386 511 q 477 812 477 706 l 477 833 q 456 942 477 907 q 400 977 435 977 q 309 788 309 977 l 309 451 z ',
    },
    '№': {
      ha: 1416,
      x_min: 120,
      x_max: 1326,
      o: 'm 1314 294 l 909 294 l 909 359 l 1314 359 l 1314 294 m 888 768 q 948 935 888 871 q 1107 998 1008 998 q 1266 935 1206 998 q 1326 764 1326 872 l 1326 694 q 1267 529 1326 592 q 1108 465 1208 465 q 947 528 1007 465 q 888 697 888 591 l 888 768 m 961 694 q 1000 573 961 617 q 1108 530 1038 530 q 1214 573 1175 530 q 1253 696 1253 616 l 1253 768 q 1215 888 1253 844 q 1107 932 1176 932 q 999 887 1038 932 q 961 764 961 842 l 961 694 m 758 0 l 670 0 l 201 845 l 201 0 l 120 0 l 120 987 l 208 987 l 677 142 l 677 987 l 758 987 l 758 0 z ',
    },
    '™': {
      ha: 857,
      x_min: 73,
      x_max: 733,
      o: 'm 684 905 l 581 623 l 546 623 l 443 910 l 443 623 l 393 623 l 393 987 l 455 987 l 564 696 l 672 987 l 733 987 l 733 623 l 684 623 l 684 905 m 338 941 l 230 941 l 230 623 l 180 623 l 180 941 l 73 941 l 73 987 l 338 987 l 338 941 z ',
    },
    '℮': {
      ha: 880,
      x_min: 101,
      x_max: 792,
      o: 'm 707 64 q 456 -14 583 -14 q 275 40 358 -14 q 147 180 193 93 q 101 367 101 267 q 150 552 101 464 q 283 693 199 640 q 456 747 367 747 q 624 700 547 747 q 745 571 701 653 q 792 391 790 488 l 792 349 l 271 349 l 271 126 q 456 52 352 52 q 706 134 589 52 l 707 64 m 456 684 q 271 601 354 684 l 271 413 l 637 413 l 637 607 q 456 684 559 684 z ',
    },
    '⅛': {
      ha: 1063,
      x_min: 67,
      x_max: 977,
      o: 'm 296 438 l 224 438 l 224 888 l 67 840 l 67 901 l 287 974 l 296 974 l 296 438 m 253 82 l 203 115 l 685 886 l 735 854 l 253 82 m 962 395 q 937 322 962 353 q 871 275 913 291 q 948 223 919 257 q 977 144 977 190 q 923 33 977 74 q 781 -7 869 -7 q 637 33 690 -7 q 583 144 583 74 q 612 224 583 190 q 688 275 640 258 q 623 322 647 292 q 598 395 598 353 q 647 503 598 463 q 779 543 696 543 q 912 502 863 543 q 962 395 962 462 m 905 147 q 869 217 905 190 q 779 243 834 243 q 690 217 724 243 q 655 147 655 191 q 690 76 655 101 q 781 52 725 52 q 870 77 836 52 q 905 147 905 103 m 890 392 q 859 457 890 431 q 779 483 828 483 q 699 457 728 483 q 670 392 670 432 q 701 327 670 351 q 781 303 731 303 q 861 328 831 303 q 890 392 890 353 z ',
    },
    '⅜': {
      ha: 1191,
      x_min: 87,
      x_max: 1090,
      o: 'm 220 747 l 278 747 q 365 773 333 749 q 397 838 397 798 q 368 905 397 880 q 284 930 339 930 q 200 905 233 930 q 168 841 168 880 l 96 841 q 150 948 96 906 q 283 990 203 990 q 420 949 370 990 q 469 840 469 909 q 444 770 469 802 q 370 722 418 738 q 480 593 480 693 q 427 482 480 524 q 285 440 373 440 q 142 483 197 440 q 87 596 87 526 l 159 596 q 194 527 159 554 q 287 499 230 499 q 377 525 346 499 q 409 595 409 551 q 269 692 409 692 l 220 692 l 220 747 m 378 82 l 328 115 l 810 886 l 860 854 l 378 82 m 1076 395 q 1051 322 1076 353 q 985 275 1027 291 q 1062 223 1033 257 q 1090 144 1090 190 q 1037 33 1090 74 q 895 -7 983 -7 q 751 33 804 -7 q 697 144 697 74 q 726 224 697 190 q 802 275 754 258 q 736 322 761 292 q 712 395 712 353 q 761 503 712 463 q 893 543 810 543 q 1026 502 977 543 q 1076 395 1076 462 m 1019 147 q 983 217 1019 190 q 893 243 948 243 q 804 217 838 243 q 769 147 769 191 q 804 76 769 101 q 895 52 839 52 q 984 77 950 52 q 1019 147 1019 103 m 1004 392 q 973 457 1004 431 q 893 483 942 483 q 813 457 842 483 q 784 392 784 432 q 814 327 784 351 q 895 303 845 303 q 975 328 945 303 q 1004 392 1004 353 z ',
    },
    '⅝': {
      ha: 1175,
      x_min: 81,
      x_max: 1074,
      o: 'm 95 718 l 128 984 l 441 984 l 441 922 l 187 922 l 167 768 q 275 793 212 793 q 409 747 359 793 q 458 621 458 701 q 409 490 458 538 q 269 441 360 441 q 138 483 191 441 q 81 593 86 525 l 152 593 q 189 523 157 546 q 269 500 222 500 q 387 622 387 500 q 355 706 387 674 q 261 738 322 738 q 171 716 205 738 l 152 703 l 95 718 m 356 82 l 306 115 l 788 886 l 838 854 l 356 82 m 1059 395 q 1034 322 1059 353 q 968 275 1010 291 q 1045 223 1016 257 q 1074 144 1074 190 q 1020 33 1074 74 q 878 -7 966 -7 q 734 33 787 -7 q 680 144 680 74 q 709 224 680 190 q 785 275 737 258 q 720 322 744 292 q 695 395 695 353 q 744 503 695 463 q 876 543 793 543 q 1009 502 960 543 q 1059 395 1059 462 m 1002 147 q 966 217 1002 190 q 876 243 931 243 q 787 217 821 243 q 752 147 752 191 q 787 76 752 101 q 878 52 822 52 q 967 77 933 52 q 1002 147 1002 103 m 987 392 q 956 457 987 431 q 876 483 925 483 q 796 457 825 483 q 767 392 767 432 q 798 327 767 351 q 878 303 828 303 q 958 328 928 303 q 987 392 987 353 z ',
    },
    '⅞': {
      ha: 1114,
      x_min: 73,
      x_max: 1021,
      o: 'm 477 943 l 229 450 l 153 450 l 401 924 l 73 924 l 73 985 l 477 985 l 477 943 m 288 82 l 237 115 l 720 886 l 770 854 l 288 82 m 1006 395 q 982 322 1006 353 q 916 275 958 291 q 992 223 964 257 q 1021 144 1021 190 q 968 33 1021 74 q 825 -7 914 -7 q 682 33 735 -7 q 628 144 628 74 q 656 224 628 190 q 733 275 685 258 q 667 322 692 292 q 643 395 643 353 q 692 503 643 463 q 824 543 741 543 q 957 502 907 543 q 1006 395 1006 462 m 949 147 q 914 217 949 190 q 824 243 879 243 q 734 217 769 243 q 700 147 700 191 q 735 76 700 101 q 825 52 770 52 q 915 77 881 52 q 949 147 949 103 m 935 392 q 904 457 935 431 q 824 483 873 483 q 744 457 773 483 q 715 392 715 432 q 745 327 715 351 q 825 303 776 303 q 905 328 876 303 q 935 392 935 353 z ',
    },
    '∂': {
      ha: 793,
      x_min: 72,
      x_max: 728,
      o: 'm 391 693 q 534 660 468 693 q 646 563 600 627 q 559 855 635 749 q 362 961 484 961 q 262 947 307 961 q 165 899 216 933 l 150 963 l 180 983 q 370 1033 262 1033 q 634 887 541 1033 q 728 474 728 741 l 728 427 q 686 198 728 300 q 568 41 644 97 q 395 -14 492 -14 q 161 83 250 -14 q 72 340 72 180 l 72 357 q 112 532 72 454 q 224 651 152 609 q 391 693 297 693 m 397 624 q 220 549 287 624 q 153 351 153 474 l 153 334 q 219 133 153 212 q 395 54 286 54 q 579 155 511 54 q 647 422 647 256 l 647 454 q 556 576 625 529 q 397 624 487 624 z ',
    },
    '∏': {
      ha: 913,
      x_min: 118,
      x_max: 794,
      o: 'm 794 -165 l 713 -165 l 713 918 l 199 918 l 199 -165 l 118 -165 l 118 987 l 794 987 l 794 -165 z ',
    },
    '∑': {
      ha: 802,
      x_min: 43,
      x_max: 800,
      o: 'm 539 392 l 142 -113 l 800 -113 l 800 -182 l 43 -182 l 43 -120 l 451 401 l 43 924 l 43 987 l 754 987 l 754 918 l 142 918 l 539 409 l 539 392 z ',
    },
    '−': {
      ha: 792,
      x_min: 112,
      x_max: 678,
      o: 'm 678 465 l 112 465 l 112 534 l 678 534 l 678 465 z ',
    },
    '√': {
      ha: 758,
      x_min: 43,
      x_max: 717,
      o: 'm 330 164 l 343 110 l 355 164 l 633 987 l 717 987 l 377 0 l 312 0 l 147 434 l 43 434 l 43 503 l 205 503 l 330 164 z ',
    },
    '∞': {
      ha: 1468,
      x_min: 81,
      x_max: 1377,
      o: 'm 1377 353 q 1336 168 1377 253 q 1222 34 1295 82 q 1059 -14 1149 -14 q 868 58 953 -14 q 728 268 784 130 q 588 57 672 129 q 400 -14 505 -14 q 171 91 262 -14 q 81 362 81 197 l 81 379 q 122 566 81 480 q 236 699 163 651 q 399 747 309 747 q 588 675 505 747 q 728 465 672 604 q 869 675 785 604 q 1060 747 954 747 q 1222 699 1149 747 q 1335 568 1295 651 q 1377 385 1376 484 l 1377 353 m 161 353 q 191 202 161 271 q 275 94 220 133 q 400 54 329 54 q 567 133 493 54 q 684 353 641 211 l 684 381 l 680 394 q 563 603 637 528 q 399 678 489 678 q 227 592 293 678 q 161 372 161 507 l 161 353 m 1296 379 q 1266 531 1296 461 q 1182 640 1236 602 q 1060 678 1129 678 q 895 603 968 678 q 779 397 821 527 l 774 381 l 774 353 l 780 334 q 893 130 821 206 q 1059 54 966 54 q 1230 139 1164 54 q 1296 361 1296 224 l 1296 379 z ',
    },
    '∫': {
      ha: 352,
      x_min: -81,
      x_max: 423,
      o: 'm 201 -66 q 147 -238 201 -180 q -3 -296 93 -296 q -81 -283 -41 -296 l -71 -216 q -9 -227 -38 -227 q 121 -60 121 -227 l 121 824 q 176 995 121 934 q 331 1056 231 1056 q 423 1043 374 1056 l 412 978 q 341 987 378 987 q 238 944 275 987 q 201 825 201 902 l 201 -66 z ',
    },
    '≈': {
      ha: 783,
      x_min: 68,
      x_max: 704,
      o: 'm 72 589 q 148 652 104 629 q 235 675 191 675 q 341 653 291 677 l 450 597 q 543 578 493 578 q 702 672 632 578 l 704 587 q 630 523 671 546 q 544 500 589 500 q 446 521 491 500 l 336 578 q 236 597 290 599 q 149 572 191 597 q 75 503 107 547 l 72 589 m 68 315 q 144 379 101 355 q 231 402 186 402 q 337 380 287 404 l 450 322 q 539 304 490 304 q 698 399 628 304 l 700 313 q 626 250 667 273 q 540 227 585 227 q 442 248 487 227 l 332 304 q 232 325 284 326 q 145 299 187 325 q 71 230 103 274 l 68 315 z ',
    },
    '≠': {
      ha: 768,
      x_min: 101,
      x_max: 667,
      o: 'm 667 283 l 308 283 l 205 104 l 157 132 l 244 283 l 101 283 l 101 355 l 286 355 l 404 562 l 101 562 l 101 634 l 446 634 l 552 819 l 600 791 l 510 634 l 667 634 l 667 562 l 469 562 l 349 355 l 667 355 l 667 283 z ',
    },
    '≤': {
      ha: 710,
      x_min: 47,
      x_max: 613,
      o: 'm 141 464 l 613 280 l 613 204 l 52 435 l 52 494 l 613 725 l 613 648 l 141 464 m 613 1 l 47 1 l 47 70 l 613 70 l 613 1 z ',
    },
    '≥': {
      ha: 720,
      x_min: 81,
      x_max: 657,
      o: 'm 566 473 l 84 658 l 84 733 l 657 502 l 657 443 l 84 212 l 84 287 l 566 473 m 647 1 l 81 1 l 81 70 l 647 70 l 647 1 z ',
    },
    '◊': {
      ha: 701,
      x_min: 37,
      x_max: 661,
      o: 'm 316 987 l 381 987 l 661 493 l 382 0 l 317 0 l 37 493 l 316 987 m 348 898 l 125 493 l 350 87 l 573 493 l 348 898 z ',
    },
    '': {
      ha: 352,
      x_min: 113,
      x_max: 231,
      o: 'm 113 168 q 129 208 113 191 q 171 224 144 224 q 214 208 198 224 q 229 168 229 191 q 214 129 229 144 q 171 113 198 113 q 129 129 144 113 q 113 168 113 144 m 115 793 q 131 834 115 817 q 173 850 146 850 q 216 834 200 850 q 231 793 231 817 q 216 754 231 770 q 173 739 200 739 q 131 754 146 739 q 115 793 115 770 z ',
    },
    '': {
      ha: 399,
      x_min: 79,
      x_max: 330,
      o: 'm 146 454 l 79 454 l 79 734 l 146 734 l 146 454 m 330 454 l 264 454 l 264 734 l 330 734 l 330 454 z ',
    },
    '': {
      ha: 330,
      x_min: 82,
      x_max: 209,
      o: 'm 130 -123 l 82 -88 q 131 54 129 -22 l 131 119 l 209 119 l 209 77 q 130 -123 209 -53 z ',
    },
    ﬁ: {
      ha: 746,
      x_min: 33,
      x_max: 629,
      o: 'm 155 0 l 155 667 l 33 667 l 33 734 l 155 734 l 155 809 q 218 991 155 926 q 398 1056 282 1056 q 585 1016 489 1056 l 572 946 q 404 982 489 982 q 236 808 236 982 l 236 734 l 408 734 l 408 667 l 236 667 l 236 0 l 155 0 m 629 0 l 548 0 l 548 734 l 629 734 l 629 0 z ',
    },
    ﬂ: {
      ha: 756,
      x_min: 43,
      x_max: 639,
      o: 'm 558 964 q 391 985 458 985 q 283 943 321 985 q 245 825 245 901 l 245 734 l 420 734 l 420 667 l 245 667 l 245 0 l 164 0 l 164 667 l 43 667 l 43 734 l 164 734 l 164 827 q 223 997 165 939 q 386 1056 281 1056 q 639 1015 470 1056 l 639 0 l 558 0 l 558 964 z ',
    },
    ﬃ: {
      ha: 1149,
      x_min: 43,
      x_max: 1033,
      o: 'm 164 0 l 164 667 l 43 667 l 43 734 l 164 734 l 164 823 q 221 996 164 935 q 381 1056 279 1056 q 467 1044 427 1056 l 461 977 q 387 985 427 985 q 283 943 320 985 q 245 825 245 901 l 245 734 l 558 734 l 558 809 q 622 991 558 926 q 802 1056 686 1056 q 989 1016 892 1056 l 975 946 q 806 982 890 982 q 639 806 639 982 l 639 734 l 811 734 l 811 667 l 639 667 l 639 0 l 558 0 l 558 667 l 245 667 l 245 0 l 164 0 m 1033 0 l 951 0 l 951 734 l 1033 734 l 1033 0 z ',
    },
    ﬄ: {
      ha: 1149,
      x_min: 43,
      x_max: 1033,
      o: 'm 164 0 l 164 667 l 43 667 l 43 734 l 164 734 l 164 823 q 221 996 164 935 q 381 1056 279 1056 q 467 1044 427 1056 l 461 977 q 387 985 427 985 q 283 943 320 985 q 245 825 245 901 l 245 734 l 558 734 l 558 827 q 617 997 559 938 q 779 1056 675 1056 q 1033 1015 864 1056 l 1033 0 l 951 0 l 951 964 q 785 985 851 985 q 677 943 715 985 q 639 825 639 901 l 639 734 l 814 734 l 814 667 l 639 667 l 639 0 l 558 0 l 558 667 l 245 667 l 245 0 l 164 0 z ',
    },
    '﻿': { ha: 0, x_min: 0, x_max: 0, o: '' },
    '￼': {
      ha: 1420,
      x_min: 60,
      x_max: 1375,
      o: 'm 557 317 q 514 210 557 251 q 402 169 470 169 q 290 210 333 169 q 245 314 246 250 l 245 393 q 288 500 245 458 q 401 542 330 542 q 513 501 469 542 q 557 397 556 460 l 557 317 m 603 171 l 603 543 l 730 543 q 836 518 797 543 q 874 441 874 494 q 817 363 874 385 q 869 330 851 354 q 886 276 886 307 q 745 171 886 171 l 603 171 m 495 393 q 470 467 495 440 q 401 493 445 493 q 332 467 357 493 q 307 393 307 440 l 307 317 q 332 244 307 271 q 402 218 357 218 q 470 244 446 218 q 495 317 495 271 l 495 393 m 1142 545 l 1204 545 l 1204 288 q 1169 204 1204 235 q 1078 172 1133 172 q 981 200 1018 172 q 944 283 944 227 l 1007 283 q 1078 222 1007 222 q 1124 240 1105 222 q 1142 288 1142 258 l 1142 545 m 60 -270 l 60 -56 l 136 -56 l 136 -193 l 269 -193 l 269 -270 l 60 -270 m 1164 -270 l 1164 -193 l 1299 -193 l 1299 -56 l 1375 -56 l 1375 -270 l 1164 -270 m 60 793 l 60 986 l 269 986 l 269 907 l 136 907 l 136 793 l 60 793 m 1164 907 l 1164 986 l 1375 986 l 1375 793 l 1299 793 l 1299 907 l 1164 907 m 667 336 l 667 222 l 753 222 q 823 278 823 222 q 756 336 823 334 l 667 336 m 894 907 l 894 986 l 1082 986 l 1082 907 l 894 907 m 623 907 l 623 986 l 811 986 l 811 907 l 623 907 m 353 907 l 353 986 l 540 986 l 540 907 l 353 907 m 894 -270 l 894 -193 l 1082 -193 l 1082 -270 l 894 -270 m 623 -270 l 623 -193 l 811 -193 l 811 -270 l 623 -270 m 353 -270 l 353 -193 l 540 -193 l 540 -270 l 353 -270 m 667 384 l 730 384 q 810 438 810 384 q 791 476 810 466 q 730 486 771 486 l 667 486 l 667 384 m 136 282 l 60 282 l 60 453 l 136 453 l 136 282 m 136 538 l 60 538 l 60 708 l 136 708 l 136 538 m 136 28 l 60 28 l 60 197 l 136 197 l 136 28 m 1375 282 l 1299 282 l 1299 453 l 1375 453 l 1375 282 m 1375 538 l 1299 538 l 1299 708 l 1375 708 l 1375 538 m 1375 28 l 1299 28 l 1299 197 l 1375 197 l 1375 28 z ',
    },
    '�': {
      ha: 1425,
      x_min: 62,
      x_max: 1361,
      o: 'm 711 1097 l 1361 436 l 711 -225 l 62 436 l 711 1097 m 767 273 q 777 332 767 312 q 812 368 787 351 q 893 440 862 394 q 924 536 924 486 q 868 674 924 623 q 710 725 811 725 q 558 678 612 725 q 503 545 504 631 l 640 545 q 661 597 642 579 q 710 614 681 614 q 768 593 749 614 q 787 536 787 571 q 724 431 787 471 q 646 355 662 387 q 630 273 630 324 l 767 273 m 767 211 l 630 211 l 630 96 l 767 96 l 767 211 m 716 -374 l 719 -374 l 719 -376 l 716 -376 l 716 -374 m 715 1467 l 718 1467 l 718 1464 l 715 1464 l 715 1467 z ',
    },
    ' ': { ha: 338, x_min: 0, x_max: 0, o: '' },
    '­': {
      ha: 397,
      x_min: 33,
      x_max: 358,
      o: 'm 358 401 l 33 401 l 33 470 l 358 470 l 358 401 z ',
    },
    Đ: {
      ha: 930,
      x_min: 22,
      x_max: 843,
      o: 'm 145 0 l 145 467 l 22 467 l 22 536 l 145 536 l 145 987 l 421 987 q 639 932 543 987 q 789 774 735 877 q 843 539 843 671 l 843 448 q 789 214 843 316 q 638 56 736 111 q 415 0 541 0 l 145 0 m 437 467 l 229 467 l 229 71 l 412 71 q 666 174 572 71 q 760 454 760 277 l 760 541 q 667 813 760 711 q 422 916 574 916 l 229 916 l 229 536 l 437 536 l 437 467 z ',
    },
    Ð: {
      ha: 930,
      x_min: 22,
      x_max: 843,
      o: 'm 145 0 l 145 467 l 22 467 l 22 536 l 145 536 l 145 987 l 421 987 q 639 932 543 987 q 789 774 735 877 q 843 539 843 671 l 843 448 q 789 214 843 316 q 638 56 736 111 q 415 0 541 0 l 145 0 m 437 467 l 229 467 l 229 71 l 412 71 q 666 174 572 71 q 760 454 760 277 l 760 541 q 667 813 760 711 q 422 916 574 916 l 229 916 l 229 536 l 437 536 l 437 467 z ',
    },
    ħ: {
      ha: 783,
      x_min: 0,
      x_max: 680,
      o: 'm 415 852 l 207 852 l 207 610 q 309 711 247 675 q 446 747 372 747 q 621 681 564 747 q 680 481 679 614 l 680 0 l 599 0 l 599 482 q 557 629 598 581 q 426 677 516 677 q 293 630 351 677 q 207 503 235 583 l 207 0 l 126 0 l 126 852 l 0 852 l 0 922 l 126 922 l 126 1042 l 207 1042 l 207 922 l 415 922 l 415 852 z ',
    },
    Ŧ: {
      ha: 829,
      x_min: 35,
      x_max: 795,
      o: 'm 619 566 l 456 566 l 456 0 l 373 0 l 373 566 l 204 566 l 204 635 l 373 635 l 373 916 l 35 916 l 35 987 l 795 987 l 795 916 l 456 916 l 456 635 l 619 635 l 619 566 z ',
    },
    ŧ: {
      ha: 446,
      x_min: 9,
      x_max: 425,
      o: 'm 232 922 l 232 734 l 384 734 l 384 667 l 232 667 l 232 488 l 425 488 l 425 418 l 232 418 l 232 178 q 254 87 232 117 q 328 58 276 58 q 393 64 348 58 l 397 -2 q 310 -14 365 -14 q 190 33 228 -14 q 151 171 152 80 l 151 418 l 9 418 l 9 488 l 151 488 l 151 667 l 16 667 l 16 734 l 151 734 l 151 922 l 232 922 z ',
    },
    À: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 486 1065 l 412 1065 l 258 1255 l 356 1255 l 486 1065 z ',
    },
    Á: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 517 1255 l 615 1255 l 458 1065 l 387 1065 l 517 1255 z ',
    },
    Â: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 625 1074 l 625 1067 l 549 1067 l 435 1191 l 322 1067 l 248 1067 l 248 1076 l 410 1249 l 461 1249 l 625 1074 z ',
    },
    Ã: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 667 1227 q 628 1122 667 1162 q 534 1082 590 1082 q 434 1120 483 1082 q 346 1157 385 1157 q 293 1136 314 1157 q 271 1077 271 1115 l 210 1079 q 247 1182 210 1141 q 342 1223 284 1223 q 398 1211 375 1223 q 441 1185 421 1200 q 483 1160 462 1171 q 531 1148 503 1148 q 584 1171 562 1148 q 606 1231 606 1193 l 667 1227 z ',
    },
    Ä: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 233 1160 q 248 1199 233 1183 q 288 1215 262 1215 q 329 1199 314 1215 q 345 1160 345 1183 q 329 1122 345 1138 q 288 1107 314 1107 q 248 1122 262 1107 q 233 1160 233 1138 m 530 1160 q 545 1199 530 1183 q 585 1215 559 1215 q 626 1199 611 1215 q 642 1160 642 1183 q 626 1122 642 1137 q 585 1106 611 1106 q 545 1122 559 1106 q 530 1160 530 1137 z ',
    },
    Å: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 305 1163 q 343 1255 305 1217 q 436 1292 382 1292 q 528 1255 490 1292 q 567 1163 567 1217 q 530 1073 567 1109 q 436 1036 492 1036 q 342 1073 380 1036 q 305 1163 305 1109 m 357 1163 q 380 1107 357 1129 q 436 1084 402 1084 q 492 1106 469 1084 q 515 1163 515 1128 q 492 1221 515 1199 q 436 1244 468 1244 q 380 1221 403 1244 q 357 1163 357 1198 z ',
    },
    Ǻ: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 521 1308 l 612 1308 l 475 1181 l 414 1181 l 521 1308 m 332 1042 q 362 1115 332 1084 q 437 1146 392 1146 q 510 1116 481 1146 q 540 1042 540 1085 q 510 969 540 999 q 437 940 480 940 q 362 970 393 940 q 332 1042 332 1000 m 378 1042 q 395 1001 378 1019 q 437 983 412 983 q 477 1000 460 983 q 494 1042 494 1017 q 477 1085 494 1068 q 437 1103 460 1103 q 395 1085 413 1103 q 378 1042 378 1067 z ',
    },
    Ç: {
      ha: 902,
      x_min: 89,
      x_max: 824,
      o: 'm 824 308 q 712 69 807 152 q 460 -14 618 -14 q 266 41 351 -14 q 136 197 182 96 q 89 427 90 298 l 89 555 q 135 789 89 687 q 268 946 181 890 q 467 1001 354 1001 q 717 915 625 1001 q 824 678 809 829 l 740 678 q 467 930 709 930 q 253 829 332 930 q 173 552 173 729 l 173 431 q 251 159 173 260 q 460 57 328 57 q 658 120 591 57 q 740 308 724 182 l 824 308 m 494 -6 l 486 -50 q 597 -165 597 -64 q 544 -264 597 -228 q 398 -301 490 -301 l 393 -248 q 492 -226 454 -248 q 530 -167 530 -204 q 501 -113 530 -127 q 411 -92 471 -98 l 431 -6 l 494 -6 z ',
    },
    È: {
      ha: 790,
      x_min: 125,
      x_max: 739,
      o: 'm 670 473 l 208 473 l 208 71 l 739 71 l 739 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 544 l 670 544 l 670 473 m 470 1069 l 396 1069 l 243 1259 l 340 1259 l 470 1069 z ',
    },
    É: {
      ha: 790,
      x_min: 125,
      x_max: 739,
      o: 'm 670 473 l 208 473 l 208 71 l 739 71 l 739 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 544 l 670 544 l 670 473 m 501 1259 l 600 1259 l 443 1069 l 372 1069 l 501 1259 z ',
    },
    Ê: {
      ha: 790,
      x_min: 125,
      x_max: 739,
      o: 'm 670 473 l 208 473 l 208 71 l 739 71 l 739 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 544 l 670 544 l 670 473 m 609 1078 l 609 1071 l 534 1071 l 420 1195 l 307 1071 l 233 1071 l 233 1080 l 394 1253 l 446 1253 l 609 1078 z ',
    },
    Ë: {
      ha: 790,
      x_min: 125,
      x_max: 739,
      o: 'm 670 473 l 208 473 l 208 71 l 739 71 l 739 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 544 l 670 544 l 670 473 m 217 1164 q 232 1203 217 1187 q 273 1219 247 1219 q 314 1203 298 1219 q 329 1164 329 1187 q 314 1126 329 1142 q 273 1111 298 1111 q 232 1126 247 1111 q 217 1164 217 1142 m 514 1164 q 529 1203 514 1187 q 570 1219 544 1219 q 611 1203 595 1219 q 626 1164 626 1187 q 611 1126 626 1141 q 570 1110 595 1110 q 529 1126 544 1110 q 514 1164 514 1141 z ',
    },
    Ì: {
      ha: 370,
      x_min: 9,
      x_max: 236,
      o: 'm 227 0 l 143 0 l 143 987 l 227 987 l 227 0 m 236 1069 l 162 1069 l 9 1259 l 106 1259 l 236 1069 z ',
    },
    Í: {
      ha: 370,
      x_min: 137,
      x_max: 365,
      o: 'm 227 0 l 143 0 l 143 987 l 227 987 l 227 0 m 267 1259 l 365 1259 l 208 1069 l 137 1069 l 267 1259 z ',
    },
    Î: {
      ha: 370,
      x_min: -1,
      x_max: 375,
      o: 'm 227 0 l 143 0 l 143 987 l 227 987 l 227 0 m 375 1078 l 375 1071 l 300 1071 l 186 1195 l 73 1071 l -1 1071 l -1 1080 l 160 1253 l 212 1253 l 375 1078 z ',
    },
    Ï: {
      ha: 370,
      x_min: -17,
      x_max: 392,
      o: 'm 227 0 l 143 0 l 143 987 l 227 987 l 227 0 m -17 1164 q -2 1203 -17 1187 q 39 1219 13 1219 q 80 1203 64 1219 q 95 1164 95 1187 q 80 1126 95 1142 q 39 1111 64 1111 q -2 1126 13 1111 q -17 1164 -17 1142 m 280 1164 q 295 1203 280 1187 q 336 1219 310 1219 q 377 1203 361 1219 q 392 1164 392 1187 q 377 1126 392 1141 q 336 1110 361 1110 q 295 1126 310 1110 q 280 1164 280 1141 z ',
    },
    Ñ: {
      ha: 986,
      x_min: 125,
      x_max: 860,
      o: 'm 860 0 l 777 0 l 209 845 l 209 0 l 125 0 l 125 987 l 209 987 l 777 142 l 777 987 l 860 987 l 860 0 m 724 1227 q 685 1122 724 1162 q 591 1082 647 1082 q 491 1120 540 1082 q 403 1157 441 1157 q 350 1136 371 1157 q 328 1077 328 1115 l 267 1079 q 304 1182 267 1141 q 399 1223 341 1223 q 455 1211 432 1223 q 498 1185 478 1200 q 539 1160 519 1171 q 588 1148 560 1148 q 641 1171 619 1148 q 663 1231 663 1193 l 724 1227 z ',
    },
    Ò: {
      ha: 940,
      x_min: 85,
      x_max: 854,
      o: 'm 854 440 q 807 201 854 304 q 673 42 760 98 q 471 -14 585 -14 q 191 110 298 -14 q 85 444 85 234 l 85 546 q 133 785 85 681 q 268 945 180 889 q 469 1001 355 1001 q 670 946 583 1001 q 805 791 758 891 q 854 558 852 690 l 854 440 m 771 547 q 690 827 771 726 q 469 928 610 928 q 250 826 332 928 q 168 543 168 725 l 168 440 q 250 162 168 264 q 471 59 331 59 q 692 160 612 59 q 771 443 771 262 l 771 547 m 519 1074 l 445 1074 l 292 1263 l 389 1263 l 519 1074 z ',
    },
    Ó: {
      ha: 940,
      x_min: 85,
      x_max: 854,
      o: 'm 854 440 q 807 201 854 304 q 673 42 760 98 q 471 -14 585 -14 q 191 110 298 -14 q 85 444 85 234 l 85 546 q 133 785 85 681 q 268 945 180 889 q 469 1001 355 1001 q 670 946 583 1001 q 805 791 758 891 q 854 558 852 690 l 854 440 m 771 547 q 690 827 771 726 q 469 928 610 928 q 250 826 332 928 q 168 543 168 725 l 168 440 q 250 162 168 264 q 471 59 331 59 q 692 160 612 59 q 771 443 771 262 l 771 547 m 550 1263 l 648 1263 l 492 1074 l 420 1074 l 550 1263 z ',
    },
    Ô: {
      ha: 940,
      x_min: 85,
      x_max: 854,
      o: 'm 854 440 q 807 201 854 304 q 673 42 760 98 q 471 -14 585 -14 q 191 110 298 -14 q 85 444 85 234 l 85 546 q 133 785 85 681 q 268 945 180 889 q 469 1001 355 1001 q 670 946 583 1001 q 805 791 758 891 q 854 558 852 690 l 854 440 m 771 547 q 690 827 771 726 q 469 928 610 928 q 250 826 332 928 q 168 543 168 725 l 168 440 q 250 162 168 264 q 471 59 331 59 q 692 160 612 59 q 771 443 771 262 l 771 547 m 658 1082 l 658 1075 l 583 1075 l 469 1199 l 355 1075 l 281 1075 l 281 1084 l 443 1257 l 494 1257 l 658 1082 z ',
    },
    Õ: {
      ha: 940,
      x_min: 85,
      x_max: 854,
      o: 'm 854 440 q 807 201 854 304 q 673 42 760 98 q 471 -14 585 -14 q 191 110 298 -14 q 85 444 85 234 l 85 546 q 133 785 85 681 q 268 945 180 889 q 469 1001 355 1001 q 670 946 583 1001 q 805 791 758 891 q 854 558 852 690 l 854 440 m 771 547 q 690 827 771 726 q 469 928 610 928 q 250 826 332 928 q 168 543 168 725 l 168 440 q 250 162 168 264 q 471 59 331 59 q 692 160 612 59 q 771 443 771 262 l 771 547 m 700 1235 q 662 1130 700 1170 q 568 1090 623 1090 q 467 1128 516 1090 q 379 1165 418 1165 q 326 1144 347 1165 q 304 1085 304 1123 l 243 1087 q 280 1190 243 1149 q 376 1231 317 1231 q 431 1219 408 1231 q 475 1194 454 1208 q 516 1168 495 1179 q 564 1156 536 1156 q 617 1179 595 1156 q 640 1239 640 1201 l 700 1235 z ',
    },
    Ö: {
      ha: 940,
      x_min: 85,
      x_max: 854,
      o: 'm 854 440 q 807 201 854 304 q 673 42 760 98 q 471 -14 585 -14 q 191 110 298 -14 q 85 444 85 234 l 85 546 q 133 785 85 681 q 268 945 180 889 q 469 1001 355 1001 q 670 946 583 1001 q 805 791 758 891 q 854 558 852 690 l 854 440 m 771 547 q 690 827 771 726 q 469 928 610 928 q 250 826 332 928 q 168 543 168 725 l 168 440 q 250 162 168 264 q 471 59 331 59 q 692 160 612 59 q 771 443 771 262 l 771 547 m 266 1168 q 281 1207 266 1192 q 321 1223 296 1223 q 362 1207 347 1223 q 378 1168 378 1192 q 362 1131 378 1146 q 321 1115 347 1115 q 281 1131 296 1115 q 266 1168 266 1146 m 563 1168 q 578 1207 563 1191 q 618 1223 593 1223 q 660 1207 644 1223 q 675 1168 675 1191 q 660 1130 675 1145 q 618 1114 644 1114 q 578 1130 593 1114 q 563 1168 563 1145 z ',
    },
    Ù: {
      ha: 913,
      x_min: 110,
      x_max: 805,
      o: 'm 805 987 l 805 313 q 761 140 804 214 q 638 26 718 66 q 457 -14 559 -14 q 207 72 301 -14 q 110 307 113 157 l 110 987 l 193 987 l 193 319 q 264 126 193 195 q 457 57 335 57 q 650 126 579 57 q 721 319 721 195 l 721 987 l 805 987 m 513 1065 l 439 1065 l 286 1255 l 383 1255 l 513 1065 z ',
    },
    Ú: {
      ha: 913,
      x_min: 110,
      x_max: 805,
      o: 'm 805 987 l 805 313 q 761 140 804 214 q 638 26 718 66 q 457 -14 559 -14 q 207 72 301 -14 q 110 307 113 157 l 110 987 l 193 987 l 193 319 q 264 126 193 195 q 457 57 335 57 q 650 126 579 57 q 721 319 721 195 l 721 987 l 805 987 m 544 1255 l 642 1255 l 486 1065 l 414 1065 l 544 1255 z ',
    },
    Û: {
      ha: 913,
      x_min: 110,
      x_max: 805,
      o: 'm 805 987 l 805 313 q 761 140 804 214 q 638 26 718 66 q 457 -14 559 -14 q 207 72 301 -14 q 110 307 113 157 l 110 987 l 193 987 l 193 319 q 264 126 193 195 q 457 57 335 57 q 650 126 579 57 q 721 319 721 195 l 721 987 l 805 987 m 652 1074 l 652 1067 l 576 1067 l 463 1191 l 349 1067 l 275 1067 l 275 1076 l 437 1249 l 488 1249 l 652 1074 z ',
    },
    Ü: {
      ha: 913,
      x_min: 110,
      x_max: 805,
      o: 'm 805 987 l 805 313 q 761 140 804 214 q 638 26 718 66 q 457 -14 559 -14 q 207 72 301 -14 q 110 307 113 157 l 110 987 l 193 987 l 193 319 q 264 126 193 195 q 457 57 335 57 q 650 126 579 57 q 721 319 721 195 l 721 987 l 805 987 m 260 1160 q 275 1199 260 1183 q 315 1215 290 1215 q 356 1199 341 1215 q 372 1160 372 1183 q 356 1122 372 1138 q 315 1107 341 1107 q 275 1122 290 1107 q 260 1160 260 1138 m 557 1160 q 572 1199 557 1183 q 612 1215 587 1215 q 653 1199 638 1215 q 669 1160 669 1183 q 653 1122 669 1137 q 612 1106 638 1106 q 572 1122 587 1106 q 557 1160 557 1137 z ',
    },
    Ý: {
      ha: 831,
      x_min: 16,
      x_max: 812,
      o: 'm 414 449 l 716 987 l 812 987 l 456 372 l 456 0 l 372 0 l 372 372 l 16 987 l 115 987 l 414 449 m 496 1251 l 595 1251 l 438 1061 l 367 1061 l 496 1251 z ',
    },
    à: {
      ha: 744,
      x_min: 68,
      x_max: 656,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 m 443 852 l 369 852 l 216 1042 l 313 1042 l 443 852 z ',
    },
    á: {
      ha: 744,
      x_min: 68,
      x_max: 656,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 m 474 1042 l 572 1042 l 416 852 l 345 852 l 474 1042 z ',
    },
    â: {
      ha: 744,
      x_min: 68,
      x_max: 656,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 m 582 860 l 582 853 l 507 853 l 393 977 l 279 853 l 205 853 l 205 862 l 367 1035 l 418 1035 l 582 860 z ',
    },
    ã: {
      ha: 744,
      x_min: 68,
      x_max: 656,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 m 624 1013 q 586 908 624 948 q 492 869 547 869 q 391 906 440 869 q 303 943 342 943 q 250 922 271 943 q 229 863 229 901 l 168 865 q 204 968 168 928 q 300 1009 241 1009 q 355 998 332 1009 q 399 972 378 986 q 440 946 419 958 q 488 935 460 935 q 542 957 519 935 q 564 1017 564 979 l 624 1013 z ',
    },
    ä: {
      ha: 744,
      x_min: 68,
      x_max: 656,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 m 190 947 q 205 986 190 970 q 245 1002 220 1002 q 287 986 271 1002 q 302 947 302 970 q 287 909 302 924 q 245 893 271 893 q 205 909 220 893 q 190 947 190 924 m 487 946 q 502 985 487 969 q 543 1001 517 1001 q 584 985 568 1001 q 599 946 599 969 q 584 908 599 924 q 543 892 568 892 q 502 908 517 892 q 487 946 487 924 z ',
    },
    å: {
      ha: 744,
      x_min: 68,
      x_max: 656,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 m 262 949 q 301 1041 262 1004 q 393 1078 339 1078 q 486 1041 447 1078 q 524 949 524 1004 q 487 859 524 896 q 393 823 450 823 q 300 859 337 823 q 262 949 262 896 m 315 949 q 337 893 315 916 q 393 871 359 871 q 450 893 427 871 q 473 949 473 915 q 449 1008 473 985 q 393 1030 425 1030 q 337 1007 360 1030 q 315 949 315 984 z ',
    },
    ǻ: {
      ha: 744,
      x_min: 68,
      x_max: 656,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 m 478 1095 l 570 1095 l 433 968 l 372 968 l 478 1095 m 289 829 q 319 902 289 871 q 394 933 349 933 q 468 903 438 933 q 497 829 497 872 q 467 756 497 786 q 394 727 437 727 q 319 757 350 727 q 289 829 289 787 m 335 829 q 352 788 335 806 q 394 770 369 770 q 435 787 418 770 q 452 829 452 804 q 435 872 452 855 q 394 890 418 890 q 353 872 370 890 q 335 829 335 854 z ',
    },
    ç: {
      ha: 715,
      x_min: 64,
      x_max: 659,
      o: 'm 377 55 q 519 103 462 55 q 581 227 576 151 l 659 227 q 617 104 656 159 q 514 18 578 49 q 377 -14 450 -14 q 148 87 233 -14 q 64 357 64 187 l 64 381 q 102 573 64 490 q 211 702 140 656 q 376 747 281 747 q 575 675 497 747 q 659 486 654 604 l 581 486 q 519 625 576 572 q 376 678 463 678 q 206 599 267 678 q 145 376 145 519 l 145 353 q 206 134 145 212 q 377 55 267 55 m 399 -6 l 391 -50 q 503 -165 503 -64 q 450 -264 503 -228 q 304 -301 396 -301 l 299 -248 q 398 -226 359 -248 q 436 -167 436 -204 q 407 -113 436 -127 q 317 -92 377 -98 l 336 -6 l 399 -6 z ',
    },
    è: {
      ha: 718,
      x_min: 61,
      x_max: 665,
      o: 'm 390 -14 q 221 33 296 -14 q 103 161 145 79 q 61 346 61 243 l 61 375 q 102 566 61 481 q 216 699 143 650 q 375 747 290 747 q 587 656 509 747 q 665 407 665 565 l 665 361 l 142 361 l 142 346 q 213 138 142 221 q 393 55 285 55 q 508 79 458 55 q 599 155 558 102 l 650 116 q 390 -14 560 -14 m 375 678 q 221 611 283 678 q 144 431 158 544 l 585 431 l 585 439 q 524 612 581 545 q 375 678 467 678 m 434 852 l 360 852 l 207 1042 l 304 1042 l 434 852 z ',
    },
    é: {
      ha: 718,
      x_min: 61,
      x_max: 665,
      o: 'm 390 -14 q 221 33 296 -14 q 103 161 145 79 q 61 346 61 243 l 61 375 q 102 566 61 481 q 216 699 143 650 q 375 747 290 747 q 587 656 509 747 q 665 407 665 565 l 665 361 l 142 361 l 142 346 q 213 138 142 221 q 393 55 285 55 q 508 79 458 55 q 599 155 558 102 l 650 116 q 390 -14 560 -14 m 375 678 q 221 611 283 678 q 144 431 158 544 l 585 431 l 585 439 q 524 612 581 545 q 375 678 467 678 m 465 1042 l 564 1042 l 407 852 l 336 852 l 465 1042 z ',
    },
    ê: {
      ha: 718,
      x_min: 61,
      x_max: 665,
      o: 'm 390 -14 q 221 33 296 -14 q 103 161 145 79 q 61 346 61 243 l 61 375 q 102 566 61 481 q 216 699 143 650 q 375 747 290 747 q 587 656 509 747 q 665 407 665 565 l 665 361 l 142 361 l 142 346 q 213 138 142 221 q 393 55 285 55 q 508 79 458 55 q 599 155 558 102 l 650 116 q 390 -14 560 -14 m 375 678 q 221 611 283 678 q 144 431 158 544 l 585 431 l 585 439 q 524 612 581 545 q 375 678 467 678 m 573 860 l 573 853 l 498 853 l 384 977 l 271 853 l 197 853 l 197 862 l 358 1035 l 410 1035 l 573 860 z ',
    },
    ë: {
      ha: 718,
      x_min: 61,
      x_max: 665,
      o: 'm 390 -14 q 221 33 296 -14 q 103 161 145 79 q 61 346 61 243 l 61 375 q 102 566 61 481 q 216 699 143 650 q 375 747 290 747 q 587 656 509 747 q 665 407 665 565 l 665 361 l 142 361 l 142 346 q 213 138 142 221 q 393 55 285 55 q 508 79 458 55 q 599 155 558 102 l 650 116 q 390 -14 560 -14 m 375 678 q 221 611 283 678 q 144 431 158 544 l 585 431 l 585 439 q 524 612 581 545 q 375 678 467 678 m 181 947 q 196 986 181 970 q 237 1002 211 1002 q 278 986 262 1002 q 293 947 293 970 q 278 909 293 924 q 237 893 262 893 q 196 909 211 893 q 181 947 181 924 m 478 946 q 493 985 478 969 q 534 1001 508 1001 q 575 985 559 1001 q 590 946 590 969 q 575 908 590 924 q 534 892 559 892 q 493 908 508 892 q 478 946 478 924 z ',
    },
    ì: {
      ha: 309,
      x_min: -20,
      x_max: 207,
      o: 'm 195 0 l 114 0 l 114 734 l 195 734 l 195 0 m 207 844 l 133 844 l -20 1034 l 77 1034 l 207 844 z ',
    },
    í: {
      ha: 309,
      x_min: 108,
      x_max: 336,
      o: 'm 195 0 l 114 0 l 114 734 l 195 734 l 195 0 m 237 1034 l 336 1034 l 179 844 l 108 844 l 237 1034 z ',
    },
    î: {
      ha: 309,
      x_min: -31,
      x_max: 346,
      o: 'm 195 0 l 114 0 l 114 734 l 195 734 l 195 0 m 346 852 l 346 845 l 271 845 l 157 969 l 43 845 l -31 845 l -31 854 l 131 1027 l 182 1027 l 346 852 z ',
    },
    ï: {
      ha: 309,
      x_min: -46,
      x_max: 363,
      o: 'm 195 0 l 114 0 l 114 734 l 195 734 l 195 0 m -46 939 q -31 978 -46 962 q 9 994 -16 994 q 51 978 35 994 q 66 939 66 962 q 51 901 66 916 q 9 885 35 885 q -31 901 -16 885 q -46 939 -46 916 m 251 938 q 266 977 251 961 q 307 993 281 993 q 348 977 332 993 q 363 938 363 961 q 348 900 363 916 q 307 884 332 884 q 266 900 281 884 q 251 938 251 916 z ',
    },
    ñ: {
      ha: 763,
      x_min: 106,
      x_max: 659,
      o: 'm 183 734 l 186 608 q 289 712 227 676 q 425 747 351 747 q 601 681 543 747 q 659 481 659 614 l 659 0 l 578 0 l 578 482 q 537 629 578 581 q 406 677 496 677 q 272 630 330 677 q 186 503 214 583 l 186 0 l 106 0 l 106 734 l 183 734 m 621 1013 q 583 908 621 947 q 489 868 545 868 q 388 905 437 868 q 300 943 339 943 q 247 922 269 943 q 226 863 226 901 l 165 865 q 202 968 165 927 q 297 1008 239 1008 q 353 997 330 1008 q 396 971 376 985 q 437 945 416 957 q 486 934 458 934 q 539 956 517 934 q 561 1017 561 979 l 621 1013 z ',
    },
    ò: {
      ha: 778,
      x_min: 61,
      x_max: 716,
      o: 'm 61 378 q 102 568 61 484 q 218 700 143 653 q 388 747 293 747 q 625 645 534 747 q 716 372 716 542 l 716 355 q 675 164 716 249 q 559 33 634 79 q 389 -14 485 -14 q 152 89 243 -14 q 61 361 61 192 l 61 378 m 142 355 q 211 139 142 224 q 389 55 279 55 q 567 139 499 55 q 635 362 635 224 l 635 378 q 604 532 635 462 q 517 640 573 602 q 388 678 460 678 q 211 593 279 678 q 142 370 142 508 l 142 355 m 437 852 l 363 852 l 210 1042 l 308 1042 l 437 852 z ',
    },
    ó: {
      ha: 778,
      x_min: 61,
      x_max: 716,
      o: 'm 61 378 q 102 568 61 484 q 218 700 143 653 q 388 747 293 747 q 625 645 534 747 q 716 372 716 542 l 716 355 q 675 164 716 249 q 559 33 634 79 q 389 -14 485 -14 q 152 89 243 -14 q 61 361 61 192 l 61 378 m 142 355 q 211 139 142 224 q 389 55 279 55 q 567 139 499 55 q 635 362 635 224 l 635 378 q 604 532 635 462 q 517 640 573 602 q 388 678 460 678 q 211 593 279 678 q 142 370 142 508 l 142 355 m 469 1042 l 567 1042 l 410 852 l 339 852 l 469 1042 z ',
    },
    ô: {
      ha: 778,
      x_min: 61,
      x_max: 716,
      o: 'm 61 378 q 102 568 61 484 q 218 700 143 653 q 388 747 293 747 q 625 645 534 747 q 716 372 716 542 l 716 355 q 675 164 716 249 q 559 33 634 79 q 389 -14 485 -14 q 152 89 243 -14 q 61 361 61 192 l 61 378 m 142 355 q 211 139 142 224 q 389 55 279 55 q 567 139 499 55 q 635 362 635 224 l 635 378 q 604 532 635 462 q 517 640 573 602 q 388 678 460 678 q 211 593 279 678 q 142 370 142 508 l 142 355 m 576 860 l 576 853 l 501 853 l 387 977 l 274 853 l 200 853 l 200 862 l 361 1035 l 413 1035 l 576 860 z ',
    },
    õ: {
      ha: 778,
      x_min: 61,
      x_max: 716,
      o: 'm 61 378 q 102 568 61 484 q 218 700 143 653 q 388 747 293 747 q 625 645 534 747 q 716 372 716 542 l 716 355 q 675 164 716 249 q 559 33 634 79 q 389 -14 485 -14 q 152 89 243 -14 q 61 361 61 192 l 61 378 m 142 355 q 211 139 142 224 q 389 55 279 55 q 567 139 499 55 q 635 362 635 224 l 635 378 q 604 532 635 462 q 517 640 573 602 q 388 678 460 678 q 211 593 279 678 q 142 370 142 508 l 142 355 m 618 1013 q 580 908 618 947 q 486 868 542 868 q 386 905 435 868 q 298 943 336 943 q 244 922 266 943 q 223 863 223 901 l 162 865 q 199 968 162 927 q 294 1008 236 1008 q 350 997 327 1008 q 393 971 373 985 q 434 945 414 957 q 483 934 455 934 q 536 956 514 934 q 558 1017 558 979 l 618 1013 z ',
    },
    ö: {
      ha: 778,
      x_min: 61,
      x_max: 716,
      o: 'm 61 378 q 102 568 61 484 q 218 700 143 653 q 388 747 293 747 q 625 645 534 747 q 716 372 716 542 l 716 355 q 675 164 716 249 q 559 33 634 79 q 389 -14 485 -14 q 152 89 243 -14 q 61 361 61 192 l 61 378 m 142 355 q 211 139 142 224 q 389 55 279 55 q 567 139 499 55 q 635 362 635 224 l 635 378 q 604 532 635 462 q 517 640 573 602 q 388 678 460 678 q 211 593 279 678 q 142 370 142 508 l 142 355 m 184 947 q 199 986 184 970 q 240 1002 214 1002 q 281 986 266 1002 q 296 947 296 970 q 281 909 296 924 q 240 893 266 893 q 199 909 214 893 q 184 947 184 924 m 481 946 q 496 985 481 969 q 537 1001 511 1001 q 578 985 563 1001 q 593 946 593 969 q 578 908 593 924 q 537 892 563 892 q 496 908 511 892 q 481 946 481 924 z ',
    },
    ù: {
      ha: 763,
      x_min: 103,
      x_max: 658,
      o: 'm 578 93 q 344 -14 505 -14 q 165 55 227 -14 q 103 256 104 123 l 103 734 l 184 734 l 184 267 q 353 57 184 57 q 576 203 530 57 l 576 734 l 658 734 l 658 0 l 579 0 l 578 93 m 439 852 l 365 852 l 212 1042 l 309 1042 l 439 852 z ',
    },
    ú: {
      ha: 763,
      x_min: 103,
      x_max: 658,
      o: 'm 578 93 q 344 -14 505 -14 q 165 55 227 -14 q 103 256 104 123 l 103 734 l 184 734 l 184 267 q 353 57 184 57 q 576 203 530 57 l 576 734 l 658 734 l 658 0 l 579 0 l 578 93 m 470 1042 l 568 1042 l 412 852 l 340 852 l 470 1042 z ',
    },
    û: {
      ha: 763,
      x_min: 103,
      x_max: 658,
      o: 'm 578 93 q 344 -14 505 -14 q 165 55 227 -14 q 103 256 104 123 l 103 734 l 184 734 l 184 267 q 353 57 184 57 q 576 203 530 57 l 576 734 l 658 734 l 658 0 l 579 0 l 578 93 m 578 860 l 578 853 l 503 853 l 389 977 l 275 853 l 201 853 l 201 862 l 363 1035 l 414 1035 l 578 860 z ',
    },
    ü: {
      ha: 763,
      x_min: 103,
      x_max: 658,
      o: 'm 578 93 q 344 -14 505 -14 q 165 55 227 -14 q 103 256 104 123 l 103 734 l 184 734 l 184 267 q 353 57 184 57 q 576 203 530 57 l 576 734 l 658 734 l 658 0 l 579 0 l 578 93 m 186 947 q 201 986 186 970 q 241 1002 216 1002 q 282 986 267 1002 q 298 947 298 970 q 282 909 298 924 q 241 893 267 893 q 201 909 216 893 q 186 947 186 924 m 483 946 q 498 985 483 969 q 538 1001 513 1001 q 579 985 564 1001 q 595 946 595 969 q 579 908 595 924 q 538 892 564 892 q 498 908 513 892 q 483 946 483 924 z ',
    },
    ý: {
      ha: 660,
      x_min: 22,
      x_max: 640,
      o: 'm 338 117 l 553 734 l 640 734 l 327 -125 l 311 -163 q 124 -296 250 -296 q 62 -287 95 -296 l 61 -220 l 103 -224 q 200 -194 163 -224 q 262 -93 237 -165 l 298 6 l 22 734 l 111 734 l 338 117 m 429 1042 l 528 1042 l 371 852 l 300 852 l 429 1042 z ',
    },
    ÿ: {
      ha: 660,
      x_min: 22,
      x_max: 640,
      o: 'm 338 117 l 553 734 l 640 734 l 327 -125 l 311 -163 q 124 -296 250 -296 q 62 -287 95 -296 l 61 -220 l 103 -224 q 200 -194 163 -224 q 262 -93 237 -165 l 298 6 l 22 734 l 111 734 l 338 117 m 145 947 q 160 986 145 970 q 201 1002 175 1002 q 242 986 227 1002 q 257 947 257 970 q 242 909 257 924 q 201 893 227 893 q 160 909 175 893 q 145 947 145 924 m 442 946 q 457 985 442 969 q 498 1001 472 1001 q 539 985 524 1001 q 554 946 554 969 q 539 908 554 924 q 498 892 524 892 q 457 908 472 892 q 442 946 442 924 z ',
    },
    Ā: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 648 1131 l 228 1131 l 228 1196 l 648 1196 l 648 1131 z ',
    },
    ā: {
      ha: 744,
      x_min: 68,
      x_max: 656,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 m 605 917 l 185 917 l 185 983 l 605 983 l 605 917 z ',
    },
    Ă: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 631 1226 q 578 1100 631 1148 q 435 1052 525 1052 q 293 1100 347 1052 q 239 1226 239 1147 l 309 1226 q 342 1140 309 1170 q 435 1110 376 1110 q 529 1141 495 1110 q 562 1226 562 1173 l 631 1226 z ',
    },
    ă: {
      ha: 744,
      x_min: 68,
      x_max: 656,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 m 589 1013 q 535 886 589 935 q 393 838 482 838 q 251 886 304 838 q 197 1013 197 934 l 266 1013 q 299 926 266 956 q 393 897 333 897 q 486 928 452 897 q 519 1013 519 959 l 589 1013 z ',
    },
    Ą: {
      ha: 867,
      x_min: 20,
      x_max: 852,
      o: 'm 473 987 l 847 0 l 834 0 q 738 -90 763 -54 q 713 -159 713 -125 q 770 -216 713 -216 q 842 -193 807 -216 l 852 -250 q 755 -280 808 -280 q 670 -250 701 -280 q 640 -165 640 -220 q 671 -77 640 -121 q 759 3 703 -33 l 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 m 235 348 l 631 348 l 433 886 l 235 348 z ',
    },
    ą: {
      ha: 744,
      x_min: 68,
      x_max: 663,
      o: 'm 568 3 q 553 102 557 37 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 645 0 q 549 -90 574 -54 q 524 -159 524 -125 q 581 -216 524 -216 q 652 -193 618 -216 l 663 -250 q 566 -280 618 -280 q 481 -250 511 -280 q 450 -165 450 -220 q 481 -78 450 -121 q 568 3 513 -34 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 z ',
    },
    Ć: {
      ha: 902,
      x_min: 89,
      x_max: 824,
      o: 'm 824 308 q 712 69 807 152 q 460 -14 618 -14 q 266 41 351 -14 q 136 197 182 96 q 89 427 90 298 l 89 555 q 135 789 89 687 q 268 946 181 890 q 467 1001 354 1001 q 717 915 625 1001 q 824 678 809 829 l 740 678 q 467 930 709 930 q 253 829 332 930 q 173 552 173 729 l 173 431 q 251 159 173 260 q 460 57 328 57 q 658 120 591 57 q 740 308 724 182 l 824 308 m 541 1274 l 640 1274 l 483 1084 l 412 1084 l 541 1274 z ',
    },
    ć: {
      ha: 715,
      x_min: 64,
      x_max: 659,
      o: 'm 377 55 q 519 103 462 55 q 581 227 576 151 l 659 227 q 617 104 656 159 q 514 18 578 49 q 377 -14 450 -14 q 148 87 233 -14 q 64 357 64 187 l 64 381 q 102 573 64 490 q 211 702 140 656 q 376 747 281 747 q 575 675 497 747 q 659 486 654 604 l 581 486 q 519 625 576 572 q 376 678 463 678 q 206 599 267 678 q 145 376 145 519 l 145 353 q 206 134 145 212 q 377 55 267 55 m 447 1042 l 545 1042 l 389 852 l 317 852 l 447 1042 z ',
    },
    Ĉ: {
      ha: 902,
      x_min: 89,
      x_max: 824,
      o: 'm 824 308 q 712 69 807 152 q 460 -14 618 -14 q 266 41 351 -14 q 136 197 182 96 q 89 427 90 298 l 89 555 q 135 789 89 687 q 268 946 181 890 q 467 1001 354 1001 q 717 915 625 1001 q 824 678 809 829 l 740 678 q 467 930 709 930 q 253 829 332 930 q 173 552 173 729 l 173 431 q 251 159 173 260 q 460 57 328 57 q 658 120 591 57 q 740 308 724 182 l 824 308 m 649 1092 l 649 1085 l 574 1085 l 460 1209 l 347 1085 l 273 1085 l 273 1094 l 434 1267 l 486 1267 l 649 1092 z ',
    },
    ĉ: {
      ha: 715,
      x_min: 64,
      x_max: 659,
      o: 'm 377 55 q 519 103 462 55 q 581 227 576 151 l 659 227 q 617 104 656 159 q 514 18 578 49 q 377 -14 450 -14 q 148 87 233 -14 q 64 357 64 187 l 64 381 q 102 573 64 490 q 211 702 140 656 q 376 747 281 747 q 575 675 497 747 q 659 486 654 604 l 581 486 q 519 625 576 572 q 376 678 463 678 q 206 599 267 678 q 145 376 145 519 l 145 353 q 206 134 145 212 q 377 55 267 55 m 555 860 l 555 853 l 479 853 l 366 977 l 252 853 l 178 853 l 178 862 l 340 1035 l 391 1035 l 555 860 z ',
    },
    Ċ: {
      ha: 902,
      x_min: 89,
      x_max: 824,
      o: 'm 824 308 q 712 69 807 152 q 460 -14 618 -14 q 266 41 351 -14 q 136 197 182 96 q 89 427 90 298 l 89 555 q 135 789 89 687 q 268 946 181 890 q 467 1001 354 1001 q 717 915 625 1001 q 824 678 809 829 l 740 678 q 467 930 709 930 q 253 829 332 930 q 173 552 173 729 l 173 431 q 251 159 173 260 q 460 57 328 57 q 658 120 591 57 q 740 308 724 182 l 824 308 m 404 1169 q 419 1208 404 1192 q 460 1224 434 1224 q 501 1208 486 1224 q 516 1169 516 1192 q 501 1131 516 1147 q 460 1116 486 1116 q 419 1131 434 1116 q 404 1169 404 1147 z ',
    },
    ċ: {
      ha: 715,
      x_min: 64,
      x_max: 659,
      o: 'm 377 55 q 519 103 462 55 q 581 227 576 151 l 659 227 q 617 104 656 159 q 514 18 578 49 q 377 -14 450 -14 q 148 87 233 -14 q 64 357 64 187 l 64 381 q 102 573 64 490 q 211 702 140 656 q 376 747 281 747 q 575 675 497 747 q 659 486 654 604 l 581 486 q 519 625 576 572 q 376 678 463 678 q 206 599 267 678 q 145 376 145 519 l 145 353 q 206 134 145 212 q 377 55 267 55 m 310 937 q 325 976 310 960 q 366 992 340 992 q 407 976 392 992 q 422 937 422 960 q 407 899 422 915 q 366 884 392 884 q 325 899 340 884 q 310 937 310 915 z ',
    },
    Č: {
      ha: 902,
      x_min: 89,
      x_max: 824,
      o: 'm 824 308 q 712 69 807 152 q 460 -14 618 -14 q 266 41 351 -14 q 136 197 182 96 q 89 427 90 298 l 89 555 q 135 789 89 687 q 268 946 181 890 q 467 1001 354 1001 q 717 915 625 1001 q 824 678 809 829 l 740 678 q 467 930 709 930 q 253 829 332 930 q 173 552 173 729 l 173 431 q 251 159 173 260 q 460 57 328 57 q 658 120 591 57 q 740 308 724 182 l 824 308 m 459 1133 l 576 1265 l 648 1265 l 648 1257 l 488 1080 l 430 1080 l 270 1257 l 270 1265 l 342 1265 l 459 1133 z ',
    },
    č: {
      ha: 715,
      x_min: 64,
      x_max: 659,
      o: 'm 377 55 q 519 103 462 55 q 581 227 576 151 l 659 227 q 617 104 656 159 q 514 18 578 49 q 377 -14 450 -14 q 148 87 233 -14 q 64 357 64 187 l 64 381 q 102 573 64 490 q 211 702 140 656 q 376 747 281 747 q 575 675 497 747 q 659 486 654 604 l 581 486 q 519 625 576 572 q 376 678 463 678 q 206 599 267 678 q 145 376 145 519 l 145 353 q 206 134 145 212 q 377 55 267 55 m 365 901 l 481 1032 l 554 1032 l 554 1024 l 394 847 l 336 847 l 176 1025 l 176 1032 l 248 1032 l 365 901 z ',
    },
    Ď: {
      ha: 909,
      x_min: 125,
      x_max: 823,
      o: 'm 125 0 l 125 987 l 401 987 q 618 932 522 987 q 769 774 715 877 q 823 539 823 671 l 823 448 q 769 214 823 316 q 618 56 715 111 q 395 0 521 0 l 125 0 m 208 916 l 208 71 l 395 71 q 646 174 551 71 q 740 454 740 277 l 740 541 q 647 813 740 711 q 402 916 554 916 l 208 916 m 406 1119 l 522 1251 l 595 1251 l 595 1242 l 435 1065 l 376 1065 l 216 1243 l 216 1251 l 289 1251 l 406 1119 z ',
    },
    ď: {
      ha: 873,
      x_min: 73,
      x_max: 854,
      o: 'm 73 374 q 150 646 73 545 q 357 747 226 747 q 583 630 505 747 l 583 1042 l 664 1042 l 664 0 l 588 0 l 585 98 q 355 -14 507 -14 q 151 89 229 -14 q 73 364 73 191 l 73 374 m 155 359 q 212 138 155 219 q 370 57 268 57 q 583 189 520 57 l 583 534 q 372 677 520 677 q 212 596 269 677 q 155 359 155 516 m 776 800 l 728 835 q 777 977 774 901 l 777 1042 l 854 1042 l 854 1000 q 776 800 854 870 z ',
    },
    Ē: {
      ha: 790,
      x_min: 125,
      x_max: 739,
      o: 'm 670 473 l 208 473 l 208 71 l 739 71 l 739 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 544 l 670 544 l 670 473 m 632 1135 l 212 1135 l 212 1200 l 632 1200 l 632 1135 z ',
    },
    ē: {
      ha: 718,
      x_min: 61,
      x_max: 665,
      o: 'm 390 -14 q 221 33 296 -14 q 103 161 145 79 q 61 346 61 243 l 61 375 q 102 566 61 481 q 216 699 143 650 q 375 747 290 747 q 587 656 509 747 q 665 407 665 565 l 665 361 l 142 361 l 142 346 q 213 138 142 221 q 393 55 285 55 q 508 79 458 55 q 599 155 558 102 l 650 116 q 390 -14 560 -14 m 375 678 q 221 611 283 678 q 144 431 158 544 l 585 431 l 585 439 q 524 612 581 545 q 375 678 467 678 m 596 916 l 176 916 l 176 982 l 596 982 l 596 916 z ',
    },
    Ĕ: {
      ha: 790,
      x_min: 125,
      x_max: 739,
      o: 'm 670 473 l 208 473 l 208 71 l 739 71 l 739 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 544 l 670 544 l 670 473 m 616 1230 q 563 1104 616 1152 q 420 1056 509 1056 q 278 1104 332 1056 q 224 1230 224 1152 l 293 1230 q 327 1144 293 1174 q 420 1114 360 1114 q 513 1145 479 1114 q 547 1230 547 1177 l 616 1230 z ',
    },
    ĕ: {
      ha: 718,
      x_min: 61,
      x_max: 665,
      o: 'm 390 -14 q 221 33 296 -14 q 103 161 145 79 q 61 346 61 243 l 61 375 q 102 566 61 481 q 216 699 143 650 q 375 747 290 747 q 587 656 509 747 q 665 407 665 565 l 665 361 l 142 361 l 142 346 q 213 138 142 221 q 393 55 285 55 q 508 79 458 55 q 599 155 558 102 l 650 116 q 390 -14 560 -14 m 375 678 q 221 611 283 678 q 144 431 158 544 l 585 431 l 585 439 q 524 612 581 545 q 375 678 467 678 m 580 1012 q 527 886 580 934 q 384 838 473 838 q 242 885 296 838 q 188 1012 188 933 l 257 1012 q 291 926 257 956 q 384 896 324 896 q 477 927 444 896 q 511 1012 511 958 l 580 1012 z ',
    },
    Ė: {
      ha: 790,
      x_min: 125,
      x_max: 739,
      o: 'm 670 473 l 208 473 l 208 71 l 739 71 l 739 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 544 l 670 544 l 670 473 m 364 1155 q 379 1194 364 1178 q 420 1210 394 1210 q 461 1194 446 1210 q 476 1155 476 1178 q 461 1117 476 1133 q 420 1101 446 1101 q 379 1117 394 1101 q 364 1155 364 1133 z ',
    },
    ė: {
      ha: 718,
      x_min: 61,
      x_max: 665,
      o: 'm 390 -14 q 221 33 296 -14 q 103 161 145 79 q 61 346 61 243 l 61 375 q 102 566 61 481 q 216 699 143 650 q 375 747 290 747 q 587 656 509 747 q 665 407 665 565 l 665 361 l 142 361 l 142 346 q 213 138 142 221 q 393 55 285 55 q 508 79 458 55 q 599 155 558 102 l 650 116 q 390 -14 560 -14 m 375 678 q 221 611 283 678 q 144 431 158 544 l 585 431 l 585 439 q 524 612 581 545 q 375 678 467 678 m 328 937 q 343 976 328 960 q 384 992 358 992 q 425 976 410 992 q 440 937 440 960 q 425 899 440 915 q 384 884 410 884 q 343 899 358 884 q 328 937 328 915 z ',
    },
    Ę: {
      ha: 790,
      x_min: 125,
      x_max: 739,
      o: 'm 670 473 l 208 473 l 208 71 l 739 71 l 739 0 l 694 0 q 598 -90 623 -54 q 573 -159 573 -125 q 631 -216 573 -216 q 702 -193 667 -216 l 712 -250 q 615 -280 668 -280 q 530 -250 561 -280 q 500 -165 500 -220 q 530 -79 500 -122 q 614 0 561 -37 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 544 l 670 544 l 670 473 z ',
    },
    ę: {
      ha: 718,
      x_min: 61,
      x_max: 665,
      o: 'm 390 -14 q 221 33 296 -14 q 103 161 145 79 q 61 346 61 243 l 61 375 q 102 566 61 481 q 216 699 143 650 q 375 747 290 747 q 587 656 509 747 q 665 407 665 565 l 665 361 l 142 361 l 142 346 q 213 138 142 221 q 393 55 285 55 q 508 79 458 55 q 599 155 558 102 l 650 116 q 555 27 611 59 l 513 -7 q 436 -130 436 -71 q 494 -187 436 -187 q 565 -165 530 -187 l 575 -222 q 478 -252 531 -252 q 393 -221 424 -252 q 363 -137 363 -191 q 427 -12 363 -74 l 390 -14 m 375 678 q 221 611 283 678 q 144 431 158 544 l 585 431 l 585 439 q 524 612 581 545 q 375 678 467 678 z ',
    },
    Ě: {
      ha: 790,
      x_min: 125,
      x_max: 739,
      o: 'm 670 473 l 208 473 l 208 71 l 739 71 l 739 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 544 l 670 544 l 670 473 m 419 1119 l 536 1251 l 608 1251 l 608 1242 l 448 1065 l 390 1065 l 230 1243 l 230 1251 l 302 1251 l 419 1119 z ',
    },
    ě: {
      ha: 718,
      x_min: 61,
      x_max: 665,
      o: 'm 390 -14 q 221 33 296 -14 q 103 161 145 79 q 61 346 61 243 l 61 375 q 102 566 61 481 q 216 699 143 650 q 375 747 290 747 q 587 656 509 747 q 665 407 665 565 l 665 361 l 142 361 l 142 346 q 213 138 142 221 q 393 55 285 55 q 508 79 458 55 q 599 155 558 102 l 650 116 q 390 -14 560 -14 m 375 678 q 221 611 283 678 q 144 431 158 544 l 585 431 l 585 439 q 524 612 581 545 q 375 678 467 678 m 383 901 l 500 1032 l 572 1032 l 572 1024 l 412 847 l 354 847 l 194 1025 l 194 1032 l 267 1032 l 383 901 z ',
    },
    Ĝ: {
      ha: 949,
      x_min: 98,
      x_max: 838,
      o: 'm 838 117 q 702 21 794 56 q 494 -14 609 -14 q 288 42 378 -14 q 149 199 198 98 q 98 433 99 301 l 98 557 q 202 882 98 763 q 481 1001 306 1001 q 726 923 633 1001 q 837 709 819 846 l 753 709 q 665 874 735 818 q 481 930 595 930 q 262 831 343 930 q 182 554 182 733 l 182 437 q 220 238 182 325 q 330 104 258 152 q 494 57 401 57 q 680 91 602 57 q 754 143 730 113 l 754 398 l 489 398 l 489 469 l 838 469 l 838 117 m 655 1092 l 655 1085 l 580 1085 l 466 1209 l 353 1085 l 279 1085 l 279 1094 l 440 1267 l 492 1267 l 655 1092 z ',
    },
    ĝ: {
      ha: 770,
      x_min: 73,
      x_max: 665,
      o: 'm 73 374 q 150 647 73 546 q 357 747 226 747 q 585 628 507 747 l 589 734 l 665 734 l 665 15 q 585 -210 665 -127 q 370 -292 506 -292 q 224 -258 294 -292 q 115 -167 155 -224 l 160 -118 q 365 -224 246 -224 q 524 -164 467 -224 q 583 3 581 -104 l 583 95 q 355 -14 505 -14 q 151 88 228 -14 q 73 363 73 190 l 73 374 m 155 359 q 212 138 155 219 q 370 57 268 57 q 583 191 520 57 l 583 532 q 500 640 554 603 q 372 677 447 677 q 212 596 269 677 q 155 359 155 516 m 570 860 l 570 853 l 494 853 l 380 977 l 267 853 l 193 853 l 193 862 l 355 1035 l 406 1035 l 570 860 z ',
    },
    Ğ: {
      ha: 949,
      x_min: 98,
      x_max: 838,
      o: 'm 838 117 q 702 21 794 56 q 494 -14 609 -14 q 288 42 378 -14 q 149 199 198 98 q 98 433 99 301 l 98 557 q 202 882 98 763 q 481 1001 306 1001 q 726 923 633 1001 q 837 709 819 846 l 753 709 q 665 874 735 818 q 481 930 595 930 q 262 831 343 930 q 182 554 182 733 l 182 437 q 220 238 182 325 q 330 104 258 152 q 494 57 401 57 q 680 91 602 57 q 754 143 730 113 l 754 398 l 489 398 l 489 469 l 838 469 l 838 117 m 662 1244 q 609 1118 662 1166 q 466 1070 555 1070 q 324 1118 378 1070 q 270 1244 270 1166 l 339 1244 q 373 1158 339 1188 q 466 1128 406 1128 q 559 1160 526 1128 q 593 1244 593 1191 l 662 1244 z ',
    },
    ğ: {
      ha: 770,
      x_min: 73,
      x_max: 665,
      o: 'm 73 374 q 150 647 73 546 q 357 747 226 747 q 585 628 507 747 l 589 734 l 665 734 l 665 15 q 585 -210 665 -127 q 370 -292 506 -292 q 224 -258 294 -292 q 115 -167 155 -224 l 160 -118 q 365 -224 246 -224 q 524 -164 467 -224 q 583 3 581 -104 l 583 95 q 355 -14 505 -14 q 151 88 228 -14 q 73 363 73 190 l 73 374 m 155 359 q 212 138 155 219 q 370 57 268 57 q 583 191 520 57 l 583 532 q 500 640 554 603 q 372 677 447 677 q 212 596 269 677 q 155 359 155 516 m 576 1012 q 523 886 576 934 q 380 838 470 838 q 238 885 292 838 q 184 1012 184 933 l 254 1012 q 287 926 254 956 q 380 896 321 896 q 474 927 440 896 q 507 1012 507 958 l 576 1012 z ',
    },
    Ġ: {
      ha: 949,
      x_min: 98,
      x_max: 838,
      o: 'm 838 117 q 702 21 794 56 q 494 -14 609 -14 q 288 42 378 -14 q 149 199 198 98 q 98 433 99 301 l 98 557 q 202 882 98 763 q 481 1001 306 1001 q 726 923 633 1001 q 837 709 819 846 l 753 709 q 665 874 735 818 q 481 930 595 930 q 262 831 343 930 q 182 554 182 733 l 182 437 q 220 238 182 325 q 330 104 258 152 q 494 57 401 57 q 680 91 602 57 q 754 143 730 113 l 754 398 l 489 398 l 489 469 l 838 469 l 838 117 m 410 1169 q 425 1208 410 1192 q 466 1224 440 1224 q 507 1208 492 1224 q 522 1169 522 1192 q 507 1131 522 1147 q 466 1116 492 1116 q 425 1131 440 1116 q 410 1169 410 1147 z ',
    },
    ġ: {
      ha: 770,
      x_min: 73,
      x_max: 665,
      o: 'm 73 374 q 150 647 73 546 q 357 747 226 747 q 585 628 507 747 l 589 734 l 665 734 l 665 15 q 585 -210 665 -127 q 370 -292 506 -292 q 224 -258 294 -292 q 115 -167 155 -224 l 160 -118 q 365 -224 246 -224 q 524 -164 467 -224 q 583 3 581 -104 l 583 95 q 355 -14 505 -14 q 151 88 228 -14 q 73 363 73 190 l 73 374 m 155 359 q 212 138 155 219 q 370 57 268 57 q 583 191 520 57 l 583 532 q 500 640 554 603 q 372 677 447 677 q 212 596 269 677 q 155 359 155 516 m 325 937 q 340 976 325 960 q 380 992 355 992 q 422 976 407 992 q 437 937 437 960 q 422 899 437 915 q 380 884 407 884 q 340 899 355 884 q 325 937 325 915 z ',
    },
    Ģ: {
      ha: 949,
      x_min: 98,
      x_max: 838,
      o: 'm 838 117 q 702 21 794 56 q 494 -14 609 -14 q 288 42 378 -14 q 149 199 198 98 q 98 433 99 301 l 98 557 q 202 882 98 763 q 481 1001 306 1001 q 726 923 633 1001 q 837 709 819 846 l 753 709 q 665 874 735 818 q 481 930 595 930 q 262 831 343 930 q 182 554 182 733 l 182 437 q 220 238 182 325 q 330 104 258 152 q 494 57 401 57 q 680 91 602 57 q 754 143 730 113 l 754 398 l 489 398 l 489 469 l 838 469 l 838 117 m 455 -317 l 407 -281 q 456 -139 454 -215 l 456 -74 l 534 -74 l 534 -117 q 455 -317 534 -246 z ',
    },
    ģ: {
      ha: 770,
      x_min: 73,
      x_max: 665,
      o: 'm 73 374 q 150 647 73 546 q 357 747 226 747 q 585 628 507 747 l 589 734 l 665 734 l 665 15 q 585 -210 665 -127 q 370 -292 506 -292 q 224 -258 294 -292 q 115 -167 155 -224 l 160 -118 q 365 -224 246 -224 q 524 -164 467 -224 q 583 3 581 -104 l 583 95 q 355 -14 505 -14 q 151 88 228 -14 q 73 363 73 190 l 73 374 m 155 359 q 212 138 155 219 q 370 57 268 57 q 583 191 520 57 l 583 532 q 500 640 554 603 q 372 677 447 677 q 212 596 269 677 q 155 359 155 516 m 405 1109 l 456 1076 q 418 950 420 1009 l 418 857 l 338 857 l 338 945 q 356 1030 338 985 q 405 1109 375 1076 z ',
    },
    Ĥ: {
      ha: 983,
      x_min: 125,
      x_max: 857,
      o: 'm 857 0 l 772 0 l 772 473 l 208 473 l 208 0 l 125 0 l 125 987 l 208 987 l 208 544 l 772 544 l 772 987 l 857 987 l 857 0 m 673 1078 l 673 1071 l 598 1071 l 484 1195 l 371 1071 l 297 1071 l 297 1080 l 458 1253 l 510 1253 l 673 1078 z ',
    },
    ĥ: {
      ha: 762,
      x_min: 106,
      x_max: 659,
      o: 'm 186 610 q 289 711 227 675 q 425 747 351 747 q 601 681 543 747 q 659 481 659 614 l 659 0 l 578 0 l 578 482 q 537 629 578 581 q 406 677 496 677 q 272 630 330 677 q 186 503 214 583 l 186 0 l 106 0 l 106 1042 l 186 1042 l 186 610 m 519 1077 l 519 1070 l 444 1070 l 330 1194 l 216 1070 l 142 1070 l 142 1079 l 304 1252 l 355 1252 l 519 1077 z ',
    },
    Ĩ: {
      ha: 370,
      x_min: -39,
      x_max: 417,
      o: 'm 227 0 l 143 0 l 143 987 l 227 987 l 227 0 m 417 1231 q 379 1126 417 1166 q 285 1086 340 1086 q 184 1124 233 1086 q 96 1161 135 1161 q 43 1140 64 1161 q 22 1081 22 1119 l -39 1083 q -2 1186 -39 1145 q 93 1227 35 1227 q 149 1215 125 1227 q 192 1190 172 1204 q 233 1164 212 1175 q 281 1152 254 1152 q 335 1175 313 1152 q 357 1235 357 1197 l 417 1231 z ',
    },
    ĩ: {
      ha: 309,
      x_min: -68,
      x_max: 388,
      o: 'm 195 0 l 114 0 l 114 734 l 195 734 l 195 0 m 388 1005 q 350 900 388 940 q 256 861 311 861 q 155 898 204 861 q 67 935 106 935 q 14 914 35 935 q -7 855 -7 893 l -68 857 q -32 960 -68 920 q 64 1001 5 1001 q 119 989 96 1001 q 163 964 142 978 q 204 938 183 949 q 252 926 224 926 q 306 949 283 926 q 328 1009 328 971 l 388 1005 z ',
    },
    Ī: {
      ha: 370,
      x_min: -22,
      x_max: 398,
      o: 'm 227 0 l 143 0 l 143 987 l 227 987 l 227 0 m 398 1135 l -22 1135 l -22 1200 l 398 1200 l 398 1135 z ',
    },
    ī: {
      ha: 309,
      x_min: -51,
      x_max: 369,
      o: 'm 195 0 l 114 0 l 114 734 l 195 734 l 195 0 m 369 909 l -51 909 l -51 975 l 369 975 l 369 909 z ',
    },
    Ĭ: {
      ha: 370,
      x_min: -10,
      x_max: 382,
      o: 'm 227 0 l 143 0 l 143 987 l 227 987 l 227 0 m 382 1230 q 329 1104 382 1152 q 186 1056 275 1056 q 44 1104 98 1056 q -10 1230 -10 1152 l 59 1230 q 93 1144 59 1174 q 186 1114 126 1114 q 279 1145 245 1114 q 313 1230 313 1177 l 382 1230 z ',
    },
    ĭ: {
      ha: 309,
      x_min: -39,
      x_max: 353,
      o: 'm 195 0 l 114 0 l 114 734 l 195 734 l 195 0 m 353 1004 q 299 878 353 926 q 157 830 246 830 q 15 878 68 830 q -39 1004 -39 926 l 30 1004 q 63 918 30 948 q 157 888 97 888 q 250 920 216 888 q 283 1004 283 951 l 353 1004 z ',
    },
    Į: {
      ha: 370,
      x_min: 26,
      x_max: 239,
      o: 'm 227 0 l 143 0 l 143 987 l 227 987 l 227 0 m 221 3 q 125 -87 150 -52 q 100 -156 100 -123 q 157 -213 100 -213 q 229 -191 194 -213 l 239 -248 q 142 -277 195 -277 q 57 -247 87 -277 q 26 -163 26 -217 q 68 -62 26 -113 q 181 28 111 -12 l 221 3 z ',
    },
    į: {
      ha: 311,
      x_min: -3,
      x_max: 213,
      o: 'm 197 0 l 115 0 l 115 734 l 197 734 l 197 0 m 101 946 q 116 985 101 969 q 157 1001 131 1001 q 198 985 182 1001 q 213 946 213 969 q 198 907 213 923 q 157 892 182 892 q 116 907 131 892 q 101 946 101 923 m 192 0 q 96 -90 121 -54 q 71 -159 71 -125 q 128 -216 71 -216 q 199 -193 165 -216 l 210 -250 q 113 -280 165 -280 q 28 -250 58 -280 q -3 -165 -3 -220 q 39 -65 -3 -115 q 152 25 81 -14 l 192 0 z ',
    },
    İ: {
      ha: 370,
      x_min: 130,
      x_max: 241,
      o: 'm 227 0 l 143 0 l 143 987 l 227 987 l 227 0 m 130 1155 q 144 1194 130 1178 q 185 1210 159 1210 q 227 1194 212 1210 q 241 1155 241 1178 q 227 1117 241 1133 q 185 1101 212 1101 q 144 1117 159 1101 q 130 1155 130 1133 z ',
    },
    Ĳ: {
      ha: 1134,
      x_min: 143,
      x_max: 1015,
      o: 'm 227 0 l 143 0 l 143 987 l 227 987 l 227 0 m 930 987 l 1015 987 l 1015 294 q 933 70 1015 153 q 716 -14 852 -14 q 495 62 572 -14 q 418 272 418 137 l 501 272 q 557 111 501 165 q 716 57 614 57 q 870 119 810 57 q 930 289 929 182 l 930 987 z ',
    },
    ĳ: {
      ha: 628,
      x_min: 101,
      x_max: 521,
      o: 'm 197 0 l 115 0 l 115 734 l 197 734 l 197 0 m 101 946 q 116 985 101 969 q 157 1001 131 1001 q 198 985 182 1001 q 213 946 213 969 q 198 907 213 923 q 157 892 182 892 q 116 907 131 892 q 101 946 101 923 m 505 734 l 505 -87 q 456 -243 505 -189 q 312 -296 407 -296 q 241 -283 275 -296 l 242 -216 q 303 -225 272 -225 q 424 -86 424 -225 l 424 734 l 505 734 m 465 1001 q 506 985 491 1001 q 521 946 521 969 q 506 907 521 923 q 465 892 491 892 q 424 907 439 892 q 410 946 410 923 q 424 985 410 969 q 465 1001 439 1001 z ',
    },
    Ĵ: {
      ha: 764,
      x_min: 48,
      x_max: 783,
      o: 'm 561 987 l 645 987 l 645 294 q 564 70 645 153 q 347 -14 483 -14 q 125 62 203 -14 q 48 272 48 137 l 132 272 q 188 111 132 165 q 347 57 244 57 q 500 119 441 57 q 561 289 559 182 l 561 987 m 783 1063 l 783 1056 l 708 1056 l 594 1180 l 481 1056 l 407 1056 l 407 1065 l 568 1238 l 620 1238 l 783 1063 z ',
    },
    ĵ: {
      ha: 317,
      x_min: -79,
      x_max: 351,
      o: 'm 202 734 l 202 -60 q 148 -236 202 -175 q -3 -296 94 -296 q -79 -283 -41 -296 l -70 -216 q -3 -227 -41 -227 q 89 -184 57 -227 q 121 -60 121 -140 l 121 734 l 202 734 m 351 832 l 351 825 l 275 825 l 161 949 l 48 825 l -26 825 l -26 834 l 136 1007 l 187 1007 l 351 832 z ',
    },
    Ķ: {
      ha: 876,
      x_min: 125,
      x_max: 851,
      o: 'm 347 490 l 208 353 l 208 0 l 125 0 l 125 987 l 208 987 l 208 455 l 714 987 l 819 987 l 406 548 l 851 0 l 749 0 l 347 490 m 395 -246 l 347 -211 q 396 -68 394 -144 l 396 -3 l 474 -3 l 474 -46 q 395 -246 474 -176 z ',
    },
    ķ: {
      ha: 680,
      x_min: 106,
      x_max: 674,
      o: 'm 280 370 l 187 280 l 187 0 l 106 0 l 106 1042 l 187 1042 l 187 375 l 264 458 l 545 734 l 647 734 l 336 425 l 674 0 l 577 0 l 280 370 m 315 -252 l 267 -216 q 316 -74 314 -150 l 316 -9 l 394 -9 l 394 -52 q 315 -252 394 -181 z ',
    },
    Ĺ: {
      ha: 732,
      x_min: 119,
      x_max: 696,
      o: 'm 209 71 l 696 71 l 696 0 l 125 0 l 125 987 l 209 987 l 209 71 m 249 1232 l 347 1232 l 191 1042 l 119 1042 l 249 1232 z ',
    },
    ĺ: {
      ha: 311,
      x_min: 109,
      x_max: 336,
      o: 'm 197 0 l 115 0 l 115 1042 l 197 1042 l 197 0 m 238 1308 l 336 1308 l 180 1118 l 109 1118 l 238 1308 z ',
    },
    Ļ: {
      ha: 732,
      x_min: 125,
      x_max: 696,
      o: 'm 209 71 l 696 71 l 696 0 l 125 0 l 125 987 l 209 987 l 209 71 m 382 -307 l 334 -272 q 383 -130 381 -205 l 383 -64 l 461 -64 l 461 -107 q 382 -307 461 -237 z ',
    },
    ļ: {
      ha: 311,
      x_min: 70,
      x_max: 197,
      o: 'm 197 0 l 115 0 l 115 1042 l 197 1042 l 197 0 m 118 -307 l 70 -272 q 119 -130 117 -205 l 119 -64 l 197 -64 l 197 -107 q 118 -307 197 -237 z ',
    },
    Ľ: {
      ha: 732,
      x_min: 125,
      x_max: 696,
      o: 'm 209 71 l 696 71 l 696 0 l 125 0 l 125 987 l 209 987 l 209 71 m 421 745 l 373 780 q 422 922 420 846 l 422 987 l 500 987 l 500 945 q 421 745 500 815 z ',
    },
    ľ: {
      ha: 413,
      x_min: 115,
      x_max: 406,
      o: 'm 197 0 l 115 0 l 115 1042 l 197 1042 l 197 0 m 327 800 l 279 835 q 328 977 326 901 l 328 1042 l 406 1042 l 406 1000 q 327 800 406 870 z ',
    },
    Ŀ: {
      ha: 732,
      x_min: 125,
      x_max: 696,
      o: 'm 209 71 l 696 71 l 696 0 l 125 0 l 125 987 l 209 987 l 209 71 m 404 526 q 418 565 404 549 q 459 581 433 581 q 500 565 486 581 q 515 526 515 549 q 500 488 515 504 q 459 473 486 473 q 418 488 433 473 q 404 526 404 504 z ',
    },
    ŀ: {
      ha: 460,
      x_min: 115,
      x_max: 401,
      o: 'm 197 0 l 115 0 l 115 1042 l 197 1042 l 197 0 m 290 535 q 304 574 290 558 q 345 590 319 590 q 387 574 372 590 q 401 535 401 558 q 387 497 401 513 q 345 481 372 481 q 304 497 319 481 q 290 535 290 513 z ',
    },
    Ń: {
      ha: 986,
      x_min: 125,
      x_max: 860,
      o: 'm 860 0 l 777 0 l 209 845 l 209 0 l 125 0 l 125 987 l 209 987 l 777 142 l 777 987 l 860 987 l 860 0 m 574 1255 l 672 1255 l 515 1065 l 444 1065 l 574 1255 z ',
    },
    ń: {
      ha: 763,
      x_min: 106,
      x_max: 659,
      o: 'm 183 734 l 186 608 q 289 712 227 676 q 425 747 351 747 q 601 681 543 747 q 659 481 659 614 l 659 0 l 578 0 l 578 482 q 537 629 578 581 q 406 677 496 677 q 272 630 330 677 q 186 503 214 583 l 186 0 l 106 0 l 106 734 l 183 734 m 471 1042 l 570 1042 l 413 852 l 342 852 l 471 1042 z ',
    },
    Ņ: {
      ha: 986,
      x_min: 125,
      x_max: 860,
      o: 'm 860 0 l 777 0 l 209 845 l 209 0 l 125 0 l 125 987 l 209 987 l 777 142 l 777 987 l 860 987 l 860 0 m 450 -307 l 402 -272 q 451 -130 449 -205 l 451 -64 l 529 -64 l 529 -107 q 450 -307 529 -237 z ',
    },
    ņ: {
      ha: 763,
      x_min: 106,
      x_max: 659,
      o: 'm 183 734 l 186 608 q 289 712 227 676 q 425 747 351 747 q 601 681 543 747 q 659 481 659 614 l 659 0 l 578 0 l 578 482 q 537 629 578 581 q 406 677 496 677 q 272 630 330 677 q 186 503 214 583 l 186 0 l 106 0 l 106 734 l 183 734 m 347 -307 l 299 -272 q 348 -130 346 -205 l 348 -64 l 426 -64 l 426 -107 q 347 -307 426 -237 z ',
    },
    Ň: {
      ha: 986,
      x_min: 125,
      x_max: 860,
      o: 'm 860 0 l 777 0 l 209 845 l 209 0 l 125 0 l 125 987 l 209 987 l 777 142 l 777 987 l 860 987 l 860 0 m 492 1115 l 608 1246 l 681 1246 l 681 1238 l 521 1061 l 463 1061 l 302 1239 l 302 1246 l 375 1246 l 492 1115 z ',
    },
    ň: {
      ha: 763,
      x_min: 106,
      x_max: 659,
      o: 'm 183 734 l 186 608 q 289 712 227 676 q 425 747 351 747 q 601 681 543 747 q 659 481 659 614 l 659 0 l 578 0 l 578 482 q 537 629 578 581 q 406 677 496 677 q 272 630 330 677 q 186 503 214 583 l 186 0 l 106 0 l 106 734 l 183 734 m 389 901 l 506 1032 l 578 1032 l 578 1024 l 418 847 l 360 847 l 200 1025 l 200 1032 l 273 1032 l 389 901 z ',
    },
    ŉ: {
      ha: 763,
      x_min: -32,
      x_max: 659,
      o: 'm 183 734 l 186 608 q 289 712 227 676 q 425 747 351 747 q 601 681 543 747 q 659 481 659 614 l 659 0 l 578 0 l 578 482 q 537 629 578 581 q 406 677 496 677 q 272 630 330 677 q 186 503 214 583 l 186 0 l 106 0 l 106 734 l 183 734 m 16 800 l -32 836 q 17 978 15 902 l 17 1043 l 95 1043 l 95 1000 q 16 800 95 871 z ',
    },
    Ō: {
      ha: 940,
      x_min: 85,
      x_max: 854,
      o: 'm 854 440 q 807 201 854 304 q 673 42 760 98 q 471 -14 585 -14 q 191 110 298 -14 q 85 444 85 234 l 85 546 q 133 785 85 681 q 268 945 180 889 q 469 1001 355 1001 q 670 946 583 1001 q 805 791 758 891 q 854 558 852 690 l 854 440 m 771 547 q 690 827 771 726 q 469 928 610 928 q 250 826 332 928 q 168 543 168 725 l 168 440 q 250 162 168 264 q 471 59 331 59 q 692 160 612 59 q 771 443 771 262 l 771 547 m 681 1139 l 261 1139 l 261 1204 l 681 1204 l 681 1139 z ',
    },
    ō: {
      ha: 778,
      x_min: 61,
      x_max: 716,
      o: 'm 61 378 q 102 568 61 484 q 218 700 143 653 q 388 747 293 747 q 625 645 534 747 q 716 372 716 542 l 716 355 q 675 164 716 249 q 559 33 634 79 q 389 -14 485 -14 q 152 89 243 -14 q 61 361 61 192 l 61 378 m 142 355 q 211 139 142 224 q 389 55 279 55 q 567 139 499 55 q 635 362 635 224 l 635 378 q 604 532 635 462 q 517 640 573 602 q 388 678 460 678 q 211 593 279 678 q 142 370 142 508 l 142 355 m 600 916 l 180 916 l 180 982 l 600 982 l 600 916 z ',
    },
    Ŏ: {
      ha: 940,
      x_min: 85,
      x_max: 854,
      o: 'm 854 440 q 807 201 854 304 q 673 42 760 98 q 471 -14 585 -14 q 191 110 298 -14 q 85 444 85 234 l 85 546 q 133 785 85 681 q 268 945 180 889 q 469 1001 355 1001 q 670 946 583 1001 q 805 791 758 891 q 854 558 852 690 l 854 440 m 771 547 q 690 827 771 726 q 469 928 610 928 q 250 826 332 928 q 168 543 168 725 l 168 440 q 250 162 168 264 q 471 59 331 59 q 692 160 612 59 q 771 443 771 262 l 771 547 m 665 1234 q 611 1108 665 1156 q 469 1060 558 1060 q 327 1108 380 1060 q 273 1234 273 1156 l 342 1234 q 375 1148 342 1178 q 469 1118 409 1118 q 562 1149 528 1118 q 595 1234 595 1181 l 665 1234 z ',
    },
    ŏ: {
      ha: 778,
      x_min: 61,
      x_max: 716,
      o: 'm 61 378 q 102 568 61 484 q 218 700 143 653 q 388 747 293 747 q 625 645 534 747 q 716 372 716 542 l 716 355 q 675 164 716 249 q 559 33 634 79 q 389 -14 485 -14 q 152 89 243 -14 q 61 361 61 192 l 61 378 m 142 355 q 211 139 142 224 q 389 55 279 55 q 567 139 499 55 q 635 362 635 224 l 635 378 q 604 532 635 462 q 517 640 573 602 q 388 678 460 678 q 211 593 279 678 q 142 370 142 508 l 142 355 m 583 1012 q 530 886 583 934 q 387 838 477 838 q 245 885 299 838 q 191 1012 191 933 l 260 1012 q 294 926 260 956 q 387 896 328 896 q 480 927 447 896 q 514 1012 514 958 l 583 1012 z ',
    },
    Ő: {
      ha: 940,
      x_min: 85,
      x_max: 854,
      o: 'm 854 440 q 807 201 854 304 q 673 42 760 98 q 471 -14 585 -14 q 191 110 298 -14 q 85 444 85 234 l 85 546 q 133 785 85 681 q 268 945 180 889 q 469 1001 355 1001 q 670 946 583 1001 q 805 791 758 891 q 854 558 852 690 l 854 440 m 771 547 q 690 827 771 726 q 469 928 610 928 q 250 826 332 928 q 168 543 168 725 l 168 440 q 250 162 168 264 q 471 59 331 59 q 692 160 612 59 q 771 443 771 262 l 771 547 m 670 1263 l 768 1263 l 578 1057 l 496 1057 l 670 1263 m 460 1263 l 553 1263 l 396 1057 l 322 1057 l 460 1263 z ',
    },
    ő: {
      ha: 778,
      x_min: 61,
      x_max: 716,
      o: 'm 61 378 q 102 568 61 484 q 218 700 143 653 q 388 747 293 747 q 625 645 534 747 q 716 372 716 542 l 716 355 q 675 164 716 249 q 559 33 634 79 q 389 -14 485 -14 q 152 89 243 -14 q 61 361 61 192 l 61 378 m 142 355 q 211 139 142 224 q 389 55 279 55 q 567 139 499 55 q 635 362 635 224 l 635 378 q 604 532 635 462 q 517 640 573 602 q 388 678 460 678 q 211 593 279 678 q 142 370 142 508 l 142 355 m 589 1041 l 686 1041 l 497 836 l 415 836 l 589 1041 m 378 1041 l 471 1041 l 315 836 l 241 836 l 378 1041 z ',
    },
    Ŕ: {
      ha: 882,
      x_min: 123,
      x_max: 821,
      o: 'm 494 411 l 207 411 l 207 0 l 123 0 l 123 987 l 444 987 q 690 911 602 987 q 779 696 779 834 q 724 529 779 602 q 574 429 668 456 l 821 9 l 821 0 l 732 0 l 494 411 m 207 482 l 463 482 q 631 542 568 482 q 695 696 695 602 q 629 859 695 801 q 442 916 562 916 l 207 916 l 207 482 m 493 1255 l 591 1255 l 435 1065 l 363 1065 l 493 1255 z ',
    },
    ŕ: {
      ha: 467,
      x_min: 106,
      x_max: 458,
      o: 'm 445 665 q 390 669 419 669 q 261 627 314 669 q 186 504 209 585 l 186 0 l 106 0 l 106 734 l 185 734 l 186 617 q 393 747 251 747 q 447 739 427 747 l 445 665 m 359 1042 l 458 1042 l 301 852 l 230 852 l 359 1042 z ',
    },
    Ŗ: {
      ha: 882,
      x_min: 123,
      x_max: 821,
      o: 'm 494 411 l 207 411 l 207 0 l 123 0 l 123 987 l 444 987 q 690 911 602 987 q 779 696 779 834 q 724 529 779 602 q 574 429 668 456 l 821 9 l 821 0 l 732 0 l 494 411 m 207 482 l 463 482 q 631 542 568 482 q 695 696 695 602 q 629 859 695 801 q 442 916 562 916 l 207 916 l 207 482 m 373 -307 l 325 -272 q 374 -130 372 -205 l 374 -64 l 452 -64 l 452 -107 q 373 -307 452 -237 z ',
    },
    ŗ: {
      ha: 467,
      x_min: 64,
      x_max: 447,
      o: 'm 445 665 q 390 669 419 669 q 261 627 314 669 q 186 504 209 585 l 186 0 l 106 0 l 106 734 l 185 734 l 186 617 q 393 747 251 747 q 447 739 427 747 l 445 665 m 112 -304 l 64 -269 q 113 -127 111 -203 l 113 -62 l 191 -62 l 191 -104 q 112 -304 191 -234 z ',
    },
    Ř: {
      ha: 882,
      x_min: 123,
      x_max: 821,
      o: 'm 494 411 l 207 411 l 207 0 l 123 0 l 123 987 l 444 987 q 690 911 602 987 q 779 696 779 834 q 724 529 779 602 q 574 429 668 456 l 821 9 l 821 0 l 732 0 l 494 411 m 207 482 l 463 482 q 631 542 568 482 q 695 696 695 602 q 629 859 695 801 q 442 916 562 916 l 207 916 l 207 482 m 411 1115 l 528 1246 l 600 1246 l 600 1238 l 440 1061 l 382 1061 l 222 1239 l 222 1246 l 294 1246 l 411 1115 z ',
    },
    ř: {
      ha: 467,
      x_min: 89,
      x_max: 467,
      o: 'm 445 665 q 390 669 419 669 q 261 627 314 669 q 186 504 209 585 l 186 0 l 106 0 l 106 734 l 185 734 l 186 617 q 393 747 251 747 q 447 739 427 747 l 445 665 m 278 901 l 395 1032 l 467 1032 l 467 1024 l 307 847 l 249 847 l 89 1025 l 89 1032 l 161 1032 l 278 901 z ',
    },
    Ś: {
      ha: 823,
      x_min: 60,
      x_max: 768,
      o: 'm 684 243 q 626 373 684 325 q 415 464 568 420 q 191 557 262 507 q 91 746 91 629 q 184 930 91 859 q 422 1001 277 1001 q 598 963 521 1001 q 719 857 676 925 q 762 706 762 789 l 677 706 q 608 868 677 807 q 422 930 539 930 q 242 879 309 930 q 175 747 175 829 q 235 625 175 672 q 429 541 296 577 q 630 466 562 505 q 733 372 699 427 q 768 244 768 318 q 674 57 768 127 q 429 -14 581 -14 q 237 24 324 -14 q 105 128 150 61 q 60 282 60 195 l 143 282 q 221 117 143 178 q 429 57 299 57 q 614 108 544 57 q 684 243 684 159 m 507 1263 l 605 1263 l 448 1074 l 377 1074 l 507 1263 z ',
    },
    ś: {
      ha: 703,
      x_min: 68,
      x_max: 627,
      o: 'm 545 186 q 497 283 545 247 q 351 341 448 320 q 199 388 253 361 q 119 454 145 415 q 93 547 93 492 q 165 691 93 634 q 351 747 238 747 q 548 687 474 747 q 623 528 623 626 l 541 528 q 487 635 541 593 q 351 678 433 678 q 223 643 271 678 q 174 550 174 607 q 215 465 174 495 q 362 410 255 435 q 522 356 469 385 q 601 288 575 328 q 627 191 627 248 q 552 42 627 98 q 356 -14 477 -14 q 148 48 228 -14 q 68 205 68 111 l 150 205 q 210 95 155 134 q 356 55 265 55 q 493 92 441 55 q 545 186 545 130 m 446 1042 l 544 1042 l 387 852 l 316 852 l 446 1042 z ',
    },
    Ŝ: {
      ha: 823,
      x_min: 60,
      x_max: 768,
      o: 'm 684 243 q 626 373 684 325 q 415 464 568 420 q 191 557 262 507 q 91 746 91 629 q 184 930 91 859 q 422 1001 277 1001 q 598 963 521 1001 q 719 857 676 925 q 762 706 762 789 l 677 706 q 608 868 677 807 q 422 930 539 930 q 242 879 309 930 q 175 747 175 829 q 235 625 175 672 q 429 541 296 577 q 630 466 562 505 q 733 372 699 427 q 768 244 768 318 q 674 57 768 127 q 429 -14 581 -14 q 237 24 324 -14 q 105 128 150 61 q 60 282 60 195 l 143 282 q 221 117 143 178 q 429 57 299 57 q 614 108 544 57 q 684 243 684 159 m 614 1082 l 614 1075 l 539 1075 l 425 1199 l 312 1075 l 238 1075 l 238 1084 l 399 1257 l 451 1257 l 614 1082 z ',
    },
    ŝ: {
      ha: 703,
      x_min: 68,
      x_max: 627,
      o: 'm 545 186 q 497 283 545 247 q 351 341 448 320 q 199 388 253 361 q 119 454 145 415 q 93 547 93 492 q 165 691 93 634 q 351 747 238 747 q 548 687 474 747 q 623 528 623 626 l 541 528 q 487 635 541 593 q 351 678 433 678 q 223 643 271 678 q 174 550 174 607 q 215 465 174 495 q 362 410 255 435 q 522 356 469 385 q 601 288 575 328 q 627 191 627 248 q 552 42 627 98 q 356 -14 477 -14 q 148 48 228 -14 q 68 205 68 111 l 150 205 q 210 95 155 134 q 356 55 265 55 q 493 92 441 55 q 545 186 545 130 m 553 860 l 553 853 l 478 853 l 364 977 l 251 853 l 177 853 l 177 862 l 338 1035 l 390 1035 l 553 860 z ',
    },
    Ş: {
      ha: 823,
      x_min: 60,
      x_max: 768,
      o: 'm 684 243 q 626 373 684 325 q 415 464 568 420 q 191 557 262 507 q 91 746 91 629 q 184 930 91 859 q 422 1001 277 1001 q 598 963 521 1001 q 719 857 676 925 q 762 706 762 789 l 677 706 q 608 868 677 807 q 422 930 539 930 q 242 879 309 930 q 175 747 175 829 q 235 625 175 672 q 429 541 296 577 q 630 466 562 505 q 733 372 699 427 q 768 244 768 318 q 674 57 768 127 q 429 -14 581 -14 q 237 24 324 -14 q 105 128 150 61 q 60 282 60 195 l 143 282 q 221 117 143 178 q 429 57 299 57 q 614 108 544 57 q 684 243 684 159 m 461 -3 l 453 -46 q 565 -162 565 -60 q 511 -261 565 -224 q 366 -298 458 -298 l 361 -245 q 459 -223 421 -245 q 498 -163 498 -201 q 468 -109 498 -124 q 378 -89 439 -94 l 398 -3 l 461 -3 z ',
    },
    ş: {
      ha: 703,
      x_min: 68,
      x_max: 627,
      o: 'm 545 186 q 497 283 545 247 q 351 341 448 320 q 199 388 253 361 q 119 454 145 415 q 93 547 93 492 q 165 691 93 634 q 351 747 238 747 q 548 687 474 747 q 623 528 623 626 l 541 528 q 487 635 541 593 q 351 678 433 678 q 223 643 271 678 q 174 550 174 607 q 215 465 174 495 q 362 410 255 435 q 522 356 469 385 q 601 288 575 328 q 627 191 627 248 q 552 42 627 98 q 356 -14 477 -14 q 148 48 228 -14 q 68 205 68 111 l 150 205 q 210 95 155 134 q 356 55 265 55 q 493 92 441 55 q 545 186 545 130 m 398 -4 l 390 -47 q 502 -163 502 -62 q 448 -262 502 -226 q 302 -299 395 -299 l 298 -246 q 396 -224 358 -246 q 435 -165 435 -202 q 405 -111 435 -125 q 315 -90 376 -96 l 335 -4 l 398 -4 z ',
    },
    Ș: {
      ha: 823,
      x_min: 60,
      x_max: 768,
      o: 'm 684 243 q 626 373 684 325 q 415 464 568 420 q 191 557 262 507 q 91 746 91 629 q 184 930 91 859 q 422 1001 277 1001 q 598 963 521 1001 q 719 857 676 925 q 762 706 762 789 l 677 706 q 608 868 677 807 q 422 930 539 930 q 242 879 309 930 q 175 747 175 829 q 235 625 175 672 q 429 541 296 577 q 630 466 562 505 q 733 372 699 427 q 768 244 768 318 q 674 57 768 127 q 429 -14 581 -14 q 237 24 324 -14 q 105 128 150 61 q 60 282 60 195 l 143 282 q 221 117 143 178 q 429 57 299 57 q 614 108 544 57 q 684 243 684 159 m 388 -317 l 340 -282 q 389 -140 387 -216 l 389 -75 l 467 -75 l 467 -117 q 388 -317 467 -247 z ',
    },
    ș: {
      ha: 703,
      x_min: 68,
      x_max: 627,
      o: 'm 545 186 q 497 283 545 247 q 351 341 448 320 q 199 388 253 361 q 119 454 145 415 q 93 547 93 492 q 165 691 93 634 q 351 747 238 747 q 548 687 474 747 q 623 528 623 626 l 541 528 q 487 635 541 593 q 351 678 433 678 q 223 643 271 678 q 174 550 174 607 q 215 465 174 495 q 362 410 255 435 q 522 356 469 385 q 601 288 575 328 q 627 191 627 248 q 552 42 627 98 q 356 -14 477 -14 q 148 48 228 -14 q 68 205 68 111 l 150 205 q 210 95 155 134 q 356 55 265 55 q 493 92 441 55 q 545 186 545 130 m 325 -319 l 277 -283 q 326 -141 323 -217 l 326 -76 l 404 -76 l 404 -119 q 325 -319 404 -248 z ',
    },
    Š: {
      ha: 823,
      x_min: 60,
      x_max: 768,
      o: 'm 684 243 q 626 373 684 325 q 415 464 568 420 q 191 557 262 507 q 91 746 91 629 q 184 930 91 859 q 422 1001 277 1001 q 598 963 521 1001 q 719 857 676 925 q 762 706 762 789 l 677 706 q 608 868 677 807 q 422 930 539 930 q 242 879 309 930 q 175 747 175 829 q 235 625 175 672 q 429 541 296 577 q 630 466 562 505 q 733 372 699 427 q 768 244 768 318 q 674 57 768 127 q 429 -14 581 -14 q 237 24 324 -14 q 105 128 150 61 q 60 282 60 195 l 143 282 q 221 117 143 178 q 429 57 299 57 q 614 108 544 57 q 684 243 684 159 m 425 1123 l 541 1255 l 614 1255 l 614 1246 l 454 1069 l 395 1069 l 235 1247 l 235 1255 l 308 1255 l 425 1123 z ',
    },
    š: {
      ha: 703,
      x_min: 68,
      x_max: 627,
      o: 'm 545 186 q 497 283 545 247 q 351 341 448 320 q 199 388 253 361 q 119 454 145 415 q 93 547 93 492 q 165 691 93 634 q 351 747 238 747 q 548 687 474 747 q 623 528 623 626 l 541 528 q 487 635 541 593 q 351 678 433 678 q 223 643 271 678 q 174 550 174 607 q 215 465 174 495 q 362 410 255 435 q 522 356 469 385 q 601 288 575 328 q 627 191 627 248 q 552 42 627 98 q 356 -14 477 -14 q 148 48 228 -14 q 68 205 68 111 l 150 205 q 210 95 155 134 q 356 55 265 55 q 493 92 441 55 q 545 186 545 130 m 363 901 l 480 1032 l 553 1032 l 553 1024 l 393 847 l 334 847 l 174 1025 l 174 1032 l 247 1032 l 363 901 z ',
    },
    Ț: {
      ha: 829,
      x_min: 35,
      x_max: 795,
      o: 'm 795 916 l 456 916 l 456 0 l 373 0 l 373 916 l 35 916 l 35 987 l 795 987 l 795 916 m 376 -314 l 328 -279 q 376 -136 374 -212 l 376 -71 l 454 -71 l 454 -114 q 376 -314 454 -243 z ',
    },
    ț: {
      ha: 446,
      x_min: 16,
      x_max: 397,
      o: 'm 232 922 l 232 734 l 384 734 l 384 667 l 232 667 l 232 178 q 254 87 232 117 q 328 58 276 58 q 393 64 348 58 l 397 -2 q 310 -14 365 -14 q 189 35 227 -14 q 151 178 151 83 l 151 667 l 16 667 l 16 734 l 151 734 l 151 922 l 232 922 m 254 -314 l 205 -279 q 254 -136 252 -212 l 254 -71 l 332 -71 l 332 -114 q 254 -314 332 -243 z ',
    },
    Ţ: {
      ha: 829,
      x_min: 35,
      x_max: 795,
      o: 'm 795 916 l 456 916 l 456 0 l 373 0 l 373 916 l 35 916 l 35 987 l 795 987 l 795 916 m 449 0 l 441 -43 q 553 -159 553 -58 q 499 -258 553 -222 q 353 -295 446 -295 l 349 -242 q 447 -220 409 -242 q 486 -161 486 -198 q 456 -106 486 -121 q 366 -86 427 -92 l 386 0 l 449 0 z ',
    },
    ţ: {
      ha: 446,
      x_min: 16,
      x_max: 431,
      o: 'm 232 922 l 232 734 l 384 734 l 384 667 l 232 667 l 232 178 q 254 87 232 117 q 328 58 276 58 q 393 64 348 58 l 397 -2 q 310 -14 365 -14 q 189 35 227 -14 q 151 178 151 83 l 151 667 l 16 667 l 16 734 l 151 734 l 151 922 l 232 922 m 327 0 l 319 -43 q 431 -159 431 -58 q 377 -258 431 -222 q 231 -295 323 -295 l 227 -242 q 325 -220 287 -242 q 363 -161 363 -198 q 334 -106 363 -121 q 244 -86 304 -92 l 264 0 l 327 0 z ',
    },
    Ť: {
      ha: 829,
      x_min: 35,
      x_max: 795,
      o: 'm 795 916 l 456 916 l 456 0 l 373 0 l 373 916 l 35 916 l 35 987 l 795 987 l 795 916 m 413 1112 l 530 1244 l 602 1244 l 602 1236 l 442 1059 l 384 1059 l 224 1236 l 224 1244 l 296 1244 l 413 1112 z ',
    },
    ť: {
      ha: 473,
      x_min: 16,
      x_max: 448,
      o: 'm 232 922 l 232 734 l 384 734 l 384 667 l 232 667 l 232 178 q 254 87 232 117 q 328 58 276 58 q 393 64 348 58 l 397 -2 q 310 -14 365 -14 q 189 35 227 -14 q 151 178 151 83 l 151 667 l 16 667 l 16 734 l 151 734 l 151 922 l 232 922 m 370 847 l 321 882 q 370 1025 368 949 l 370 1090 l 448 1090 l 448 1047 q 370 847 448 918 z ',
    },
    Ũ: {
      ha: 913,
      x_min: 110,
      x_max: 805,
      o: 'm 805 987 l 805 313 q 761 140 804 214 q 638 26 718 66 q 457 -14 559 -14 q 207 72 301 -14 q 110 307 113 157 l 110 987 l 193 987 l 193 319 q 264 126 193 195 q 457 57 335 57 q 650 126 579 57 q 721 319 721 195 l 721 987 l 805 987 m 694 1227 q 655 1122 694 1162 q 562 1082 617 1082 q 461 1120 510 1082 q 373 1157 412 1157 q 320 1136 341 1157 q 298 1077 298 1115 l 237 1079 q 274 1182 237 1141 q 370 1223 311 1223 q 425 1211 402 1223 q 469 1185 448 1200 q 510 1160 489 1171 q 558 1148 530 1148 q 611 1171 589 1148 q 633 1231 633 1193 l 694 1227 z ',
    },
    ũ: {
      ha: 763,
      x_min: 103,
      x_max: 658,
      o: 'm 578 93 q 344 -14 505 -14 q 165 55 227 -14 q 103 256 104 123 l 103 734 l 184 734 l 184 267 q 353 57 184 57 q 576 203 530 57 l 576 734 l 658 734 l 658 0 l 579 0 l 578 93 m 620 1013 q 582 908 620 948 q 488 869 543 869 q 387 906 436 869 q 299 943 338 943 q 246 922 267 943 q 224 863 224 901 l 163 865 q 200 968 163 928 q 296 1009 237 1009 q 351 998 328 1009 q 395 972 374 986 q 436 946 415 958 q 484 935 456 935 q 537 957 515 935 q 559 1017 559 979 l 620 1013 z ',
    },
    Ū: {
      ha: 913,
      x_min: 110,
      x_max: 805,
      o: 'm 805 987 l 805 313 q 761 140 804 214 q 638 26 718 66 q 457 -14 559 -14 q 207 72 301 -14 q 110 307 113 157 l 110 987 l 193 987 l 193 319 q 264 126 193 195 q 457 57 335 57 q 650 126 579 57 q 721 319 721 195 l 721 987 l 805 987 m 675 1131 l 255 1131 l 255 1196 l 675 1196 l 675 1131 z ',
    },
    ū: {
      ha: 763,
      x_min: 103,
      x_max: 658,
      o: 'm 578 93 q 344 -14 505 -14 q 165 55 227 -14 q 103 256 104 123 l 103 734 l 184 734 l 184 267 q 353 57 184 57 q 576 203 530 57 l 576 734 l 658 734 l 658 0 l 579 0 l 578 93 m 601 917 l 181 917 l 181 983 l 601 983 l 601 917 z ',
    },
    Ŭ: {
      ha: 913,
      x_min: 110,
      x_max: 805,
      o: 'm 805 987 l 805 313 q 761 140 804 214 q 638 26 718 66 q 457 -14 559 -14 q 207 72 301 -14 q 110 307 113 157 l 110 987 l 193 987 l 193 319 q 264 126 193 195 q 457 57 335 57 q 650 126 579 57 q 721 319 721 195 l 721 987 l 805 987 m 659 1226 q 605 1100 659 1148 q 463 1052 552 1052 q 320 1100 374 1052 q 267 1226 267 1147 l 336 1226 q 369 1140 336 1170 q 463 1110 403 1110 q 556 1141 522 1110 q 589 1226 589 1173 l 659 1226 z ',
    },
    ŭ: {
      ha: 763,
      x_min: 103,
      x_max: 658,
      o: 'm 578 93 q 344 -14 505 -14 q 165 55 227 -14 q 103 256 104 123 l 103 734 l 184 734 l 184 267 q 353 57 184 57 q 576 203 530 57 l 576 734 l 658 734 l 658 0 l 579 0 l 578 93 m 585 1013 q 531 886 585 935 q 389 838 478 838 q 247 886 300 838 q 193 1013 193 934 l 262 1013 q 295 926 262 956 q 389 897 329 897 q 482 928 448 897 q 515 1013 515 959 l 585 1013 z ',
    },
    Ů: {
      ha: 913,
      x_min: 110,
      x_max: 805,
      o: 'm 805 987 l 805 313 q 761 140 804 214 q 638 26 718 66 q 457 -14 559 -14 q 207 72 301 -14 q 110 307 113 157 l 110 987 l 193 987 l 193 319 q 264 126 193 195 q 457 57 335 57 q 650 126 579 57 q 721 319 721 195 l 721 987 l 805 987 m 332 1163 q 371 1255 332 1217 q 463 1292 409 1292 q 555 1255 517 1292 q 594 1163 594 1217 q 557 1073 594 1109 q 463 1036 519 1036 q 370 1073 407 1036 q 332 1163 332 1109 m 385 1163 q 407 1107 385 1129 q 463 1084 429 1084 q 519 1106 496 1084 q 543 1163 543 1128 q 519 1221 543 1199 q 463 1244 495 1244 q 407 1221 430 1244 q 385 1163 385 1198 z ',
    },
    ů: {
      ha: 763,
      x_min: 103,
      x_max: 658,
      o: 'm 578 93 q 344 -14 505 -14 q 165 55 227 -14 q 103 256 104 123 l 103 734 l 184 734 l 184 267 q 353 57 184 57 q 576 203 530 57 l 576 734 l 658 734 l 658 0 l 579 0 l 578 93 m 258 949 q 297 1041 258 1004 q 389 1078 335 1078 q 481 1041 443 1078 q 520 949 520 1004 q 483 859 520 896 q 389 823 446 823 q 296 859 333 823 q 258 949 258 896 m 311 949 q 333 893 311 916 q 389 871 355 871 q 446 893 422 871 q 469 949 469 915 q 445 1008 469 985 q 389 1030 421 1030 q 333 1007 356 1030 q 311 949 311 984 z ',
    },
    Ű: {
      ha: 913,
      x_min: 110,
      x_max: 805,
      o: 'm 805 987 l 805 313 q 761 140 804 214 q 638 26 718 66 q 457 -14 559 -14 q 207 72 301 -14 q 110 307 113 157 l 110 987 l 193 987 l 193 319 q 264 126 193 195 q 457 57 335 57 q 650 126 579 57 q 721 319 721 195 l 721 987 l 805 987 m 664 1255 l 762 1255 l 572 1049 l 490 1049 l 664 1255 m 454 1255 l 547 1255 l 390 1049 l 316 1049 l 454 1255 z ',
    },
    ű: {
      ha: 763,
      x_min: 103,
      x_max: 688,
      o: 'm 578 93 q 344 -14 505 -14 q 165 55 227 -14 q 103 256 104 123 l 103 734 l 184 734 l 184 267 q 353 57 184 57 q 576 203 530 57 l 576 734 l 658 734 l 658 0 l 579 0 l 578 93 m 590 1041 l 688 1041 l 498 836 l 416 836 l 590 1041 m 380 1041 l 473 1041 l 316 836 l 242 836 l 380 1041 z ',
    },
    Ų: {
      ha: 913,
      x_min: 110,
      x_max: 805,
      o: 'm 805 987 l 805 318 q 761 139 805 216 q 633 23 717 63 q 539 -64 564 -29 q 515 -133 515 -100 q 572 -190 515 -190 q 644 -168 609 -190 l 654 -224 q 557 -254 610 -254 q 472 -224 503 -254 q 441 -140 441 -194 q 510 -10 441 -75 l 457 -14 q 207 72 301 -14 q 110 307 113 157 l 110 987 l 193 987 l 193 319 q 264 126 193 195 q 457 57 335 57 q 650 126 579 57 q 721 319 721 195 l 721 987 l 805 987 z ',
    },
    ų: {
      ha: 763,
      x_min: 103,
      x_max: 671,
      o: 'm 654 0 q 557 -90 583 -54 q 532 -159 532 -125 q 590 -216 532 -216 q 661 -193 627 -216 l 671 -250 q 574 -280 627 -280 q 490 -250 520 -280 q 459 -165 459 -220 q 491 -77 459 -121 q 579 4 523 -33 l 578 93 q 344 -14 505 -14 q 165 55 227 -14 q 103 256 104 123 l 103 734 l 184 734 l 184 267 q 353 57 184 57 q 576 203 530 57 l 576 734 l 658 734 l 658 0 l 654 0 z ',
    },
    Ŵ: {
      ha: 1245,
      x_min: 41,
      x_max: 1209,
      o: 'm 307 267 l 338 113 l 375 260 l 589 987 l 665 987 l 876 260 l 913 112 l 945 267 l 1124 987 l 1209 987 l 956 0 l 876 0 l 652 772 l 627 870 l 603 772 l 374 0 l 294 0 l 41 987 l 127 987 l 307 267 m 817 1074 l 817 1067 l 741 1067 l 627 1191 l 514 1067 l 440 1067 l 440 1076 l 602 1249 l 653 1249 l 817 1074 z ',
    },
    ŵ: {
      ha: 1047,
      x_min: 43,
      x_max: 999,
      o: 'm 281 169 l 294 106 l 310 172 l 486 734 l 555 734 l 730 177 l 749 100 l 764 171 l 915 734 l 999 734 l 785 0 l 716 0 l 528 582 l 519 622 l 510 581 l 325 0 l 256 0 l 43 734 l 126 734 l 281 169 m 708 860 l 708 853 l 633 853 l 519 977 l 406 853 l 332 853 l 332 862 l 493 1035 l 545 1035 l 708 860 z ',
    },
    Ŷ: {
      ha: 831,
      x_min: 16,
      x_max: 812,
      o: 'm 414 449 l 716 987 l 812 987 l 456 372 l 456 0 l 372 0 l 372 372 l 16 987 l 115 987 l 414 449 m 604 1069 l 604 1063 l 529 1063 l 415 1187 l 302 1063 l 228 1063 l 228 1072 l 389 1244 l 441 1244 l 604 1069 z ',
    },
    ŷ: {
      ha: 660,
      x_min: 22,
      x_max: 640,
      o: 'm 338 117 l 553 734 l 640 734 l 327 -125 l 311 -163 q 124 -296 250 -296 q 62 -287 95 -296 l 61 -220 l 103 -224 q 200 -194 163 -224 q 262 -93 237 -165 l 298 6 l 22 734 l 111 734 l 338 117 m 537 860 l 537 853 l 462 853 l 348 977 l 235 853 l 161 853 l 161 862 l 322 1035 l 374 1035 l 537 860 z ',
    },
    Ÿ: {
      ha: 831,
      x_min: 16,
      x_max: 812,
      o: 'm 414 449 l 716 987 l 812 987 l 456 372 l 456 0 l 372 0 l 372 372 l 16 987 l 115 987 l 414 449 m 212 1156 q 227 1195 212 1179 q 268 1211 242 1211 q 309 1195 294 1211 q 324 1156 324 1179 q 309 1118 324 1134 q 268 1103 294 1103 q 227 1118 242 1103 q 212 1156 212 1134 m 509 1156 q 524 1195 509 1179 q 565 1211 539 1211 q 606 1195 591 1211 q 621 1156 621 1179 q 606 1118 621 1133 q 565 1102 591 1102 q 524 1118 539 1102 q 509 1156 509 1133 z ',
    },
    Ź: {
      ha: 831,
      x_min: 61,
      x_max: 772,
      o: 'm 162 71 l 772 71 l 772 0 l 61 0 l 61 63 l 647 916 l 79 916 l 79 987 l 750 987 l 750 928 l 162 71 m 496 1255 l 595 1255 l 438 1065 l 367 1065 l 496 1255 z ',
    },
    ź: {
      ha: 675,
      x_min: 59,
      x_max: 636,
      o: 'm 159 68 l 636 68 l 636 0 l 59 0 l 59 60 l 504 664 l 73 664 l 73 734 l 606 734 l 606 673 l 159 68 m 429 1042 l 527 1042 l 370 852 l 299 852 l 429 1042 z ',
    },
    Ż: {
      ha: 831,
      x_min: 61,
      x_max: 772,
      o: 'm 162 71 l 772 71 l 772 0 l 61 0 l 61 63 l 647 916 l 79 916 l 79 987 l 750 987 l 750 928 l 162 71 m 359 1151 q 374 1190 359 1174 q 415 1206 389 1206 q 456 1190 441 1206 q 471 1151 471 1174 q 456 1113 471 1128 q 415 1097 441 1097 q 374 1113 389 1097 q 359 1151 359 1128 z ',
    },
    ż: {
      ha: 675,
      x_min: 59,
      x_max: 636,
      o: 'm 159 68 l 636 68 l 636 0 l 59 0 l 59 60 l 504 664 l 73 664 l 73 734 l 606 734 l 606 673 l 159 68 m 292 937 q 307 976 292 960 q 347 992 321 992 q 389 976 374 992 q 404 937 404 960 q 389 899 404 915 q 347 884 374 884 q 307 899 321 884 q 292 937 292 915 z ',
    },
    Ž: {
      ha: 831,
      x_min: 61,
      x_max: 772,
      o: 'm 162 71 l 772 71 l 772 0 l 61 0 l 61 63 l 647 916 l 79 916 l 79 987 l 750 987 l 750 928 l 162 71 m 414 1115 l 531 1246 l 604 1246 l 604 1238 l 444 1061 l 385 1061 l 225 1239 l 225 1246 l 298 1246 l 414 1115 z ',
    },
    ž: {
      ha: 675,
      x_min: 59,
      x_max: 636,
      o: 'm 159 68 l 636 68 l 636 0 l 59 0 l 59 60 l 504 664 l 73 664 l 73 734 l 606 734 l 606 673 l 159 68 m 347 901 l 463 1033 l 536 1033 l 536 1025 l 376 848 l 317 848 l 157 1025 l 157 1033 l 230 1033 l 347 901 z ',
    },
    Ǽ: {
      ha: 1265,
      x_min: 12,
      x_max: 1236,
      o: 'm 1236 0 l 682 0 l 671 264 l 267 264 l 111 0 l 12 0 l 609 987 l 1195 987 l 1195 918 l 724 918 l 740 549 l 1139 549 l 1139 479 l 743 479 l 760 68 l 1236 68 l 1236 0 m 312 340 l 668 340 l 644 903 l 312 340 m 728 1259 l 827 1259 l 670 1069 l 599 1069 l 728 1259 z ',
    },
    ǽ: {
      ha: 1175,
      x_min: 58,
      x_max: 1124,
      o: 'm 858 -14 q 696 23 764 -14 q 588 126 629 60 q 470 23 547 60 q 298 -14 393 -14 q 121 44 184 -14 q 58 199 58 101 q 133 366 58 305 q 347 429 208 427 l 530 429 l 530 488 q 487 628 530 578 q 358 678 444 678 q 214 634 270 678 q 157 519 157 590 l 77 528 q 156 686 77 625 q 358 747 235 747 q 503 711 443 747 q 590 603 563 675 q 695 709 631 671 q 838 747 759 747 q 1047 666 970 747 q 1124 437 1123 584 l 1124 365 l 611 365 l 611 345 q 676 132 611 209 q 858 55 741 55 q 1078 135 983 55 l 1109 76 q 858 -14 1011 -14 m 311 54 q 436 85 372 54 q 530 160 499 117 l 530 363 l 356 363 q 205 322 263 363 q 140 210 146 281 l 140 196 q 184 92 140 130 q 311 54 229 54 m 838 678 q 687 612 748 678 q 613 431 626 545 l 1044 431 l 1044 452 q 989 617 1044 557 q 838 678 935 678 m 664 1042 l 762 1042 l 606 852 l 534 852 l 664 1042 z ',
    },
    Ǿ: {
      ha: 940,
      x_min: 85,
      x_max: 864,
      o: 'm 854 440 q 807 201 854 304 q 673 42 760 98 q 471 -14 585 -14 q 254 52 345 -14 l 186 -56 l 115 -56 l 208 91 q 85 446 85 216 l 85 546 q 133 785 85 681 q 268 945 180 889 q 469 1001 355 1001 q 722 906 621 1001 l 794 1021 l 864 1021 l 763 860 q 854 547 854 738 l 854 440 m 168 440 q 251 159 168 263 l 680 839 q 469 928 599 928 q 250 826 332 928 q 168 543 168 725 l 168 440 m 771 547 q 717 787 771 690 l 294 116 q 471 59 367 59 q 692 160 612 59 q 771 443 771 262 l 771 547 m 565 1301 l 663 1301 l 507 1112 l 435 1112 l 565 1301 z ',
    },
    ǿ: {
      ha: 773,
      x_min: 60,
      x_max: 715,
      o: 'm 60 378 q 101 568 60 484 q 217 700 142 653 q 387 747 292 747 q 532 715 465 747 l 585 823 l 649 823 l 581 685 q 680 557 645 636 q 715 378 715 477 l 715 355 q 674 164 715 250 q 558 33 633 79 q 389 -14 484 -14 q 253 14 315 -14 l 199 -95 l 136 -95 l 203 43 q 98 172 135 91 q 60 355 60 252 l 60 378 m 141 355 q 165 216 141 281 q 236 111 189 152 l 500 649 q 387 678 449 678 q 210 593 278 678 q 141 370 141 508 l 141 355 m 634 378 q 612 509 634 448 q 547 614 589 571 l 284 78 q 389 55 330 55 q 566 139 498 55 q 634 362 634 224 l 634 378 m 436 1041 l 534 1041 l 378 851 l 307 851 l 436 1041 z ',
    },
    Ά: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 174 1077 l 254 1077 l 180 871 l 124 871 l 174 1077 z ',
    },
    Έ: {
      ha: 790,
      x_min: -132,
      x_max: 739,
      o: 'm 670 473 l 208 473 l 208 71 l 739 71 l 739 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 544 l 670 544 l 670 473 m -81 1078 l -1 1078 l -75 871 l -132 871 l -81 1078 z ',
    },
    Ή: {
      ha: 983,
      x_min: -123,
      x_max: 857,
      o: 'm 857 0 l 772 0 l 772 473 l 208 473 l 208 0 l 125 0 l 125 987 l 208 987 l 208 544 l 772 544 l 772 987 l 857 987 l 857 0 m -73 1078 l 7 1078 l -66 872 l -123 872 l -73 1078 z ',
    },
    Ί: {
      ha: 370,
      x_min: -112,
      x_max: 227,
      o: 'm 227 0 l 143 0 l 143 987 l 227 987 l 227 0 m -62 1076 l 18 1076 l -56 870 l -112 870 l -62 1076 z ',
    },
    Ό: {
      ha: 954,
      x_min: 0,
      x_max: 868,
      o: 'm 868 440 q 821 201 868 304 q 686 42 774 98 q 484 -14 599 -14 q 205 110 311 -14 q 98 444 98 234 l 98 546 q 146 785 98 681 q 281 945 194 889 q 483 1001 369 1001 q 684 946 597 1001 q 819 791 771 891 q 868 558 866 690 l 868 440 m 785 547 q 704 827 785 726 q 483 928 623 928 q 263 826 345 928 q 182 543 182 725 l 182 440 q 263 162 182 264 q 484 59 345 59 q 705 160 626 59 q 785 443 785 262 l 785 547 m 50 1077 l 130 1077 l 56 871 l 0 871 l 50 1077 z ',
    },
    Ύ: {
      ha: 899,
      x_min: -139,
      x_max: 880,
      o: 'm 482 449 l 784 987 l 880 987 l 524 372 l 524 0 l 440 0 l 440 372 l 84 987 l 183 987 l 482 449 m -89 1077 l -9 1077 l -83 871 l -139 871 l -89 1077 z ',
    },
    Ώ: {
      ha: 923,
      x_min: 8,
      x_max: 833,
      o: 'm 522 81 q 684 222 625 117 q 743 486 742 327 l 743 574 q 667 833 743 736 q 463 930 590 930 q 259 832 334 930 q 183 570 183 734 l 183 494 q 242 226 183 334 q 409 81 300 117 l 409 0 l 95 0 l 95 71 l 290 71 q 150 248 201 136 q 98 494 98 359 l 98 572 q 144 797 98 699 q 273 948 190 895 q 463 1001 356 1001 q 653 947 570 1001 q 782 795 736 893 q 827 573 827 698 l 827 494 q 775 246 827 358 q 637 71 724 134 l 833 71 l 833 0 l 522 0 l 522 81 m 58 1077 l 138 1077 l 64 871 l 8 871 l 58 1077 z ',
    },
    ΐ: {
      ha: 416,
      x_min: -63,
      x_max: 392,
      o: 'm 205 734 l 205 183 q 227 94 205 123 q 300 64 249 64 q 366 71 332 64 l 370 3 q 283 -7 332 -7 q 161 42 199 -7 q 123 184 123 90 l 123 734 l 205 734 m 154 1114 l 245 1114 l 196 943 l 130 943 l 154 1114 m -63 932 q -48 971 -63 956 q -7 987 -33 987 q 34 971 19 987 q 49 932 49 956 q 34 895 49 910 q -7 879 19 879 q -48 895 -33 879 q -63 932 -63 910 m 280 932 q 295 971 280 955 q 336 987 310 987 q 377 971 361 987 q 392 932 392 955 q 377 894 392 909 q 336 878 361 878 q 295 894 310 878 q 280 932 280 909 z ',
    },
    Α: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 z ',
    },
    Β: {
      ha: 851,
      x_min: 125,
      x_max: 774,
      o: 'm 125 0 l 125 987 l 416 987 q 657 922 575 987 q 739 729 739 857 q 697 596 739 652 q 583 515 656 539 q 722 430 669 496 q 774 278 774 364 q 690 74 774 147 q 455 0 606 0 l 125 0 m 208 475 l 208 71 l 458 71 q 628 125 566 71 q 691 277 691 180 q 631 421 691 368 q 465 475 571 475 l 208 475 m 208 545 l 436 545 q 598 594 541 547 q 655 731 655 641 q 596 871 655 826 q 416 916 536 916 l 208 916 l 208 545 z ',
    },
    Ε: {
      ha: 790,
      x_min: 125,
      x_max: 739,
      o: 'm 670 473 l 208 473 l 208 71 l 739 71 l 739 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 544 l 670 544 l 670 473 z ',
    },
    Ζ: {
      ha: 831,
      x_min: 61,
      x_max: 772,
      o: 'm 162 71 l 772 71 l 772 0 l 61 0 l 61 63 l 647 916 l 79 916 l 79 987 l 750 987 l 750 928 l 162 71 z ',
    },
    Η: {
      ha: 983,
      x_min: 125,
      x_max: 857,
      o: 'm 857 0 l 772 0 l 772 473 l 208 473 l 208 0 l 125 0 l 125 987 l 208 987 l 208 544 l 772 544 l 772 987 l 857 987 l 857 0 z ',
    },
    Ι: {
      ha: 370,
      x_min: 143,
      x_max: 227,
      o: 'm 227 0 l 143 0 l 143 987 l 227 987 l 227 0 z ',
    },
    Κ: {
      ha: 876,
      x_min: 125,
      x_max: 851,
      o: 'm 347 490 l 208 353 l 208 0 l 125 0 l 125 987 l 208 987 l 208 455 l 714 987 l 819 987 l 406 548 l 851 0 l 749 0 l 347 490 z ',
    },
    Μ: {
      ha: 1202,
      x_min: 125,
      x_max: 1076,
      o: 'm 235 987 l 600 116 l 965 987 l 1076 987 l 1076 0 l 993 0 l 993 430 l 1000 871 l 632 0 l 568 0 l 201 867 l 208 433 l 208 0 l 125 0 l 125 987 l 235 987 z ',
    },
    Ν: {
      ha: 986,
      x_min: 125,
      x_max: 860,
      o: 'm 860 0 l 777 0 l 209 845 l 209 0 l 125 0 l 125 987 l 209 987 l 777 142 l 777 987 l 860 987 l 860 0 z ',
    },
    Ο: {
      ha: 940,
      x_min: 85,
      x_max: 854,
      o: 'm 854 440 q 807 201 854 304 q 673 42 760 98 q 471 -14 585 -14 q 191 110 298 -14 q 85 444 85 234 l 85 546 q 133 785 85 681 q 268 945 180 889 q 469 1001 355 1001 q 670 946 583 1001 q 805 791 758 891 q 854 558 852 690 l 854 440 m 771 547 q 690 827 771 726 q 469 928 610 928 q 250 826 332 928 q 168 543 168 725 l 168 440 q 250 162 168 264 q 471 59 331 59 q 692 160 612 59 q 771 443 771 262 l 771 547 z ',
    },
    Ρ: {
      ha: 855,
      x_min: 125,
      x_max: 793,
      o: 'm 208 402 l 208 0 l 125 0 l 125 987 l 461 987 q 704 909 615 987 q 793 692 793 830 q 708 478 793 553 q 459 402 622 402 l 208 402 m 208 473 l 461 473 q 646 530 583 473 q 710 691 710 588 q 647 854 710 793 q 467 916 583 915 l 208 916 l 208 473 z ',
    },
    Τ: {
      ha: 829,
      x_min: 35,
      x_max: 795,
      o: 'm 795 916 l 456 916 l 456 0 l 373 0 l 373 916 l 35 916 l 35 987 l 795 987 l 795 916 z ',
    },
    Υ: {
      ha: 831,
      x_min: 16,
      x_max: 812,
      o: 'm 414 449 l 716 987 l 812 987 l 456 372 l 456 0 l 372 0 l 372 372 l 16 987 l 115 987 l 414 449 z ',
    },
    Χ: {
      ha: 850,
      x_min: 37,
      x_max: 813,
      o: 'm 425 570 l 703 987 l 803 987 l 476 500 l 813 0 l 713 0 l 425 431 l 136 0 l 37 0 l 375 500 l 47 987 l 147 987 l 425 570 z ',
    },
    Ϊ: {
      ha: 370,
      x_min: -17,
      x_max: 392,
      o: 'm 227 0 l 143 0 l 143 987 l 227 987 l 227 0 m -17 1164 q -2 1203 -17 1187 q 39 1219 13 1219 q 80 1203 64 1219 q 95 1164 95 1187 q 80 1126 95 1142 q 39 1111 64 1111 q -2 1126 13 1111 q -17 1164 -17 1142 m 280 1164 q 295 1203 280 1187 q 336 1219 310 1219 q 377 1203 361 1219 q 392 1164 392 1187 q 377 1126 392 1141 q 336 1110 361 1110 q 295 1126 310 1110 q 280 1164 280 1141 z ',
    },
    Ϋ: {
      ha: 831,
      x_min: 16,
      x_max: 812,
      o: 'm 414 449 l 716 987 l 812 987 l 456 372 l 456 0 l 372 0 l 372 372 l 16 987 l 115 987 l 414 449 m 212 1156 q 227 1195 212 1179 q 268 1211 242 1211 q 309 1195 294 1211 q 324 1156 324 1179 q 309 1118 324 1134 q 268 1103 294 1103 q 227 1118 242 1103 q 212 1156 212 1134 m 509 1156 q 524 1195 509 1179 q 565 1211 539 1211 q 606 1195 591 1211 q 621 1156 621 1179 q 606 1118 621 1133 q 565 1102 591 1102 q 524 1118 539 1102 q 509 1156 509 1133 z ',
    },
    ά: {
      ha: 782,
      x_min: 75,
      x_max: 777,
      o: 'm 663 734 l 663 182 q 732 59 664 59 q 766 64 746 59 l 777 1 q 711 -14 749 -14 q 626 17 658 -14 q 585 119 595 47 q 355 -14 511 -14 q 158 74 233 -14 q 76 318 83 163 l 75 361 q 151 644 75 541 q 357 747 227 747 q 584 608 511 747 l 593 734 l 663 734 m 157 347 q 212 131 157 208 q 370 54 268 54 q 582 218 519 54 l 582 500 q 498 632 552 585 q 372 678 444 678 q 214 594 271 678 q 157 347 157 510 m 437 1047 l 517 1047 l 444 841 l 387 841 l 437 1047 z ',
    },
    έ: {
      ha: 755,
      x_min: 73,
      x_max: 678,
      o: 'm 73 201 q 225 376 73 337 q 122 443 158 400 q 85 538 85 486 q 162 692 85 637 q 373 747 239 747 q 582 687 498 747 q 666 536 666 627 l 585 536 q 524 638 585 597 q 373 678 463 678 q 223 642 278 678 q 168 540 168 605 q 376 408 168 408 l 496 408 l 496 340 l 366 340 q 209 307 262 340 q 155 200 155 273 q 214 95 155 134 q 373 55 273 55 q 533 99 470 55 q 597 210 597 143 l 678 210 q 592 47 678 109 q 373 -14 507 -14 q 155 45 238 -14 q 73 201 73 104 m 387 1046 l 467 1046 l 393 840 l 337 840 l 387 1046 z ',
    },
    ή: {
      ha: 773,
      x_min: 107,
      x_max: 665,
      o: 'm 182 734 l 186 617 q 421 747 264 747 q 605 680 546 747 q 665 469 665 612 l 665 -281 l 584 -281 l 584 465 q 541 626 584 573 q 403 678 498 678 q 189 507 237 678 l 189 0 l 107 0 l 107 734 l 182 734 m 406 1047 l 486 1047 l 412 841 l 355 841 l 406 1047 z ',
    },
    ί: {
      ha: 416,
      x_min: 123,
      x_max: 370,
      o: 'm 205 734 l 205 183 q 227 94 205 123 q 300 64 249 64 q 366 71 332 64 l 370 3 q 283 -7 332 -7 q 161 42 199 -7 q 123 184 123 90 l 123 734 l 205 734 m 180 1033 l 260 1033 l 186 827 l 130 827 l 180 1033 z ',
    },
    ΰ: {
      ha: 743,
      x_min: 105,
      x_max: 659,
      o: 'm 186 734 l 186 271 q 229 110 187 165 q 350 55 271 55 q 515 142 452 55 q 578 366 578 229 q 498 734 576 544 l 587 734 q 642 551 626 637 q 659 368 659 464 q 576 90 659 193 q 353 -14 494 -14 q 169 60 232 -14 q 105 275 105 133 l 105 734 l 186 734 m 346 1114 l 437 1114 l 388 943 l 322 943 l 346 1114 m 129 932 q 144 971 129 956 q 184 987 159 987 q 226 971 211 987 q 241 932 241 956 q 226 895 241 910 q 184 879 211 879 q 144 895 159 879 q 129 932 129 910 m 472 932 q 487 971 472 955 q 528 987 502 987 q 569 971 553 987 q 584 932 584 955 q 569 894 584 909 q 528 878 553 878 q 487 894 502 878 q 472 932 472 909 z ',
    },
    κ: {
      ha: 753,
      x_min: 110,
      x_max: 716,
      o: 'm 285 336 l 192 336 l 192 0 l 110 0 l 110 734 l 192 734 l 192 408 l 283 408 l 583 734 l 682 734 l 357 376 l 716 0 l 613 0 l 285 336 z ',
    },
    ο: {
      ha: 778,
      x_min: 61,
      x_max: 716,
      o: 'm 61 378 q 102 568 61 484 q 218 700 143 653 q 388 747 293 747 q 625 645 534 747 q 716 372 716 542 l 716 355 q 675 164 716 249 q 559 33 634 79 q 389 -14 485 -14 q 152 89 243 -14 q 61 361 61 192 l 61 378 m 142 355 q 211 139 142 224 q 389 55 279 55 q 567 139 499 55 q 635 362 635 224 l 635 378 q 604 532 635 462 q 517 640 573 602 q 388 678 460 678 q 211 593 279 678 q 142 370 142 508 l 142 355 z ',
    },
    μ: {
      ha: 773,
      x_min: 110,
      x_max: 664,
      o: 'm 191 734 l 191 302 q 233 118 191 180 q 369 55 276 55 q 511 94 458 55 q 583 212 563 133 l 583 734 l 664 734 l 664 0 l 590 0 l 585 104 q 374 -14 519 -14 q 191 71 250 -14 l 191 -282 l 110 -282 l 110 734 l 191 734 z ',
    },
    ν: {
      ha: 668,
      x_min: 26,
      x_max: 641,
      o: 'm 334 112 l 557 734 l 641 734 l 367 0 l 301 0 l 26 734 l 109 734 l 334 112 z ',
    },
    χ: {
      ha: 675,
      x_min: 43,
      x_max: 743,
      o: 'm 104 741 q 262 625 205 741 l 289 569 l 393 333 l 602 734 l 687 734 l 433 245 l 580 -86 l 604 -135 q 688 -216 642 -216 q 743 -212 728 -216 l 730 -281 q 683 -290 712 -290 q 592 -254 629 -290 q 507 -111 555 -219 l 388 159 l 159 -281 l 73 -281 l 349 248 l 216 547 l 194 593 q 93 670 156 670 l 47 666 l 43 733 q 104 741 72 741 z ',
    },
    ϊ: {
      ha: 416,
      x_min: -36,
      x_max: 373,
      o: 'm 205 734 l 205 183 q 227 94 205 123 q 300 64 249 64 q 366 71 332 64 l 370 3 q 283 -7 332 -7 q 161 42 199 -7 q 123 184 123 90 l 123 734 l 205 734 m -36 932 q -21 971 -36 956 q 20 987 -6 987 q 61 971 45 987 q 76 932 76 956 q 61 895 76 910 q 20 879 45 879 q -21 895 -6 879 q -36 932 -36 910 m 261 932 q 276 971 261 955 q 317 987 291 987 q 358 971 342 987 q 373 932 373 955 q 358 894 373 909 q 317 878 342 878 q 276 894 291 878 q 261 932 261 909 z ',
    },
    ϋ: {
      ha: 743,
      x_min: 105,
      x_max: 659,
      o: 'm 186 734 l 186 271 q 229 110 187 165 q 350 55 271 55 q 515 142 452 55 q 578 366 578 229 q 498 734 576 544 l 587 734 q 642 551 626 637 q 659 368 659 464 q 576 90 659 193 q 353 -14 494 -14 q 169 60 232 -14 q 105 275 105 133 l 105 734 l 186 734 m 156 932 q 171 971 156 956 q 212 987 186 987 q 253 971 237 987 q 268 932 268 956 q 253 895 268 910 q 212 879 237 879 q 171 895 186 879 q 156 932 156 910 m 453 932 q 468 971 453 955 q 509 987 483 987 q 550 971 534 987 q 565 932 565 955 q 550 894 565 909 q 509 878 534 878 q 468 894 483 878 q 453 932 453 909 z ',
    },
    ό: {
      ha: 778,
      x_min: 61,
      x_max: 716,
      o: 'm 61 378 q 102 568 61 484 q 218 700 143 653 q 388 747 293 747 q 625 645 534 747 q 716 372 716 542 l 716 355 q 675 164 716 249 q 559 33 634 79 q 389 -14 485 -14 q 152 89 243 -14 q 61 361 61 192 l 61 378 m 142 355 q 211 139 142 224 q 389 55 279 55 q 567 139 499 55 q 635 362 635 224 l 635 378 q 604 532 635 462 q 517 640 573 602 q 388 678 460 678 q 211 593 279 678 q 142 370 142 508 l 142 355 m 401 1047 l 481 1047 l 407 841 l 351 841 l 401 1047 z ',
    },
    ύ: {
      ha: 743,
      x_min: 105,
      x_max: 659,
      o: 'm 186 734 l 186 271 q 229 110 187 165 q 350 55 271 55 q 515 142 452 55 q 578 366 578 229 q 498 734 576 544 l 587 734 q 642 551 626 637 q 659 368 659 464 q 576 90 659 193 q 353 -14 494 -14 q 169 60 232 -14 q 105 275 105 133 l 105 734 l 186 734 m 372 1033 l 452 1033 l 378 827 l 322 827 l 372 1033 z ',
    },
    ώ: {
      ha: 1145,
      x_min: 93,
      x_max: 1052,
      o: 'm 274 734 q 174 359 176 541 q 221 137 174 219 q 355 55 269 55 q 484 121 438 55 q 531 316 530 188 l 531 522 l 613 522 l 613 317 q 660 122 614 189 q 790 55 705 55 q 923 136 874 55 q 971 359 971 218 q 870 734 969 538 l 959 734 q 1031 543 1011 629 q 1052 360 1052 456 q 983 86 1052 186 q 793 -14 915 -14 q 656 28 713 -14 q 572 158 599 71 q 351 -14 519 -14 q 161 87 229 -14 q 93 360 93 187 q 114 549 93 460 q 186 734 136 637 l 274 734 m 595 1031 l 675 1031 l 602 825 l 545 825 l 595 1031 z ',
    },
    Ё: {
      ha: 790,
      x_min: 125,
      x_max: 739,
      o: 'm 670 473 l 208 473 l 208 71 l 739 71 l 739 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 544 l 670 544 l 670 473 m 217 1164 q 232 1203 217 1187 q 273 1219 247 1219 q 314 1203 298 1219 q 329 1164 329 1187 q 314 1126 329 1142 q 273 1111 298 1111 q 232 1126 247 1111 q 217 1164 217 1142 m 514 1164 q 529 1203 514 1187 q 570 1219 544 1219 q 611 1203 595 1219 q 626 1164 626 1187 q 611 1126 626 1141 q 570 1110 595 1110 q 529 1126 544 1110 q 514 1164 514 1141 z ',
    },
    Ѓ: {
      ha: 766,
      x_min: 125,
      x_max: 721,
      o: 'm 721 916 l 209 916 l 209 0 l 125 0 l 125 987 l 721 987 l 721 916 m 500 1259 l 598 1259 l 441 1069 l 370 1069 l 500 1259 z ',
    },
    Ѕ: {
      ha: 823,
      x_min: 60,
      x_max: 768,
      o: 'm 684 243 q 626 373 684 325 q 415 464 568 420 q 191 557 262 507 q 91 746 91 629 q 184 930 91 859 q 422 1001 277 1001 q 598 963 521 1001 q 719 857 676 925 q 762 706 762 789 l 677 706 q 608 868 677 807 q 422 930 539 930 q 242 879 309 930 q 175 747 175 829 q 235 625 175 672 q 429 541 296 577 q 630 466 562 505 q 733 372 699 427 q 768 244 768 318 q 674 57 768 127 q 429 -14 581 -14 q 237 24 324 -14 q 105 128 150 61 q 60 282 60 195 l 143 282 q 221 117 143 178 q 429 57 299 57 q 614 108 544 57 q 684 243 684 159 z ',
    },
    І: {
      ha: 370,
      x_min: 143,
      x_max: 227,
      o: 'm 227 0 l 143 0 l 143 987 l 227 987 l 227 0 z ',
    },
    Ї: {
      ha: 370,
      x_min: -17,
      x_max: 392,
      o: 'm 227 0 l 143 0 l 143 987 l 227 987 l 227 0 m -17 1164 q -2 1203 -17 1187 q 39 1219 13 1219 q 80 1203 64 1219 q 95 1164 95 1187 q 80 1126 95 1142 q 39 1111 64 1111 q -2 1126 13 1111 q -17 1164 -17 1142 m 280 1164 q 295 1203 280 1187 q 336 1219 310 1219 q 377 1203 361 1219 q 392 1164 392 1187 q 377 1126 392 1141 q 336 1110 361 1110 q 295 1126 310 1110 q 280 1164 280 1141 z ',
    },
    Ј: {
      ha: 764,
      x_min: 48,
      x_max: 645,
      o: 'm 561 987 l 645 987 l 645 294 q 564 70 645 153 q 347 -14 483 -14 q 125 62 203 -14 q 48 272 48 137 l 132 272 q 188 111 132 165 q 347 57 244 57 q 500 119 441 57 q 561 289 559 182 l 561 987 z ',
    },
    К: {
      ha: 887,
      x_min: 128,
      x_max: 860,
      o: 'm 347 463 l 212 463 l 212 0 l 128 0 l 128 987 l 212 987 l 212 534 l 343 534 l 725 987 l 830 987 l 425 502 l 860 0 l 756 0 l 347 463 z ',
    },
    Ќ: {
      ha: 876,
      x_min: 125,
      x_max: 851,
      o: 'm 347 490 l 208 353 l 208 0 l 125 0 l 125 987 l 208 987 l 208 455 l 714 987 l 819 987 l 406 548 l 851 0 l 749 0 l 347 490 m 490 1223 l 589 1223 l 432 1033 l 361 1033 l 490 1223 z ',
    },
    Ў: {
      ha: 871,
      x_min: 37,
      x_max: 833,
      o: 'm 454 355 l 735 987 l 833 987 l 452 161 l 427 114 q 216 -14 355 -14 q 152 -7 174 -14 l 159 61 q 216 56 174 56 q 351 142 300 56 l 368 173 l 411 261 l 37 987 l 133 987 l 454 355 m 634 1230 q 581 1104 634 1152 q 438 1056 528 1056 q 296 1104 350 1056 q 242 1230 242 1152 l 311 1230 q 345 1144 311 1174 q 438 1114 378 1114 q 531 1145 498 1114 q 565 1230 565 1177 l 634 1230 z ',
    },
    А: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 z ',
    },
    В: {
      ha: 851,
      x_min: 125,
      x_max: 774,
      o: 'm 125 0 l 125 987 l 416 987 q 657 922 575 987 q 739 729 739 857 q 697 596 739 652 q 583 515 656 539 q 722 430 669 496 q 774 278 774 364 q 690 74 774 147 q 455 0 606 0 l 125 0 m 208 475 l 208 71 l 458 71 q 628 125 566 71 q 691 277 691 180 q 631 421 691 368 q 465 475 571 475 l 208 475 m 208 545 l 436 545 q 598 594 541 547 q 655 731 655 641 q 596 871 655 826 q 416 916 536 916 l 208 916 l 208 545 z ',
    },
    Г: {
      ha: 766,
      x_min: 125,
      x_max: 721,
      o: 'm 721 916 l 209 916 l 209 0 l 125 0 l 125 987 l 721 987 l 721 916 z ',
    },
    Е: {
      ha: 790,
      x_min: 125,
      x_max: 739,
      o: 'm 670 473 l 208 473 l 208 71 l 739 71 l 739 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 544 l 670 544 l 670 473 z ',
    },
    Й: {
      ha: 986,
      x_min: 127,
      x_max: 857,
      o: 'm 774 987 l 857 987 l 857 0 l 774 0 l 774 845 l 212 0 l 127 0 l 127 987 l 212 987 l 212 143 l 774 987 m 694 1230 q 641 1104 694 1152 q 498 1056 587 1056 q 356 1104 410 1056 q 302 1230 302 1152 l 371 1230 q 405 1144 371 1174 q 498 1114 438 1114 q 591 1145 557 1114 q 625 1230 625 1177 l 694 1230 z ',
    },
    М: {
      ha: 1202,
      x_min: 125,
      x_max: 1076,
      o: 'm 235 987 l 600 116 l 965 987 l 1076 987 l 1076 0 l 993 0 l 993 430 l 1000 871 l 632 0 l 568 0 l 201 867 l 208 433 l 208 0 l 125 0 l 125 987 l 235 987 z ',
    },
    Н: {
      ha: 983,
      x_min: 125,
      x_max: 857,
      o: 'm 857 0 l 772 0 l 772 473 l 208 473 l 208 0 l 125 0 l 125 987 l 208 987 l 208 544 l 772 544 l 772 987 l 857 987 l 857 0 z ',
    },
    О: {
      ha: 940,
      x_min: 85,
      x_max: 854,
      o: 'm 854 440 q 807 201 854 304 q 673 42 760 98 q 471 -14 585 -14 q 191 110 298 -14 q 85 444 85 234 l 85 546 q 133 785 85 681 q 268 945 180 889 q 469 1001 355 1001 q 670 946 583 1001 q 805 791 758 891 q 854 558 852 690 l 854 440 m 771 547 q 690 827 771 726 q 469 928 610 928 q 250 826 332 928 q 168 543 168 725 l 168 440 q 250 162 168 264 q 471 59 331 59 q 692 160 612 59 q 771 443 771 262 l 771 547 z ',
    },
    П: {
      ha: 983,
      x_min: 128,
      x_max: 854,
      o: 'm 854 0 l 770 0 l 770 916 l 212 916 l 212 0 l 128 0 l 128 987 l 854 987 l 854 0 z ',
    },
    Р: {
      ha: 855,
      x_min: 125,
      x_max: 793,
      o: 'm 208 402 l 208 0 l 125 0 l 125 987 l 461 987 q 704 909 615 987 q 793 692 793 830 q 708 478 793 553 q 459 402 622 402 l 208 402 m 208 473 l 461 473 q 646 530 583 473 q 710 691 710 588 q 647 854 710 793 q 467 916 583 915 l 208 916 l 208 473 z ',
    },
    С: {
      ha: 902,
      x_min: 89,
      x_max: 824,
      o: 'm 824 308 q 712 69 807 152 q 460 -14 618 -14 q 266 41 351 -14 q 136 197 182 96 q 89 427 90 298 l 89 555 q 135 789 89 687 q 268 946 181 890 q 467 1001 354 1001 q 717 915 625 1001 q 824 678 809 829 l 740 678 q 467 930 709 930 q 253 829 332 930 q 173 552 173 729 l 173 431 q 251 159 173 260 q 460 57 328 57 q 658 120 591 57 q 740 308 724 182 l 824 308 z ',
    },
    Т: {
      ha: 829,
      x_min: 35,
      x_max: 795,
      o: 'm 795 916 l 456 916 l 456 0 l 373 0 l 373 916 l 35 916 l 35 987 l 795 987 l 795 916 z ',
    },
    Х: {
      ha: 850,
      x_min: 37,
      x_max: 813,
      o: 'm 425 570 l 703 987 l 803 987 l 476 500 l 813 0 l 713 0 l 425 431 l 136 0 l 37 0 l 375 500 l 47 987 l 147 987 l 425 570 z ',
    },
    а: {
      ha: 744,
      x_min: 68,
      x_max: 656,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 z ',
    },
    е: {
      ha: 718,
      x_min: 61,
      x_max: 665,
      o: 'm 390 -14 q 221 33 296 -14 q 103 161 145 79 q 61 346 61 243 l 61 375 q 102 566 61 481 q 216 699 143 650 q 375 747 290 747 q 587 656 509 747 q 665 407 665 565 l 665 361 l 142 361 l 142 346 q 213 138 142 221 q 393 55 285 55 q 508 79 458 55 q 599 155 558 102 l 650 116 q 390 -14 560 -14 m 375 678 q 221 611 283 678 q 144 431 158 544 l 585 431 l 585 439 q 524 612 581 545 q 375 678 467 678 z ',
    },
    й: {
      ha: 781,
      x_min: 111,
      x_max: 669,
      o: 'm 588 734 l 669 734 l 669 0 l 588 0 l 588 603 l 192 0 l 111 0 l 111 734 l 192 734 l 192 130 l 588 734 m 584 998 q 531 872 584 920 q 388 824 477 824 q 246 872 300 824 q 192 998 192 920 l 261 998 q 295 912 261 942 q 388 882 328 882 q 481 913 448 882 q 515 998 515 945 l 584 998 z ',
    },
    о: {
      ha: 778,
      x_min: 61,
      x_max: 716,
      o: 'm 61 378 q 102 568 61 484 q 218 700 143 653 q 388 747 293 747 q 625 645 534 747 q 716 372 716 542 l 716 355 q 675 164 716 249 q 559 33 634 79 q 389 -14 485 -14 q 152 89 243 -14 q 61 361 61 192 l 61 378 m 142 355 q 211 139 142 224 q 389 55 279 55 q 567 139 499 55 q 635 362 635 224 l 635 378 q 604 532 635 462 q 517 640 573 602 q 388 678 460 678 q 211 593 279 678 q 142 370 142 508 l 142 355 z ',
    },
    р: {
      ha: 770,
      x_min: 105,
      x_max: 696,
      o: 'm 696 359 q 620 87 696 188 q 416 -14 544 -14 q 186 92 266 -14 l 186 -282 l 105 -282 l 105 734 l 180 734 l 184 630 q 414 747 264 747 q 621 648 546 747 q 696 371 696 548 l 696 359 m 614 374 q 557 595 614 514 q 396 677 499 677 q 268 641 321 677 q 186 536 214 605 l 186 184 q 269 88 215 121 q 397 55 323 55 q 557 137 500 55 q 614 374 614 218 z ',
    },
    с: {
      ha: 715,
      x_min: 64,
      x_max: 659,
      o: 'm 377 55 q 519 103 462 55 q 581 227 576 151 l 659 227 q 617 104 656 159 q 514 18 578 49 q 377 -14 450 -14 q 148 87 233 -14 q 64 357 64 187 l 64 381 q 102 573 64 490 q 211 702 140 656 q 376 747 281 747 q 575 675 497 747 q 659 486 654 604 l 581 486 q 519 625 576 572 q 376 678 463 678 q 206 599 267 678 q 145 376 145 519 l 145 353 q 206 134 145 212 q 377 55 267 55 z ',
    },
    у: {
      ha: 660,
      x_min: 22,
      x_max: 640,
      o: 'm 338 117 l 553 734 l 640 734 l 327 -125 l 311 -163 q 124 -296 250 -296 q 62 -287 95 -296 l 61 -220 l 103 -224 q 200 -194 163 -224 q 262 -93 237 -165 l 298 6 l 22 734 l 111 734 l 338 117 z ',
    },
    х: {
      ha: 675,
      x_min: 33,
      x_max: 642,
      o: 'm 336 436 l 534 734 l 631 734 l 382 374 l 642 0 l 546 0 l 337 311 l 128 0 l 33 0 l 292 374 l 43 734 l 138 734 l 336 436 z ',
    },
    ё: {
      ha: 718,
      x_min: 61,
      x_max: 665,
      o: 'm 390 -14 q 221 33 296 -14 q 103 161 145 79 q 61 346 61 243 l 61 375 q 102 566 61 481 q 216 699 143 650 q 375 747 290 747 q 587 656 509 747 q 665 407 665 565 l 665 361 l 142 361 l 142 346 q 213 138 142 221 q 393 55 285 55 q 508 79 458 55 q 599 155 558 102 l 650 116 q 390 -14 560 -14 m 375 678 q 221 611 283 678 q 144 431 158 544 l 585 431 l 585 439 q 524 612 581 545 q 375 678 467 678 m 181 947 q 196 986 181 970 q 237 1002 211 1002 q 278 986 262 1002 q 293 947 293 970 q 278 909 293 924 q 237 893 262 893 q 196 909 211 893 q 181 947 181 924 m 478 946 q 493 985 478 969 q 534 1001 508 1001 q 575 985 559 1001 q 590 946 590 969 q 575 908 590 924 q 534 892 559 892 q 493 908 508 892 q 478 946 478 924 z ',
    },
    ѓ: {
      ha: 585,
      x_min: 111,
      x_max: 563,
      o: 'm 563 664 l 192 664 l 192 0 l 111 0 l 111 734 l 563 734 l 563 664 m 370 1027 l 469 1027 l 312 838 l 241 838 l 370 1027 z ',
    },
    ѕ: {
      ha: 703,
      x_min: 68,
      x_max: 627,
      o: 'm 545 186 q 497 283 545 247 q 351 341 448 320 q 199 388 253 361 q 119 454 145 415 q 93 547 93 492 q 165 691 93 634 q 351 747 238 747 q 548 687 474 747 q 623 528 623 626 l 541 528 q 487 635 541 593 q 351 678 433 678 q 223 643 271 678 q 174 550 174 607 q 215 465 174 495 q 362 410 255 435 q 522 356 469 385 q 601 288 575 328 q 627 191 627 248 q 552 42 627 98 q 356 -14 477 -14 q 148 48 228 -14 q 68 205 68 111 l 150 205 q 210 95 155 134 q 356 55 265 55 q 493 92 441 55 q 545 186 545 130 z ',
    },
    і: {
      ha: 311,
      x_min: 101,
      x_max: 213,
      o: 'm 197 0 l 115 0 l 115 734 l 197 734 l 197 0 m 101 946 q 116 985 101 969 q 157 1001 131 1001 q 198 985 182 1001 q 213 946 213 969 q 198 907 213 923 q 157 892 182 892 q 116 907 131 892 q 101 946 101 923 z ',
    },
    ї: {
      ha: 309,
      x_min: -46,
      x_max: 363,
      o: 'm 195 0 l 114 0 l 114 734 l 195 734 l 195 0 m -46 939 q -31 978 -46 962 q 9 994 -16 994 q 51 978 35 994 q 66 939 66 962 q 51 901 66 916 q 9 885 35 885 q -31 901 -16 885 q -46 939 -46 916 m 251 938 q 266 977 251 961 q 307 993 281 993 q 348 977 332 993 q 363 938 363 961 q 348 900 363 916 q 307 884 332 884 q 266 900 281 884 q 251 938 251 916 z ',
    },
    ј: {
      ha: 317,
      x_min: -71,
      x_max: 210,
      o: 'm 193 734 l 193 -87 q 144 -243 193 -189 q 1 -296 96 -296 q -71 -283 -36 -296 l -69 -216 q -8 -225 -39 -225 q 113 -86 113 -225 l 113 734 l 193 734 m 153 1001 q 195 985 180 1001 q 210 946 210 969 q 195 907 210 923 q 153 892 180 892 q 113 907 127 892 q 98 946 98 923 q 113 985 98 969 q 153 1001 127 1001 z ',
    },
    ќ: {
      ha: 720,
      x_min: 111,
      x_max: 709,
      o: 'm 305 336 l 193 336 l 193 0 l 111 0 l 111 734 l 193 734 l 193 408 l 304 408 l 573 734 l 675 734 l 374 378 l 709 0 l 602 0 l 305 336 m 447 1027 l 545 1027 l 389 837 l 317 837 l 447 1027 z ',
    },
    ў: {
      ha: 660,
      x_min: 22,
      x_max: 640,
      o: 'm 338 117 l 553 734 l 640 734 l 327 -125 l 311 -163 q 124 -296 250 -296 q 62 -287 95 -296 l 61 -220 l 103 -224 q 200 -194 163 -224 q 262 -93 237 -165 l 298 6 l 22 734 l 111 734 l 338 117 m 544 1013 q 491 886 544 935 q 348 838 437 838 q 206 886 260 838 q 152 1013 152 934 l 221 1013 q 255 926 221 956 q 348 897 288 897 q 441 928 408 897 q 475 1013 475 959 l 544 1013 z ',
    },
    Ẁ: {
      ha: 1245,
      x_min: 41,
      x_max: 1209,
      o: 'm 307 267 l 338 113 l 375 260 l 589 987 l 665 987 l 876 260 l 913 112 l 945 267 l 1124 987 l 1209 987 l 956 0 l 876 0 l 652 772 l 627 870 l 603 772 l 374 0 l 294 0 l 41 987 l 127 987 l 307 267 m 677 1065 l 604 1065 l 450 1255 l 548 1255 l 677 1065 z ',
    },
    ẁ: {
      ha: 1047,
      x_min: 43,
      x_max: 999,
      o: 'm 281 169 l 294 106 l 310 172 l 486 734 l 555 734 l 730 177 l 749 100 l 764 171 l 915 734 l 999 734 l 785 0 l 716 0 l 528 582 l 519 622 l 510 581 l 325 0 l 256 0 l 43 734 l 126 734 l 281 169 m 569 852 l 495 852 l 342 1042 l 439 1042 l 569 852 z ',
    },
    Ẃ: {
      ha: 1245,
      x_min: 41,
      x_max: 1209,
      o: 'm 307 267 l 338 113 l 375 260 l 589 987 l 665 987 l 876 260 l 913 112 l 945 267 l 1124 987 l 1209 987 l 956 0 l 876 0 l 652 772 l 627 870 l 603 772 l 374 0 l 294 0 l 41 987 l 127 987 l 307 267 m 709 1255 l 807 1255 l 650 1065 l 579 1065 l 709 1255 z ',
    },
    ẃ: {
      ha: 1047,
      x_min: 43,
      x_max: 999,
      o: 'm 281 169 l 294 106 l 310 172 l 486 734 l 555 734 l 730 177 l 749 100 l 764 171 l 915 734 l 999 734 l 785 0 l 716 0 l 528 582 l 519 622 l 510 581 l 325 0 l 256 0 l 43 734 l 126 734 l 281 169 m 600 1042 l 699 1042 l 542 852 l 471 852 l 600 1042 z ',
    },
    Ẅ: {
      ha: 1245,
      x_min: 41,
      x_max: 1209,
      o: 'm 307 267 l 338 113 l 375 260 l 589 987 l 665 987 l 876 260 l 913 112 l 945 267 l 1124 987 l 1209 987 l 956 0 l 876 0 l 652 772 l 627 870 l 603 772 l 374 0 l 294 0 l 41 987 l 127 987 l 307 267 m 425 1160 q 439 1199 425 1183 q 480 1215 454 1215 q 521 1199 506 1215 q 536 1160 536 1183 q 521 1122 536 1138 q 480 1107 506 1107 q 439 1122 454 1107 q 425 1160 425 1138 m 722 1160 q 736 1199 722 1183 q 777 1215 751 1215 q 818 1199 803 1215 q 833 1160 833 1183 q 818 1122 833 1137 q 777 1106 803 1106 q 736 1122 751 1106 q 722 1160 722 1137 z ',
    },
    ẅ: {
      ha: 1047,
      x_min: 43,
      x_max: 999,
      o: 'm 281 169 l 294 106 l 310 172 l 486 734 l 555 734 l 730 177 l 749 100 l 764 171 l 915 734 l 999 734 l 785 0 l 716 0 l 528 582 l 519 622 l 510 581 l 325 0 l 256 0 l 43 734 l 126 734 l 281 169 m 316 947 q 331 986 316 970 q 372 1002 346 1002 q 413 986 397 1002 q 428 947 428 970 q 413 909 428 924 q 372 893 397 893 q 331 909 346 893 q 316 947 316 924 m 613 946 q 628 985 613 969 q 669 1001 643 1001 q 710 985 694 1001 q 725 946 725 969 q 710 908 725 924 q 669 892 694 892 q 628 908 643 892 q 613 946 613 924 z ',
    },
    Ỳ: {
      ha: 831,
      x_min: 16,
      x_max: 812,
      o: 'm 414 449 l 716 987 l 812 987 l 456 372 l 456 0 l 372 0 l 372 372 l 16 987 l 115 987 l 414 449 m 465 1061 l 391 1061 l 238 1251 l 336 1251 l 465 1061 z ',
    },
    ỳ: {
      ha: 660,
      x_min: 22,
      x_max: 640,
      o: 'm 338 117 l 553 734 l 640 734 l 327 -125 l 311 -163 q 124 -296 250 -296 q 62 -287 95 -296 l 61 -220 l 103 -224 q 200 -194 163 -224 q 262 -93 237 -165 l 298 6 l 22 734 l 111 734 l 338 117 m 398 852 l 324 852 l 171 1042 l 269 1042 l 398 852 z ',
    },
    '′': {
      ha: 236,
      x_min: 81,
      x_max: 153,
      o: 'm 153 946 l 142 739 l 81 739 q 84 1042 84 940 l 153 1042 l 153 946 z ',
    },
    '″': {
      ha: 399,
      x_min: 97,
      x_max: 325,
      o: 'm 165 947 l 151 734 l 97 734 l 99 1042 l 165 1042 l 165 947 m 325 947 l 311 734 l 256 734 l 259 1042 l 325 1042 l 325 947 z ',
    },
    '‼': {
      ha: 627,
      x_min: 104,
      x_max: 529,
      o: 'm 193 275 l 117 275 l 113 987 l 197 987 l 193 275 m 104 47 q 119 86 104 71 q 159 102 134 102 q 200 86 185 102 q 216 47 216 71 q 200 10 216 25 q 159 -5 185 -5 q 119 10 134 -5 q 104 47 104 25 m 506 275 l 431 275 l 427 987 l 511 987 l 506 275 m 417 47 q 432 86 417 71 q 473 102 447 102 q 514 86 498 102 q 529 47 529 71 q 514 10 529 25 q 473 -5 498 -5 q 432 10 447 -5 q 417 47 417 25 z ',
    },
    ǰ: {
      ha: 317,
      x_min: -79,
      x_max: 350,
      o: 'm 202 734 l 202 -60 q 148 -236 202 -175 q -3 -296 94 -296 q -79 -283 -41 -296 l -70 -216 q -3 -227 -41 -227 q 89 -184 57 -227 q 121 -60 121 -140 l 121 734 l 202 734 m 161 873 l 277 1005 l 350 1005 l 350 997 l 190 820 l 132 820 l -28 998 l -28 1005 l 44 1005 l 161 873 z ',
    },
    ʼ: {
      ha: 247,
      x_min: 42,
      x_max: 185,
      o: 'm 90 745 l 42 781 q 105 953 103 863 l 105 1042 l 185 1042 l 185 973 q 90 745 185 831 z ',
    },
    Ḿ: {
      ha: 1202,
      x_min: 125,
      x_max: 1076,
      o: 'm 235 987 l 600 116 l 965 987 l 1076 987 l 1076 0 l 993 0 l 993 430 l 1000 871 l 632 0 l 568 0 l 201 867 l 208 433 l 208 0 l 125 0 l 125 987 l 235 987 m 685 1255 l 783 1255 l 627 1065 l 555 1065 l 685 1255 z ',
    },
    ḿ: {
      ha: 1231,
      x_min: 102,
      x_max: 1131,
      o: 'm 180 734 l 182 614 q 284 714 223 681 q 420 747 345 747 q 640 605 593 747 q 745 710 680 673 q 890 747 811 747 q 1131 490 1126 747 l 1131 0 l 1050 0 l 1050 484 q 1007 629 1049 582 q 871 677 966 677 q 724 620 784 675 q 656 486 663 565 l 656 0 l 575 0 l 575 490 q 531 630 574 584 q 396 677 487 677 q 264 633 319 677 q 183 503 210 589 l 183 0 l 102 0 l 102 734 l 180 734 m 701 1042 l 799 1042 l 642 852 l 571 852 l 701 1042 z ',
    },
    Ḁ: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 342 -154 q 373 -85 342 -113 q 447 -57 404 -57 q 519 -85 490 -57 q 549 -154 549 -113 q 519 -221 549 -194 q 447 -248 490 -248 q 373 -221 404 -248 q 342 -154 342 -195 m 392 -154 q 408 -189 392 -175 q 447 -202 424 -202 q 486 -189 470 -202 q 501 -154 501 -175 q 486 -118 501 -132 q 447 -104 470 -104 q 408 -118 424 -104 q 392 -154 392 -132 z ',
    },
    ḁ: {
      ha: 744,
      x_min: 68,
      x_max: 656,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 m 236 -154 q 267 -85 236 -113 q 340 -57 297 -57 q 413 -85 383 -57 q 443 -154 443 -113 q 413 -221 443 -194 q 340 -248 383 -248 q 267 -221 298 -248 q 236 -154 236 -195 m 286 -154 q 301 -189 286 -175 q 340 -202 317 -202 q 379 -189 363 -202 q 395 -154 395 -175 q 379 -118 395 -132 q 340 -104 363 -104 q 301 -118 317 -104 q 286 -154 286 -132 z ',
    },
    Ѐ: {
      ha: 790,
      x_min: 125,
      x_max: 739,
      o: 'm 670 473 l 208 473 l 208 71 l 739 71 l 739 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 544 l 670 544 l 670 473 m 470 1069 l 396 1069 l 243 1259 l 340 1259 l 470 1069 z ',
    },
    Ѝ: {
      ha: 986,
      x_min: 127,
      x_max: 857,
      o: 'm 774 987 l 857 987 l 857 0 l 774 0 l 774 845 l 212 0 l 127 0 l 127 987 l 212 987 l 212 143 l 774 987 m 548 1069 l 474 1069 l 321 1259 l 418 1259 l 548 1069 z ',
    },
    ѐ: {
      ha: 718,
      x_min: 61,
      x_max: 665,
      o: 'm 390 -14 q 221 33 296 -14 q 103 161 145 79 q 61 346 61 243 l 61 375 q 102 566 61 481 q 216 699 143 650 q 375 747 290 747 q 587 656 509 747 q 665 407 665 565 l 665 361 l 142 361 l 142 346 q 213 138 142 221 q 393 55 285 55 q 508 79 458 55 q 599 155 558 102 l 650 116 q 390 -14 560 -14 m 375 678 q 221 611 283 678 q 144 431 158 544 l 585 431 l 585 439 q 524 612 581 545 q 375 678 467 678 m 434 852 l 360 852 l 207 1042 l 304 1042 l 434 852 z ',
    },
    ѝ: {
      ha: 781,
      x_min: 111,
      x_max: 669,
      o: 'm 588 734 l 669 734 l 669 0 l 588 0 l 588 603 l 192 0 l 111 0 l 111 734 l 192 734 l 192 130 l 588 734 m 438 838 l 364 838 l 211 1027 l 309 1027 l 438 838 z ',
    },
    Ѱ: {
      ha: 964,
      x_min: 83,
      x_max: 877,
      o: 'm 519 336 q 719 426 646 346 q 793 635 793 507 l 793 987 l 877 987 l 877 638 q 782 374 877 474 q 519 264 686 274 l 519 0 l 435 0 l 435 264 q 180 373 274 275 q 83 629 85 471 l 83 987 l 167 987 l 167 635 q 200 485 167 551 q 294 381 234 419 q 435 336 355 343 l 435 987 l 519 987 l 519 336 z ',
    },
    ѱ: {
      ha: 949,
      x_min: 82,
      x_max: 875,
      o: 'm 509 734 l 509 56 q 719 154 644 66 q 794 392 794 241 q 699 734 792 557 l 788 734 q 856 561 837 640 q 875 393 875 481 q 780 100 875 204 q 509 -12 684 -4 l 509 -300 l 429 -300 l 429 -12 q 174 101 263 0 q 82 388 85 202 l 82 734 l 163 734 l 163 393 q 232 156 165 243 q 429 57 298 69 l 429 734 l 509 734 z ',
    },
    Ѷ: {
      ha: 855,
      x_min: 19,
      x_max: 836,
      o: 'm 370 173 l 391 99 l 412 173 l 619 819 q 695 957 649 915 q 814 1000 741 1000 l 836 1000 l 835 927 q 782 920 801 927 q 696 806 728 901 l 430 0 l 351 0 l 19 987 l 110 987 l 370 173 m 360 1019 l 278 1019 l 73 1200 l 177 1200 l 360 1019 m 525 1019 l 451 1019 l 281 1200 l 377 1200 l 525 1019 z ',
    },
    ѷ: {
      ha: 684,
      x_min: 6,
      x_max: 695,
      o: 'm 294 184 l 320 91 l 348 184 l 481 608 q 542 714 506 681 q 631 747 578 747 q 695 732 672 747 l 684 667 q 644 675 667 675 q 560 599 588 675 l 353 0 l 288 0 l 30 734 l 114 734 l 294 184 m 293 819 l 211 819 l 6 1000 l 110 1000 l 293 819 m 458 819 l 384 819 l 214 1000 l 310 1000 l 458 819 z ',
    },
    ѹ: {
      ha: 1438,
      x_min: 61,
      x_max: 1418,
      o: 'm 61 378 q 102 568 61 484 q 218 700 143 653 q 388 747 293 747 q 625 645 534 747 q 716 372 716 542 l 716 355 q 675 164 716 249 q 559 33 634 79 q 389 -14 485 -14 q 152 89 243 -14 q 61 361 61 192 l 61 378 m 142 355 q 211 139 142 224 q 389 55 279 55 q 567 139 499 55 q 635 362 635 224 l 635 378 q 604 532 635 462 q 517 640 573 602 q 388 678 460 678 q 211 593 279 678 q 142 370 142 508 l 142 355 m 1116 117 l 1331 734 l 1418 734 l 1105 -125 l 1088 -163 q 902 -296 1028 -296 q 840 -287 873 -296 l 839 -220 l 881 -224 q 978 -194 941 -224 q 1040 -93 1015 -165 l 1076 6 l 800 734 l 888 734 l 1116 117 z ',
    },
    Ѹ: {
      ha: 1600,
      x_min: 85,
      x_max: 1580,
      o: 'm 854 440 q 807 201 854 304 q 673 42 760 98 q 471 -14 585 -14 q 191 110 298 -14 q 85 444 85 234 l 85 546 q 133 785 85 681 q 268 945 180 889 q 469 1001 355 1001 q 670 946 583 1001 q 805 791 758 891 q 854 558 852 690 l 854 440 m 771 547 q 690 827 771 726 q 469 928 610 928 q 250 826 332 928 q 168 543 168 725 l 168 440 q 250 162 168 264 q 471 59 331 59 q 692 160 612 59 q 771 443 771 262 l 771 547 m 1278 117 l 1493 734 l 1580 734 l 1267 -125 l 1251 -163 q 1064 -296 1190 -296 q 1002 -287 1035 -296 l 1001 -220 l 1043 -224 q 1140 -194 1103 -224 q 1202 -93 1177 -165 l 1238 6 l 962 734 l 1050 734 l 1278 117 z ',
    },
    Ҙ: {
      ha: 826,
      x_min: 61,
      x_max: 762,
      o: 'm 664 730 q 597 876 664 823 q 406 930 530 930 q 229 873 298 930 q 159 728 159 816 l 75 728 q 118 869 75 807 q 238 966 161 931 q 406 1001 315 1001 q 656 930 566 1001 q 747 728 747 859 q 699 593 747 652 q 570 506 651 534 q 762 268 762 454 q 666 63 762 140 q 406 -14 569 -14 q 225 24 307 -14 q 102 126 144 62 q 61 274 61 190 l 144 274 q 218 118 144 179 q 406 56 292 56 q 606 113 534 56 q 679 265 679 170 q 614 416 679 366 q 416 467 550 466 l 290 467 l 290 539 l 412 539 q 600 588 535 540 q 664 730 664 635 m 459 -292 l 378 -292 l 378 33 l 459 33 l 459 -292 z ',
    },
    ҙ: {
      ha: 703,
      x_min: 66,
      x_max: 631,
      o: 'm 536 540 q 486 642 536 606 q 350 678 437 678 q 215 638 271 678 q 159 536 159 597 l 79 536 q 157 687 79 627 q 350 747 235 747 q 547 692 476 747 q 617 538 617 638 q 585 443 617 486 q 490 376 552 400 q 631 201 631 338 q 554 45 631 102 q 351 -13 477 -13 q 147 49 228 -13 q 66 210 66 111 l 148 210 q 207 99 148 144 q 351 55 265 55 q 496 94 444 55 q 549 200 549 133 q 500 307 549 274 q 353 339 452 339 l 241 339 l 241 410 l 367 410 q 536 540 536 414 m 387 -291 l 306 -291 l 306 33 l 387 33 l 387 -291 z ',
    },
    Ҫ: {
      ha: 902,
      x_min: 89,
      x_max: 824,
      o: 'm 824 308 q 712 69 807 152 q 460 -14 618 -14 q 266 41 351 -14 q 136 197 182 96 q 89 427 90 298 l 89 555 q 135 789 89 687 q 268 946 181 890 q 467 1001 354 1001 q 717 915 625 1001 q 824 678 809 829 l 740 678 q 467 930 709 930 q 253 829 332 930 q 173 552 173 729 l 173 431 q 251 159 173 260 q 460 57 328 57 q 658 120 591 57 q 740 308 724 182 l 824 308 m 501 -292 l 420 -292 l 420 33 l 501 33 l 501 -292 z ',
    },
    ҫ: {
      ha: 715,
      x_min: 64,
      x_max: 659,
      o: 'm 377 55 q 519 103 462 55 q 581 227 576 151 l 659 227 q 617 104 656 159 q 514 18 578 49 q 377 -14 450 -14 q 148 87 233 -14 q 64 357 64 187 l 64 381 q 102 573 64 490 q 211 702 140 656 q 376 747 281 747 q 575 675 497 747 q 659 486 654 604 l 581 486 q 519 625 576 572 q 376 678 463 678 q 206 599 267 678 q 145 376 145 519 l 145 353 q 206 134 145 212 q 377 55 267 55 m 407 -292 l 326 -292 l 326 33 l 407 33 l 407 -292 z ',
    },
    Ү: {
      ha: 831,
      x_min: 16,
      x_max: 812,
      o: 'm 414 449 l 716 987 l 812 987 l 456 372 l 456 0 l 372 0 l 372 372 l 16 987 l 115 987 l 414 449 z ',
    },
    ү: {
      ha: 680,
      x_min: 30,
      x_max: 653,
      o: 'm 569 734 l 653 734 l 382 21 l 382 -281 l 301 -281 l 301 21 l 30 734 l 114 734 l 328 162 l 342 102 l 359 162 l 569 734 z ',
    },
    Ӏ: {
      ha: 370,
      x_min: 143,
      x_max: 227,
      o: 'm 227 0 l 143 0 l 143 987 l 227 987 l 227 0 z ',
    },
    Ӂ: {
      ha: 1222,
      x_min: 16,
      x_max: 1207,
      o: 'm 766 470 l 654 470 l 654 0 l 571 0 l 571 470 l 456 470 l 121 0 l 16 0 l 381 510 l 40 987 l 141 987 l 452 541 l 571 541 l 571 987 l 654 987 l 654 541 l 771 541 l 1082 987 l 1182 987 l 841 511 l 1207 0 l 1102 0 l 766 470 m 806 1230 q 752 1104 806 1152 q 610 1056 699 1056 q 468 1104 522 1056 q 414 1230 414 1152 l 483 1230 q 516 1144 483 1174 q 610 1114 550 1114 q 703 1145 669 1114 q 736 1230 736 1177 l 806 1230 z ',
    },
    ӂ: {
      ha: 1065,
      x_min: 16,
      x_max: 1042,
      o: 'm 667 339 l 569 339 l 569 0 l 488 0 l 488 339 l 391 339 l 119 0 l 16 0 l 320 376 l 50 734 l 149 734 l 389 412 l 488 412 l 488 734 l 569 734 l 569 412 l 668 412 l 909 734 l 1008 734 l 737 376 l 1042 0 l 938 0 l 667 339 m 723 998 q 670 872 723 920 q 527 824 616 824 q 385 872 439 824 q 331 998 331 920 l 400 998 q 434 912 400 942 q 527 882 467 882 q 620 913 587 882 q 654 998 654 945 l 723 998 z ',
    },
    ӏ: {
      ha: 370,
      x_min: 143,
      x_max: 227,
      o: 'm 227 0 l 143 0 l 143 987 l 227 987 l 227 0 z ',
    },
    Ӑ: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 631 1226 q 578 1100 631 1148 q 435 1052 525 1052 q 293 1100 347 1052 q 239 1226 239 1147 l 309 1226 q 342 1140 309 1170 q 435 1110 376 1110 q 529 1141 495 1110 q 562 1226 562 1173 l 631 1226 z ',
    },
    ӑ: {
      ha: 744,
      x_min: 68,
      x_max: 656,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 m 589 1013 q 535 886 589 935 q 393 838 482 838 q 251 886 304 838 q 197 1013 197 934 l 266 1013 q 299 926 266 956 q 393 897 333 897 q 486 928 452 897 q 519 1013 519 959 l 589 1013 z ',
    },
    Ӓ: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 233 1160 q 248 1199 233 1183 q 288 1215 262 1215 q 329 1199 314 1215 q 345 1160 345 1183 q 329 1122 345 1138 q 288 1107 314 1107 q 248 1122 262 1107 q 233 1160 233 1138 m 530 1160 q 545 1199 530 1183 q 585 1215 559 1215 q 626 1199 611 1215 q 642 1160 642 1183 q 626 1122 642 1137 q 585 1106 611 1106 q 545 1122 559 1106 q 530 1160 530 1137 z ',
    },
    ӓ: {
      ha: 744,
      x_min: 68,
      x_max: 656,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 m 190 947 q 205 986 190 970 q 245 1002 220 1002 q 287 986 271 1002 q 302 947 302 970 q 287 909 302 924 q 245 893 271 893 q 205 909 220 893 q 190 947 190 924 m 487 946 q 502 985 487 969 q 543 1001 517 1001 q 584 985 568 1001 q 599 946 599 969 q 584 908 599 924 q 543 892 568 892 q 502 908 517 892 q 487 946 487 924 z ',
    },
    Ӕ: {
      ha: 1265,
      x_min: 12,
      x_max: 1236,
      o: 'm 1236 0 l 682 0 l 671 264 l 267 264 l 111 0 l 12 0 l 609 987 l 1195 987 l 1195 918 l 724 918 l 740 549 l 1139 549 l 1139 479 l 743 479 l 760 68 l 1236 68 l 1236 0 m 312 340 l 668 340 l 644 903 l 312 340 z ',
    },
    ӕ: {
      ha: 1175,
      x_min: 58,
      x_max: 1124,
      o: 'm 858 -14 q 696 23 764 -14 q 588 126 629 60 q 470 23 547 60 q 298 -14 393 -14 q 121 44 184 -14 q 58 199 58 101 q 133 366 58 305 q 347 429 208 427 l 530 429 l 530 488 q 487 628 530 578 q 358 678 444 678 q 214 634 270 678 q 157 519 157 590 l 77 528 q 156 686 77 625 q 358 747 235 747 q 503 711 443 747 q 590 603 563 675 q 695 709 631 671 q 838 747 759 747 q 1047 666 970 747 q 1124 437 1123 584 l 1124 365 l 611 365 l 611 345 q 676 132 611 209 q 858 55 741 55 q 1078 135 983 55 l 1109 76 q 858 -14 1011 -14 m 311 54 q 436 85 372 54 q 530 160 499 117 l 530 363 l 356 363 q 205 322 263 363 q 140 210 146 281 l 140 196 q 184 92 140 130 q 311 54 229 54 m 838 678 q 687 612 748 678 q 613 431 626 545 l 1044 431 l 1044 452 q 989 617 1044 557 q 838 678 935 678 z ',
    },
    Ӗ: {
      ha: 790,
      x_min: 125,
      x_max: 739,
      o: 'm 670 473 l 208 473 l 208 71 l 739 71 l 739 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 544 l 670 544 l 670 473 m 616 1230 q 563 1104 616 1152 q 420 1056 509 1056 q 278 1104 332 1056 q 224 1230 224 1152 l 293 1230 q 327 1144 293 1174 q 420 1114 360 1114 q 513 1145 479 1114 q 547 1230 547 1177 l 616 1230 z ',
    },
    ӗ: {
      ha: 718,
      x_min: 61,
      x_max: 665,
      o: 'm 390 -14 q 221 33 296 -14 q 103 161 145 79 q 61 346 61 243 l 61 375 q 102 566 61 481 q 216 699 143 650 q 375 747 290 747 q 587 656 509 747 q 665 407 665 565 l 665 361 l 142 361 l 142 346 q 213 138 142 221 q 393 55 285 55 q 508 79 458 55 q 599 155 558 102 l 650 116 q 390 -14 560 -14 m 375 678 q 221 611 283 678 q 144 431 158 544 l 585 431 l 585 439 q 524 612 581 545 q 375 678 467 678 m 580 1012 q 527 886 580 934 q 384 838 473 838 q 242 885 296 838 q 188 1012 188 933 l 257 1012 q 291 926 257 956 q 384 896 324 896 q 477 927 444 896 q 511 1012 511 958 l 580 1012 z ',
    },
    Ӛ: {
      ha: 972,
      x_min: 84,
      x_max: 890,
      o: 'm 464 1001 q 776 878 662 1001 q 890 539 890 755 l 890 455 q 838 214 890 321 q 694 46 786 106 q 492 -14 602 -14 q 193 101 302 -14 q 84 420 84 216 l 84 500 l 806 500 l 806 546 q 716 828 806 726 q 464 929 627 929 q 186 848 304 929 l 159 910 q 464 1001 276 1001 m 492 58 q 712 160 626 58 q 806 432 798 263 l 168 432 l 168 396 q 254 148 168 239 q 492 58 339 58 m 247 1133 q 262 1172 247 1156 q 302 1188 277 1188 q 343 1172 328 1188 q 359 1133 359 1156 q 343 1095 359 1111 q 302 1080 328 1080 q 262 1095 277 1080 q 247 1133 247 1111 m 544 1133 q 559 1172 544 1156 q 600 1187 574 1187 q 641 1172 625 1187 q 656 1133 656 1156 q 641 1095 656 1110 q 600 1079 625 1079 q 559 1095 574 1079 q 544 1133 544 1110 z ',
    },
    ә: {
      ha: 715,
      x_min: 62,
      x_max: 662,
      o: 'm 342 747 q 505 701 431 747 q 620 572 578 655 q 662 389 662 489 l 662 351 q 621 165 662 249 q 509 34 580 81 q 353 -14 437 -14 q 140 71 218 -14 q 62 302 62 155 l 62 368 l 581 368 l 581 388 q 514 596 581 513 q 342 679 448 679 q 210 656 262 679 q 121 597 157 634 l 87 653 q 342 747 178 747 m 353 55 q 502 123 439 55 q 578 299 565 191 l 144 299 l 144 279 q 201 118 144 180 q 353 55 258 55 z ',
    },
    ӛ: {
      ha: 715,
      x_min: 62,
      x_max: 662,
      o: 'm 342 747 q 505 701 431 747 q 620 572 578 655 q 662 389 662 489 l 662 351 q 621 165 662 249 q 509 34 580 81 q 353 -14 437 -14 q 140 71 218 -14 q 62 302 62 155 l 62 368 l 581 368 l 581 388 q 514 596 581 513 q 342 679 448 679 q 210 656 262 679 q 121 597 157 634 l 87 653 q 342 747 178 747 m 353 55 q 502 123 439 55 q 578 299 565 191 l 144 299 l 144 279 q 201 118 144 180 q 353 55 258 55 m 178 947 q 193 986 178 970 q 234 1002 208 1002 q 275 986 260 1002 q 290 947 290 970 q 275 909 290 924 q 234 893 260 893 q 193 909 208 893 q 178 947 178 924 m 475 946 q 490 985 475 969 q 531 1001 505 1001 q 572 985 557 1001 q 587 946 587 969 q 572 908 587 924 q 531 892 557 892 q 490 908 505 892 q 475 946 475 924 z ',
    },
    Ӝ: {
      ha: 1222,
      x_min: 16,
      x_max: 1207,
      o: 'm 766 470 l 654 470 l 654 0 l 571 0 l 571 470 l 456 470 l 121 0 l 16 0 l 381 510 l 40 987 l 141 987 l 452 541 l 571 541 l 571 987 l 654 987 l 654 541 l 771 541 l 1082 987 l 1182 987 l 841 511 l 1207 0 l 1102 0 l 766 470 m 407 1164 q 422 1203 407 1187 q 463 1219 437 1219 q 504 1203 488 1219 q 519 1164 519 1187 q 504 1126 519 1142 q 463 1111 488 1111 q 422 1126 437 1111 q 407 1164 407 1142 m 704 1164 q 719 1203 704 1187 q 760 1219 734 1219 q 801 1203 785 1219 q 816 1164 816 1187 q 801 1126 816 1141 q 760 1110 785 1110 q 719 1126 734 1110 q 704 1164 704 1141 z ',
    },
    ӝ: {
      ha: 1065,
      x_min: 16,
      x_max: 1042,
      o: 'm 667 339 l 569 339 l 569 0 l 488 0 l 488 339 l 391 339 l 119 0 l 16 0 l 320 376 l 50 734 l 149 734 l 389 412 l 488 412 l 488 734 l 569 734 l 569 412 l 668 412 l 909 734 l 1008 734 l 737 376 l 1042 0 l 938 0 l 667 339 m 324 932 q 339 971 324 956 q 380 987 354 987 q 421 971 406 987 q 436 932 436 956 q 421 895 436 910 q 380 879 406 879 q 339 895 354 879 q 324 932 324 910 m 621 932 q 636 971 621 955 q 677 987 651 987 q 718 971 703 987 q 733 932 733 955 q 718 894 733 909 q 677 878 703 878 q 636 894 651 878 q 621 932 621 909 z ',
    },
    Ӟ: {
      ha: 826,
      x_min: 61,
      x_max: 762,
      o: 'm 664 730 q 597 876 664 823 q 406 930 530 930 q 229 873 298 930 q 159 728 159 816 l 75 728 q 118 869 75 807 q 238 966 161 931 q 406 1001 315 1001 q 656 930 566 1001 q 747 728 747 859 q 699 593 747 652 q 570 506 651 534 q 762 268 762 454 q 666 63 762 140 q 406 -14 569 -14 q 225 24 307 -14 q 102 126 144 62 q 61 274 61 190 l 144 274 q 218 118 144 179 q 406 56 292 56 q 606 113 534 56 q 679 265 679 170 q 614 416 679 366 q 416 467 550 466 l 290 467 l 290 539 l 412 539 q 600 588 535 540 q 664 730 664 635 m 215 1179 q 230 1218 215 1202 q 271 1234 245 1234 q 312 1218 296 1234 q 327 1179 327 1202 q 312 1141 327 1156 q 271 1125 296 1125 q 230 1141 245 1125 q 215 1179 215 1156 m 512 1178 q 527 1217 512 1201 q 568 1233 542 1233 q 609 1217 593 1233 q 624 1178 624 1201 q 609 1140 624 1156 q 568 1124 593 1124 q 527 1140 542 1124 q 512 1178 512 1156 z ',
    },
    ӟ: {
      ha: 703,
      x_min: 66,
      x_max: 631,
      o: 'm 536 540 q 486 642 536 606 q 350 678 437 678 q 215 638 271 678 q 159 536 159 597 l 79 536 q 157 687 79 627 q 350 747 235 747 q 547 692 476 747 q 617 538 617 638 q 585 443 617 486 q 490 376 552 400 q 631 201 631 338 q 554 45 631 102 q 351 -13 477 -13 q 147 49 228 -13 q 66 210 66 111 l 148 210 q 207 99 148 144 q 351 55 265 55 q 496 94 444 55 q 549 200 549 133 q 500 307 549 274 q 353 339 452 339 l 241 339 l 241 410 l 367 410 q 536 540 536 414 m 143 946 q 158 985 143 969 q 199 1001 173 1001 q 240 985 224 1001 q 255 946 255 969 q 240 908 255 924 q 199 892 224 892 q 158 908 173 892 q 143 946 143 924 m 440 945 q 455 984 440 968 q 496 1000 470 1000 q 537 984 522 1000 q 552 945 552 968 q 537 907 552 923 q 496 892 522 892 q 455 907 470 892 q 440 945 440 923 z ',
    },
    Ӣ: {
      ha: 986,
      x_min: 127,
      x_max: 857,
      o: 'm 774 987 l 857 987 l 857 0 l 774 0 l 774 845 l 212 0 l 127 0 l 127 987 l 212 987 l 212 143 l 774 987 m 710 1135 l 290 1135 l 290 1200 l 710 1200 l 710 1135 z ',
    },
    ӣ: {
      ha: 781,
      x_min: 111,
      x_max: 669,
      o: 'm 588 734 l 669 734 l 669 0 l 588 0 l 588 603 l 192 0 l 111 0 l 111 734 l 192 734 l 192 130 l 588 734 m 600 903 l 180 903 l 180 968 l 600 968 l 600 903 z ',
    },
    Ӥ: {
      ha: 986,
      x_min: 127,
      x_max: 857,
      o: 'm 774 987 l 857 987 l 857 0 l 774 0 l 774 845 l 212 0 l 127 0 l 127 987 l 212 987 l 212 143 l 774 987 m 295 1164 q 310 1203 295 1187 q 351 1219 325 1219 q 392 1203 376 1219 q 407 1164 407 1187 q 392 1126 407 1142 q 351 1111 376 1111 q 310 1126 325 1111 q 295 1164 295 1142 m 592 1164 q 607 1203 592 1187 q 648 1219 622 1219 q 689 1203 673 1219 q 704 1164 704 1187 q 689 1126 704 1141 q 648 1110 673 1110 q 607 1126 622 1110 q 592 1164 592 1141 z ',
    },
    ӥ: {
      ha: 781,
      x_min: 111,
      x_max: 669,
      o: 'm 588 734 l 669 734 l 669 0 l 588 0 l 588 603 l 192 0 l 111 0 l 111 734 l 192 734 l 192 130 l 588 734 m 185 932 q 200 971 185 956 q 241 987 215 987 q 282 971 267 987 q 297 932 297 956 q 282 895 297 910 q 241 879 267 879 q 200 895 215 879 q 185 932 185 910 m 482 932 q 497 971 482 955 q 538 987 512 987 q 579 971 564 987 q 594 932 594 955 q 579 894 594 909 q 538 878 564 878 q 497 894 512 878 q 482 932 482 909 z ',
    },
    Ӧ: {
      ha: 940,
      x_min: 85,
      x_max: 854,
      o: 'm 854 440 q 807 201 854 304 q 673 42 760 98 q 471 -14 585 -14 q 191 110 298 -14 q 85 444 85 234 l 85 546 q 133 785 85 681 q 268 945 180 889 q 469 1001 355 1001 q 670 946 583 1001 q 805 791 758 891 q 854 558 852 690 l 854 440 m 771 547 q 690 827 771 726 q 469 928 610 928 q 250 826 332 928 q 168 543 168 725 l 168 440 q 250 162 168 264 q 471 59 331 59 q 692 160 612 59 q 771 443 771 262 l 771 547 m 266 1168 q 281 1207 266 1192 q 321 1223 296 1223 q 362 1207 347 1223 q 378 1168 378 1192 q 362 1131 378 1146 q 321 1115 347 1115 q 281 1131 296 1115 q 266 1168 266 1146 m 563 1168 q 578 1207 563 1191 q 618 1223 593 1223 q 660 1207 644 1223 q 675 1168 675 1191 q 660 1130 675 1145 q 618 1114 644 1114 q 578 1130 593 1114 q 563 1168 563 1145 z ',
    },
    ӧ: {
      ha: 778,
      x_min: 61,
      x_max: 716,
      o: 'm 61 378 q 102 568 61 484 q 218 700 143 653 q 388 747 293 747 q 625 645 534 747 q 716 372 716 542 l 716 355 q 675 164 716 249 q 559 33 634 79 q 389 -14 485 -14 q 152 89 243 -14 q 61 361 61 192 l 61 378 m 142 355 q 211 139 142 224 q 389 55 279 55 q 567 139 499 55 q 635 362 635 224 l 635 378 q 604 532 635 462 q 517 640 573 602 q 388 678 460 678 q 211 593 279 678 q 142 370 142 508 l 142 355 m 184 947 q 199 986 184 970 q 240 1002 214 1002 q 281 986 266 1002 q 296 947 296 970 q 281 909 296 924 q 240 893 266 893 q 199 909 214 893 q 184 947 184 924 m 481 946 q 496 985 481 969 q 537 1001 511 1001 q 578 985 563 1001 q 593 946 593 969 q 578 908 593 924 q 537 892 563 892 q 496 908 511 892 q 481 946 481 924 z ',
    },
    Ө: {
      ha: 935,
      x_min: 80,
      x_max: 849,
      o: 'm 849 440 q 802 201 849 304 q 667 42 755 98 q 465 -14 580 -14 q 265 42 353 -14 q 129 200 177 98 q 80 435 81 302 l 80 546 q 128 785 80 681 q 263 945 176 889 q 464 1001 351 1001 q 665 946 578 1001 q 800 791 752 891 q 849 558 847 690 l 849 440 m 163 525 l 766 525 l 766 547 q 686 827 766 726 q 464 928 605 928 q 245 826 327 928 q 163 543 163 725 l 163 525 m 766 456 l 163 456 l 163 440 q 244 162 163 264 q 465 59 326 59 q 686 160 606 59 q 766 442 766 261 l 766 456 z ',
    },
    ө: {
      ha: 785,
      x_min: 60,
      x_max: 715,
      o: 'm 60 378 q 101 568 60 484 q 217 700 142 653 q 387 747 292 747 q 624 644 533 747 q 715 372 715 541 l 715 355 q 674 164 715 250 q 558 33 633 79 q 389 -14 484 -14 q 151 90 241 -14 q 60 361 60 193 l 60 378 m 633 334 l 142 334 q 215 132 148 210 q 389 55 281 55 q 562 134 496 55 q 633 334 627 212 m 387 678 q 215 601 283 678 q 142 402 148 523 l 633 402 q 558 601 626 524 q 387 678 491 678 z ',
    },
    Ӫ: {
      ha: 935,
      x_min: 80,
      x_max: 849,
      o: 'm 849 440 q 802 201 849 304 q 667 42 755 98 q 465 -14 580 -14 q 265 42 353 -14 q 129 200 177 98 q 80 435 81 302 l 80 546 q 128 785 80 681 q 263 945 176 889 q 464 1001 351 1001 q 665 946 578 1001 q 800 791 752 891 q 849 558 847 690 l 849 440 m 163 525 l 766 525 l 766 547 q 686 827 766 726 q 464 928 605 928 q 245 826 327 928 q 163 543 163 725 l 163 525 m 766 456 l 163 456 l 163 440 q 244 162 163 264 q 465 59 326 59 q 686 160 606 59 q 766 442 766 261 l 766 456 m 281 1161 q 296 1200 281 1184 q 337 1216 311 1216 q 378 1200 363 1216 q 393 1161 393 1184 q 378 1123 393 1139 q 337 1107 363 1107 q 296 1123 311 1107 q 281 1161 281 1139 m 578 1160 q 593 1199 578 1183 q 634 1215 608 1215 q 675 1199 660 1215 q 690 1160 690 1183 q 675 1122 690 1138 q 634 1107 660 1107 q 593 1122 608 1107 q 578 1160 578 1138 z ',
    },
    ӫ: {
      ha: 785,
      x_min: 60,
      x_max: 715,
      o: 'm 60 378 q 101 568 60 484 q 217 700 142 653 q 387 747 292 747 q 624 644 533 747 q 715 372 715 541 l 715 355 q 674 164 715 250 q 558 33 633 79 q 389 -14 484 -14 q 151 90 241 -14 q 60 361 60 193 l 60 378 m 633 334 l 142 334 q 215 132 148 210 q 389 55 281 55 q 562 134 496 55 q 633 334 627 212 m 387 678 q 215 601 283 678 q 142 402 148 523 l 633 402 q 558 601 626 524 q 387 678 491 678 m 176 956 q 191 995 176 979 q 232 1011 206 1011 q 273 995 258 1011 q 288 956 288 979 q 273 918 288 934 q 232 903 258 903 q 191 918 206 903 q 176 956 176 934 m 473 956 q 488 995 473 979 q 529 1010 503 1010 q 570 995 555 1010 q 585 956 585 979 q 570 918 585 933 q 529 902 555 902 q 488 918 503 902 q 473 956 473 933 z ',
    },
    Ӭ: {
      ha: 917,
      x_min: 96,
      x_max: 830,
      o: 'm 179 308 q 263 118 196 178 q 458 57 330 57 q 669 160 592 57 q 747 436 747 263 l 747 460 l 334 460 l 334 532 l 747 532 l 747 556 q 667 829 747 728 q 453 930 588 930 q 179 678 210 930 l 96 678 q 203 916 111 831 q 453 1001 296 1001 q 727 879 623 1001 q 830 549 830 758 l 830 431 q 784 198 830 300 q 653 41 737 96 q 458 -14 568 -14 q 205 70 300 -14 q 96 308 111 153 l 179 308 m 267 1179 q 281 1218 267 1202 q 322 1234 296 1234 q 363 1218 348 1234 q 378 1179 378 1202 q 363 1141 378 1157 q 322 1126 348 1126 q 281 1141 296 1126 q 267 1179 267 1157 m 564 1179 q 578 1218 564 1202 q 619 1234 593 1234 q 660 1218 645 1234 q 675 1179 675 1202 q 660 1141 675 1156 q 619 1125 645 1125 q 578 1141 593 1125 q 564 1179 564 1156 z ',
    },
    ӭ: {
      ha: 743,
      x_min: 75,
      x_max: 664,
      o: 'm 351 678 q 210 630 267 678 q 154 505 154 581 l 75 505 q 112 627 75 571 q 214 715 149 682 q 351 747 278 747 q 571 653 486 747 q 663 405 656 558 l 664 353 q 625 162 664 247 q 515 32 585 78 q 351 -14 444 -14 q 153 59 231 -14 q 75 248 75 132 l 154 248 q 210 108 154 161 q 351 55 266 55 q 515 131 453 55 q 582 336 577 207 l 250 336 l 250 405 l 582 405 q 514 604 576 529 q 351 678 453 678 m 168 947 q 183 986 168 970 q 224 1002 198 1002 q 265 986 250 1002 q 280 947 280 970 q 265 909 280 924 q 224 893 250 893 q 183 909 198 893 q 168 947 168 924 m 465 946 q 480 985 465 969 q 521 1001 495 1001 q 562 985 547 1001 q 577 946 577 969 q 562 908 577 924 q 521 892 547 892 q 480 908 495 892 q 465 946 465 924 z ',
    },
    Ӯ: {
      ha: 871,
      x_min: 37,
      x_max: 833,
      o: 'm 454 355 l 735 987 l 833 987 l 452 161 l 427 114 q 216 -14 355 -14 q 152 -7 174 -14 l 159 61 q 216 56 174 56 q 351 142 300 56 l 368 173 l 411 261 l 37 987 l 133 987 l 454 355 m 650 1135 l 231 1135 l 231 1200 l 650 1200 l 650 1135 z ',
    },
    ӯ: {
      ha: 660,
      x_min: 22,
      x_max: 640,
      o: 'm 338 117 l 553 734 l 640 734 l 327 -125 l 311 -163 q 124 -296 250 -296 q 62 -287 95 -296 l 61 -220 l 103 -224 q 200 -194 163 -224 q 262 -93 237 -165 l 298 6 l 22 734 l 111 734 l 338 117 m 560 917 l 140 917 l 140 983 l 560 983 l 560 917 z ',
    },
    Ӱ: {
      ha: 871,
      x_min: 37,
      x_max: 833,
      o: 'm 454 355 l 735 987 l 833 987 l 452 161 l 427 114 q 216 -14 355 -14 q 152 -7 174 -14 l 159 61 q 216 56 174 56 q 351 142 300 56 l 368 173 l 411 261 l 37 987 l 133 987 l 454 355 m 235 1164 q 250 1203 235 1187 q 291 1219 265 1219 q 332 1203 317 1219 q 347 1164 347 1187 q 332 1126 347 1142 q 291 1111 317 1111 q 250 1126 265 1111 q 235 1164 235 1142 m 532 1164 q 547 1203 532 1187 q 588 1219 562 1219 q 629 1203 614 1219 q 644 1164 644 1187 q 629 1126 644 1141 q 588 1110 614 1110 q 547 1126 562 1110 q 532 1164 532 1141 z ',
    },
    ӱ: {
      ha: 660,
      x_min: 22,
      x_max: 640,
      o: 'm 338 117 l 553 734 l 640 734 l 327 -125 l 311 -163 q 124 -296 250 -296 q 62 -287 95 -296 l 61 -220 l 103 -224 q 200 -194 163 -224 q 262 -93 237 -165 l 298 6 l 22 734 l 111 734 l 338 117 m 145 947 q 160 986 145 970 q 201 1002 175 1002 q 242 986 227 1002 q 257 947 257 970 q 242 909 257 924 q 201 893 227 893 q 160 909 175 893 q 145 947 145 924 m 442 946 q 457 985 442 969 q 498 1001 472 1001 q 539 985 524 1001 q 554 946 554 969 q 539 908 554 924 q 498 892 524 892 q 457 908 472 892 q 442 946 442 924 z ',
    },
    Ӳ: {
      ha: 871,
      x_min: 37,
      x_max: 833,
      o: 'm 454 355 l 735 987 l 833 987 l 452 161 l 427 114 q 216 -14 355 -14 q 152 -7 174 -14 l 159 61 q 216 56 174 56 q 351 142 300 56 l 368 173 l 411 261 l 37 987 l 133 987 l 454 355 m 640 1259 l 737 1259 l 548 1053 l 466 1053 l 640 1259 m 429 1259 l 522 1259 l 366 1053 l 292 1053 l 429 1259 z ',
    },
    ӳ: {
      ha: 660,
      x_min: 22,
      x_max: 647,
      o: 'm 338 117 l 553 734 l 640 734 l 327 -125 l 311 -163 q 124 -296 250 -296 q 62 -287 95 -296 l 61 -220 l 103 -224 q 200 -194 163 -224 q 262 -93 237 -165 l 298 6 l 22 734 l 111 734 l 338 117 m 549 1041 l 647 1041 l 458 836 l 376 836 l 549 1041 m 339 1041 l 432 1041 l 275 836 l 201 836 l 339 1041 z ',
    },
    Ӵ: {
      ha: 924,
      x_min: 117,
      x_max: 798,
      o: 'm 798 987 l 798 0 l 714 0 l 714 449 q 447 410 575 410 q 199 483 279 410 q 117 706 119 555 l 117 987 l 201 987 l 201 711 q 260 536 201 591 q 447 481 319 481 q 714 520 576 481 l 714 987 l 798 987 m 264 1164 q 279 1203 264 1187 q 319 1219 294 1219 q 360 1203 345 1219 q 376 1164 376 1187 q 360 1126 376 1142 q 319 1111 345 1111 q 279 1126 294 1111 q 264 1164 264 1142 m 561 1164 q 576 1203 561 1187 q 616 1219 591 1219 q 657 1203 642 1219 q 673 1164 673 1187 q 657 1126 673 1141 q 616 1110 642 1110 q 576 1126 591 1110 q 561 1164 561 1141 z ',
    },
    ӵ: {
      ha: 739,
      x_min: 75,
      x_max: 628,
      o: 'm 628 0 l 547 0 l 547 309 q 355 272 446 272 q 143 336 209 272 q 75 536 77 400 l 75 734 l 155 734 l 155 540 q 202 388 157 435 q 355 342 247 342 q 547 377 453 345 l 547 734 l 628 734 l 628 0 m 143 932 q 158 971 143 956 q 199 987 173 987 q 240 971 224 987 q 255 932 255 956 q 240 895 255 910 q 199 879 224 879 q 158 895 173 879 q 143 932 143 910 m 440 932 q 455 971 440 955 q 496 987 470 987 q 537 971 522 987 q 552 932 552 955 q 537 894 552 909 q 496 878 522 878 q 455 894 470 878 q 440 932 440 909 z ',
    },
    Ӹ: {
      ha: 1160,
      x_min: 126,
      x_max: 1027,
      o: 'm 210 559 l 454 559 q 629 525 555 559 q 742 429 703 492 q 781 281 781 366 q 694 75 781 150 q 452 0 606 0 l 126 0 l 126 987 l 210 987 l 210 559 m 210 488 l 210 71 l 454 71 q 633 127 568 71 q 697 282 697 184 q 635 431 697 376 q 460 488 572 486 l 210 488 m 1027 0 l 944 0 l 944 987 l 1027 987 l 1027 0 m 396 1164 q 411 1203 396 1187 q 452 1219 426 1219 q 493 1203 477 1219 q 508 1164 508 1187 q 493 1126 508 1142 q 452 1111 477 1111 q 411 1126 426 1111 q 396 1164 396 1142 m 693 1164 q 708 1203 693 1187 q 749 1219 723 1219 q 790 1203 774 1219 q 805 1164 805 1187 q 790 1126 805 1141 q 749 1110 774 1110 q 708 1126 723 1110 q 693 1164 693 1141 z ',
    },
    ӹ: {
      ha: 1043,
      x_min: 115,
      x_max: 923,
      o: 'm 195 467 l 407 467 q 550 438 488 467 q 643 356 611 408 q 674 235 674 304 q 601 64 674 128 q 404 0 528 0 l 115 0 l 115 734 l 195 734 l 195 467 m 195 398 l 195 68 l 406 68 q 543 113 493 68 q 593 233 593 157 q 544 352 593 307 q 412 398 496 397 l 195 398 m 923 0 l 842 0 l 842 734 l 923 734 l 923 0 m 337 932 q 352 971 337 956 q 393 987 367 987 q 434 971 418 987 q 449 932 449 956 q 434 895 449 910 q 393 879 418 879 q 352 895 367 879 q 337 932 337 910 m 634 932 q 649 971 634 955 q 690 987 664 987 q 731 971 715 987 q 746 932 746 955 q 731 894 746 909 q 690 878 715 878 q 649 894 664 878 q 634 932 634 909 z ',
    },
    ԁ: {
      ha: 772,
      x_min: 73,
      x_max: 664,
      o: 'm 73 374 q 150 646 73 545 q 357 747 226 747 q 583 630 505 747 l 583 1042 l 664 1042 l 664 0 l 588 0 l 585 98 q 355 -14 507 -14 q 151 89 229 -14 q 73 364 73 191 l 73 374 m 155 359 q 212 138 155 219 q 370 57 268 57 q 583 189 520 57 l 583 534 q 372 677 520 677 q 212 596 269 677 q 155 359 155 516 z ',
    },
    Ạ: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 389 -157 q 404 -118 389 -134 q 444 -102 418 -102 q 486 -118 471 -102 q 500 -157 500 -134 q 486 -195 500 -179 q 444 -210 471 -210 q 404 -195 418 -210 q 389 -157 389 -179 z ',
    },
    ạ: {
      ha: 744,
      x_min: 68,
      x_max: 656,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 m 282 -157 q 297 -118 282 -134 q 338 -102 312 -102 q 379 -118 364 -102 q 394 -157 394 -134 q 379 -195 394 -179 q 338 -210 364 -210 q 297 -195 312 -210 q 282 -157 282 -179 z ',
    },
    Ả: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 406 1070 l 406 1155 q 492 1173 465 1160 q 519 1219 519 1186 q 483 1266 519 1249 q 383 1283 446 1283 l 388 1335 q 534 1303 481 1335 q 587 1218 587 1272 q 556 1145 587 1173 q 468 1112 526 1117 l 467 1070 l 406 1070 z ',
    },
    ả: {
      ha: 744,
      x_min: 68,
      x_max: 656,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 m 363 857 l 363 941 q 450 959 422 946 q 477 1005 477 972 q 440 1053 477 1036 q 340 1069 403 1069 l 345 1122 q 491 1090 438 1122 q 545 1004 545 1058 q 514 931 545 960 q 425 899 483 903 l 425 857 l 363 857 z ',
    },
    Ấ: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 395 1213 l 473 1213 l 656 1049 l 561 1049 l 434 1166 l 307 1049 l 212 1049 l 395 1213 m 713 1319 l 806 1319 l 683 1162 l 614 1162 l 713 1319 z ',
    },
    ấ: {
      ha: 744,
      x_min: 68,
      x_max: 764,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 m 352 1000 l 431 1000 l 613 836 l 518 836 l 391 954 l 264 836 l 169 836 l 352 1000 m 671 1106 l 764 1106 l 640 949 l 571 949 l 671 1106 z ',
    },
    Ầ: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 655 1048 l 559 1048 l 433 1165 l 306 1048 l 211 1048 l 393 1212 l 472 1212 l 655 1048 m 253 1161 l 184 1161 l 62 1318 l 153 1318 l 253 1161 z ',
    },
    ầ: {
      ha: 744,
      x_min: 19,
      x_max: 656,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 m 612 834 l 517 834 l 390 951 l 263 834 l 168 834 l 351 998 l 429 998 l 612 834 m 210 947 l 142 947 l 19 1104 l 111 1104 l 210 947 z ',
    },
    Ẩ: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 658 1061 l 572 1061 l 435 1187 l 300 1061 l 214 1061 l 390 1225 l 481 1225 l 658 1061 m 628 1146 l 627 1222 q 699 1235 678 1225 q 720 1271 720 1245 q 609 1323 720 1323 l 612 1373 q 737 1346 691 1373 q 783 1273 783 1318 q 682 1181 783 1192 l 682 1146 l 628 1146 z ',
    },
    ẩ: {
      ha: 744,
      x_min: 68,
      x_max: 741,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 m 615 848 l 529 848 l 393 974 l 257 848 l 171 848 l 347 1012 l 438 1012 l 615 848 m 585 933 l 585 1009 q 656 1022 635 1013 q 677 1058 677 1032 q 566 1110 677 1110 l 570 1160 q 694 1133 648 1160 q 741 1060 741 1105 q 640 968 741 979 l 640 933 l 585 933 z ',
    },
    Ẫ: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 656 1044 l 570 1044 l 433 1160 l 298 1044 l 212 1044 l 401 1202 l 466 1202 l 656 1044 m 610 1343 q 581 1273 610 1303 q 511 1243 553 1243 q 474 1250 490 1243 q 433 1275 458 1256 q 396 1298 408 1294 q 370 1303 385 1303 q 331 1284 347 1303 q 315 1239 315 1265 l 272 1248 q 301 1324 272 1292 q 370 1356 331 1356 q 444 1326 403 1356 q 511 1296 484 1296 q 550 1312 534 1296 q 566 1354 566 1328 l 610 1343 z ',
    },
    ẫ: {
      ha: 744,
      x_min: 68,
      x_max: 656,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 m 613 831 l 527 831 l 391 947 l 255 831 l 169 831 l 359 989 l 423 989 l 613 831 m 567 1131 q 538 1060 567 1090 q 469 1030 510 1030 q 431 1037 447 1030 q 390 1062 415 1043 q 353 1085 365 1081 q 327 1090 342 1090 q 289 1071 304 1090 q 273 1026 273 1053 l 229 1035 q 259 1111 229 1079 q 327 1143 288 1143 q 401 1113 360 1143 q 469 1083 441 1083 q 508 1099 492 1083 q 524 1141 524 1115 l 567 1131 z ',
    },
    Ậ: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 625 1074 l 625 1067 l 549 1067 l 435 1191 l 322 1067 l 248 1067 l 248 1076 l 410 1249 l 461 1249 l 625 1074 m 389 -157 q 404 -118 389 -134 q 444 -102 418 -102 q 486 -118 471 -102 q 500 -157 500 -134 q 486 -195 500 -179 q 444 -210 471 -210 q 404 -195 418 -210 q 389 -157 389 -179 z ',
    },
    ậ: {
      ha: 744,
      x_min: 68,
      x_max: 656,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 m 582 860 l 582 853 l 507 853 l 393 977 l 279 853 l 205 853 l 205 862 l 367 1035 l 418 1035 l 582 860 m 282 -157 q 297 -118 282 -134 q 338 -102 312 -102 q 379 -118 364 -102 q 394 -157 394 -134 q 379 -195 394 -179 q 338 -210 364 -210 q 297 -195 312 -210 q 282 -157 282 -179 z ',
    },
    Ắ: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 629 1205 q 574 1102 629 1141 q 431 1063 519 1063 q 287 1102 341 1063 q 232 1205 232 1141 l 302 1205 q 336 1135 302 1159 q 431 1110 370 1110 q 525 1136 491 1110 q 558 1205 558 1162 l 629 1205 m 484 1340 l 557 1340 l 442 1200 l 389 1200 l 484 1340 z ',
    },
    ắ: {
      ha: 744,
      x_min: 68,
      x_max: 656,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 m 586 991 q 531 888 586 927 q 388 850 477 850 q 244 888 298 850 q 189 991 189 927 l 259 991 q 293 921 259 945 q 388 897 328 897 q 482 922 448 897 q 515 991 515 948 l 586 991 m 441 1126 l 515 1126 l 399 987 l 346 987 l 441 1126 z ',
    },
    Ằ: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 629 1205 q 574 1102 629 1141 q 431 1063 519 1063 q 287 1102 341 1063 q 232 1205 232 1141 l 302 1205 q 336 1135 302 1159 q 431 1110 370 1110 q 525 1136 491 1110 q 558 1205 558 1162 l 629 1205 m 305 1340 l 379 1340 l 473 1200 l 420 1200 l 305 1340 z ',
    },
    ằ: {
      ha: 744,
      x_min: 68,
      x_max: 656,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 m 586 991 q 531 888 586 927 q 388 850 477 850 q 244 888 298 850 q 189 991 189 927 l 259 991 q 293 921 259 945 q 388 897 328 897 q 482 922 448 897 q 515 991 515 948 l 586 991 m 262 1126 l 336 1126 l 431 987 l 378 987 l 262 1126 z ',
    },
    Ẳ: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 618 1206 q 567 1105 618 1144 q 427 1067 515 1067 q 287 1105 338 1067 q 235 1206 235 1144 l 302 1206 q 335 1138 302 1162 q 427 1114 368 1114 q 518 1138 486 1114 q 550 1206 550 1163 l 618 1206 m 401 1206 l 400 1278 q 484 1293 458 1282 q 509 1332 509 1304 q 474 1371 509 1357 q 378 1385 439 1385 l 383 1426 q 524 1400 473 1426 q 575 1331 575 1375 q 546 1271 575 1294 q 460 1243 516 1248 l 460 1206 l 401 1206 z ',
    },
    ẳ: {
      ha: 744,
      x_min: 68,
      x_max: 656,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 m 576 993 q 524 892 576 930 q 384 853 472 853 q 244 892 296 853 q 192 993 192 930 l 259 993 q 292 924 259 948 q 384 900 325 900 q 475 925 443 900 q 507 993 507 949 l 576 993 m 358 992 l 357 1065 q 441 1079 415 1068 q 467 1118 467 1090 q 432 1157 467 1143 q 336 1171 397 1171 l 340 1213 q 481 1187 431 1213 q 532 1117 532 1161 q 503 1057 532 1080 q 417 1029 473 1034 l 417 992 l 358 992 z ',
    },
    Ẵ: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 620 1204 q 569 1100 620 1141 q 431 1060 517 1060 q 293 1100 345 1060 q 242 1204 242 1141 l 311 1204 q 342 1134 311 1159 q 431 1108 374 1108 q 519 1134 488 1108 q 550 1204 550 1159 l 620 1204 m 617 1372 q 585 1293 617 1324 q 508 1263 553 1263 q 470 1269 486 1263 q 427 1293 454 1274 q 387 1315 399 1311 q 361 1318 376 1318 q 316 1301 335 1318 q 298 1255 298 1284 l 253 1265 q 284 1343 253 1312 q 361 1374 315 1374 q 433 1349 394 1374 q 483 1320 471 1324 q 508 1317 494 1317 q 553 1336 534 1317 q 572 1383 572 1354 l 617 1372 z ',
    },
    ẵ: {
      ha: 744,
      x_min: 68,
      x_max: 656,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 m 577 991 q 526 887 577 927 q 389 846 475 846 q 251 887 302 846 q 199 991 199 927 l 269 991 q 300 920 269 945 q 389 895 331 895 q 476 920 445 895 q 507 991 507 945 l 577 991 m 574 1158 q 542 1080 574 1110 q 465 1049 510 1049 q 428 1055 444 1049 q 384 1079 412 1061 q 345 1101 356 1097 q 319 1105 333 1105 q 274 1087 292 1105 q 255 1042 255 1070 l 210 1052 q 241 1129 210 1098 q 319 1160 273 1160 q 390 1135 351 1160 q 440 1107 429 1110 q 465 1103 452 1103 q 511 1122 492 1103 q 530 1170 530 1141 l 574 1158 z ',
    },
    Ặ: {
      ha: 867,
      x_min: 20,
      x_max: 847,
      o: 'm 658 277 l 210 277 l 108 0 l 20 0 l 394 987 l 473 987 l 847 0 l 760 0 l 658 277 m 235 348 l 631 348 l 433 886 l 235 348 m 631 1226 q 578 1100 631 1148 q 435 1052 525 1052 q 293 1100 347 1052 q 239 1226 239 1147 l 309 1226 q 342 1140 309 1170 q 435 1110 376 1110 q 529 1141 495 1110 q 562 1226 562 1173 l 631 1226 m 389 -157 q 404 -118 389 -134 q 444 -102 418 -102 q 486 -118 471 -102 q 500 -157 500 -134 q 486 -195 500 -179 q 444 -210 471 -210 q 404 -195 418 -210 q 389 -157 389 -179 z ',
    },
    ặ: {
      ha: 744,
      x_min: 68,
      x_max: 656,
      o: 'm 569 0 q 553 102 557 35 q 445 17 511 47 q 304 -14 378 -14 q 133 45 199 -14 q 68 195 68 104 q 157 364 68 302 q 406 427 246 427 l 553 427 l 553 510 q 504 634 553 589 q 363 679 456 679 q 222 635 278 679 q 167 531 167 592 l 85 532 q 167 683 85 619 q 367 747 248 747 q 561 686 490 747 q 633 515 631 625 l 633 168 q 656 8 633 61 l 656 0 l 569 0 m 314 58 q 459 98 395 58 q 553 203 524 137 l 553 364 l 408 364 q 218 320 286 363 q 149 201 149 277 q 195 99 149 140 q 314 58 241 58 m 589 1013 q 535 886 589 935 q 393 838 482 838 q 251 886 304 838 q 197 1013 197 934 l 266 1013 q 299 926 266 956 q 393 897 333 897 q 486 928 452 897 q 519 1013 519 959 l 589 1013 m 282 -157 q 297 -118 282 -134 q 338 -102 312 -102 q 379 -118 364 -102 q 394 -157 394 -134 q 379 -195 394 -179 q 338 -210 364 -210 q 297 -195 312 -210 q 282 -157 282 -179 z ',
    },
    Ẹ: {
      ha: 790,
      x_min: 125,
      x_max: 739,
      o: 'm 670 473 l 208 473 l 208 71 l 739 71 l 739 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 544 l 670 544 l 670 473 m 360 -150 q 375 -111 360 -127 q 416 -95 390 -95 q 457 -111 442 -95 q 472 -150 472 -127 q 457 -188 472 -172 q 416 -203 442 -203 q 375 -188 390 -203 q 360 -150 360 -172 z ',
    },
    ẹ: {
      ha: 718,
      x_min: 61,
      x_max: 665,
      o: 'm 390 -14 q 221 33 296 -14 q 103 161 145 79 q 61 346 61 243 l 61 375 q 102 566 61 481 q 216 699 143 650 q 375 747 290 747 q 587 656 509 747 q 665 407 665 565 l 665 361 l 142 361 l 142 346 q 213 138 142 221 q 393 55 285 55 q 508 79 458 55 q 599 155 558 102 l 650 116 q 390 -14 560 -14 m 375 678 q 221 611 283 678 q 144 431 158 544 l 585 431 l 585 439 q 524 612 581 545 q 375 678 467 678 m 324 -155 q 339 -116 324 -132 q 380 -100 354 -100 q 421 -116 406 -100 q 436 -155 436 -132 q 421 -193 436 -178 q 380 -209 406 -209 q 339 -193 354 -209 q 324 -155 324 -178 z ',
    },
    Ẻ: {
      ha: 790,
      x_min: 125,
      x_max: 739,
      o: 'm 670 473 l 208 473 l 208 71 l 739 71 l 739 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 544 l 670 544 l 670 473 m 391 1074 l 391 1159 q 477 1177 450 1164 q 504 1223 504 1190 q 467 1270 504 1253 q 368 1287 430 1287 l 372 1339 q 518 1308 465 1339 q 572 1222 572 1276 q 541 1149 572 1177 q 452 1116 510 1121 l 452 1074 l 391 1074 z ',
    },
    ẻ: {
      ha: 718,
      x_min: 61,
      x_max: 665,
      o: 'm 390 -14 q 221 33 296 -14 q 103 161 145 79 q 61 346 61 243 l 61 375 q 102 566 61 481 q 216 699 143 650 q 375 747 290 747 q 587 656 509 747 q 665 407 665 565 l 665 361 l 142 361 l 142 346 q 213 138 142 221 q 393 55 285 55 q 508 79 458 55 q 599 155 558 102 l 650 116 q 390 -14 560 -14 m 375 678 q 221 611 283 678 q 144 431 158 544 l 585 431 l 585 439 q 524 612 581 545 q 375 678 467 678 m 355 856 l 355 941 q 441 959 414 945 q 468 1004 468 972 q 431 1052 468 1035 q 332 1069 394 1069 l 336 1121 q 483 1089 429 1121 q 536 1004 536 1057 q 505 931 536 959 q 416 898 474 903 l 416 856 l 355 856 z ',
    },
    Ẽ: {
      ha: 790,
      x_min: 125,
      x_max: 739,
      o: 'm 670 473 l 208 473 l 208 71 l 739 71 l 739 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 544 l 670 544 l 670 473 m 651 1231 q 613 1126 651 1166 q 519 1086 574 1086 q 418 1124 467 1086 q 330 1161 369 1161 q 277 1140 298 1161 q 256 1081 256 1119 l 195 1083 q 232 1186 195 1145 q 327 1227 269 1227 q 382 1215 359 1227 q 426 1190 406 1204 q 467 1164 446 1175 q 515 1152 488 1152 q 569 1175 547 1152 q 591 1235 591 1197 l 651 1231 z ',
    },
    ẽ: {
      ha: 718,
      x_min: 61,
      x_max: 665,
      o: 'm 390 -14 q 221 33 296 -14 q 103 161 145 79 q 61 346 61 243 l 61 375 q 102 566 61 481 q 216 699 143 650 q 375 747 290 747 q 587 656 509 747 q 665 407 665 565 l 665 361 l 142 361 l 142 346 q 213 138 142 221 q 393 55 285 55 q 508 79 458 55 q 599 155 558 102 l 650 116 q 390 -14 560 -14 m 375 678 q 221 611 283 678 q 144 431 158 544 l 585 431 l 585 439 q 524 612 581 545 q 375 678 467 678 m 615 1013 q 577 908 615 947 q 483 868 538 868 q 382 905 431 868 q 294 943 333 943 q 241 922 262 943 q 220 863 220 901 l 159 865 q 196 968 159 927 q 291 1008 233 1008 q 347 997 323 1008 q 390 971 370 985 q 431 945 410 957 q 479 934 452 934 q 533 956 511 934 q 555 1017 555 979 l 615 1013 z ',
    },
    Ế: {
      ha: 790,
      x_min: 125,
      x_max: 791,
      o: 'm 670 473 l 208 473 l 208 71 l 739 71 l 739 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 544 l 670 544 l 670 473 m 379 1217 l 458 1217 l 640 1053 l 545 1053 l 418 1171 l 292 1053 l 196 1053 l 379 1217 m 698 1323 l 791 1323 l 667 1166 l 598 1166 l 698 1323 z ',
    },
    ế: {
      ha: 718,
      x_min: 61,
      x_max: 755,
      o: 'm 390 -14 q 221 33 296 -14 q 103 161 145 79 q 61 346 61 243 l 61 375 q 102 566 61 481 q 216 699 143 650 q 375 747 290 747 q 587 656 509 747 q 665 407 665 565 l 665 361 l 142 361 l 142 346 q 213 138 142 221 q 393 55 285 55 q 508 79 458 55 q 599 155 558 102 l 650 116 q 390 -14 560 -14 m 375 678 q 221 611 283 678 q 144 431 158 544 l 585 431 l 585 439 q 524 612 581 545 q 375 678 467 678 m 343 1000 l 422 1000 l 604 836 l 509 836 l 382 953 l 256 836 l 160 836 l 343 1000 m 662 1105 l 755 1105 l 631 949 l 562 949 l 662 1105 z ',
    },
    Ề: {
      ha: 790,
      x_min: 46,
      x_max: 739,
      o: 'm 670 473 l 208 473 l 208 71 l 739 71 l 739 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 544 l 670 544 l 670 473 m 640 1052 l 544 1052 l 417 1169 l 290 1052 l 195 1052 l 378 1216 l 456 1216 l 640 1052 m 237 1165 l 169 1165 l 46 1322 l 138 1322 l 237 1165 z ',
    },
    ề: {
      ha: 718,
      x_min: 10,
      x_max: 665,
      o: 'm 390 -14 q 221 33 296 -14 q 103 161 145 79 q 61 346 61 243 l 61 375 q 102 566 61 481 q 216 699 143 650 q 375 747 290 747 q 587 656 509 747 q 665 407 665 565 l 665 361 l 142 361 l 142 346 q 213 138 142 221 q 393 55 285 55 q 508 79 458 55 q 599 155 558 102 l 650 116 q 390 -14 560 -14 m 375 678 q 221 611 283 678 q 144 431 158 544 l 585 431 l 585 439 q 524 612 581 545 q 375 678 467 678 m 604 833 l 508 833 l 381 951 l 254 833 l 159 833 l 342 998 l 420 998 l 604 833 m 201 947 l 133 947 l 10 1103 l 102 1103 l 201 947 z ',
    },
    Ể: {
      ha: 790,
      x_min: 125,
      x_max: 768,
      o: 'm 670 473 l 208 473 l 208 71 l 739 71 l 739 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 544 l 670 544 l 670 473 m 642 1065 l 556 1065 l 420 1191 l 284 1065 l 198 1065 l 374 1229 l 465 1229 l 642 1065 m 612 1150 l 612 1226 q 684 1239 663 1230 q 705 1275 705 1249 q 593 1327 705 1327 l 597 1377 q 722 1350 675 1377 q 768 1277 768 1322 q 667 1185 768 1196 l 667 1150 l 612 1150 z ',
    },
    ể: {
      ha: 718,
      x_min: 61,
      x_max: 732,
      o: 'm 390 -14 q 221 33 296 -14 q 103 161 145 79 q 61 346 61 243 l 61 375 q 102 566 61 481 q 216 699 143 650 q 375 747 290 747 q 587 656 509 747 q 665 407 665 565 l 665 361 l 142 361 l 142 346 q 213 138 142 221 q 393 55 285 55 q 508 79 458 55 q 599 155 558 102 l 650 116 q 390 -14 560 -14 m 375 678 q 221 611 283 678 q 144 431 158 544 l 585 431 l 585 439 q 524 612 581 545 q 375 678 467 678 m 606 847 l 520 847 l 384 973 l 248 847 l 162 847 l 338 1011 l 429 1011 l 606 847 m 576 932 l 576 1008 q 648 1022 627 1012 q 669 1057 669 1031 q 557 1109 669 1109 l 561 1160 q 686 1132 640 1160 q 732 1059 732 1105 q 631 968 732 978 l 631 932 l 576 932 z ',
    },
    Ễ: {
      ha: 790,
      x_min: 125,
      x_max: 739,
      o: 'm 670 473 l 208 473 l 208 71 l 739 71 l 739 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 544 l 670 544 l 670 473 m 640 1048 l 554 1048 l 418 1164 l 282 1048 l 196 1048 l 386 1206 l 450 1206 l 640 1048 m 594 1348 q 566 1277 594 1307 q 496 1247 537 1247 q 458 1254 474 1247 q 417 1279 442 1260 q 380 1302 392 1298 q 354 1307 369 1307 q 316 1288 332 1307 q 300 1243 300 1270 l 256 1252 q 286 1328 256 1296 q 354 1360 315 1360 q 428 1330 387 1360 q 496 1300 469 1300 q 535 1316 519 1300 q 551 1358 551 1332 l 594 1348 z ',
    },
    ễ: {
      ha: 718,
      x_min: 61,
      x_max: 665,
      o: 'm 390 -14 q 221 33 296 -14 q 103 161 145 79 q 61 346 61 243 l 61 375 q 102 566 61 481 q 216 699 143 650 q 375 747 290 747 q 587 656 509 747 q 665 407 665 565 l 665 361 l 142 361 l 142 346 q 213 138 142 221 q 393 55 285 55 q 508 79 458 55 q 599 155 558 102 l 650 116 q 390 -14 560 -14 m 375 678 q 221 611 283 678 q 144 431 158 544 l 585 431 l 585 439 q 524 612 581 545 q 375 678 467 678 m 604 830 l 518 830 l 382 947 l 246 830 l 160 830 l 350 988 l 414 988 l 604 830 m 558 1130 q 530 1059 558 1089 q 460 1029 501 1029 q 422 1036 438 1029 q 381 1061 406 1042 q 345 1085 356 1080 q 318 1089 333 1089 q 280 1070 296 1089 q 264 1025 264 1052 l 220 1034 q 250 1110 220 1078 q 318 1142 279 1142 q 392 1112 351 1142 q 460 1082 433 1082 q 499 1098 483 1082 q 515 1140 515 1114 l 558 1130 z ',
    },
    Ệ: {
      ha: 790,
      x_min: 125,
      x_max: 739,
      o: 'm 670 473 l 208 473 l 208 71 l 739 71 l 739 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 544 l 670 544 l 670 473 m 609 1078 l 609 1071 l 534 1071 l 420 1195 l 307 1071 l 233 1071 l 233 1080 l 394 1253 l 446 1253 l 609 1078 m 360 -150 q 375 -111 360 -127 q 416 -95 390 -95 q 457 -111 442 -95 q 472 -150 472 -127 q 457 -188 472 -172 q 416 -203 442 -203 q 375 -188 390 -203 q 360 -150 360 -172 z ',
    },
    ệ: {
      ha: 718,
      x_min: 61,
      x_max: 665,
      o: 'm 390 -14 q 221 33 296 -14 q 103 161 145 79 q 61 346 61 243 l 61 375 q 102 566 61 481 q 216 699 143 650 q 375 747 290 747 q 587 656 509 747 q 665 407 665 565 l 665 361 l 142 361 l 142 346 q 213 138 142 221 q 393 55 285 55 q 508 79 458 55 q 599 155 558 102 l 650 116 q 390 -14 560 -14 m 375 678 q 221 611 283 678 q 144 431 158 544 l 585 431 l 585 439 q 524 612 581 545 q 375 678 467 678 m 573 860 l 573 853 l 498 853 l 384 977 l 271 853 l 197 853 l 197 862 l 358 1035 l 410 1035 l 573 860 m 324 -155 q 339 -116 324 -132 q 380 -100 354 -100 q 421 -116 406 -100 q 436 -155 436 -132 q 421 -193 436 -178 q 380 -209 406 -209 q 339 -193 354 -209 q 324 -155 324 -178 z ',
    },
    Ỉ: {
      ha: 370,
      x_min: 133,
      x_max: 337,
      o: 'm 227 0 l 143 0 l 143 987 l 227 987 l 227 0 m 156 1074 l 156 1159 q 242 1177 215 1164 q 269 1223 269 1190 q 232 1270 269 1253 q 133 1287 195 1287 l 138 1339 q 284 1308 231 1339 q 337 1222 337 1276 q 306 1149 337 1177 q 218 1116 275 1121 l 217 1074 l 156 1074 z ',
    },
    ỉ: {
      ha: 309,
      x_min: 104,
      x_max: 308,
      o: 'm 195 0 l 114 0 l 114 734 l 195 734 l 195 0 m 127 848 l 127 932 q 213 950 186 937 q 240 996 240 964 q 203 1044 240 1027 q 104 1061 166 1061 l 109 1113 q 255 1081 201 1113 q 308 996 308 1049 q 277 923 308 951 q 189 890 246 895 l 188 848 l 127 848 z ',
    },
    Ị: {
      ha: 370,
      x_min: 125,
      x_max: 237,
      o: 'm 227 0 l 143 0 l 143 987 l 227 987 l 227 0 m 125 -154 q 140 -115 125 -131 q 181 -99 155 -99 q 222 -115 208 -99 q 237 -154 237 -131 q 222 -192 237 -176 q 181 -208 208 -208 q 140 -192 155 -208 q 125 -154 125 -176 z ',
    },
    ị: {
      ha: 311,
      x_min: 96,
      x_max: 213,
      o: 'm 197 0 l 115 0 l 115 734 l 197 734 l 197 0 m 101 946 q 116 985 101 969 q 157 1001 131 1001 q 198 985 182 1001 q 213 946 213 969 q 198 907 213 923 q 157 892 182 892 q 116 907 131 892 q 101 946 101 923 m 96 -150 q 111 -111 96 -127 q 151 -95 125 -95 q 193 -111 178 -95 q 208 -150 208 -127 q 193 -188 208 -172 q 151 -203 178 -203 q 111 -188 125 -203 q 96 -150 96 -172 z ',
    },
    Ọ: {
      ha: 940,
      x_min: 85,
      x_max: 854,
      o: 'm 854 440 q 807 201 854 304 q 673 42 760 98 q 471 -14 585 -14 q 191 110 298 -14 q 85 444 85 234 l 85 546 q 133 785 85 681 q 268 945 180 889 q 469 1001 355 1001 q 670 946 583 1001 q 805 791 758 891 q 854 558 852 690 l 854 440 m 771 547 q 690 827 771 726 q 469 928 610 928 q 250 826 332 928 q 168 543 168 725 l 168 440 q 250 162 168 264 q 471 59 331 59 q 692 160 612 59 q 771 443 771 262 l 771 547 m 412 -157 q 427 -118 412 -134 q 467 -102 441 -102 q 509 -118 494 -102 q 524 -157 524 -134 q 509 -195 524 -179 q 467 -210 494 -210 q 427 -195 441 -210 q 412 -157 412 -179 z ',
    },
    ọ: {
      ha: 778,
      x_min: 61,
      x_max: 716,
      o: 'm 61 378 q 102 568 61 484 q 218 700 143 653 q 388 747 293 747 q 625 645 534 747 q 716 372 716 542 l 716 355 q 675 164 716 249 q 559 33 634 79 q 389 -14 485 -14 q 152 89 243 -14 q 61 361 61 192 l 61 378 m 142 355 q 211 139 142 224 q 389 55 279 55 q 567 139 499 55 q 635 362 635 224 l 635 378 q 604 532 635 462 q 517 640 573 602 q 388 678 460 678 q 211 593 279 678 q 142 370 142 508 l 142 355 m 330 -159 q 345 -120 330 -136 q 386 -104 360 -104 q 427 -120 412 -104 q 442 -159 442 -136 q 427 -197 442 -182 q 386 -213 412 -213 q 345 -197 360 -213 q 330 -159 330 -182 z ',
    },
    Ỏ: {
      ha: 940,
      x_min: 85,
      x_max: 854,
      o: 'm 854 440 q 807 201 854 304 q 673 42 760 98 q 471 -14 585 -14 q 191 110 298 -14 q 85 444 85 234 l 85 546 q 133 785 85 681 q 268 945 180 889 q 469 1001 355 1001 q 670 946 583 1001 q 805 791 758 891 q 854 558 852 690 l 854 440 m 771 547 q 690 827 771 726 q 469 928 610 928 q 250 826 332 928 q 168 543 168 725 l 168 440 q 250 162 168 264 q 471 59 331 59 q 692 160 612 59 q 771 443 771 262 l 771 547 m 439 1078 l 439 1163 q 526 1181 498 1168 q 553 1227 553 1194 q 516 1274 553 1257 q 416 1291 479 1291 l 421 1343 q 567 1312 514 1343 q 621 1226 621 1280 q 590 1153 621 1181 q 501 1120 559 1125 l 500 1078 l 439 1078 z ',
    },
    ỏ: {
      ha: 778,
      x_min: 61,
      x_max: 716,
      o: 'm 61 378 q 102 568 61 484 q 218 700 143 653 q 388 747 293 747 q 625 645 534 747 q 716 372 716 542 l 716 355 q 675 164 716 249 q 559 33 634 79 q 389 -14 485 -14 q 152 89 243 -14 q 61 361 61 192 l 61 378 m 142 355 q 211 139 142 224 q 389 55 279 55 q 567 139 499 55 q 635 362 635 224 l 635 378 q 604 532 635 462 q 517 640 573 602 q 388 678 460 678 q 211 593 279 678 q 142 370 142 508 l 142 355 m 358 856 l 358 941 q 444 959 417 945 q 471 1004 471 972 q 434 1052 471 1035 q 335 1069 397 1069 l 340 1121 q 486 1089 433 1121 q 539 1004 539 1057 q 508 931 539 959 q 420 898 477 903 l 419 856 l 358 856 z ',
    },
    Ố: {
      ha: 940,
      x_min: 85,
      x_max: 854,
      o: 'm 854 440 q 807 201 854 304 q 673 42 760 98 q 471 -14 585 -14 q 191 110 298 -14 q 85 444 85 234 l 85 546 q 133 785 85 681 q 268 945 180 889 q 469 1001 355 1001 q 670 946 583 1001 q 805 791 758 891 q 854 558 852 690 l 854 440 m 771 547 q 690 827 771 726 q 469 928 610 928 q 250 826 332 928 q 168 543 168 725 l 168 440 q 250 162 168 264 q 471 59 331 59 q 692 160 612 59 q 771 443 771 262 l 771 547 m 428 1221 l 507 1221 l 689 1057 l 594 1057 l 467 1175 l 340 1057 l 245 1057 l 428 1221 m 747 1327 l 840 1327 l 716 1171 l 647 1171 l 747 1327 z ',
    },
    ố: {
      ha: 778,
      x_min: 61,
      x_max: 758,
      o: 'm 61 378 q 102 568 61 484 q 218 700 143 653 q 388 747 293 747 q 625 645 534 747 q 716 372 716 542 l 716 355 q 675 164 716 249 q 559 33 634 79 q 389 -14 485 -14 q 152 89 243 -14 q 61 361 61 192 l 61 378 m 142 355 q 211 139 142 224 q 389 55 279 55 q 567 139 499 55 q 635 362 635 224 l 635 378 q 604 532 635 462 q 517 640 573 602 q 388 678 460 678 q 211 593 279 678 q 142 370 142 508 l 142 355 m 347 1000 l 425 1000 l 608 836 l 513 836 l 386 953 l 259 836 l 163 836 l 347 1000 m 665 1105 l 758 1105 l 635 949 l 566 949 l 665 1105 z ',
    },
    Ồ: {
      ha: 940,
      x_min: 85,
      x_max: 854,
      o: 'm 854 440 q 807 201 854 304 q 673 42 760 98 q 471 -14 585 -14 q 191 110 298 -14 q 85 444 85 234 l 85 546 q 133 785 85 681 q 268 945 180 889 q 469 1001 355 1001 q 670 946 583 1001 q 805 791 758 891 q 854 558 852 690 l 854 440 m 771 547 q 690 827 771 726 q 469 928 610 928 q 250 826 332 928 q 168 543 168 725 l 168 440 q 250 162 168 264 q 471 59 331 59 q 692 160 612 59 q 771 443 771 262 l 771 547 m 688 1056 l 593 1056 l 466 1173 l 339 1056 l 244 1056 l 427 1220 l 505 1220 l 688 1056 m 286 1169 l 218 1169 l 95 1326 l 186 1326 l 286 1169 z ',
    },
    ồ: {
      ha: 778,
      x_min: 14,
      x_max: 716,
      o: 'm 61 378 q 102 568 61 484 q 218 700 143 653 q 388 747 293 747 q 625 645 534 747 q 716 372 716 542 l 716 355 q 675 164 716 249 q 559 33 634 79 q 389 -14 485 -14 q 152 89 243 -14 q 61 361 61 192 l 61 378 m 142 355 q 211 139 142 224 q 389 55 279 55 q 567 139 499 55 q 635 362 635 224 l 635 378 q 604 532 635 462 q 517 640 573 602 q 388 678 460 678 q 211 593 279 678 q 142 370 142 508 l 142 355 m 607 833 l 511 833 l 385 951 l 258 833 l 163 833 l 345 998 l 424 998 l 607 833 m 205 947 l 136 947 l 14 1103 l 105 1103 l 205 947 z ',
    },
    Ổ: {
      ha: 940,
      x_min: 85,
      x_max: 854,
      o: 'm 854 440 q 807 201 854 304 q 673 42 760 98 q 471 -14 585 -14 q 191 110 298 -14 q 85 444 85 234 l 85 546 q 133 785 85 681 q 268 945 180 889 q 469 1001 355 1001 q 670 946 583 1001 q 805 791 758 891 q 854 558 852 690 l 854 440 m 771 547 q 690 827 771 726 q 469 928 610 928 q 250 826 332 928 q 168 543 168 725 l 168 440 q 250 162 168 264 q 471 59 331 59 q 692 160 612 59 q 771 443 771 262 l 771 547 m 691 1069 l 605 1069 l 469 1195 l 333 1069 l 247 1069 l 423 1233 l 514 1233 l 691 1069 m 661 1154 l 661 1230 q 732 1243 711 1234 q 753 1279 753 1253 q 642 1331 753 1331 l 646 1381 q 770 1354 724 1381 q 817 1281 817 1326 q 715 1190 817 1200 l 715 1154 l 661 1154 z ',
    },
    ổ: {
      ha: 778,
      x_min: 61,
      x_max: 735,
      o: 'm 61 378 q 102 568 61 484 q 218 700 143 653 q 388 747 293 747 q 625 645 534 747 q 716 372 716 542 l 716 355 q 675 164 716 249 q 559 33 634 79 q 389 -14 485 -14 q 152 89 243 -14 q 61 361 61 192 l 61 378 m 142 355 q 211 139 142 224 q 389 55 279 55 q 567 139 499 55 q 635 362 635 224 l 635 378 q 604 532 635 462 q 517 640 573 602 q 388 678 460 678 q 211 593 279 678 q 142 370 142 508 l 142 355 m 610 847 l 524 847 l 387 973 l 252 847 l 165 847 l 342 1011 l 433 1011 l 610 847 m 580 932 l 579 1008 q 651 1022 630 1012 q 672 1057 672 1031 q 561 1109 672 1109 l 564 1160 q 689 1132 643 1160 q 735 1059 735 1105 q 634 968 735 978 l 634 932 l 580 932 z ',
    },
    Ỗ: {
      ha: 940,
      x_min: 85,
      x_max: 854,
      o: 'm 854 440 q 807 201 854 304 q 673 42 760 98 q 471 -14 585 -14 q 191 110 298 -14 q 85 444 85 234 l 85 546 q 133 785 85 681 q 268 945 180 889 q 469 1001 355 1001 q 670 946 583 1001 q 805 791 758 891 q 854 558 852 690 l 854 440 m 771 547 q 690 827 771 726 q 469 928 610 928 q 250 826 332 928 q 168 543 168 725 l 168 440 q 250 162 168 264 q 471 59 331 59 q 692 160 612 59 q 771 443 771 262 l 771 547 m 689 1052 l 603 1052 l 467 1168 l 331 1052 l 245 1052 l 435 1210 l 499 1210 l 689 1052 m 643 1352 q 614 1281 643 1311 q 545 1251 586 1251 q 507 1258 523 1251 q 466 1283 491 1264 q 429 1306 441 1302 q 403 1311 418 1311 q 365 1292 380 1311 q 349 1247 349 1274 l 305 1256 q 335 1332 305 1300 q 403 1364 364 1364 q 477 1334 436 1364 q 545 1304 517 1304 q 584 1320 568 1304 q 600 1362 600 1336 l 643 1352 z ',
    },
    ỗ: {
      ha: 778,
      x_min: 61,
      x_max: 716,
      o: 'm 61 378 q 102 568 61 484 q 218 700 143 653 q 388 747 293 747 q 625 645 534 747 q 716 372 716 542 l 716 355 q 675 164 716 249 q 559 33 634 79 q 389 -14 485 -14 q 152 89 243 -14 q 61 361 61 192 l 61 378 m 142 355 q 211 139 142 224 q 389 55 279 55 q 567 139 499 55 q 635 362 635 224 l 635 378 q 604 532 635 462 q 517 640 573 602 q 388 678 460 678 q 211 593 279 678 q 142 370 142 508 l 142 355 m 608 830 l 522 830 l 385 947 l 250 830 l 163 830 l 353 988 l 418 988 l 608 830 m 562 1130 q 533 1059 562 1089 q 463 1029 505 1029 q 426 1036 441 1029 q 385 1061 410 1042 q 348 1085 359 1080 q 321 1089 336 1089 q 283 1070 299 1089 q 267 1025 267 1052 l 224 1034 q 253 1110 224 1078 q 321 1142 283 1142 q 395 1112 355 1142 q 463 1082 436 1082 q 502 1098 486 1082 q 518 1140 518 1114 l 562 1130 z ',
    },
    Ộ: {
      ha: 940,
      x_min: 85,
      x_max: 854,
      o: 'm 854 440 q 807 201 854 304 q 673 42 760 98 q 471 -14 585 -14 q 191 110 298 -14 q 85 444 85 234 l 85 546 q 133 785 85 681 q 268 945 180 889 q 469 1001 355 1001 q 670 946 583 1001 q 805 791 758 891 q 854 558 852 690 l 854 440 m 771 547 q 690 827 771 726 q 469 928 610 928 q 250 826 332 928 q 168 543 168 725 l 168 440 q 250 162 168 264 q 471 59 331 59 q 692 160 612 59 q 771 443 771 262 l 771 547 m 658 1082 l 658 1075 l 583 1075 l 469 1199 l 355 1075 l 281 1075 l 281 1084 l 443 1257 l 494 1257 l 658 1082 m 412 -157 q 427 -118 412 -134 q 467 -102 441 -102 q 509 -118 494 -102 q 524 -157 524 -134 q 509 -195 524 -179 q 467 -210 494 -210 q 427 -195 441 -210 q 412 -157 412 -179 z ',
    },
    ộ: {
      ha: 778,
      x_min: 61,
      x_max: 716,
      o: 'm 61 378 q 102 568 61 484 q 218 700 143 653 q 388 747 293 747 q 625 645 534 747 q 716 372 716 542 l 716 355 q 675 164 716 249 q 559 33 634 79 q 389 -14 485 -14 q 152 89 243 -14 q 61 361 61 192 l 61 378 m 142 355 q 211 139 142 224 q 389 55 279 55 q 567 139 499 55 q 635 362 635 224 l 635 378 q 604 532 635 462 q 517 640 573 602 q 388 678 460 678 q 211 593 279 678 q 142 370 142 508 l 142 355 m 576 860 l 576 853 l 501 853 l 387 977 l 274 853 l 200 853 l 200 862 l 361 1035 l 413 1035 l 576 860 m 330 -159 q 345 -120 330 -136 q 386 -104 360 -104 q 427 -120 412 -104 q 442 -159 442 -136 q 427 -197 442 -182 q 386 -213 412 -213 q 345 -197 360 -213 q 330 -159 330 -182 z ',
    },
    Ớ: {
      ha: 968,
      x_min: 90,
      x_max: 982,
      o: 'm 859 440 q 812 201 859 304 q 677 42 765 98 q 475 -14 590 -14 q 274 42 362 -14 q 138 200 186 98 q 90 435 90 302 l 90 546 q 137 784 90 680 q 272 944 184 888 q 474 1001 360 1001 q 638 966 566 1001 q 762 867 711 931 q 871 915 835 870 q 907 1061 907 959 l 982 1061 q 939 886 982 951 q 801 807 896 821 q 859 555 857 701 l 859 440 m 777 547 q 696 827 777 726 q 474 928 615 928 q 255 826 336 928 q 173 543 173 725 l 173 440 q 254 162 173 264 q 475 59 336 59 q 696 160 616 59 q 777 442 777 261 l 777 547 m 566 1257 l 664 1257 l 507 1067 l 436 1067 l 566 1257 z ',
    },
    ớ: {
      ha: 777,
      x_min: 60,
      x_max: 803,
      o: 'm 60 378 q 101 568 60 484 q 217 700 142 653 q 387 747 292 747 q 620 650 530 747 q 707 689 681 654 q 733 812 733 724 l 803 812 q 767 661 803 713 q 660 596 732 608 q 715 386 713 506 l 715 355 q 674 164 715 250 q 558 33 633 79 q 389 -14 484 -14 q 151 90 241 -14 q 60 361 60 193 l 60 378 m 141 355 q 209 139 141 224 q 389 55 277 55 q 566 139 498 55 q 634 362 634 224 l 634 378 q 566 593 634 508 q 387 678 497 678 q 210 593 278 678 q 141 370 141 508 l 141 355 m 476 1042 l 574 1042 l 418 852 l 347 852 l 476 1042 z ',
    },
    Ờ: {
      ha: 968,
      x_min: 90,
      x_max: 982,
      o: 'm 859 440 q 812 201 859 304 q 677 42 765 98 q 475 -14 590 -14 q 274 42 362 -14 q 138 200 186 98 q 90 435 90 302 l 90 546 q 137 784 90 680 q 272 944 184 888 q 474 1001 360 1001 q 638 966 566 1001 q 762 867 711 931 q 871 915 835 870 q 907 1061 907 959 l 982 1061 q 939 886 982 951 q 801 807 896 821 q 859 555 857 701 l 859 440 m 777 547 q 696 827 777 726 q 474 928 615 928 q 255 826 336 928 q 173 543 173 725 l 173 440 q 254 162 173 264 q 475 59 336 59 q 696 160 616 59 q 777 442 777 261 l 777 547 m 534 1067 l 460 1067 l 307 1257 l 405 1257 l 534 1067 z ',
    },
    ờ: {
      ha: 777,
      x_min: 60,
      x_max: 803,
      o: 'm 60 378 q 101 568 60 484 q 217 700 142 653 q 387 747 292 747 q 620 650 530 747 q 707 689 681 654 q 733 812 733 724 l 803 812 q 767 661 803 713 q 660 596 732 608 q 715 386 713 506 l 715 355 q 674 164 715 250 q 558 33 633 79 q 389 -14 484 -14 q 151 90 241 -14 q 60 361 60 193 l 60 378 m 141 355 q 209 139 141 224 q 389 55 277 55 q 566 139 498 55 q 634 362 634 224 l 634 378 q 566 593 634 508 q 387 678 497 678 q 210 593 278 678 q 141 370 141 508 l 141 355 m 445 852 l 371 852 l 218 1042 l 315 1042 l 445 852 z ',
    },
    Ở: {
      ha: 968,
      x_min: 90,
      x_max: 982,
      o: 'm 859 440 q 812 201 859 304 q 677 42 765 98 q 475 -14 590 -14 q 274 42 362 -14 q 138 200 186 98 q 90 435 90 302 l 90 546 q 137 784 90 680 q 272 944 184 888 q 474 1001 360 1001 q 638 966 566 1001 q 762 867 711 931 q 871 915 835 870 q 907 1061 907 959 l 982 1061 q 939 886 982 951 q 801 807 896 821 q 859 555 857 701 l 859 440 m 777 547 q 696 827 777 726 q 474 928 615 928 q 255 826 336 928 q 173 543 173 725 l 173 440 q 254 162 173 264 q 475 59 336 59 q 696 160 616 59 q 777 442 777 261 l 777 547 m 455 1072 l 455 1156 q 541 1174 514 1161 q 568 1220 568 1187 q 531 1267 568 1251 q 432 1284 494 1284 l 437 1337 q 583 1305 530 1337 q 636 1219 636 1273 q 605 1146 636 1175 q 517 1114 574 1118 l 516 1072 l 455 1072 z ',
    },
    ở: {
      ha: 777,
      x_min: 60,
      x_max: 803,
      o: 'm 60 378 q 101 568 60 484 q 217 700 142 653 q 387 747 292 747 q 620 650 530 747 q 707 689 681 654 q 733 812 733 724 l 803 812 q 767 661 803 713 q 660 596 732 608 q 715 386 713 506 l 715 355 q 674 164 715 250 q 558 33 633 79 q 389 -14 484 -14 q 151 90 241 -14 q 60 361 60 193 l 60 378 m 141 355 q 209 139 141 224 q 389 55 277 55 q 566 139 498 55 q 634 362 634 224 l 634 378 q 566 593 634 508 q 387 678 497 678 q 210 593 278 678 q 141 370 141 508 l 141 355 m 366 856 l 366 941 q 452 959 425 945 q 479 1004 479 972 q 442 1052 479 1035 q 342 1069 405 1069 l 347 1121 q 493 1089 440 1121 q 547 1004 547 1057 q 516 931 547 959 q 427 898 485 903 l 427 856 l 366 856 z ',
    },
    Ỡ: {
      ha: 968,
      x_min: 90,
      x_max: 982,
      o: 'm 859 440 q 812 201 859 304 q 677 42 765 98 q 475 -14 590 -14 q 274 42 362 -14 q 138 200 186 98 q 90 435 90 302 l 90 546 q 137 784 90 680 q 272 944 184 888 q 474 1001 360 1001 q 638 966 566 1001 q 762 867 711 931 q 871 915 835 870 q 907 1061 907 959 l 982 1061 q 939 886 982 951 q 801 807 896 821 q 859 555 857 701 l 859 440 m 777 547 q 696 827 777 726 q 474 928 615 928 q 255 826 336 928 q 173 543 173 725 l 173 440 q 254 162 173 264 q 475 59 336 59 q 696 160 616 59 q 777 442 777 261 l 777 547 m 715 1228 q 677 1123 715 1163 q 583 1084 639 1084 q 483 1121 532 1084 q 395 1158 433 1158 q 341 1137 363 1158 q 320 1078 320 1116 l 259 1080 q 296 1183 259 1143 q 391 1224 333 1224 q 447 1213 424 1224 q 490 1187 470 1201 q 531 1161 511 1173 q 580 1149 552 1149 q 633 1172 611 1149 q 655 1232 655 1194 l 715 1228 z ',
    },
    ỡ: {
      ha: 777,
      x_min: 60,
      x_max: 803,
      o: 'm 60 378 q 101 568 60 484 q 217 700 142 653 q 387 747 292 747 q 620 650 530 747 q 707 689 681 654 q 733 812 733 724 l 803 812 q 767 661 803 713 q 660 596 732 608 q 715 386 713 506 l 715 355 q 674 164 715 250 q 558 33 633 79 q 389 -14 484 -14 q 151 90 241 -14 q 60 361 60 193 l 60 378 m 141 355 q 209 139 141 224 q 389 55 277 55 q 566 139 498 55 q 634 362 634 224 l 634 378 q 566 593 634 508 q 387 678 497 678 q 210 593 278 678 q 141 370 141 508 l 141 355 m 626 1013 q 588 908 626 947 q 494 868 549 868 q 393 905 442 868 q 305 943 344 943 q 252 922 273 943 q 231 863 231 901 l 170 865 q 207 968 170 927 q 302 1008 243 1008 q 357 997 334 1008 q 401 971 380 985 q 442 945 421 957 q 490 934 463 934 q 544 956 522 934 q 566 1017 566 979 l 626 1013 z ',
    },
    Ợ: {
      ha: 968,
      x_min: 90,
      x_max: 982,
      o: 'm 859 440 q 812 201 859 304 q 677 42 765 98 q 475 -14 590 -14 q 274 42 362 -14 q 138 200 186 98 q 90 435 90 302 l 90 546 q 137 784 90 680 q 272 944 184 888 q 474 1001 360 1001 q 638 966 566 1001 q 762 867 711 931 q 871 915 835 870 q 907 1061 907 959 l 982 1061 q 939 886 982 951 q 801 807 896 821 q 859 555 857 701 l 859 440 m 777 547 q 696 827 777 726 q 474 928 615 928 q 255 826 336 928 q 173 543 173 725 l 173 440 q 254 162 173 264 q 475 59 336 59 q 696 160 616 59 q 777 442 777 261 l 777 547 m 410 -157 q 425 -118 410 -134 q 465 -102 439 -102 q 507 -118 492 -102 q 522 -157 522 -134 q 507 -195 522 -179 q 465 -210 492 -210 q 425 -195 439 -210 q 410 -157 410 -179 z ',
    },
    ợ: {
      ha: 777,
      x_min: 60,
      x_max: 803,
      o: 'm 60 378 q 101 568 60 484 q 217 700 142 653 q 387 747 292 747 q 620 650 530 747 q 707 689 681 654 q 733 812 733 724 l 803 812 q 767 661 803 713 q 660 596 732 608 q 715 386 713 506 l 715 355 q 674 164 715 250 q 558 33 633 79 q 389 -14 484 -14 q 151 90 241 -14 q 60 361 60 193 l 60 378 m 141 355 q 209 139 141 224 q 389 55 277 55 q 566 139 498 55 q 634 362 634 224 l 634 378 q 566 593 634 508 q 387 678 497 678 q 210 593 278 678 q 141 370 141 508 l 141 355 m 335 -163 q 350 -124 335 -140 q 391 -108 365 -108 q 432 -124 417 -108 q 447 -163 447 -140 q 432 -201 447 -185 q 391 -216 417 -216 q 350 -201 365 -216 q 335 -163 335 -185 z ',
    },
    Ụ: {
      ha: 913,
      x_min: 110,
      x_max: 805,
      o: 'm 805 987 l 805 313 q 761 140 804 214 q 638 26 718 66 q 457 -14 559 -14 q 207 72 301 -14 q 110 307 113 157 l 110 987 l 193 987 l 193 319 q 264 126 193 195 q 457 57 335 57 q 650 126 579 57 q 721 319 721 195 l 721 987 l 805 987 m 397 -157 q 412 -118 397 -134 q 453 -102 427 -102 q 494 -118 479 -102 q 509 -157 509 -134 q 494 -195 509 -179 q 453 -210 479 -210 q 412 -195 427 -210 q 397 -157 397 -179 z ',
    },
    ụ: {
      ha: 763,
      x_min: 103,
      x_max: 658,
      o: 'm 578 93 q 344 -14 505 -14 q 165 55 227 -14 q 103 256 104 123 l 103 734 l 184 734 l 184 267 q 353 57 184 57 q 576 203 530 57 l 576 734 l 658 734 l 658 0 l 579 0 l 578 93 m 287 -157 q 302 -118 287 -134 q 342 -102 317 -102 q 384 -118 369 -102 q 399 -157 399 -134 q 384 -195 399 -179 q 342 -210 369 -210 q 302 -195 317 -210 q 287 -157 287 -179 z ',
    },
    Ủ: {
      ha: 913,
      x_min: 110,
      x_max: 805,
      o: 'm 805 987 l 805 313 q 761 140 804 214 q 638 26 718 66 q 457 -14 559 -14 q 207 72 301 -14 q 110 307 113 157 l 110 987 l 193 987 l 193 319 q 264 126 193 195 q 457 57 335 57 q 650 126 579 57 q 721 319 721 195 l 721 987 l 805 987 m 433 1070 l 433 1155 q 519 1173 492 1160 q 547 1219 547 1186 q 510 1266 547 1249 q 410 1283 473 1283 l 415 1335 q 561 1303 508 1335 q 614 1218 614 1272 q 584 1145 614 1173 q 495 1112 553 1117 l 494 1070 l 433 1070 z ',
    },
    ủ: {
      ha: 763,
      x_min: 103,
      x_max: 658,
      o: 'm 578 93 q 344 -14 505 -14 q 165 55 227 -14 q 103 256 104 123 l 103 734 l 184 734 l 184 267 q 353 57 184 57 q 576 203 530 57 l 576 734 l 658 734 l 658 0 l 579 0 l 578 93 m 359 857 l 359 941 q 446 959 418 946 q 473 1005 473 972 q 436 1053 473 1036 q 336 1069 399 1069 l 341 1122 q 487 1090 434 1122 q 541 1004 541 1058 q 510 931 541 960 q 421 899 479 903 l 420 857 l 359 857 z ',
    },
    Ứ: {
      ha: 945,
      x_min: 110,
      x_max: 1028,
      o: 'm 805 987 l 805 844 q 921 896 886 850 q 956 1047 956 942 l 1028 1047 q 973 849 1028 913 q 805 781 918 786 l 805 313 q 761 140 804 214 q 638 26 718 66 q 457 -14 559 -14 q 207 72 301 -14 q 110 307 113 157 l 110 987 l 193 987 l 193 319 q 264 126 193 195 q 457 57 335 57 q 650 126 579 57 q 721 319 721 195 l 721 987 l 805 987 m 542 1259 l 640 1259 l 484 1069 l 412 1069 l 542 1259 z ',
    },
    ứ: {
      ha: 814,
      x_min: 103,
      x_max: 842,
      o: 'm 842 800 q 797 640 842 688 q 658 587 753 591 l 658 0 l 579 0 l 578 93 q 344 -14 505 -14 q 165 55 227 -14 q 103 256 104 123 l 103 734 l 184 734 l 184 267 q 353 57 184 57 q 576 203 530 57 l 576 734 l 658 734 l 658 644 q 764 703 743 648 q 774 800 774 730 l 842 800 m 475 1027 l 573 1027 l 416 838 l 345 838 l 475 1027 z ',
    },
    Ừ: {
      ha: 945,
      x_min: 110,
      x_max: 1028,
      o: 'm 805 987 l 805 844 q 921 896 886 850 q 956 1047 956 942 l 1028 1047 q 973 849 1028 913 q 805 781 918 786 l 805 313 q 761 140 804 214 q 638 26 718 66 q 457 -14 559 -14 q 207 72 301 -14 q 110 307 113 157 l 110 987 l 193 987 l 193 319 q 264 126 193 195 q 457 57 335 57 q 650 126 579 57 q 721 319 721 195 l 721 987 l 805 987 m 511 1069 l 437 1069 l 283 1259 l 381 1259 l 511 1069 z ',
    },
    ừ: {
      ha: 814,
      x_min: 103,
      x_max: 842,
      o: 'm 842 800 q 797 640 842 688 q 658 587 753 591 l 658 0 l 579 0 l 578 93 q 344 -14 505 -14 q 165 55 227 -14 q 103 256 104 123 l 103 734 l 184 734 l 184 267 q 353 57 184 57 q 576 203 530 57 l 576 734 l 658 734 l 658 644 q 764 703 743 648 q 774 800 774 730 l 842 800 m 444 838 l 370 838 l 216 1027 l 314 1027 l 444 838 z ',
    },
    Ử: {
      ha: 945,
      x_min: 110,
      x_max: 1028,
      o: 'm 805 987 l 805 844 q 921 896 886 850 q 956 1047 956 942 l 1028 1047 q 973 849 1028 913 q 805 781 918 786 l 805 313 q 761 140 804 214 q 638 26 718 66 q 457 -14 559 -14 q 207 72 301 -14 q 110 307 113 157 l 110 987 l 193 987 l 193 319 q 264 126 193 195 q 457 57 335 57 q 650 126 579 57 q 721 319 721 195 l 721 987 l 805 987 m 431 1074 l 431 1159 q 517 1177 490 1164 q 545 1223 545 1190 q 508 1270 545 1253 q 408 1287 471 1287 l 413 1339 q 559 1308 506 1339 q 612 1222 612 1276 q 582 1149 612 1177 q 493 1116 551 1121 l 492 1074 l 431 1074 z ',
    },
    ử: {
      ha: 814,
      x_min: 103,
      x_max: 842,
      o: 'm 842 800 q 797 640 842 688 q 658 587 753 591 l 658 0 l 579 0 l 578 93 q 344 -14 505 -14 q 165 55 227 -14 q 103 256 104 123 l 103 734 l 184 734 l 184 267 q 353 57 184 57 q 576 203 530 57 l 576 734 l 658 734 l 658 644 q 764 703 743 648 q 774 800 774 730 l 842 800 m 364 842 l 364 926 q 450 944 423 931 q 477 990 477 958 q 440 1038 477 1021 q 341 1055 404 1055 l 346 1107 q 492 1075 439 1107 q 545 989 545 1043 q 514 917 545 945 q 426 884 484 888 l 425 842 l 364 842 z ',
    },
    Ữ: {
      ha: 945,
      x_min: 110,
      x_max: 1028,
      o: 'm 805 987 l 805 844 q 921 896 886 850 q 956 1047 956 942 l 1028 1047 q 973 849 1028 913 q 805 781 918 786 l 805 313 q 761 140 804 214 q 638 26 718 66 q 457 -14 559 -14 q 207 72 301 -14 q 110 307 113 157 l 110 987 l 193 987 l 193 319 q 264 126 193 195 q 457 57 335 57 q 650 126 579 57 q 721 319 721 195 l 721 987 l 805 987 m 692 1231 q 653 1126 692 1166 q 559 1086 615 1086 q 459 1124 508 1086 q 371 1161 410 1161 q 318 1140 339 1161 q 296 1081 296 1119 l 235 1083 q 272 1186 235 1145 q 368 1227 309 1227 q 423 1215 400 1227 q 467 1190 446 1204 q 508 1164 487 1175 q 556 1152 528 1152 q 609 1175 587 1152 q 631 1235 631 1197 l 692 1231 z ',
    },
    ữ: {
      ha: 814,
      x_min: 103,
      x_max: 842,
      o: 'm 842 800 q 797 640 842 688 q 658 587 753 591 l 658 0 l 579 0 l 578 93 q 344 -14 505 -14 q 165 55 227 -14 q 103 256 104 123 l 103 734 l 184 734 l 184 267 q 353 57 184 57 q 576 203 530 57 l 576 734 l 658 734 l 658 644 q 764 703 743 648 q 774 800 774 730 l 842 800 m 625 999 q 586 894 625 934 q 492 854 548 854 q 392 892 441 854 q 304 929 342 929 q 251 908 272 929 q 229 849 229 887 l 168 851 q 205 954 168 913 q 300 995 242 995 q 356 983 333 995 q 399 958 379 972 q 440 932 420 943 q 489 920 461 920 q 542 943 520 920 q 564 1003 564 965 l 625 999 z ',
    },
    Ự: {
      ha: 945,
      x_min: 110,
      x_max: 1028,
      o: 'm 805 987 l 805 844 q 921 896 886 850 q 956 1047 956 942 l 1028 1047 q 973 849 1028 913 q 805 781 918 786 l 805 313 q 761 140 804 214 q 638 26 718 66 q 457 -14 559 -14 q 207 72 301 -14 q 110 307 113 157 l 110 987 l 193 987 l 193 319 q 264 126 193 195 q 457 57 335 57 q 650 126 579 57 q 721 319 721 195 l 721 987 l 805 987 m 395 -159 q 410 -120 395 -136 q 451 -104 425 -104 q 492 -120 477 -104 q 507 -159 507 -136 q 492 -197 507 -181 q 451 -212 477 -212 q 410 -197 425 -212 q 395 -159 395 -181 z ',
    },
    ự: {
      ha: 814,
      x_min: 103,
      x_max: 842,
      o: 'm 842 800 q 797 640 842 688 q 658 587 753 591 l 658 0 l 579 0 l 578 93 q 344 -14 505 -14 q 165 55 227 -14 q 103 256 104 123 l 103 734 l 184 734 l 184 267 q 353 57 184 57 q 576 203 530 57 l 576 734 l 658 734 l 658 644 q 764 703 743 648 q 774 800 774 730 l 842 800 m 321 -157 q 336 -118 321 -134 q 376 -102 351 -102 q 418 -118 403 -102 q 433 -157 433 -134 q 418 -195 433 -179 q 376 -210 403 -210 q 336 -195 351 -210 q 321 -157 321 -179 z ',
    },
    Ỵ: {
      ha: 831,
      x_min: 16,
      x_max: 812,
      o: 'm 414 449 l 716 987 l 812 987 l 456 372 l 456 0 l 372 0 l 372 372 l 16 987 l 115 987 l 414 449 m 356 -153 q 371 -114 356 -130 q 412 -98 386 -98 q 453 -114 438 -98 q 468 -153 468 -130 q 453 -191 468 -176 q 412 -207 438 -207 q 371 -191 386 -207 q 356 -153 356 -176 z ',
    },
    ỵ: {
      ha: 660,
      x_min: 22,
      x_max: 640,
      o: 'm 338 117 l 553 734 l 640 734 l 327 -125 l 311 -163 q 124 -296 250 -296 q 62 -287 95 -296 l 61 -220 l 103 -224 q 200 -194 163 -224 q 262 -93 237 -165 l 298 6 l 22 734 l 111 734 l 338 117 m 425 -263 q 439 -224 425 -240 q 480 -208 454 -208 q 522 -224 507 -208 q 536 -263 536 -240 q 522 -301 536 -286 q 480 -317 507 -317 q 439 -301 454 -317 q 425 -263 425 -286 z ',
    },
    Ỷ: {
      ha: 831,
      x_min: 16,
      x_max: 812,
      o: 'm 414 449 l 716 987 l 812 987 l 456 372 l 456 0 l 372 0 l 372 372 l 16 987 l 115 987 l 414 449 m 386 1066 l 386 1151 q 472 1169 445 1156 q 499 1215 499 1182 q 462 1262 499 1245 q 363 1279 425 1279 l 368 1331 q 514 1299 460 1331 q 567 1214 567 1267 q 536 1141 567 1169 q 448 1108 505 1113 l 447 1066 l 386 1066 z ',
    },
    ỷ: {
      ha: 660,
      x_min: 22,
      x_max: 640,
      o: 'm 338 117 l 553 734 l 640 734 l 327 -125 l 311 -163 q 124 -296 250 -296 q 62 -287 95 -296 l 61 -220 l 103 -224 q 200 -194 163 -224 q 262 -93 237 -165 l 298 6 l 22 734 l 111 734 l 338 117 m 319 857 l 319 941 q 405 959 378 946 q 432 1005 432 972 q 395 1053 432 1036 q 296 1069 358 1069 l 300 1122 q 447 1090 393 1122 q 500 1004 500 1058 q 469 931 500 960 q 380 899 438 903 l 380 857 l 319 857 z ',
    },
    Ỹ: {
      ha: 831,
      x_min: 16,
      x_max: 812,
      o: 'm 414 449 l 716 987 l 812 987 l 456 372 l 456 0 l 372 0 l 372 372 l 16 987 l 115 987 l 414 449 m 646 1223 q 608 1118 646 1158 q 514 1078 570 1078 q 413 1116 463 1078 q 326 1153 364 1153 q 272 1132 294 1153 q 251 1073 251 1111 l 190 1075 q 227 1178 190 1137 q 322 1219 264 1219 q 378 1207 355 1219 q 421 1181 401 1196 q 462 1156 441 1167 q 511 1144 483 1144 q 564 1166 542 1144 q 586 1227 586 1189 l 646 1223 z ',
    },
    ỹ: {
      ha: 660,
      x_min: 22,
      x_max: 640,
      o: 'm 338 117 l 553 734 l 640 734 l 327 -125 l 311 -163 q 124 -296 250 -296 q 62 -287 95 -296 l 61 -220 l 103 -224 q 200 -194 163 -224 q 262 -93 237 -165 l 298 6 l 22 734 l 111 734 l 338 117 m 579 1013 q 541 908 579 948 q 447 869 503 869 q 346 906 395 869 q 258 943 297 943 q 205 922 227 943 q 184 863 184 901 l 123 865 q 160 968 123 928 q 255 1009 197 1009 q 311 998 288 1009 q 354 972 334 986 q 395 946 374 958 q 444 935 416 935 q 497 957 475 935 q 519 1017 519 979 l 579 1013 z ',
    },
    '₫': {
      ha: 792,
      x_min: 73,
      x_max: 783,
      o: 'm 73 374 q 150 646 73 545 q 357 747 226 747 q 583 630 505 747 l 583 1042 l 664 1042 l 664 0 l 588 0 l 585 98 q 355 -14 507 -14 q 151 89 229 -14 q 73 364 73 191 l 73 374 m 155 359 q 212 138 155 219 q 370 57 268 57 q 583 189 520 57 l 583 534 q 372 677 520 677 q 212 596 269 677 q 155 359 155 516 m 783 852 l 368 852 l 368 922 l 783 922 l 783 852 m 713 -174 l 115 -174 l 115 -106 l 713 -106 l 713 -174 z ',
    },
    Ҭ: {
      ha: 829,
      x_min: 35,
      x_max: 795,
      o: 'm 795 916 l 456 916 l 456 0 l 373 0 l 373 916 l 35 916 l 35 987 l 795 987 l 795 916 m 528 -241 l 446 -241 l 446 83 l 528 83 l 528 -241 z ',
    },
    ҭ: {
      ha: 660,
      x_min: 27,
      x_max: 633,
      o: 'm 633 665 l 366 665 l 366 0 l 284 0 l 284 665 l 27 665 l 27 734 l 633 734 l 633 665 m 439 -241 l 358 -241 l 358 83 l 439 83 l 439 -241 z ',
    },
    Ӌ: {
      ha: 924,
      x_min: 117,
      x_max: 798,
      o: 'm 798 987 l 798 0 l 714 0 l 714 449 q 447 410 575 410 q 199 483 279 410 q 117 706 119 555 l 117 987 l 201 987 l 201 711 q 260 536 201 591 q 447 481 319 481 q 714 520 576 481 l 714 987 l 798 987 m 722 -241 l 641 -241 l 641 83 l 722 83 l 722 -241 z ',
    },
    ӌ: {
      ha: 739,
      x_min: 75,
      x_max: 628,
      o: 'm 628 0 l 547 0 l 547 309 q 355 272 446 272 q 143 336 209 272 q 75 536 77 400 l 75 734 l 155 734 l 155 540 q 202 388 157 435 q 355 342 247 342 q 547 377 453 345 l 547 734 l 628 734 l 628 0 m 553 -241 l 472 -241 l 472 83 l 553 83 l 553 -241 z ',
    },
    Ӷ: {
      ha: 766,
      x_min: 125,
      x_max: 721,
      o: 'm 721 916 l 209 916 l 209 0 l 125 0 l 125 987 l 721 987 l 721 916 m 281 -241 l 200 -241 l 200 83 l 281 83 l 281 -241 z ',
    },
    ӷ: {
      ha: 585,
      x_min: 111,
      x_max: 563,
      o: 'm 563 664 l 192 664 l 192 0 l 111 0 l 111 734 l 563 734 l 563 664 m 264 -241 l 183 -241 l 183 83 l 264 83 l 264 -241 z ',
    },
    Ҿ: {
      ha: 1048,
      x_min: 24,
      x_max: 966,
      o: 'm 640 -14 q 343 95 452 -14 q 234 399 234 205 l 234 497 q 78 568 132 508 q 24 730 24 629 l 95 730 q 129 617 95 661 q 234 563 163 573 l 234 585 q 282 798 234 702 q 414 947 330 894 q 600 1000 498 1000 q 869 884 772 1000 q 966 564 966 768 l 966 495 l 318 495 l 318 393 q 402 146 318 235 q 640 56 486 56 q 905 137 793 56 l 930 75 q 640 -14 825 -14 m 318 560 l 882 560 l 882 589 q 806 837 882 747 q 600 928 730 928 q 396 831 473 928 q 318 578 318 735 l 318 560 m 670 -302 l 589 -302 l 589 22 l 670 22 l 670 -302 z ',
    },
    ҿ: {
      ha: 819,
      x_min: -7,
      x_max: 751,
      o: 'm 475 -14 q 306 33 382 -14 q 189 161 231 79 q 146 346 146 243 l 146 375 q 32 443 71 391 q -7 579 -7 495 l 62 579 q 151 439 62 466 q 256 662 169 577 q 460 747 342 747 q 672 656 594 747 q 751 407 751 565 l 751 361 l 227 361 l 227 346 q 299 138 227 221 q 479 55 370 55 q 594 79 544 55 q 684 155 644 102 l 735 116 q 475 -14 646 -14 m 460 678 q 306 611 369 678 q 230 431 243 544 l 670 431 l 670 439 q 610 612 667 545 q 460 678 553 678 m 515 -286 l 433 -286 l 433 39 l 515 39 l 515 -286 z ',
    },
    һ: {
      ha: 762,
      x_min: 106,
      x_max: 659,
      o: 'm 186 610 q 289 711 227 675 q 425 747 351 747 q 601 681 543 747 q 659 481 659 614 l 659 0 l 578 0 l 578 482 q 537 629 578 581 q 406 677 496 677 q 272 630 330 677 q 186 503 214 583 l 186 0 l 106 0 l 106 1042 l 186 1042 l 186 610 z ',
    },
    Ҍ: {
      ha: 850,
      x_min: -2,
      x_max: 776,
      o: 'm 413 764 l 204 764 l 204 559 l 449 559 q 624 525 550 559 q 737 429 698 492 q 776 281 776 366 q 688 75 776 150 q 446 0 601 0 l 121 0 l 121 764 l -2 764 l -2 833 l 121 833 l 121 987 l 204 987 l 204 833 l 413 833 l 413 764 m 204 488 l 204 71 l 448 71 q 627 127 563 71 q 692 282 692 184 q 629 431 692 376 q 454 488 567 486 l 204 488 z ',
    },
    Ѣ: {
      ha: 850,
      x_min: -2,
      x_max: 776,
      o: 'm 413 764 l 204 764 l 204 559 l 449 559 q 624 525 550 559 q 737 429 698 492 q 776 281 776 366 q 688 75 776 150 q 446 0 601 0 l 121 0 l 121 764 l -2 764 l -2 833 l 121 833 l 121 987 l 204 987 l 204 833 l 413 833 l 413 764 m 204 488 l 204 71 l 448 71 q 627 127 563 71 q 692 282 692 184 q 629 431 692 376 q 454 488 567 486 l 204 488 z ',
    },
    Ғ: {
      ha: 766,
      x_min: 29,
      x_max: 721,
      o: 'm 444 479 l 209 479 l 209 0 l 125 0 l 125 479 l 29 479 l 29 549 l 125 549 l 125 987 l 721 987 l 721 916 l 209 916 l 209 549 l 444 549 l 444 479 z ',
    },
    ғ: {
      ha: 585,
      x_min: 0,
      x_max: 563,
      o: 'm 415 340 l 192 340 l 192 0 l 111 0 l 111 340 l 0 340 l 0 410 l 111 410 l 111 734 l 563 734 l 563 664 l 192 664 l 192 410 l 415 410 l 415 340 z ',
    },
    Ҟ: {
      ha: 901,
      x_min: -9,
      x_max: 873,
      o: 'm 361 463 l 225 463 l 225 0 l 142 0 l 142 740 l -9 740 l -9 809 l 142 809 l 142 987 l 225 987 l 225 809 l 406 809 l 406 740 l 225 740 l 225 534 l 357 534 l 739 987 l 844 987 l 438 502 l 873 0 l 770 0 l 361 463 z ',
    },
    ҟ: {
      ha: 696,
      x_min: -7,
      x_max: 690,
      o: 'm 296 357 l 203 357 l 203 0 l 121 0 l 121 841 l -7 841 l -7 910 l 121 910 l 121 1042 l 203 1042 l 203 910 l 408 910 l 408 841 l 203 841 l 203 427 l 288 427 l 562 734 l 661 734 l 363 399 l 690 0 l 593 0 l 296 357 z ',
    },
    Ұ: {
      ha: 831,
      x_min: 16,
      x_max: 812,
      o: 'm 621 365 l 456 365 l 456 0 l 372 0 l 372 365 l 206 365 l 206 434 l 336 434 l 16 987 l 115 987 l 414 449 l 716 987 l 812 987 l 492 434 l 621 434 l 621 365 z ',
    },
    ұ: {
      ha: 680,
      x_min: 30,
      x_max: 653,
      o: 'm 549 -3 l 382 -3 l 382 -281 l 301 -281 l 301 -3 l 134 -3 l 134 66 l 283 66 l 30 734 l 114 734 l 328 162 l 342 102 l 359 162 l 569 734 l 653 734 l 399 66 l 549 66 l 549 -3 z ',
    },
    Ӿ: {
      ha: 850,
      x_min: 37,
      x_max: 813,
      o: 'm 632 472 l 495 472 l 813 0 l 713 0 l 425 431 l 136 0 l 37 0 l 355 472 l 217 472 l 217 541 l 347 541 l 47 987 l 147 987 l 425 570 l 703 987 l 803 987 l 503 541 l 632 541 l 632 472 z ',
    },
    ӿ: {
      ha: 675,
      x_min: 33,
      x_max: 642,
      o: 'm 548 342 l 404 342 l 642 0 l 546 0 l 337 311 l 128 0 l 33 0 l 269 342 l 133 342 l 133 411 l 266 411 l 43 734 l 138 734 l 336 436 l 534 734 l 631 734 l 408 411 l 548 411 l 548 342 z ',
    },
    ԑ: {
      ha: 755,
      x_min: 73,
      x_max: 678,
      o: 'm 73 201 q 225 376 73 337 q 122 443 158 400 q 85 538 85 486 q 162 692 85 637 q 373 747 239 747 q 582 687 498 747 q 666 536 666 627 l 585 536 q 524 638 585 597 q 373 678 463 678 q 223 642 278 678 q 168 540 168 605 q 376 408 168 408 l 496 408 l 496 340 l 366 340 q 209 307 262 340 q 155 200 155 273 q 214 95 155 134 q 373 55 273 55 q 533 99 470 55 q 597 210 597 143 l 678 210 q 592 47 678 109 q 373 -14 507 -14 q 155 45 238 -14 q 73 201 73 104 z ',
    },
    '₣': {
      ha: 781,
      x_min: 17,
      x_max: 736,
      o: 'm 669 461 l 208 461 l 208 0 l 125 0 l 125 987 l 736 987 l 736 916 l 208 916 l 208 532 l 669 532 l 669 461 m 432 196 l 17 196 l 17 265 l 432 265 l 432 196 z ',
    },
    '―': {
      ha: 1072,
      x_min: 85,
      x_max: 991,
      o: 'm 991 465 l 85 465 l 85 534 l 991 534 l 991 465 z ',
    },
    Ὅ: {
      ha: 987,
      x_min: -258,
      x_max: 902,
      o: 'm 902 440 q 855 201 902 304 q 720 42 808 98 q 518 -14 633 -14 q 239 110 345 -14 q 132 444 132 234 l 132 546 q 180 785 132 681 q 315 945 228 889 q 517 1001 403 1001 q 718 946 631 1001 q 852 791 805 891 q 902 558 900 690 l 902 440 m 819 547 q 738 827 819 726 q 517 928 657 928 q 297 826 379 928 q 216 543 216 725 l 216 440 q 297 162 216 264 q 518 59 378 59 q 739 160 660 59 q 819 443 819 262 l 819 547 m -86 793 l 5 1009 l 92 1009 l 92 995 l -38 785 l -86 785 l -86 793 m -258 1009 l -182 1009 l -182 943 q -118 810 -180 859 l -151 777 q -230 846 -202 801 q -258 945 -258 890 l -258 1009 z ',
    },
  },
  familyName: 'Roboto Light',
  ascender: 1289,
  descender: -339,
  underlinePosition: -102,
  underlineThickness: 68,
  boundingBox: { yMin: -376, xMin: -1020, yMax: 1467, xMax: 1580 },
  resolution: 1000,
  original_font_information: {
    format: 0,
    copyright: 'Copyright 2011 Google Inc. All Rights Reserved.',
    fontFamily: 'Roboto Light',
    fontSubfamily: 'Regular',
    uniqueID: 'Roboto Light',
    fullName: 'Roboto Light',
    version: 'Version 2.137; 2017',
    postScriptName: 'Roboto-Light',
    trademark: 'Roboto is a trademark of Google.',
    designer: 'Google',
    manufacturerURL: 'Google.com',
    designerURL: 'Christian Robertson',
    licence: 'Licensed under the Apache License, Version 2.0',
    licenceURL: 'http://www.apache.org/licenses/LICENSE-2.0',
    preferredFamily: 'Roboto',
    preferredSubfamily: 'Light',
  },
  cssFontWeight: 'normal',
  cssFontStyle: 'normal',
}
