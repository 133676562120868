import * as Three from 'three'
import { Dispatch, SetStateAction, useRef } from 'react'
import {
  CameraControls,
  ContactShadows,
  Environment,
  OrthographicCamera,
  Text3D,
  TransformControls,
} from '@react-three/drei'
import CamControlsThree from 'camera-controls'
import { Canvas, useLoader, useThree, extend } from '@react-three/fiber'
import { MutableRefObject, useEffect, useState } from 'react'
import { Box3, MeshStandardMaterial, Vector3, VSMShadowMap } from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import roboto from 'lib/roboto_light_regular'
import { Sphere } from 'lib/sphere'
import { isTouchDevice } from 'lib/commons'
import { ModelType, editorTypes, modelTypes } from 'lib/constants'
import { Coordinate } from 'lib/utils'
import { Disk } from 'lib/disk'
import { GeometryState, GeometryStateType, WebsiteState } from 'lib/state'

interface CameraUpdaterProps {
  cameraControlRef: MutableRefObject<CameraControls | null>
  cameraRef: MutableRefObject<Three.OrthographicCamera | undefined>
  meshObject: Three.Mesh[]
  modelBeingUpdated: boolean
  socketType: ModelType
  boundingBox: {
    boxHelper: Three.BoxHelper
    minVector: Vector3
    maxVector: Vector3
  }[]
  zRotationMultiplier: number
  setZRotationMultiplier: Dispatch<SetStateAction<number>>
  gizmoControlsBeingUsed: boolean
  selectedObjectIndex: number
  exportedObjects: GeometryStateType[]
  modelType: ModelType
}
//TODO remove the any type
function CameraRotator({
  cameraControlRef,
  cameraRef,
  meshObject,
  modelBeingUpdated,
  socketType,
  boundingBox,
  zRotationMultiplier,
  setZRotationMultiplier,
  gizmoControlsBeingUsed,
  selectedObjectIndex,
  exportedObjects,
  modelType,
}: CameraUpdaterProps) {
  const t = useThree()

  const getTargetVectorAndPositionBoundingBox = () => {
    const lampshadeObjectIndex = t.scene.children.findIndex(
      (object) => object.name == 'lampshade' + selectedObjectIndex
    )
    const lampShadeObject = t.scene.children[lampshadeObjectIndex]

    const boundingBoxObjectIndex = t.scene.children.findIndex(
      (object) => object.name == 'boundingBoxGroup' + selectedObjectIndex
    )
    const boundingBoxObject = t.scene.children[boundingBoxObjectIndex]

    if (socketType.modelOrientationAngle) {
      boundingBoxObject.rotation.set(0, 0, Math.PI)
      boundingBoxObject.position.set(
        0,
        boundingBox[selectedObjectIndex].minVector.y,
        0
      )
    } else if (exportedObjects[selectedObjectIndex].flipped) {
      boundingBoxObject.rotation.set(0, 0, 0)
      boundingBoxObject.position.set(
        0,
        -1 * boundingBox[selectedObjectIndex].minVector.y,
        0
      )
    } else {
      boundingBoxObject.rotation.set(0, 0, 0)
      boundingBoxObject.position.set(0, 0, 0)
    }

    const bBox = new Box3()
    bBox.setFromObject(lampShadeObject)
    const centerVector = new Vector3(0, 0, 0)

    bBox.getCenter(centerVector)
    return { centerVector, bBox }
  }

  useEffect(() => {
    if (t.scene) {
      const yAxis = new Vector3(0, 1, 0)

      const upAxis = yAxis.applyAxisAngle(
        new Vector3(1, 0, 0),
        socketType.modelOrientationAngle
      )

      upAxis.applyAxisAngle(
        new Vector3(0, 0, 1),
        -1 * (Math.PI / 2) * zRotationMultiplier
      )

      cameraControlRef.current!.camera.up.set(upAxis.x, upAxis.y, upAxis.z)
      cameraControlRef.current!.updateCameraUp()
      cameraControlRef.current!.zoomTo(modelType.cameraPosition.zoom)
    }
  }, [socketType, zRotationMultiplier])

  useEffect(() => {
    const { centerVector, bBox } = getTargetVectorAndPositionBoundingBox()
    const padding = 10
    cameraControlRef.current!.setTarget(
      centerVector.x,
      centerVector.y,
      centerVector.z,
      true
    )

    // cameraControlRef.current!.setPosition(
    //   centerVector.x,
    //   centerVector.y,
    //   200,
    //   true
    // )

    // cameraControlRef.current!.fitToBox(bBox, true, {
    //   paddingTop: padding,
    //   paddingRight: padding,
    //   paddingLeft: padding,
    //   paddingBottom: padding,
    // })
  }, [meshObject, modelBeingUpdated, selectedObjectIndex])

  return <></>
}

interface LampViewerProps {
  geometryState: GeometryState
  websiteState: WebsiteState
  canvasRef: MutableRefObject<HTMLCanvasElement | null>
  socketColor: string
}

const mouseStateNoRotate = {
  left: CamControlsThree.ACTION.NONE,
  right: CamControlsThree.ACTION.TRUCK,
  middle: CamControlsThree.ACTION.ZOOM,
  wheel: CamControlsThree.ACTION.ZOOM,
}

const mouseStateRotate = {
  left: CamControlsThree.ACTION.ROTATE,
  right: CamControlsThree.ACTION.TRUCK,
  middle: CamControlsThree.ACTION.ZOOM,
  wheel: CamControlsThree.ACTION.ZOOM,
}

const LampViewer = ({
  geometryState,
  websiteState,
  canvasRef,
  socketColor,
}: LampViewerProps) => {
  const cameraControlRef: MutableRefObject<CameraControls | null> = useRef(null)
  const cameraRef: MutableRefObject<Three.OrthographicCamera | undefined> =
    useRef(undefined)

  function LampLoader(props: any) {
    const result: any = useLoader(GLTFLoader, '/static/BothLamps.gltf')

    let newMaterial = new MeshStandardMaterial({
      color: parseInt(props.socketColor, 16),
      roughness: 0.5,
    })

    const scene = result.scene.clone()
    scene.children[0].material = newMaterial
    scene.children[0].scale.set(0.11, 0.1, 0.11)

    scene.children[1].material = newMaterial

    scene.children.forEach((child: any, index: number) => {
      index == props.index ? (child.visible = true) : (child.visible = false)
    })
    return <primitive name='lampModel' object={scene} />
  }

  //TODO change the any type. This is just for getting the website up sooner
  function BulbLoader(props: any) {
    const result = useLoader(GLTFLoader, '/static/Bulb.gltf')
    const scene = result.scene.clone()

    if (props.socketType == modelTypes.leroyMerlin.id)
      scene.children[0].position.set(0, -6.3, 0)
    else scene.children[0].position.set(0, 0, 0)

    // const bulbPosition = scene.children[0].position
    return (
      <>
        <primitive name='bulb' object={scene} />
      </>
    )
  }

  const [gizmoControlsBeingUsed, setGizmoControlsBeingUsed] = useState(false)

  const [scene, setScene] = [websiteState.scene, websiteState.setScene]

  const addSphereOrDisk = (event: any) => {
    if (!websiteState.lampViewerEditable) return

    if (geometryState.spline.modelType.id == modelTypes.bubble.id) {
      const point = event.point
      point.applyAxisAngle(
        new Vector3(0, 0, 1),
        ((-1 * Math.PI) / 2) * websiteState.zRotationMultiplier
      )

      const v =
        (-1 * point.y) /
        (websiteState.boundingBox[0].maxVector.y -
          websiteState.boundingBox[0].minVector.y)
      const vector1 = new Vector3(point.x, 0, point.z)
      const unitVectorX = new Vector3(0, 0, 1)

      let u = vector1.angleTo(unitVectorX)

      if (point.x > 0) u = 2 * Math.PI - u

      u = u / (2 * Math.PI)

      const lipWidthRatio =
        30 /
        geometryState.spline.curve.getPhysicalLength(
          geometryState.spline.modelType.editorType
        )
      if (v < 1 - lipWidthRatio && v > lipWidthRatio)
        geometryState.setSpheres((prev) => [
          ...prev,
          new Sphere(u, v, (Math.random() * 4 + 2) * 10),
        ])
    } else if (geometryState.spline.modelType.id == modelTypes.candle.id) {
      const point = event.point
      point.applyAxisAngle(
        new Vector3(0, 0, 1),
        ((-1 * Math.PI) / 2) * websiteState.zRotationMultiplier
      )

      const disks = [...geometryState.disks]
      const pointVector = new Vector3(point.x, 0, point.z)
      const physicalPointVector =
        Coordinate.convertViewerCoordinatesToPhysical(pointVector)

      const origin = new Vector3(0, 0, 0)
      const viewerCentersOfDisks = disks.map((disk) => disk.getViewerCenter())

      const pointOfMinimumDistance = viewerCentersOfDisks.reduce(
        (minValue, center, index) => {
          const origin = new Vector3(0, 0, 0)
          const distance = pointVector.distanceTo(center)

          if (distance < minValue.distance) {
            return { distance, index }
          } else return minValue
        },
        { distance: pointVector.distanceTo(origin), index: -1 }
      )

      const closestCenter =
        pointOfMinimumDistance.index == -1
          ? origin
          : disks[pointOfMinimumDistance.index].getPhysicalCenter()
      // const closestCenter = new Vector3(0, 0, 0);

      const radius =
        geometryState.spline.curve.getPhysicalPoint(1, editorTypes.candle).x / 2

      const uValueForPoint = Coordinate.getUValueFromPoint(
        physicalPointVector,
        closestCenter
      )
      const pointFromUValue = Coordinate.getPointFromUvalue(
        uValueForPoint,
        radius * 1.9,
        closestCenter
      )

      const newDisk = new Disk(pointFromUValue.x, pointFromUValue.y, radius)
      geometryState.setDisks([...disks, newDisk])
    } else {
      return 0
    }
  }

  const deleteSphere = (delIndex: number) => {
    let newSpheres = [...geometryState.spheresRef.current].filter(
      (_, index) => index != delIndex
    )

    geometryState.setSpheres(newSpheres)
  }

  const deleteCylinder = () => {
    if (geometryState.splineRef.current.modelType.id == modelTypes.candle.id) {
      let newSpheres = [...geometryState.spheresRef.current]

      newSpheres.pop()

      geometryState.setSpheres(newSpheres)
    }
  }

  //TODO remove all any types for events from the file. This is only a placeholder
  const keyDownDeleteFunction = (event: any) => {
    if (
      event.key === 'Delete' &&
      geometryState.splineRef.current.modelType.id == modelTypes.bubble.id
    )
      deleteSphere(websiteState.selectedSphereRef.current)
    else if (
      event.key === 'Delete' &&
      geometryState.splineRef.current.modelType.id == modelTypes.candle.id
    )
      deleteCylinder()
  }

  useEffect(() => {
    document.addEventListener('keydown', keyDownDeleteFunction, false)
  }, [])

  return (
    <>
      {geometryState.spline != null && websiteState.hasMounted && (
        <Canvas
          orthographic
          camera={{ zoom: 1, position: [0, 0, 100] }}
          shadows
          ref={canvasRef}
          onCreated={({ gl, camera, scene }) => {
            setScene(scene)
            cameraRef.current = camera as Three.OrthographicCamera
          }}
          onClick={(e) => {
            websiteState.setSelectedSphere(-1)
          }}
        >
          <ambientLight intensity={0.3} />

          <CameraControls
            ref={cameraControlRef}
            camera={cameraRef.current}
            mouseButtons={
              gizmoControlsBeingUsed ? mouseStateNoRotate : mouseStateRotate
            }
            maxPolarAngle={2 * Math.PI}
          />

          {geometryState.modelType.id == modelTypes.leroyMerlin.id && (
            <LampLoader index={0} socketColor={socketColor} />
          )}

          {(geometryState.modelType.id == modelTypes.e27.id ||
            geometryState.modelType.id == modelTypes.bigLamp.id) && (
            <LampLoader index={1} socketColor={socketColor} />
          )}

          {(geometryState.modelType.id == modelTypes.e27.id ||
            geometryState.modelType.id == modelTypes.leroyMerlin.id) && (
            <BulbLoader socketType={geometryState.modelType.id} />
          )}

          <Environment
            near={1}
            far={1000}
            resolution={256}
            files='/static/img/empty_warehouse_01_1k.hdr'
          />

          {
            //This is here for debugging purposes only. To have an object at (0,0,0)
            // <>
            //   <mesh>
            //     <sphereBufferGeometry />
            //     <meshStandardMaterial color='hotpink' />
            //   </mesh>
            //   <mesh>
            //     <sphereBufferGeometry position={[0, 0, 1]} />
            //     <meshStandardMaterial color='hotpink' />
            //   </mesh>
            // </>
          }

          {websiteState.meshObject && (
            <mesh
              onClick={(e) => {
                e.nativeEvent.stopPropagation()
                e.stopPropagation()
                addSphereOrDisk(e)
              }}
              onContextMenu={(e) => {
                e.nativeEvent.stopPropagation()
                e.stopPropagation()
                deleteCylinder()
              }}
              geometry={websiteState.meshObject[0].geometry}
              material={websiteState.meshObject[0].material}
              receiveShadow
              castShadow
              name={'lampshade0'}
              position={[0, 0, 0]}
            />
          )}

          {websiteState.exportedObjects[1] && websiteState.meshObject && (
            <mesh
              onClick={(e) => {
                e.nativeEvent.stopPropagation()
                e.stopPropagation()
                addSphereOrDisk(e)
              }}
              onContextMenu={(e) => {
                e.nativeEvent.stopPropagation()
                e.stopPropagation()
                deleteCylinder()
              }}
              geometry={websiteState.meshObject[1].geometry}
              material={websiteState.meshObject[1].material}
              rotation={[
                0,
                0,
                websiteState.exportedObjects[1].flipped ? Math.PI : 0,
              ]}
              receiveShadow
              castShadow
              name={'lampshade1'}
              position={[0, 0, 0]}
              visible={geometryState.modelType.id === modelTypes.stacker.id}
            />
          )}

          {!websiteState.modelBeingUpdated &&
            geometryState.spline.modelType.id == modelTypes.bubble.id &&
            geometryState.spheres.map((sphere, index) => {
              return (
                <mesh
                  geometry={sphere.getViewerGeometry(geometryState.spline)}
                  material={Sphere.getViewerMaterial(
                    index == websiteState.selectedSphere
                  )}
                  onClick={(e) => {
                    e.nativeEvent.stopPropagation()
                    e.stopPropagation()
                    websiteState.setSelectedSphere(index)
                  }}
                  onContextMenu={(e) => {
                    e.nativeEvent.stopPropagation()
                    e.stopPropagation()
                    deleteSphere(index)
                  }}
                  key={index}
                ></mesh>
              )
            })}

          {!websiteState.modelBeingUpdated && websiteState.boundingBox && (
            <ContactShadows
              blur={2}
              opacity={0.5}
              width={
                websiteState.boundingBox[0].maxVector.x -
                websiteState.boundingBox[0].minVector.x
              }
              height={
                websiteState.boundingBox[0].maxVector.z -
                websiteState.boundingBox[0].minVector.z
              }
              position={[0, websiteState.boundingBox[0].minVector.y - 1, 0]}
              far={50}
              color='#000000'
            />
          )}
          <group name={'boundingBoxGroup0'}>
            {websiteState.boundingBox && websiteState.infoMode ? (
              <primitive
                name={'boundingBox'}
                object={websiteState.boundingBox[0].boxHelper}
                position={[0, 0, 0]}
              />
            ) : (
              <></>
            )}
            {websiteState.boundingBox &&
              websiteState.infoMode &&
              (['x', 'y', 'z'] as ('x' | 'y' | 'z')[]).map((axis) => {
                const size: number =
                  websiteState.boundingBox[0].maxVector[axis] -
                  websiteState.boundingBox[0].minVector[axis]
                let position = new Vector3(0, 0, 0)
                const midPoint =
                  websiteState.boundingBox[0].minVector[axis] + size / 2
                switch (axis) {
                  case 'x':
                    position = websiteState.boundingBox[0].minVector.clone()
                    position.x = midPoint
                    position.y = websiteState.boundingBox[0].maxVector.y + 1
                    break
                  case 'y':
                    position = websiteState.boundingBox[0].maxVector.clone()
                    position.y = midPoint
                    break
                  case 'z':
                    position = websiteState.boundingBox[0].maxVector.clone()

                    position.z = midPoint
                    break
                }
                return (
                  <Text3D
                    key={axis}
                    font={roboto as any} //this was changed to any type because there seems to be a problem with ThreeJS' expected font
                    position={[position.x, position.y, position.z]}
                    size={0.8}
                  >
                    {size.toFixed(2) + 'cm'}
                    <meshLambertMaterial attach='material' color={'silver'} />
                  </Text3D>
                )
              })}
          </group>

          {websiteState.exportedObjects[1] && (
            <group
              name={'boundingBoxGroup1'}
              position={[
                0,
                websiteState.exportedObjects[1].flipped
                  ? -1 * websiteState.boundingBox[1].minVector.y
                  : 0,
                0,
              ]}
              visible={geometryState.modelType.id === modelTypes.stacker.id}
            >
              {websiteState.boundingBox && websiteState.infoMode ? (
                <primitive
                  name={'boundingBox'}
                  object={websiteState.boundingBox[1].boxHelper}
                  position={[0, 0, 0]}
                />
              ) : (
                <></>
              )}
              {websiteState.boundingBox &&
                websiteState.infoMode &&
                (['x', 'y', 'z'] as ('x' | 'y' | 'z')[]).map((axis) => {
                  const size: number =
                    websiteState.boundingBox[1].maxVector[axis] -
                    websiteState.boundingBox[1].minVector[axis]
                  let position = new Vector3(0, 0, 0)
                  const midPoint =
                    websiteState.boundingBox[1].minVector[axis] + size / 2
                  switch (axis) {
                    case 'x':
                      position = websiteState.boundingBox[1].minVector.clone()
                      position.x = midPoint
                      position.y = websiteState.boundingBox[1].maxVector.y + 1
                      break
                    case 'y':
                      position = websiteState.boundingBox[1].maxVector.clone()
                      position.y = midPoint
                      break
                    case 'z':
                      position = websiteState.boundingBox[1].maxVector.clone()

                      position.z = midPoint
                      break
                  }
                  return (
                    <Text3D
                      key={axis}
                      font={roboto as any} //this was changed to any type because there seems to be a problem with ThreeJS' expected font
                      position={[position.x, position.y, position.z]}
                      size={0.8}
                    >
                      {size.toFixed(2) + 'cm'}
                      <meshLambertMaterial attach='material' color={'silver'} />
                    </Text3D>
                  )
                })}
            </group>
          )}

          {websiteState.boundingBox[0] && (
            <CameraRotator
              selectedObjectIndex={websiteState.selectedObjectIndex}
              exportedObjects={websiteState.exportedObjects}
              cameraRef={cameraRef}
              cameraControlRef={cameraControlRef}
              meshObject={websiteState.meshObject}
              modelBeingUpdated={websiteState.modelBeingUpdated}
              socketType={geometryState.modelType}
              boundingBox={websiteState.boundingBox}
              zRotationMultiplier={websiteState.zRotationMultiplier}
              setZRotationMultiplier={websiteState.setZRotationMultiplier}
              gizmoControlsBeingUsed={gizmoControlsBeingUsed}
              modelType={geometryState.modelType}
            />
          )}
        </Canvas>
      )}
    </>
  )
}

export default LampViewer
